import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
import LeftCaret from '../../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../../assets/Icons/Slider/rightIcon.svg';

const SliderThinkVastu = () => {
  const eowData = [
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/7.png',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/8.png',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/9.png',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/10.png',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/11.png',
    },
  ];
  const swipperSliderCustomeStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box
      marginTop={['0', '0px', '0px', '60px', '80px', '80px']}
      marginBottom={['0', '0', '0', '100px', '150px', '150px']}
      paddingTop={['80px', '80px', '80px', '85px', '115px', '115px']}
      paddingBottom={['80px', '80px', '80px', '85px', '115px', '115px']}
      _hover={{ cursor: `url(${arrowImage}), auto` }}
      background={['#F5F5F5F5']}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        display={['block', 'block', 'block', 'none', 'none', 'none']}
        padding={['0']}
      >
        {/* Hide This Section For Design But It Will showing If Need */}
        <Flex
          justifyContent={['space-between']}
          paddingTop={['20px']}
          width={['90%']}
          display={['none', 'none', 'none', 'none', 'none', 'none']}
          margin={['0 auto']}
          paddingBottom={['31px']}
        >
          <Text
            fontSize={['14px']}
            color={['#333']}
            fontFamily="var(--garnett-regular)"
          >
            {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
          </Text>
          <Box display={['flex']}>
            <Box onClick={() => goPrev()} mr={'20px'}>
              <Image src={LeftCaret} cursor={'pointer'} />
            </Box>
            <Box onClick={() => goNext()}>
              <Image src={RightCaret} cursor={'pointer'} />
            </Box>
          </Box>
        </Flex>
      </Container>
      <Swiper
        ref={swiperRef}
        observeParents={true}
        observer={true}
        modules={[Navigation, FreeMode]}
        speed={750}
        slidesPerView={2.7}
        spaceBetween={24}
        style={swipperSliderCustomeStyle}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
            freeMode: false,
          },
          480: {
            slidesPerView: 2.6,
            spaceBetween: 20,
            freeMode: false,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
        }}
        onSlideChange={swiper => handleSlideChange(swiper)}
      >
        {eowData.map((data, index) => (
          <SwiperSlide key={index}>
            <Box>
              <Image
                src={data.image}
                w={'100%'}
                minH={['180px', '200px', '220px', '318px', '318px', '318px']}
                height={['180px', '200px', '220px', '318px', '400px', '470px']}
                objectFit={'cover'}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default SliderThinkVastu;
