import React from 'react'
import {Box} from '@chakra-ui/react'
import HeroBanner from './HeroBanner'
import OurRating from '../Reusable/OurRatings';
import OurWork from '../Reusable/ClientSaysSlider';
import GrowthJourney from '../Reusable/GrowthJourneyEXP'
import LeadingCtc from './OurLeadingCTA'
import Counter from '../Reusable/CaseStudy/Counter'
import Stories from '../Home/Stories';
import ClientSaysLoveSlider from '../Home/CleintSaysSliderUse';
import ClientsLogo from '../Home/ClientsLogo';
import CuratedService from './CuratedServices'
import OurWorksUse from '../Reusable/OurWorksNew'
import ContactForm from '../Home/ContactForm';
import LetsWorkTogetherMarqueeUse from '../Reusable/Marquees/LetsWorkToGether'
import CommenFaqReuse from './CommenFaqUse'
import OurProcessUse from './OurProcessUse';
import TechnologyMarqueeUse from './TechnologyMarqueeUse';
import CompanyLogoMarquee from '../Reusable/LogoMarquee';
import AdvancedTechUse from './AdvancedTechUse'
import GreatXPoints from './GreatXPoints'
import TextWithImgCollTwo from './TextWithImageUse'
const ServiceXYindustry = () => {
  return (
    <Box>
      <HeroBanner />
      <CompanyLogoMarquee />
      <GreatXPoints />
      <OurRating />
      <CuratedService />
      <OurWorksUse />
      <GrowthJourney />
      <OurWork />
      <TextWithImgCollTwo/>
      <LeadingCtc />
      <AdvancedTechUse />
      <TechnologyMarqueeUse />
      <Counter />
      <OurProcessUse />
      <CommenFaqReuse />
      <ClientsLogo />
      <ClientSaysLoveSlider />
      <Stories />
      <LetsWorkTogetherMarqueeUse />
      <ContactForm />
    </Box>
  );
}

export default ServiceXYindustry
