import { Box, Heading, Image, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Custome.css';

import logo1 from '../../assets/Reusable/OurRatingsLogo/1.svg';
import logo2 from '../../assets/Reusable/OurRatingsLogo/2.svg';
import logo3 from '../../assets/Reusable/OurRatingsLogo/3.svg';
import logo4 from '../../assets/Reusable/OurRatingsLogo/4.svg';

const OurRatings = ({ SecbgColor }) => {
  const logos = [
    { id: 1, logo: logo1 },
    { id: 2, logo: logo2 },
    { id: 3, logo: logo3 },
    { id: 4, logo: logo4 },
  ];

  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');

  return (
    <Box background={SecbgColor}>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={'0 auto'}
        background={'#F5F5F5'}
        padding={[
          '34px 0 58px',
          '34px 0 58px',
          '34px 0 58px',
          '60px 0',
          '60px 0',
          '60px 0',
        ]}
      >
        {/* Our Ratings Heading */}
        <Box textAlign={'center'}>
          <Heading
            as={'h2'}
            color={'#333'}
            fontSize={['24px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular);'}
          >
            Our Ratings
          </Heading>
        </Box>
        {/* Slider */}
        <Box maxW={['827px']} margin={['0 auto']} padding={['0px 20px']}>
          {!isLargerThan992 ? (
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
              loop={true} // Enable infinite scrolling
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                420: {
                  slidesPerView: 1,
                },
                662: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 4,
                },
              }}
              speed={1000} // Adjust the speed of the transition
              spaceBetween={20} // Adjust space between slides
              className="OurRatingSlider"
            >
              {/* SliderItems */}
              {logos.map(item => (
                <SwiperSlide key={item.id}>
                  <Image
                    src={item.logo}
                    objectFit={'none'}
                    alt="Company Logo"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            // Grid Wrapper
            <Box
              display={['grid']}
              gridTemplateColumns={['repeat(4,1fr)']}
              gap={['64px']}
            >
              {/* Grid Item */}
              {logos.map(item => (
                <Box key={item.id} display={'flex'} alignItems={'end'}>
                  <Image
                    src={item.logo}
                    width={'auto'}
                    height={'auto'}
                    objectFit={'cover'}
                    alt="Company Logo"
                  />
                </Box>
              ))}
            </Box>
          )}
          {/* SliderWrapper */}
        </Box>
      </Box>
    </Box>
  );
};

export default OurRatings;
