import { Box, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Desktop from '../../assets/BgPatterns/ToolsAndTech/Desktop.gif';
import Mobile from '../../assets/BgPatterns/ToolsAndTech/Mobile.gif';

const Banner = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/tools-and-technologies?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  return (
    <Box
      minH={['450px', '450px', '450px', '520px', '520px', '520px']}
      bg={'#0D0E4D'}
      padding={[
        '95px 0 210px',
        '95px 0 210px',
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
      ]}
      overflow={'hidden'}
      display={'flex'}
      alignItems={'end'}
      position={'relative'}
      ref={heroSectionRef}
      id="hero-section"
    >
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'block', 'block', 'block', 'block', 'block']}
          src={Desktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'none', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Text Part */}

        <Box maxW={['396px', '396px', '440px', '519px', '679px', '679px']}>
          <Text
            as="h1"
            fontSize={['36px', '36px', '39px', '44px', '54px', '58px']}
            color={'#fff'}
            fontFamily={'var(--garnett-semibold)'}
            fontWeight={'600'}
            pb={'22px'}
            lineHeight={[
              '50.15px',
              '50.15px',
              '50.15px',
              '80.79px',
              '80.79px',
              '80.79px',
            ]}
          >
            {firstItem?.main_heading}
          </Text>
          <Text
            as="p"
            fontSize={['14px', '14px', '14px', '23px', '23px', '23px']}
            color={'#fff'}
            fontFamily={'var(--foundersGrotesk-regular)'}
            fontWeight={'400'}
            maxW={'580px'}
            lineHeight={[
              '16.8px',
              '16.8px',
              '16.8px',
              '27.6px',
              '27.6px',
              '27.6px',
            ]}
            letterSpacing={['-2.2%']}
          >
            {firstItem?.main_description}
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
