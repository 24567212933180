import { Box, Image } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import DesktopImg from '../../assets/Resources/textwithimg/bannerDesktop.webp';
import MobileImg from '../../assets/Resources/textwithimg/bannerMobile.webp';
import Breadcrumb from './breadcrum';

const Banner = () => {
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  return (
    // Main Wrapper
    <Box
      backgroundColor={'#0D0E4D'}
      padding={['60px 0']}
      ref={heroSectionRef}
      id="hero-section"
    >
      {/* Main Container */}
      <Box maxW={['92%']} margin={'0 auto'}>
        {/* BreadCrum */}
        <Breadcrumb />
      </Box>
      {/* Main banner content */}
      <Box maxW={['96%']} marginLeft={'auto'}>
        <Image
          display={['none', 'none', 'block']}
          src={DesktopImg}
          w={['100%']}
          h={['100%']}
          objectFit={'cover'}
          alt="desktoImage"
        />
        <Image
          display={['block', 'block', 'none']}
          w={['100%']}
          h={['100%']}
          objectFit={'cover'}
          src={MobileImg}
          alt="MobileImage"
        />
      </Box>
    </Box>
  );
};

export default Banner;
