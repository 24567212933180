import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
import LeftCaret from '../../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../../assets/Icons/Slider/rightIcon.svg';

const PainPointDiscoverdSlider = () => {
  const ppdData = [
    {
      heading: 'Limited Identity Impedes Growth',
      paragraph:
        "The brand's underdeveloped identity restricts its global expansion and recognition.",
    },
    {
      heading: 'Blending with Competitors',
      paragraph:
        'A non-distinct identity places IROS at risk of being indistinguishable from competitors.',
    },
    {
      heading: 'Weak Brand Recognition',
      paragraph:
        'Inconsistent messaging and lack of impactful visuals lead to weak brand recognition & recall.',
    },
    {
      heading: 'Marketing Adaptation Struggles',
      paragraph:
        'IROS faces challenges adapting its marketing to diverse stakeholder perceptions.',
    },
    {
      heading: 'Difficulty Standing Out',
      paragraph:
        'IROS struggles to differentiate itself in a competitive market.',
    },
    {
      heading: 'Unclear Brand Values',
      paragraph:
        'Communicating the core values and strengths of the IROS brand is challenging.',
    },
    {
      heading: 'Inconsistent Brand Image',
      paragraph:
        'The brand experiences inconsistency across various customer interaction points.',
    },
  ];
  const swipperSliderCustomeStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };

  return (
    <Box>
      <Box
        marginTop={['40px', '40px', '60px', '80px', '80px']}
        paddingTop={['27px', '27px', '27px', '72px', '72px', '72px']}
        paddingBottom={['47px', '47px', '47px', '90px', '90px', '90px']}
        _hover={{ cursor: `url(${arrowImage}), auto` }}
        background={['#F5F5F5F5']}
      >
        {/* This Title show only for Desktop */}
        <Box display={['none', 'none', 'none', 'block']}>
          <Text
            maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
            m={'0 auto'}
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#333333']}
            fontWeight={['500']}
            paddingBottom={['60px']}
            lineHeight={'normal'}
          >
            PAIN POINTS DISCOVERED
          </Text>
        </Box>

        {/* Next And Prev Button On slider */}
        <Box
          maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
          m={'0 auto'}
          display={['block', 'block', 'block', 'none', 'none', 'none']}
          padding={['0']}
        >
          <Flex
            justifyContent={['space-between']}
            width={['90%']}
            margin={['0 auto']}
            paddingBottom={['46px']}
          >
            <Text
              fontSize={['14px']}
              color={['#333']}
              fontFamily="var(--garnett-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text>
            <Box display={['flex']}>
              <Box onClick={() => goPrev()} mr={'20px'}>
                <Image src={LeftCaret} cursor={'pointer'} />
              </Box>
              <Box onClick={() => goNext()}>
                <Image src={RightCaret} cursor={'pointer'} />
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Slider */}
        <Swiper
          ref={swiperRef}
          observeParents={true}
          autoplay={true}
          speed={750}
          slidesPerView={'auto'}
          spaceBetween={20}
          style={swipperSliderCustomeStyle}
          className="sliderpainPoint"
          onSlideChange={swiper => handleSlideChange(swiper)}
        >
          {ppdData.map((data, index) => (
            <SwiperSlide key={index}>
              <Box
                padding={['26px 30px 75px']}
                style={{
                  background: index % 2 === 0 ? '#003247' : '#00D2D2',
                  borderRadius: '6px',
                }}
                width={[
                  '216px!important',
                  '216px!important',
                  '216px!important',
                  '336px!important',
                ]}
                height={['224px', '224px', '224px', '346px', '346px', '346px']}
                display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                {/* Title */}
                <Heading
                  as={'h3'}
                  fontSize={['18px', '18px', '18px', '32px']}
                  fontWeight={'600'}
                  lineHeight={'normal'}
                  color={'#fff'}
                  fontFamily={'var(--garnett-semibold);'}
                  paddingBottom={['30px', '30px', '30px', '40px', '40px']}
                >
                  {data.heading}
                </Heading>
                {/* Paragraph */}
                <Text
                  as={'p'}
                  style={{
                    color: index % 2 === 0 ? '#5CE1E1' : '#000',
                  }}
                  fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                >
                  {data.paragraph}
                </Text>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
export default PainPointDiscoverdSlider;
