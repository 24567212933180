import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import HeroSection from './DetailPage/HeroSection';
import SolutionParagraph from './DetailPage/SolutionParagraph';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import StrategiesSection from './DetailPage/StrategiesSection';
import IndustrySolution from './DetailPage/IndustrySolution';
import RelatedCaseStudies from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import WorkTogether from '../Reusable/LetsWorkToGether';
import lwt1 from '../../assets/Reusable/LetsWorkToGether/purple1.svg';
import lwt2 from '../../assets/Reusable/LetsWorkToGether/purple2.svg';
const Industry = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>

      <Box>
        <SolutionParagraph />
      </Box>

      <Box>
        <LogoMarqueeReuse />
      </Box>

      <Box>
        <StrategiesSection />
      </Box>

      <Box>
        <IndustrySolution />
      </Box>

      {/* <Box>
                <ClientAboutUs />
            </Box> */}

      <Box>
        <RelatedCaseStudies />
      </Box>

      <Box>
        <WorkTogether bgColor="#30054A" hand1Img={lwt1} hand2Img={lwt2} />
      </Box>
    </>
  );
};

export default Industry;
