import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import bannerGif from '../../assets/BgPatterns/Partners/banner.gif';
import img from '../../assets/BgPatterns/UnderConstruction/Img.png';
import './Custom.css';

const Construction = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isThankYouOpen,
    onOpen: onThankYouOpen,
    onClose: onThankYouClose,
  } = useDisclosure();

  const heading = {
    fontFamily: 'var(--slickRegular)',
    color: '#fff',
    fontSize: ['32px', '32px', '54px', '48px', '48px', '48px'],
    lineHeight: 'normal',
    fontWeight: '400',
    paddingBottom: ['24px', '24px', '24px', '50px', '50px', '50px'],
  };

  const paragraph = {
    color: '#fff',
    fontSize: ['18px', '18px', '30px', '24px', '24px', '24px'],
    lineHeight: 'normal',
    fontWeight: '400',
    fontFamily: 'var(--foundersGrotesk-regular);',
  };

  const btns = {
    color: '#fff',
    lineHeight: 'normal',
    fontWeight: '400',
    fontSize: ['20px'],
    fontFamily: 'var(--foundersGrotesk-regular);',
    display: 'inline-flex',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['100%', '100%', '100%', '204px', '204px', '204px'],
    padding: '6px 14px 0 14px',
  };

  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleEmailChange = e => setEmail(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();

    // Validate the form
    if (!email) {
      toast({
        title: 'Please enter your email address',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    // Check if the email already exists
    try {
      const existingEmailsResponse = await fetch(
        'https://cms.wearetenet.com/api/launching-soons?populate=*'
      );
      const existingEmailsData = await existingEmailsResponse.json();

      const emailExists = existingEmailsData.data.some(
        entry => entry.attributes.email === email
      );

      if (emailExists) {
        // Show message box for duplicate email
        setEmailExists(true);
        return;
      }

      // Email does not exist, proceed with submission
      const formData = {
        data: {
          email,
        },
      };

      const response = await fetch(
        'https://cms.wearetenet.com/api/launching-soons',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer 8979fca7f4441861ed50a2a4afc1acfd819a5d2055c8682566dc7518ec29cb082b8ae7202644a8f350f5368e4d35207bf05cb0256e98a7fb9467f0a2749bff434d650b7dd3ed1714abef6b49734878b0eb9276b052cc31ceecbbe7dd510e3102efa2e14ef0cc7326ebb2743ddd5c1457c8b815db1626d7a09c1c42dccf199216',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setEmailExists(false);
        setEmail('');
        onClose();
        onThankYouOpen();
      } else {
        const errorData = await response.json();
        console.error('Error:', response.status, errorData);
        toast({
          title: 'Submission Failed',
          description:
            'There was a problem with your submission. Please try again.',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Network Error',
        description:
          'There was a problem connecting to the server. Please try again later.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const [emailExists, setEmailExists] = useState(false);

  const handleModalClose = () => {
    setEmail('');
    onClose(); // Close the modal
    setEmailExists(false); // Reset emailExists state
  };

  return (
    <Box overflow={'hidden'} position={'relative'}>
      <Box
        height={['auto', 'auto', 'auto', '100vh', '100vh', '100vh']}
        display={['flex']}
        alignItems={['center']}
        className="heightSection"
      >
        {/* Banner */}
        <Box
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        >
          <Image
            src={bannerGif}
            objectFit={['cover', 'cover', 'cover', 'cover', 'cover', 'cover']}
            w={'100%'}
            h={'100%'}
            position={['relative']}
            top={['0', '0', '0', '0', '0', '25px']}
          />
        </Box>
        {/* <Box style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1 }} display={["block", "block", "none", "none", "none", "none"]}>
          <Image src={bannerGif1} objectFit={['cover', 'cover', 'cover', 'cover', 'cover', 'cover']} w={'100%'} h={'100%'} position={["relative"]} top={["25px"]}/>
        </Box> */}
        <Box maxW={['80%']} margin={['0 auto']} width={['80%']}>
          {/* Row */}
          <Box
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
            flexDirection="row"
            justifyContent={'space-between'}
            padding={[
              '40px 0 50px',
              '40px 0 50px',
              '80px 0 50px',
              '80px 0 50px',
              '80px 0 50px',
              '80px 0 50px',
            ]}
            gap={['60px', '60px', '60px', '36px', '36px', '36px']}
            className="displaySection"
          >
            {/* Text Column */}
            <Box
              maxW={['100%', '100%', '100%', '60%', '60%', '60%']}
              display={['flex']}
              alignItems={'center'}
              className="widthPart"
            >
              <Box>
                {/* Texts And Headings */}
                <Box maxW={['100%']}>
                  <Heading as={'h2'} sx={heading} className="headingText">
                    Welcome, adventurous visitor!
                  </Heading>
                  {/* Paragaraph 1 */}
                  <Text
                    as={'p'}
                    sx={paragraph}
                    paddingBottom={['20px', '20px', '20px', '40px', '40px']}
                    className="headingParagraph"
                  >
                    We're upgrading our website to enhance your experience.
                  </Text>
                  {/* Pargaph 2 */}
                  <Text
                    as={'p'}
                    sx={paragraph}
                    paddingBottom={['40px']}
                    className="headingParagraph"
                  >
                    Some pages might be temporarily unavailable while we work
                    our magic. Click the button below to explore our site—some
                    areas are still under construction, but we promise it'll be
                    worth the adventure!
                  </Text>
                </Box>
                {/* Buttons */}
                {/* Btn Group */}
                <Box
                  display={['flex']}
                  flexWrap={'wrap'}
                  gap={['18px']}
                  className="paddingPart"
                  paddingBottom={['60px', '60px', '60px', '0']}
                >
                  {/* Proceed To Website */}
                  <Box>
                    <Link
                      href="/main"
                      sx={btns}
                      backgroundColor={'#00763D'}
                      className="buttonWidth"
                    >
                      Proceed to website
                    </Link>
                  </Box>
                  {/* Modal Button For Popup */}
                  <Box>
                    <Link
                      onClick={onOpen}
                      sx={btns}
                      border={'1px'}
                      borderColor={'#00763D'}
                      backgroundColor={'#111'}
                      className="buttonWidth"
                    >
                      Notify me
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Image Column */}
            <Box
              w={['100%', '100%', '100%', '40%', '40%', '40%']}
              className="imgSec"
            >
              <Image
                src={img}
                w={'100%'}
                height={'auto'}
                className="imageSection"
                alt="under construction img"
              />
            </Box>
          </Box>
        </Box>

        {/* Modal */}
        <Modal isOpen={isOpen} onClose={handleModalClose}>
          <ModalOverlay />
          {/* Email Content */}
          <Box>
            <ModalContent
              padding={[
                '56px 10px 38px',
                '56px 10px 38px',
                '56px 10px 38px',
                '56px 10px 44px',
                '56px 10px 44px',
                '56px 10px 44px',
              ]}
              borderRadius={'0'}
              maxWidth={['90%', '90%', '600px', '600px', '600px', '600px']}
            >
              <Box maxWidth={['337px']} margin={'0 auto'}>
                {/* Headings */}
                <Box textAlign={'center'}>
                  <ModalHeader
                    lineHeight={'normal'}
                    fontWeight={'400'}
                    fontSize={['40px', '40px', '40px', '48px', '48px']}
                    color={'#111'}
                    fontFamily={'var(--garnett-regular);'}
                    padding={'0'}
                  >
                    Stay Updated!
                  </ModalHeader>
                  <Text
                    as={'p'}
                    lineHeight={'normal'}
                    fontWeight={'400'}
                    fontSize={['14px', '14px', '14px', '20px', '20px']}
                    color={'#111'}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    padding={[
                      '14px 0 40px',
                      '14px 0 40px',
                      '14px 0 40px',
                      '26px 0 66px',
                      '26px 0 66px',
                      '26px 0 66px',
                    ]}
                    maxW={['246px', '246px', '246px', '100%']}
                  >
                    Join our notification list to receive the latest updates
                    straight to your inbox!
                  </Text>
                </Box>
                <ModalCloseButton />
              </Box>
              <Box maxW={['80%']} margin={'0 auto'}>
                <ModalBody padding={['0']}>
                  {/* Input For SignupEmail */}
                  <Box
                    display={'flex'}
                    height={['30px', '30px', '30px', '46px', '46px', '46px']}
                    gap={'1px'}
                    maxW={[
                      '298px',
                      '298px',
                      '466px',
                      '466px',
                      '466px',
                      '466px',
                    ]}
                    margin={'0 auto'}
                    as="form"
                    onSubmit={handleSubmit}
                  >
                    <Input
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={handleEmailChange}
                      backgroundColor={'#0D0E4D'}
                      lineHeight={['27px']}
                      fontWeight={'400'}
                      color={'#fff'}
                      fontSize={[
                        '16px',
                        '16px',
                        '16px',
                        '25px',
                        '25px',
                        '25px',
                      ]}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      borderRadius={'0'}
                      border={'2px'}
                      borderColor={'#0D0E4D'}
                      paddingTop={['8px']}
                      height={['30px', '30px', '30px', '46px', '46px', '46px']}
                      _placeholder={{
                        color: '#fff',
                        fontSize: [
                          '16px',
                          '16px',
                          '16px',
                          '25px',
                          '25px',
                          '25px',
                        ],
                      }}
                    />
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      backgroundColor={'#0D0E4D'}
                      lineHeight={['27px']}
                      fontWeight={'400'}
                      color={'#fff'}
                      fontSize={[
                        '16px',
                        '16px',
                        '16px',
                        '25px',
                        '25px',
                        '25px',
                      ]}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      borderRadius={'0'}
                      height={['30px', '30px', '30px', '46px', '46px', '46px']}
                      border={'0'}
                      paddingTop={['7px']}
                      className="secondButton"
                      width={['200px']}
                      _hover={{ backgroundColor: 'black' }}
                    >
                      Notify Me
                    </Button>
                  </Box>
                  {/* Show Error iF HAVE Allready Exists your Email In DataBase */}
                  <Box
                    paddingTop={['12px', '12px', '12px', '18px', '18px']}
                    maxW={[
                      '298px',
                      '298px',
                      '466px',
                      '466px',
                      '466px',
                      '466px',
                    ]}
                    margin={'0 auto'}
                    display={emailExists ? 'block' : 'none'}
                  >
                    <Text
                      as={'p'}
                      color={'#E0005E'}
                      lineHeight={'normal'}
                      fontWeight={'400'}
                      fontSize={[
                        '12px',
                        '12px',
                        '12px',
                        '16px',
                        '16px',
                        '16px',
                      ]}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      textAlign={'center'}
                    >
                      ** It looks like you've already signed up for our
                      notifications with this email.
                    </Text>
                  </Box>
                </ModalBody>
              </Box>
            </ModalContent>
          </Box>
        </Modal>
        {/* Thank You */}
        <Modal isOpen={isThankYouOpen} onClose={onThankYouClose}>
          <ModalOverlay />
          {/* ThankYou Content */}
          <Box>
            <ModalContent
              padding={[
                '40px 10px 30px',
                '40px 10px 30px',
                '40px 10px 30px',
                '60px 10px 85px',
                '60px 10px 85px',
                '60px 10px 85px',
              ]}
              borderRadius={'0'}
              maxWidth={['90%', '90%', '600px', '600px', '600px', '600px']}
            >
              <Box
                maxWidth={[
                  '360px',
                  '360px',
                  '400px',
                  '500px',
                  '500px',
                  '500px',
                ]}
                margin={'0 auto'}
              >
                <Box textAlign={'center'}>
                  <ModalHeader
                    lineHeight={'normal'}
                    fontWeight={'400'}
                    fontSize={['40px', '40px', '40px', '48px', '48px']}
                    color={'#111'}
                    fontFamily={'var(--garnett-regular);'}
                    padding={'0'}
                  >
                    Thank you
                  </ModalHeader>
                  <Text
                    as={'p'}
                    lineHeight={'normal'}
                    fontWeight={'400'}
                    fontSize={['16px', '16px', '16px', '18px', '18px']}
                    color={'#111'}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    padding={[
                      '14px 0 0px',
                      '14px 0 0px',
                      '14px 0 0px',
                      '26px 0 0px',
                      '26px 0 0px',
                      '26px 0 0px',
                    ]}
                    maxW={['246px', '246px', '246px', '100%']}
                  >
                    You're all set! We've added you to our notification list, so
                    you'll be the first to know about our latest updates,
                    offers, and news. Stay tuned for exciting things coming your
                    way!
                  </Text>
                </Box>
                <ModalCloseButton />
              </Box>
            </ModalContent>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Construction;
