import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const OurLeading = () => {
  const title = {
    color: '#fff',
    fontFamily: [
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-semibold)',
      'var(--garnett-semibold)',
    ],
    fontSize: ['20px', '28px', '28px', '28px', '38px', '42px'],
    fontWeight: ['400', '400', '400', '600', '600', '600'],
    lineHeight: 'normal',
    textAlign: ['center', 'center', 'center', 'center', 'left'],
  };

  const paragraph = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#fff',
    fontSize: ['14px', '16px', '20px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['43px', '43px', '43px', '43px', '18px', '18px'],
    paddingTop: ['40px', '40px', '40px', '40px', '18px', '18px'],
    textAlign: ['center', 'center', 'center', 'center', 'left'],
    maxW: ['317px', '100%'],
    margin: '0 auto',
  };

  const linkDesign = {
    backgroundColor: '#7610B5',
    fontFamily: 'var(--foundersGrotesk-regular)',
    display: 'inline-flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    height: '41px',
    padding: '0px 12px',
  };

  return (
    // Container
    <Box
      maxW={['100%', '100%', '100%', '80%', '80%']}
      margin={['0 auto']}
      marginBottom={['0', '0', '150px', '150px', '150px', '150px']}
    >
      <Box
        bg={'#30054A'}
        padding={[
          '40px 0 33px',
          '40px 0 33px',
          '40px 0 33px',
          '40px 0 33px',
          '66px 0 80px',
          '66px 0 80px',
        ]}
      >
        <Box maxW={['86%']} margin={['0 auto']}>
          {/* Row */}
          <Box
            display={['block', 'block', 'block', 'block', 'flex', 'flex']}
            justifyContent={['space-between']}
          >
            {/* Col 1 title */}
            <Box
              maxW={['561px', '561px', '561px', '561px', '550px', '561px']}
              margin={['0 auto', '0 auto', '0 auto', '0 auto', '0']}
            >
              <Heading as={'h2'} sx={title}>
                Engage with Our Leading User Experience Specialists
              </Heading>
            </Box>
            {/* Col 2 text with button */}
            <Box
              maxWidth={['350px']}
              margin={['0 auto', '0 auto', '0 auto', '0 auto', '0']}
              textAlign={['center', 'center', 'center', 'center', 'left']}
            >
              {/* Paragraph */}
              <Text as={'p'} sx={paragraph}>
                Elevate your service with our exceptional user experience
                expertise. Reach out to our specialists to enhance your digital
                solutions today.
              </Text>
              <Link to="#" style={linkDesign}>
                Get in Touch
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OurLeading;
