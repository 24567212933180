import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const approachContent = () => {
  const backgroundText = [
    {
      title:
        'Our journey with Pazazz began with brand discovery workshops and a deep dive into market analysis. We aimed to uncover their unique story and brand attributes, as well as the latest trends and consumer demands in the athleisure segment. We pinpointed key challenges in brand differentiation, user engagement, and digital presence. Armed with these insights, we crafted a strategy to build a distinctive brand identity and a dynamic digital ecosystem tailored to their target audience.',
      description:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
      description2:
        "We start every project by diving in our client’s world. What they want, what they need, and what’s their vision.<br><br>We then move on to the other side, the audience. What user’s intend to do, what they would want more, and what are they currently experiencing with other players in the game.<br><br>We kicked off the development phase by defining user roles to ensure personalized fitness experiences.<br><br>We designed a scalable backend infrastructure and efficient database architecture. Planning for essential third-party integrations was a key step to enhance the end product.<br><br>We also developed and integrated the required modules to enable e-commerce capabilities in the mobile application and website. With everything in place, we mapped out development sprints using Agile best practices to keep the process smooth and iterative. This approach allowed us to deliver a robust, user-friendly fitness platform tailored to our users' needs.",
    },
  ];
  return (
    <Box>
      <Container
        width={['100%', '100%', '100%', '100%', '100%', '100%']}
        maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        padding={['0']}
      >
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
          src="https://d3us4ynq1qsik6.cloudfront.net/work/strongr/3.webp"
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          margin={['0 auto']}
        />
        <Image
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
          src="https://d3us4ynq1qsik6.cloudfront.net/work/strongr/6.webp"
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          margin={['0 auto']}
        />

        <Box
          maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
          margin={['0 auto']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        >
          <Box
            display={['block', 'block', 'block', 'flex']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={['space-between']}
            alignItems={['baseline']}
            gap={['10%']}
            margin={['0 auto']}
            paddingTop={['16px', '18px', '20px', '26px', '30px', '32px']}
            marginBottom={['40px', '40px', '40px', '80px', '80px', '80px']}
          >
            <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
              {' '}
              <Text
                as="h1"
                fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-medium)"
                color={['#860038']}
                fontWeight={['500']}
                lineHeight={'normal'}
              >
                APPROACH
              </Text>
            </Box>

            <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
              {' '}
              <Text
                as="p"
                fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                fontWeight={'400'}
                lineHeight={'normal'}
                dangerouslySetInnerHTML={{
                  __html: backgroundText[0].description2.replace(
                    /<br\s*\/?>/gi,
                    '<br>'
                  ),
                }}
              ></Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default approachContent;
