import { Box } from '@chakra-ui/react';
import React from 'react';

import Counter from '../Reusable/CaseStudy/Counter';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import HeroSection from './DetailPage/HeroSection';
import ApproachContent from './DetailPage/approachContent';
import BackgroundContent from './DetailPage/backgroundContent';
import Slider from './DetailPage/slider';
import SolutionContent from './DetailPage/solutionContent';
import TechnologyContent from './DetailPage/technologyContent';

import LetsWorkTogether from './DetailPage/LetsWorkToGether';

const CaseStudy = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>

      <Box>
        <BackgroundContent />
      </Box>
      <Box>
        <ApproachContent />
      </Box>

      <Box>
        <Slider />
      </Box>

      <Box>
        <SolutionContent />
      </Box>

      <Box>
        <TechnologyContent />
      </Box>

      {/* Same */}
      <Box>
        <Counter
          customMarginTop={['80px', '80px', '80px', '100px', '120px', '150px']}
        />
      </Box>

      <Box>
        <ExplorwOtherWorkSlider />
      </Box>

      <Box>
        <LetsWorkTogether />
      </Box>
    </>
  );
};

export default CaseStudy;
