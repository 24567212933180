import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Center, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import BannerImage2 from '../../assets/ContactUs/Banner/banner.webp';
import ContactForm from './ContactForm';

const Banner = () => {
    return (
        <Box 
            overflow={'hidden'} 
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']} 
            alignItems={'start'} 
            position={'relative'}
        >
            <Box bg={'#000'} w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                <Box 
                    w={["90%", "90%", "100%", "100%", "100%", "100%"]} 
                    ml={'auto'} 
                    margin={"0 auto"} 
                    paddingTop={["20px", "20px", "20px", "50px", "50px", "50px"]} 
                    paddingLeft={[0, 0, "10%", "10%", "10%", "10%"]}
                >
                    <Breadcrumb 
                        spacing="8px" 
                        separator=">" 
                        color={["#ffffff"]} 
                        alignItems={["baseline"]} 
                        display={["none", "none", "none", "block", "block", "block"]}
                    >
                        <BreadcrumbItem alignItems={["baseline"]}>
                            <BreadcrumbLink 
                                href="/" 
                                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']} 
                                color={["#ffffff"]} 
                                fontFamily='var(--foundersGrotesk-light)' 
                                fontWeight={"400"}
                            >
                                <Center>Home</Center>
                            </BreadcrumbLink>
                        </BreadcrumbItem>

                        <BreadcrumbItem alignItems={["baseline"]}>
                            <BreadcrumbLink 
                                href="#" 
                                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']} 
                                color={["#ffffff"]} 
                                fontFamily='var(--foundersGrotesk-light)' 
                                fontWeight={"400"}
                            >
                                <Center>Contact us</Center>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>

                    {/* Text Part */}
                    <Box paddingTop={["60px", "60px", "60px", "80px", "80px", "80px"]}>
                        <Text 
                            as='h1' 
                            css={{
                                fontSize: ['35px', '35px', '35px', '58px', '58px', '58px'],
                                color: '#fff',
                                fontFamily: 'var(--garnett-semibold)',
                                fontWeight: '600',
                            }}
                        >
                            Drop us a line. We’re great listeners.
                        </Text>
                        <Text 
                            as='p' 
                            css={{
                                fontSize: ['14px', '14px', '14px', '23px', '23px', '23px'],
                                color: '#fff',
                                fontFamily: 'var(--foundersGrotesk-regular)',
                                fontWeight: '400',
                                padding: ['17px 0 28px', '17px 0 28px', '17px 0 28px', '17px 0 28px', '17px 0 28px'],
                                maxWidth: '511px'
                            }}
                        >
                            We’d love to hear about your brand, your visions, current challenges. And don’t worry - no pitch, no commitment, and no strings attached from our side.
                        </Text>
                    </Box>

                    <Link
                        to="#"
                        css={{
                            fontSize: '20px',
                            color: '#fff',
                            fontWeight: '400',
                            fontFamily: 'var(--foundersGrotesk-regular)',
                            padding: '9px 43px 4px',
                            display: 'inline-block',
                            minWidth: '55px',
                            backgroundColor: '#9B4DFF',
                            marginRight: '13px',
                            transition: 'transform 0.5s ease',
                            '&:hover': {
                                transform: 'translate3d(0, -3px, 0)',
                            }
                        }}
                        className='contactFirst'
                    >
                        Business
                    </Link>

                    <Link
                        to="#"
                        css={{
                            fontSize: '20px',
                            color: '#fff',
                            fontWeight: '400',
                            fontFamily: 'var(--foundersGrotesk-regular)',
                            padding: '9px 43px 4px',
                            display: 'inline-block',
                            minWidth: '55px',
                            border: '1px solid',
                            borderColor: '#fff',
                            transition: 'transform 0.5s ease',
                            '&:hover': {
                                transform: 'translate3d(0, -3px, 0)',
                            }
                        }}
                        className='contactSecond'
                    >
                        Careers
                    </Link>

                    <Box 
                        display={'flex'} 
                        justifyContent={'end'} 
                        mt={'80px'} 
                        className='contactClass'
                    >
                        <Image 
                            src={BannerImage2} 
                            height={'100%'} 
                            objectFit={'cover'} 
                            position={["relative"]} 
                            top={"1px"}
                        />
                    </Box>
                </Box>
            </Box>

            <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                <ContactForm />
            </Box>
        </Box>
    )
}

export default Banner;
