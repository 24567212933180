import React from 'react';
import TextWithImg from '../Reusable/DetailPageSolutionAndIndustry/TextWithImg';

const TextWithImgUse = () => {
  return (
    <div>
      <TextWithImg />
    </div>
  );
};

export default TextWithImgUse;
