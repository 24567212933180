import {
  Box,
  chakra,
  Container,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import './Custom.css';

const BusinessTab = () => {
  const { slug } = useParams();
  const location = useLocation(); // Use location to extract region from the URL
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        let region = pathParts[1]; 

        if (!['us', 'in', 'ae'].includes(region)) {
          region = 'global';
        }

        const response = await axios.get(
          `https://cms.wearetenet.com/api/homepages?filters[region][$eq]=${region}&populate=*`
        );

        if (response.data.data.length > 0) {
          setData(response.data.data[0].attributes);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug, location]);

  const getDescriptionText = descriptions => {
    if (!descriptions || !Array.isArray(descriptions)) {
      return [];
    }
    return descriptions.map(desc =>
      desc.children.map(child => child.text).join(' ')
    );
  };

  const getImageUrl = imageData => {
    return imageData?.data?.attributes?.url || '';
  };

  const tabData = [
    {
      title: data.strategy_heading,
      img: getImageUrl(data.strategy_image),
      descriptions: [
        data.strategy_description_1,
        ...getDescriptionText(data.strategy_description_2 || []),
      ],
    },
    {
      title: data.design_1_heading,
      img: getImageUrl(data.design_1_image),
      descriptions: [
        data.design_1_description_1,
        ...getDescriptionText(data.design_1_description_2 || []),
      ],
    },
    {
      title: data.technology_heading,
      img: getImageUrl(data.technology_image),
      descriptions: [
        data.technology_description_1,
        ...getDescriptionText(data.technology_description_2 || []),
      ],
    },
    {
      title: data.growth_1_heading,
      img: getImageUrl(data.growth_1_image),
      descriptions: [
        data.growth_1_description_1,
        ...getDescriptionText(data.growht_1_description_2 || []),
      ],
    },
  ];

  //   {
  //     title: 'Adaptive Strategies for Unpredictable Markets',
  //     img: img, // Replace with the actual image variable
  //     descriptions: [
  //       "It's about envisioning and executing adaptive strategies that anticipate and mold the future.",
  //       'Our strategic approach ensures every investment is directly linked to measurable outcomes, blending insight with innovation to navigate complexities.',
  //       'By understanding your objectives at a granular level, we tailor strategies that not only meet but exceed your goals, ensuring efficient allocation of resources for maximum impact and success.',
  //     ],
  //   },
  //   {
  //     title: 'Design That Connects and Converts',
  //     img: img1, // Replace with the actual image variable
  //     descriptions: [
  //       'Every pixel and process at Tenet is infused with the philosophy of intentional design.',
  //       'Our design philosophy merges creativity with practicality, ensuring every visual, UI, and UX aspect we craft connects with your audience and strengthens your brand identity.',
  //       'We utilize the latest trends and technologies to provide solutions that are visually impressive, easy to use, and in line with your business objectives.',
  //     ],
  //   },
  //   {
  //     title: 'Innovative Tech that Drives Business',
  //     img: img2, // Replace with the actual image variable
  //     descriptions: [
  //       'We leverage this power to advance your business.',
  //       'Our technology services cover web and app development, software engineering, and system integration, focusing on scalable, secure solutions that adapt to market changes.',
  //       'We stay ahead in technological advancements, equipping your business with necessary tools to excel in a competitive environment and simplifying complex solutions for easy management.',
  //     ],
  //   },
  //   {
  //     title: 'Fueling Growth with Measurable Impact',
  //     img: img4, // Replace with the actual image variable
  //     descriptions: [
  //       "Tenet's growth services aim for sustainable progress, blending data-driven marketing, SEO, conversion optimization, and customer engagement to build a loyal customer base.",
  //       'Our holistic approach ensures consistent, efficient growth across channels. We collaborate with you to spot growth opportunities, optimize marketing investments, and boost ROI, helping your business not only grow but flourish.',
  //     ],
  //   },
  // ];

  return (
    <Container
      paddingTop={['80px', '80px', '80px', '100px', '120px', '150px']}
      width={['100%']}
      maxWidth={['100%']}
      paddingLeft={'0'}
      paddingRight={'0'}
    >
      <Box margin={['0 auto']}>
        <Text
          as="h2"
          fontSize={['32px', '32px', '32px', '40px', '44px', '48px']}
          color="#000000"
          textAlign={'center'}
          fontFamily="var(--garnett-regular)"
          fontWeight="400"
          lineHeight={['normal']}
        >
          <chakra.span
            fontFamily="var(--garnett-semibold)"
            color="#0057FF"
            style={{
              WebkitFontSmoothing: 'antialiased',
              textRendering: 'optimizeLegibility',
            }}
          >
            Accelerating
          </chakra.span>
          &nbsp;your business through
        </Text>
      </Box>
      <Tabs backgroundColor="white" marginTop={['40px']}>
        <Flex justify="center">
          <TabList css={{ borderBottom: 'none' }}>
            {['Strategy', 'Design', 'Technology', 'Growth'].map(
              (tab, index) => (
                <Tab
                  key={index}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  fontWeight={'400'}
                  paddingLeft={['10px', '18px', '35px', '70px']}
                  paddingRight={['10px', '18px', '35px', '70px']}
                  _selected={{ bg: '#0057FF', color: 'white' }}
                  colorScheme="blue"
                  variant="solid"
                >
                  {tab}
                </Tab>
              )
            )}
          </TabList>
        </Flex>

        {/* Pannel */}
        <TabPanels>
          {tabData.map((tab, index) => (
            <TabPanel key={index} padding={'0'}>
              <Box
                display={['flex']}
                flexWrap={['wrap']}
                justifyContent={['center', 'center', 'center', 'flex-end']}
                gap={['20px', '20px', '15px', '15px']}
                paddingLeft={['10px', '18px', '35px', '70px', '70px', '70px']}
                paddingRight={['10px', '18px', '35px', '70px', '70px', '70px']}
                backgroundColor={'#F5F5F5'}
                width={['100%', '90%', '83%']}
                margin={['0 auto']}
                className="customTab"
              >
                <Box
                  display={['flex']}
                  flexDirection={['column', 'column', 'row']}
                  width={['100%']}
                  maxWidth={['100%']}
                  gap={['40px', '40px', '15px', '85px', '85px', '85px']}
                  marginBottom={['0', '0', '68px', '48px', '65px', '65px']}
                  marginTop={['0', '0', '68px', '48px', '65px', '65px']}
                >
                  <Box flex="1">
                    <Flex
                      alignItems={['flex-start']}
                      gap={['25px', '25px', '25px', '30px']}
                      flexDirection={['column']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${tab.img || ''}`}
                        alt={`Project 0${index + 1}`}
                        width={'100%'}
                        loading="lazy"
                        fetchpriority="low"
                      />
                    </Flex>
                  </Box>
                  <Box
                    flex="1"
                    marginBottom={['15px', '15px', '25px', '35px']}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Flex alignItems="center" gap={['25px']}>
                      <Text
                        as="p"
                        fontSize={[
                          '24px',
                          '24px',
                          '24px',
                          '28px',
                          '30px',
                          '36px',
                        ]}
                        fontFamily="var(--garnett-regular)"
                        fontWeight="400"
                        color="#333333"
                        marginBottom={[
                          '15px',
                          '15px',
                          '18px',
                          '20px',
                          '22px',
                          '25px',
                        ]}
                        lineHeight={['normal']}
                        width={['100%', '100%', '100%', '80%', '80%', '80%']}
                      >
                        {tab.title}
                      </Text>
                    </Flex>
                    {tab.descriptions.map((desc, descIndex) => (
                      <Text
                        key={descIndex}
                        as="p"
                        fontSize={
                          descIndex === 0
                            ? ['16px', '18px', '18px', '20px', '24px', '24px']
                            : ['16px', '16px', '16px', '16px', '20px', '20px']
                        }
                        fontFamily={
                          descIndex === 0
                            ? 'var(--garnett-regular)'
                            : 'var(--foundersGrotesk-regular);'
                        }
                        fontWeight="400"
                        color="#333333"
                        marginBottom={[
                          '15px',
                          '15px',
                          '18px',
                          '20px',
                          '22px',
                          '25px',
                        ]}
                        lineHeight={['normal']}
                      >
                        {desc}
                      </Text>
                    ))}
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Container>
  );
};
export default BusinessTab;
