import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OurValues = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/abouts?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;

  // Section Headings
  const heading = {
    fontFamily: 'var(--garnett-regular)',
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontWeight: '400',
    lineHeight: 'normal',
    mb: ['22px', '22px', '22px', '30px', '30px', '30px'],
    color: '#333',
  };
  const paragraph = {
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    fontFamily: ' var(--foundersGrotesk-regular)',
    mb: ['40px', '40px', '40px', '60px', '60px', '60px'],
    lineHeight: 'normal',
    color: '#333',
  };

  // Values Styles
  const valueSubtitle = {
    color: '#000',
    fontSize: ['18px', '18px', '22px', '32px', '36px', '40px'],
    fontFamily: ['var(--garnett-regular)'],
    lineHeight: 'normal',
    fontWeight: ['600', '600', '600', '400', '400', '400'],
    mb: ['14px', '18px', '20px', '30px', '40px', '40px'],
  };
  const valueSubText = {
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };

  const valuesData = [
    {
      heading: firstItem?.point_1_heading,
      description: firstItem?.point_1_description,
      image: firstItem?.point_1_image?.data?.attributes?.url,
    },
    {
      heading: firstItem?.point_2_heading,
      description: firstItem?.point_2_description,
      image: firstItem?.point_2_image?.data?.attributes?.url,
    },
    {
      heading: firstItem?.point_3_heading,
      description: firstItem?.point_3_description,
      image: firstItem?.point_3_image?.data?.attributes?.url,
    },
    {
      heading: firstItem?.point_4_heading,
      description: firstItem?.point_4_description,
      image: firstItem?.point_4_image?.data?.attributes?.url,
    },
  ];

  return (
    <Box marginBottom={['80px', '80px', '80px', '130px', '151px', '151px']}>
      <Container
        maxWidth={['100%', '100%', '100%', '82%', '82%', '82%']}
        m="0 auto"
        padding="0"
      >
        {/* Value Titles And Image */}
        <Box>
          <Box
            maxW={['90%', '90%', '90%', '55%', '55%', '55%']}
            margin={['0 auto', '0 auto', '0 auto', '0', '0', '0']}
          >
            <Text
              as="h2"
              sx={heading}
              textAlign={['center', 'center', 'center', 'left']}
            >
              {firstItem?.values_heading}
            </Text>
            <Text
              as="p"
              sx={paragraph}
              textAlign={['center', 'center', 'center', 'left']}
            >
              {firstItem?.values_description}
            </Text>
          </Box>
          <Box>
            <Image
              src={`https://cms.wearetenet.com${
                firstItem?.values_image?.data?.attributes?.url || ''
              }`}
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
        </Box>

        {/* Values Short Description With Title */}
        <Box
          maxW={['100%', '100%', '100%', '100%', '75%', '75%']}
          ml="auto"
          pt={['23px', '23px', '23px', '60px', '80px', '80px']}
          className="customOurValue"
        >
          <Box
            display={['grid']}
            rowGap={['10px', '10px', '10px', '84px', '84px', '84px']}
            columnGap={['20px', '20px', '20px', '90px', '90px']}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(2,1fr)',
            ]}
            padding={['0px 20px', '0px 20px', '0px 20px', '0']}
          >
            {valuesData.map((value, index) => (
              <Flex
                key={index}
                mb={['12px', '12px', '12px', '0px', '0px', '0px']}
                padding={[
                  '8px 16px 18px',
                  '8px 16px 18px',
                  '8px 16px 18px',
                  '0px',
                  '0px',
                  '0px',
                ]}
                bg={['#F5F5F5', '#F5F5F5', '#F5F5F5', '#fff', '#fff', '#fff']}
              >
                <Box
                  mr={['15px', '15px', '15px', '26px', '26px', '26px']}
                  pt={['5px', '5px', '5px', '10px', '10px', '10px']}
                  minW={['37px']}
                >
                  <Image
                    src={`https://cms.wearetenet.com${value.image || ''}`}
                    w={['32px', '32px', '32px', '37px', '37px', '37px']}
                    h={['32px', '32px', '32px', '37px', '37px', '37px']}
                    objectFit="cover"
                    padding={['4px', '4px', '4px', '0px']}
                    background={['#fff', '#fff', '#fff', 'transparent']}
                  />
                </Box>
                <Box>
                  <Text as="h3" sx={valueSubtitle} marginTop="0">
                    {value.heading}
                  </Text>
                  <Text as="p" sx={valueSubText}>
                    {value.description}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurValues;
