import React from 'react'
import TxtWithImageColTwoReuse from '../Reusable/TextWithImg/TwoColumns'
const TextWithImageUse = () => {
  return (
    <div>
      <TxtWithImageColTwoReuse/>
    </div>
  )
}

export default TextWithImageUse
