import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import TestImg from '../../assets/Career/Testimonials/1.svg';
import coma1 from '../../assets/Career/Testimonials/coma1.svg';
import coma2 from '../../assets/Career/Testimonials/coma2.svg';
import HoverImg from '../../assets/Icons/Slider/whiteAboutJourny/sliderhover.svg';
import axios from 'axios';
import LeftCaret from '../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../assets/Icons/Slider/rightIcon.svg';
const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]); // Initialize testimonials and setTestimonials state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          'https://cms.wearetenet.com/api/career-testimonials/?populate=*'
        );
        setTestimonials(response.data.data); // Update testimonials state
      } catch (err) {
        setError('Failed to fetch testimonials');
      } finally {
        setLoading(false);
      }
    };
    fetchTestimonials();
  }, []);
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const testimnialsTitle = {
    fontSize: ['18px', '26px', '30px', '35px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: ['171px', '271px', '371px', '510px', '510px'],
    color: '#000',
  };
  const testimnialsParagraph = {
    fontSize: ['14px', '16px', '18px', '32px', '32px', '32px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#333',
    padding: '0',
  };
  const testimnialsName = {
    fontSize: ['14px', '14px', '14px', '32px', '32px', '32px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#111',
    paddingBottom: ['2px', '2px', '2px', '4px'],
  };
  const testimnialsDesignation = {
    fontSize: ['14px', '14px', '14px', '24px', '24px', '24px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#111',
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '" style="border-radius: 0; width: 11px; height: 11px; margin: 0px 5px;"></span>'
      );
    },
  };

  // Handle Next and Prev slide actions
  const goNext = () => {
    swiperRef.current.swiper.slideNext();
  };

  const goPrev = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1);
  };

  return (
    <Box
      bg={'#F5F5F5'}
      p={[
        '23px 0 13px',
        '23px 0 13px',
        '23px 0 13px',
        '50px 0 24px',
        '100px 0 24px',
        '100px 0 24px',
      ]}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '77%', '77%', '77%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
      >
        {/* Slider Navigations For Mobile */}
        <Box
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          paddingBottom={['37px']}
        >
          <Flex justifyContent="space-between" display={['flex']}>
            <Text
              fontSize="14px"
              color="#333"
              fontFamily="var(--garnett-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
              //
              visibility={'hidden'}
            >
              {`${currentSlide}/${
                swiperRef.current?.swiper?.slides?.length ?? 4
              }`}
            </Text>
            <Box display="flex">
              <Box onClick={goPrev} mr="20px">
                <Image src={LeftCaret} cursor="pointer" />
              </Box>
              <Box onClick={goNext}>
                <Image src={RightCaret} cursor="pointer" />
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Slider */}
        <Box _hover={{ cursor: `url(${HoverImg}), auto` }}>
          <Swiper
            ref={swiperRef}
            onSlideChange={handleSlideChange}
            observeParents={true}
            observer={true}
            pagination={pagination}
            modules={[Pagination]}
            speed={750}
            slidesPerView={4.2}
            spaceBetween="30"
            breakpoints={{
              0: {
                slidesPerView: 1,

                freeMode: false,
              },
              320: {
                slidesPerView: 1,

                freeMode: false,
              },
              480: {
                slidesPerView: 1,

                freeMode: false,
              },
              768: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 1,
              },
            }}
          >
            {testimonials.map((testimonial, index) => {
              const { name, designation, heading, description, image } =
                testimonial.attributes;
              const imageUrl = image?.data?.attributes?.url;
              return (
                <SwiperSlide style={{ margin: '0' }}>
                  <Flex
                    alignItems={['center', 'center', 'xenter']}
                    mb={['50px', '50px', '50px', '80px', '80px', '80px']}
                    gap={['20px', '20px', '40px', '50px', '100px', '100px']}
                    paddingLeft={['16px', '16px', '32px', '32px']}
                  >
                    {/* Text Part */}
                    <Box w={['60%']}>
                      <Box>
                        <Text as={'h3'} sx={testimnialsTitle}>
                          {heading}
                        </Text>
                        {/* Paragraph */}
                        <Box
                          pt={['27px', '27px', '27px', '68px', '78px', '78px']}
                          pb={['15px', '15px', '15px', '45px', '45px', '45px']}
                          maxW={['400px', '400px', '500px', '597px', '597px']}
                        >
                          {/* ComA 1 */}

                          <Text
                            as={'p'}
                            sx={testimnialsParagraph}
                            _before={{
                              content: '""',
                              position: 'absolute',
                              top: ['-4px', '-4px', '-16px', '-16px'],
                              left: ['-15px', '-15px', '-32px', '-32px'],
                              width: ['11px', '11px', '22px', '32px'], // Set width
                              height: ['7px', '7px', '14px', '20px'], // Set height
                              backgroundImage: `url(${coma1})`,
                              backgroundSize: 'cover', // Adjust as needed
                              backgroundRepeat: 'no-repeat', // Ensure the image does not repeat
                              backgroundPosition: 'left top', // Position the image
                            }}
                            position={'relative'}
                          >
                            {description}
                            <Image
                              width={['11px', '11px', '22px', '32px']}
                              height={['7px', '7px', '14px', '20px']}
                              src={coma2}
                              alt=""
                              display={'inline'}
                            />
                          </Text>
                        </Box>
                        {/* Designation And Title */}
                        <Box>
                          <Text as={'h4'} sx={testimnialsName}>
                            {name}
                          </Text>
                          <Text as={'p'} sx={testimnialsDesignation}>
                            {designation}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    {/* Image Part */}
                    <Box w={['40%']}>
                      <Image
                        src={`https://cms.wearetenet.com${imageUrl}`}
                        w={'100%'}
                        h={'auto'}
                        objectFit={'cover'}
                      />
                    </Box>
                  </Flex>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
