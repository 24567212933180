import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';
import desktop from '../../../assets/CaseStudy/Iros/desktop.webp';
import mobile from '../../../assets/CaseStudy/Iros/mobile.webp';

const backgroundContent = () => {
  return (
    <Box
      paddingTop={['80px', '80px', '80px', '150px', '150px', '150px']}
      paddingBottom={['80px', '80px', '80px', '150px', '150px', '150px']}
    >
      <Container width={['100%']} maxWidth={['100%']} padding={['0']}>
        <Box
          maxW={['100%', '100%', '100%', '90%', '90%', '90%']}
          margin={'0 auto'}
          paddingBottom={['24px', '24px', '24px', '40px', '40px', '40px']}
        >
          {/*  ColTwo  Image */}
          <Box
            background={'#f5f5f5'}
            padding={[
              '20px 20px',
              '20px 20px',
              '20px 20px',
              '50px 40px',
              '50px 40px',
              '50px 40px',
            ]}
          >
            {/* Desktop */}
            <Box
              display={['none', 'block', 'block', 'block', 'block', 'block']}
            >
              <Image width={'100%'} height={'auto'} src={desktop} />
            </Box>
            {/* Mobile */}
            <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
              <Image width={'100%'} height={'auto'} src={mobile} />
            </Box>
          </Box>
        </Box>

        {/* Text */}
        <Box
          maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
          margin={['0 auto']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        >
          <Box
            display={['block', 'block', 'block', 'flex']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={['space-between']}
            alignItems={['baseline']}
            gap={['10%']}
            margin={['0 auto']}
          >
            <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
              <Text
                as="h1"
                fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-medium)"
                color={['#860038']}
                fontWeight={['500']}
                lineHeight={'normal'}
              >
                BACKGROUND
              </Text>
            </Box>
            <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
              <Text
                as="p"
                fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                After proving their mettle in the industry, IROS ws ready to
                expand it’s reach and needed a brand that would expand it’s
                horizons and position it as the go-to choice for their target
                audience.
                <br />
                <br />
                IROS’s messaging and visual identity did not reflect its core
                values and forward-thinking nature. It lacked a clear brand
                strategy and relatable identity for its audience, partners, and
                team. As IROS enters its next growth phase, it needed a cohesive
                brand that embodies its core values and crafts a narrative that
                can resonate with all stakeholders. Also, due to the lack of
                established guidelines the previous brand identity was not
                cohesive and was used inconsistently by internal teams, leading
                to a fractured brand experience externally.
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default backgroundContent;
