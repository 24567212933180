import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BreadCrum = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  return (
    <Breadcrumb
      spacing="8px"
      separator=">"
      color={['#ffffff']}
      alignItems={['baseline']}
      display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
    >
      <BreadcrumbItem alignItems={['baseline']}>
        <BreadcrumbLink
          href="/"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>Home</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem alignItems={['baseline']}>
        <BreadcrumbLink
          href="/work"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>Case Studies</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem
        isCurrentPage
        alignItems={['baseline']}
        className="breadCrumb1"
      >
        <BreadcrumbLink
          href="#"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>{firstItem?.case_study_name}</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default BreadCrum;
