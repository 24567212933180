import React from 'react'
import CommenFaq from '../Reusable/Faq/CommenFaq'
const CommenFaqUse = () => {
  return (
    <div>
      <CommenFaq/>
    </div>
  )
}

export default CommenFaqUse
