import { Box, Heading } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CheckRevenue = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const title = {
    color: '#fff',
    fontFamily: [
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-semibold)',
      'var(--garnett-semibold)',
    ],
    fontSize: ['24px', '24px', '24px', '36px', '36px', '36px'],
    fontWeight: ['400', '400', '400', '600', '600', '600'],
    lineHeight: 'normal',
    paddingBottom: ['38px', '38px', '38px', '43px'],
  };

  const linkDesign = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    display: 'inline-flex',
    justifyContent: 'center',
    fontWeight: '400',
    alignItems: 'center',
    backgroundColor: firstItem?.button_background || '#000',
    color: '#fff',
    fontSize: '20px',
    lineHeight: 'normal',
    height: '42px',
    padding: '4px 12px 0',
    border: '2px solid #fff',
    width: '200px',
  };
  return (
    // Container
    <Box
      maxW={['100%', '100%', '100%', '80%', '80%']}
      margin={['0 auto']}
      marginBottom={['0', '0', '150px', '150px', '150px', '150px']}
    >
      <Box
        bg={firstItem?.background_color}
        padding={[
          '40px 0 43px',
          '40px 0 43px',
          '40px 0 43px',
          '69px 0 69px',
          '69px 0 69px',
          '69px 0 69px',
        ]}
      >
        <Box
          maxW={['100%', '100%', '86%', '86%', '86%', '86%']}
          margin={['0 auto']}
          padding={['0 40px', '0 40px', '0']}
        >
          {/* Row */}
          <Box>
            {/* Col 1 title */}
            <Box
              maxW={['317px', '449px', '669px', '889px', '889px', '889px']}
              margin={['0 auto', '0 auto', '0 auto', '0 auto']}
              textAlign={'center'}
            >
              <Heading as={'h2'} sx={title}>
                {firstItem?.featured_box_heading}
              </Heading>
            </Box>
            {/* Col 2 text with button */}
            <Box
              maxWidth={['350px']}
              margin={['0 auto', '0 auto', '0 auto', '0 auto']}
              textAlign={['center', 'center', 'center', 'center']}
              justifyContent={'center'}
            >
              <Link to={firstItem?.featured_box_button_link} style={linkDesign}>
                {firstItem?.featured_box_button}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckRevenue;
