import { Box, Container, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrum from './BreadCrum';
import './Custom.css';
import Introduction from './introduction';

const HeroSection = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;

  // Split the services string into an array
  const servicesArray = firstItem?.services_provided?.split(',') || [];

  // Function to chunk the array into groups of 2
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Chunk the services array into groups of 2
  const chunkedServices = chunkArray(servicesArray, 2);

  const bannerText = [
    {
      title: 'Modernizing Tradition with Think Vastu’s Expertise',
      description:
        "At Kodeglobe, we blend empathy with expertise to deliver exceptional service and innovative solutions, ensuring your brand thrives in today's dynamic world.",
    },
  ];
  const boxOneID = useBreakpointValue({
    base: 'hero-section',
    md: 'hero-section',
    lg: 'dynamicID',
    xl: 'dynamicID'
  });

  const boxTwoID = useBreakpointValue({
    base: 'dynamicID',
    md: 'dynamicID',
    lg: 'hero-section',
    xl: 'hero-section'
  });
  const styles = {
    title: {
      fontSize: ['32px', '32px', '36px', '54px', '60px', '64px'],
      fontFamily: 'var(--garnett-semibold)',
      color: '#ffffff',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    industryText: {
      fontSize: ['16px', '16px', '16px', '18px', '20px', '20px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      marginBottom: ['6px', '6px', '6px', '8px', '8px', '8px'],
    },
    tagText: {
      fontSize: ['12px', '12px', '12px', '12px', '12px', '12px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      width: 'fit-content',
      paddingTop: '5px',
      paddingBottom: '2px',
      paddingLeft: '8px',
      paddingRight: '8px',
      marginBottom: '7px',
      lineHeight: 'normal',
    },
  };

  return (
    <Box position="relative">
      <Box id={boxOneID}>
        <Box id={boxTwoID}
          position={[
            'relative',
            'relative',
            'relative',
            'absolute',
            'absolute',
            'absolute',
          ]}
          left={'0'}
          right={'0'}
          top={'0'}
          bottom={['0%', '0%', '0%', '43%', '43%', '43%']}
          bg={firstItem?.hex_header_background_color}
          zIndex={'-1'}
        ></Box>
        <Box
          background={[
            firstItem?.hex_header_background_color,
            firstItem?.hex_header_background_color,
            firstItem?.hex_header_background_color,
            'transparent',
            'transparent',
            'transparent',
          ]}
          paddingTop={['95px', '75px', '65px', '56px', '56px', '56px']}
        >
          <Container
            width={['100%', '100%', '100%', '94%', '94%', '94%']}
            maxW={['100%', '100%', '100%', '94%', '94%', '94%']}
            marginBottom={['0px', '0px', 0, 0, 0, 0]}
            padding={['0', '0', '0', '0 20px', '0 20px']}
          >
            <BreadCrum />
            {/* This is a Case Study Banner Title And Tags of Services and Inustry */}
            <Box
              padding={[
                '0px 20px 80px',
                '0px 20px 80px',
                '0px 20px 80px',
                '66px 0 137px',
                '66px 0 137px',
                '66px 0 137px',
              ]}
            >
              <Flex
                direction={['column', 'column', 'row', 'row', 'row', 'row']}
                gap={['40px', '40px', '40px', '0', '0', '0']}
                justifyContent={'space-between'}
              >
                <Box
                  w={['100%', '100%', '70%', '78%', '83%', '83%']}
                  color="#FFFFFF"
                  display="flex"
                  flexDirection="column"
                >
                  {/* Banner Title */}
                  <Box>
                    <Box maxW={['100%', '100%', '100%', '100%', '90%', '90%']}>
                      <Text as="h1" sx={styles.title}>
                        {firstItem?.title}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                {/* Start Tags And Industry Services */}
                <Box w={['290px', '290px']}>
                  <Box
                    marginBottom={[
                      '30px',
                      '30px',
                      '30px',
                      '34px',
                      '34px',
                      '34px',
                    ]}
                  >
                    <Text sx={styles.industryText}>Industry</Text>
                    <Text
                      sx={styles.tagText}
                      border={`${firstItem?.borderWidth || '1px'} ${firstItem?.borderStyle || 'solid'
                        } ${firstItem?.hex_border_color || 'black'}`}
                    >
                      {firstItem?.industry}
                    </Text>
                  </Box>
                  <Box>
                    <Text sx={styles.industryText}>Services Provided</Text>
                    {chunkedServices.map((chunk, index) => (
                      <Flex key={index} gap={['6px']}>
                        {chunk.map((service, i) => (
                          <Text
                            key={i}
                            sx={styles.tagText}
                            border={`${firstItem?.borderWidth || '1px'} ${firstItem?.borderStyle || 'solid'
                              } ${firstItem?.hex_border_color || 'black'}`}
                          >
                            {service}
                          </Text>
                        ))}
                      </Flex>
                    ))}
                  </Box>
                </Box>
                {/* End Tags And Industry Services */}
              </Flex>
            </Box>
            {/* Image Display Only For Mobile Not Desktop tHIS IS A Introduction Image */}
            <Box display={['block', 'block', 'block', 'none', 'none', 'none']}>
              <Image
                src={`https://cms.wearetenet.com${firstItem?.banner_image?.data?.attributes?.url || ''
                  }`}
                width={['100%', '100%', '100%', '72%', '72%', '72%']}
                margin={['0 auto']}
              />
            </Box>
          </Container>
        </Box>
        <Box>
          <Introduction />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
