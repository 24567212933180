import { Box, Container, Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const WhyChoosTenetAsWhiteLabel = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/white-labels?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    pb: ['22px', '22px', '22px', '30px', '30px', '30px'],
    maxW: '686px',
  };
  const sectionParagraph = {
    fontSize: ['14px', '16px', '18px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '864px',
  };
  const valueSubtitle = {
    color: '#000',
    fontSize: ['18px', '20px', '24px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: '20px',
  };
  const valueSubText = {
    fontSize: ['14px', '16px', '18px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };
  const valueBox = {
    bg: ['#E9F0FF', '#E9F0FF', '#E9F0FF', '#FFF', 'FFF', '#FFF'],
    p: [
      '25px 19px 30px 8px',
      '25px 19px 30px 8px',
      '25px 19px 30px 8px',
      '0px',
      '0px',
      '0px',
    ],
    mb: ['18px', '18px', '18px', '0px', '0px', '0px'],
  };

  return (
    <Box
      p={[
        '62px 0',
        '62px 0',
        '62px 0',
        '150px 0 150px',
        '150px 0 150px',
        '150px 0 150px',
      ]}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
      >
        {/* Section Heading */}
        <Box pb={['38px', '38px', '38px', '60px', '60px', '60px']}>
          <Text as={'h2'} sx={sectionH2Heading}>
            {firstItem?.why_heading_1}
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            {firstItem?.why_description_1}
          </Text>
        </Box>

        {/* Why Choose As Our Tenet */}
        <Box>
          <Box
            maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
            ml={['auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
          >
            {/* First Row */}
            <Box
              display={['block', 'block', 'block', 'grid', 'grid', 'grid']}
              rowGap={'50px'}
              columnGap={'100px'}
              gridTemplateColumns={'repeat(2,1fr)'}
            >
              {/*  Value One */}
              <Flex sx={valueBox}>
                <Box>
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.why_point_1_heading}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.why_point_1_description}
                  </Text>
                </Box>
              </Flex>
              {/* Value Two */}
              <Flex sx={valueBox}>
                <Box>
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.why_point_2_heading}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.why_point_2_description}
                  </Text>
                </Box>
              </Flex>
              {/*  Value three */}
              <Flex sx={valueBox}>
                <Box>
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.why_point_3_heading}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.why_point_3_description}
                  </Text>
                </Box>
              </Flex>
              {/* Value Two */}
              <Flex sx={valueBox}>
                <Box>
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.why_point_4_heading}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.why_point_4_description}
                  </Text>
                </Box>
              </Flex>
            </Box>
            {/* Second Row */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyChoosTenetAsWhiteLabel;
