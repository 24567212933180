import { AspectRatio, Box, Heading, Image, Text } from '@chakra-ui/react';
 import image1 from '../../assets/Temp/SericeX/1.svg'
import React from 'react';
 
const AdvanceTechUseInEcoo = () => {
 
  const advancedTechData = [
    {
      Image: image1,
      title: 'AI/ML',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: image1,
      title: 'Metaverse',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: image1,
      title: 'Data Analytics ',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: image1,
      title: 'Cloud Computing',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: image1,
      title: 'Blockchain',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
  ];
  return (
    <Box
      padding={[
        '80px 0 58px',
        '80px 0 58px',
        '80px 0 58px',
        '100px 0 130px',
        '100px 0 130px',
        '100px 0 130px',
      ]}
      background={
        'linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);'
      }
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px 0px']}
      >
        {/* Heading Section */}
        <Box paddingBottom={['63px', '63px', '63px', '92px']}>
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular);'}
            color={'#000'}
            maxW={['792px']}
          >
            Advanced technologies we employ in our Healthcare
          </Heading>
        </Box>
        {/* Grid Points */}
        <Box maxW={['1127px']} ml={'auto'}>
          {/*  */}
          <Box
            display={'grid'}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(2,1fr)',
            ]}
            columnGap={['20px', '20px', '60px', '80px', '105px', '105px']}
            rowGap={['40px']}
          >
            { 
              advancedTechData.map((point, index) => {
                 
                return (
                  <Box
                    key={index}
                    display={['flex']}
                    alignItems={'start'}
                    gap={['40px', '40px', '40px', '17px']}
                  >
                    {/* Image */}
                    <AspectRatio ratio={1} minW={'49px'}>
                      <Image
                        src={point.Image}
                        w={['100%']}
                        height={['100%']}
                        objectFit={'cover'}
                        alt="Hero Image"
                      />
                    </AspectRatio>
                    {/* Title And Paragraphs */}
                    <Box>
                      <Heading
                        as={'h3'}
                        fontSize={[
                          '24px',
                          '24px',
                          '24px',
                          '36px',
                          '36px',
                          '36px',
                        ]}
                        color={'#000'}
                        fontFamily={'var(--garnett-regular);'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        paddingBottom={['20px', '20px', '20px', '30px']}
                      >
                        {point.title}
                      </Heading>
                      <Text
                        as={'p'}
                        fontFamily={'var(--foundersGrotesk-regular);'}
                        fontSize={[
                          '14px',
                          '14px',
                          '14px',
                          '20px',
                          '20px',
                          '20px',
                        ]}
                        color={'#333'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                      >
                        {point.description}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdvanceTechUseInEcoo;
