import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Image,
    Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import minus from '../../assets/Reusable/FAQ/minus.svg';
import plus from '../../assets/Reusable/FAQ/plus.svg';

const FAQ = () => {
    const { slug } = useParams();
    const [data, setData] = useState([]); // Initialize as an empty array
    const [error, setError] = useState(null);
    const [visibleItems, setVisibleItem] = useState(5);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://cms.wearetenet.com/api/industry-solution-faqs?filters[slug][$eq]=${slug}&populate=*`
                );
                if (response.data.data.length > 0) {
                    setData(response.data.data);
                } else {
                    setError('No data found');
                }
            } catch (err) {
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    const handleAccordionClick = index => {
        setActiveIndex(prevIndex => (prevIndex === index ? -1 : index));
    };

    const showMore = () => {
        setVisibleItem(prev => prev + 5);
    };

    // Accordion Styling
    const accordianIndex = {
        fontSize: ['20px', '20px', '20px', '32px', '32px', '32px'],
        fontFamily: 'var(--garnett-regular)',
        fontWeight: '400',
        color: '#333',
        lineHeight: 'normal',
    };
    const accordianTitle = {
        fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
        fontFamily: 'var(--garnett-regular)',
        fontWeight: '400',
        color: '#333',
        lineHeight: 'normal',
    };
    const accordianParagraph = {
        fontFamily: 'var(--foundersGrotesk-regular)',
        fontWeight: '400',
        fontSize: ['16px', '16px', '16px', '18px', '18px', '18px'],
        lineHeight: 'normal',
        ml: ['54px', '54px', '54px', '83px'],
        mt: '24px',
    };
    const accordianItemBox = {
        p: '8px 0',
        borderTop: 'none',
        borderBottom: '1px',
        borderBottomColor: '#B5B5B5',
    };

    const faqData = data.map((item, index) => ({
        index: index + 1, // Auto-incrementing index starting from 1
        title: item.attributes.heading,
        content: item.attributes.description,
    }));

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Box mt={['45px', '45px', '45px', '45px', '65px']}>
            <Accordion allowToggle={false} defaultIndex={[0]} allowMultiple={false}>
                {faqData.slice(0, visibleItems).map((item, index) => (
                    <AccordionItem key={index} sx={accordianItemBox}>
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton
                                        onClick={() => handleAccordionClick(index)}
                                        px={'0'}
                                        _hover={{ backgroundColor: 'transparent!important' }}
                                    >
                                        <Box as="span" flex="1" textAlign="left">
                                            <Flex alignItems={'center'}>
                                                {/* Accordion Index Number */}
                                                <Box
                                                    mr={['24px', '24px', '24px', '53px', '53px']}
                                                    minW={['45px']}
                                                >
                                                    <Text as={'span'} sx={accordianIndex}>
                                                        0{item.index}
                                                    </Text>
                                                </Box>
                                                {/* Accordion Title */}
                                                <Box>
                                                    <Text as={'h3'} sx={accordianTitle}>
                                                        {item.title}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Image
                                            src={isExpanded ? minus : plus}
                                            width={['24px', '24px', '24px', '32px', '32px']}
                                            height={['24px', '24px', '24px', '32px', '32px']}
                                        />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Text as={'p'} sx={accordianParagraph}>
                                        {item.content}
                                    </Text>
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
            {/* More Questions Pagination Button */}
            {visibleItems < faqData.length && (
                <Box paddingTop={['24px', '24px', '24px', '40px', '40px', '40px']}>
                    <Button
                        fontSize={['20px', '20px', '20px', '20px', '20px', '20px']}
                        color={'#fff'}
                        fontFamily={'var(--foundersGrotesk-regular)'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        backgroundColor={'#9B4DFF'}
                        borderRadius={'0'}
                        border={'none'}
                        padding={['16px 14px 14px']}
                        _hover={{ backgroundColor: '#000' }}
                        onClick={showMore}
                    >
                        More Questions
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default FAQ;
