import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import LeftPagination from '../../assets/Career/JobPostIcon/LeftPaination.svg';
import linkIcon from '../../assets/Career/JobPostIcon/linkIcon.svg';
import RightPagination from '../../assets/Career/JobPostIcon/RightPagination.svg';
import NoPostSec from '../Career/nojobpostsection';

const CareerPosts = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSmallerThan562] = useMediaQuery('(max-width: 562px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-openings?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);
  const testimnialsTitle = {
    color: '#333',
    fontSize: ['32px', '32px', '32px', '30px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '510px',
  };
  // Job Post
  const postTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    color: '#333',
    fontWeight: '400',
  };
  const postCatogary = {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '16px'],
    color: '#fff',
    bg: '#B882FF',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    p: '5px 10px 2px ',
    display: 'inline-block',
  };
  const postLocation = {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '16px'],
    bg: '#7610B5',
    color: '#fff',
    fontFamily: 'var(--garnett-regular)',
    p: '5px 10px 5px',
    display: 'inline-block',
  };
  // Search And Select
  const selectDesign = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    color: '#000',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    p: '11px 14px 7px',
    border: '1px',
    borderColor: '#B882FF',
    borderRadius: '0px',
    minHeight: '52px',
  };
  const postSearchInput = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333',
    bg: '#F5F5F5',
    w: '100%',
    borderRadius: 'none',
    pl: '40px',

    minHeight: '52px',
  };

  // Paginations Button
  const paginationButtonNumber = {
    backgroundColor: 'transparent',
    borderRadius: '0',
    height: '34px',
    _hover: { backgroundColor: '#EBDBFF' },
  };
  // Select Options
  const options = ['Web Development', 'App Development', 'Android'];
  return (
    <>
      {data.length > 0 ? (
        <Box
          p={['80px 0', '80px 0', '80px 0', '150px 0', '150px 0', '150px 0']}
        >
          <Container
            maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
            m={'0 auto'}
          >
            {/* Title */}
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              paddingBottom={['40px', '40px', '40px', '46px']}
              gap={['10px']}
            >
              <Box
                mb={['28px', '28px', '28px', '0', '0', '0']}
                w={['100%', '100%', '100%', '50%', '50%']}
                textAlign={[
                  'center',
                  'center',
                  'center',
                  'left',
                  'left',
                  'left',
                ]}
                display={['flex', 'flex', 'flex', 'block']}
                justifyContent={['center']}
              >
                <Text as={'h3'} sx={testimnialsTitle}>
                  Current Openings
                </Text>
              </Box>

              {/* Search And option  */}
              <Flex
                w={['100%']}
                maxW={['100%', '100%', '100%', '600px', '600px', '600px']}
                gap={['16px']}
                height={['52px']}
              >
                {/* Select */}
                <Box w={['50%', '32%', '32%', '32%', '32%', '32%']}>
                  <Select
                    id="select"
                    placeholder="All Department"
                    sx={selectDesign}
                  >
                    {options.map((option, Index) => (
                      <option key={Index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </Box>

                {/* Search */}
                <Box
                  position="relative"
                  w={['50%', '64%', '64%', '64%', '64%', '64%']}
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_2507_60191)">
                            <path
                              d="M21.7492 20.7775L16.0851 15.1134C17.4462 13.4794 18.1249 11.3836 17.98 9.26201C17.8352 7.14037 16.878 5.15625 15.3075 3.7224C13.737 2.28854 11.6742 1.51535 9.54821 1.56366C7.42218 1.61197 5.39663 2.47807 3.89292 3.98178C2.3892 5.4855 1.5231 7.51105 1.47479 9.63707C1.42648 11.7631 2.19967 13.8259 3.63353 15.3964C5.06738 16.9668 7.05151 17.9241 9.17314 18.0689C11.2948 18.2137 13.3906 17.535 15.0246 16.174L20.6886 21.8381L21.7492 20.7775ZM2.9992 9.83807C2.9992 8.50304 3.39508 7.198 4.13678 6.08797C4.87848 4.97794 5.93269 4.11277 7.16609 3.60188C8.39949 3.09099 9.75669 2.95732 11.0661 3.21777C12.3754 3.47822 13.5782 4.12109 14.5222 5.0651C15.4662 6.0091 16.1091 7.21184 16.3695 8.52121C16.63 9.83058 16.4963 11.1878 15.9854 12.4212C15.4745 13.6546 14.6093 14.7088 13.4993 15.4505C12.3893 16.1922 11.0842 16.5881 9.7492 16.5881C7.9596 16.5861 6.24386 15.8743 4.97842 14.6088C3.71298 13.3434 3.00119 11.6277 2.9992 9.83807Z"
                              fill="#333333"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2507_60191">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.0878906)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                      paddingTop={['11px']}
                    />
                    <Input
                      className="Input"
                      variant="outline"
                      type="text"
                      id="search"
                      placeholder="Search"
                      sx={postSearchInput}
                      paddingTop={['5px']}
                      _placeholder={{
                        fontSize: [
                          '14px',
                          '14px',
                          '14px',
                          '20px',
                          '20px',
                          '20px',
                        ],
                        color: '#333',
                        fontFamily: 'var(--foundersGrotesk-regular)',
                        fontWeight: '400',
                        paddingTop: ['4px'],
                      }}
                    />
                  </InputGroup>
                </Box>
              </Flex>
            </Box>
            {/* Searching And Shorting Functionality */}
            <Box
              display={'grid'}
              gridTemplateColumns={
                isSmallerThan562 ? 'repeat(1,1fr)' : 'repeat(2,1fr)'
              }
              columnGap={'16px'}
              rowGap={['18px', '18px', '18px', '22px']}
            >
              {data.map(item => {
                const { id, attributes } = item;
                const { job_title, job_type, location, slug } = attributes;

                return (
                  <Box
                    key={id}
                    bg={'#F5F5F5'}
                    p={[
                      '12px 10px 14px 18px',
                      '12px 10px 14px 18px',
                      '12px 10px 14px 18px',
                      '20px 16px 27px 32px',
                      '20px 16px 27px 32px',
                      '20px 16px 27px 32px',
                    ]}
                  >
                    {/* Title And Link */}
                    <Flex justifyContent={'space-between'} mb={'20px'}>
                      {/* Post Title */}
                      <Box>
                        <Text as={'h4'} sx={postTitle}>
                          {job_title}
                        </Text>
                      </Box>
                      {/* Post Link */}
                      <Box>
                        <Link href={`/career/${slug}`}>
                          <Image
                            src={linkIcon}
                            w={['18px', '18px', '18px', '32px', '32px']}
                            height={'auto'}
                          />
                        </Link>
                      </Box>
                    </Flex>
                    <Flex justifyContent={'space-between'}>
                      {/* Post Category AND post Location */}
                      {/* Category */}
                      <Box>
                        <Text as={'span'} sx={postCatogary}>
                          {job_type}
                        </Text>
                      </Box>
                      {/* Location */}
                      <Box>
                        <Text as={'span'} sx={postLocation}>
                          {location}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
            {/* Pagination */}
            <Box paddingTop={['40px', '40px', '40px', '45px']} display={'none'}>
              <Box
                display={['flex']}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box>
                  <Image src={LeftPagination} alt />
                </Box>
                <Box>
                  <ButtonGroup>
                    <Button sx={paginationButtonNumber}>1</Button>
                    <Button sx={paginationButtonNumber}>2</Button>
                    <Button sx={paginationButtonNumber}>3</Button>
                    <Button sx={paginationButtonNumber}>4</Button>
                    <Button sx={paginationButtonNumber}>5</Button>
                    <Button sx={paginationButtonNumber}>6</Button>
                  </ButtonGroup>
                </Box>
                <Box>
                  <Image src={RightPagination} alt />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box>
          <NoPostSec />
        </Box>
      )}
    </>
  );
};

export default CareerPosts;
