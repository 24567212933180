import {
  Box,
  Container,
  Flex,
  Image,
  Link,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import CtcIcon1 from '../../../assets/Service/tabs/ctc1.svg';
import g42image from '../../../assets/work/g42.webp';
import irosimage from '../../../assets/work/iros.webp';
import WorkPageInteraction from '../../Popups/WorkPageInteraction/WorkPageInteraction';
import './Custom.css';

const OurWork = () => {
  const containerRef = useRef(null);
  const stickyBoxRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('Case Studies');
  let desktopOffset = 68;
  let mobileOffset = 60;

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const stickyBox = stickyBoxRef.current;
      if (!container || !stickyBox) return;

      const containerBottom = container.getBoundingClientRect().bottom;
      const stickyBoxHeight = stickyBox.offsetHeight;
      let offset = window.innerWidth <= 768 ? mobileOffset : desktopOffset;

      if (containerBottom <= stickyBoxHeight + offset) {
        stickyBox.style.position = 'static';
        stickyBox.style.bottom = '0';
        stickyBox.style.top = 'auto';
      } else {
        stickyBox.style.position = 'sticky';
        stickyBox.style.top = `${offset}px`;
        stickyBox.style.bottom = 'auto';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTabChange = index => {
    setSelectedTab(index); // Update the selected tab
    if (containerRef.current) {
      // Scroll the container into view with smooth behavior
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleShowMoreClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <Box>
      <Box
        borderBottom={['1px solid #B5B5B5']}
        width={['90%', '90%', '90%', '80%', '80%', '80%']}
        margin={['0 auto']}
      ></Box>
      <Box
        p={[
          '23px 0 0px',
          '23px 0 0px',
          '23px 0 0px',
          '61px 0',
          '61px 0',
          '61px 0',
        ]}
        marginTop={['10px', '10px', '10px', '15px', '15px', '15px']}
      >
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
          m={'0 auto'}
        >
          <Flex display={['block', 'block', 'block', 'flex', 'flex', 'flex']}>
            <Box w={['100%', '100%', '100%', '54%', '54%', '54%']}>
              <Text
                as={'h2'}
                fontSize={['24px', '26px', '28px', '36px', '36px', '36px']}
                color={'#000000'}
                fontWeight={'400'}
                fontFamily={'var(--garnett-regular)'}
                maxW={'646px'}
                paddingBottom={['40px', '40px', '40px', 0, 0, 0]}
              >
                From global icons to trailblazing start-ups, we partner with
                ambitious leaders at pivotal inflection points.
              </Text>
            </Box>
            <Box
              background={['#3E001A']}
              w={['100%', '100%', '100%', '46%', '46%', '46%']}
              pt={['10px', '10px', '10px', '0px', '0px', '0px']}
            >
              <Flex
                className="caseMobile"
                paddingTop={['18px', '20px', '22px', '25px', '30px', '32px']}
                paddingBottom={['28px', '28px', '26px', '25px', '30px', '32px']}
                justifyContent={['center']}
              >
                <Box
                  w={[]}
                  display={'flex'}
                  alignItems={[
                    'start',
                    'start',
                    'start',
                    'center',
                    'center',
                    'center',
                  ]}
                  mr={['20px', '20px', '20px', '15px', '15px', '15px']}
                >
                  <Image
                    src={CtcIcon1}
                    pt={['15px', '15px', '15px', '0px', '0px', '0px']}
                    width={['100%', '95%', '85%', '85%', '85%', '85%']}
                  />
                </Box>
                <Box>
                  <Box maxW={'400px'} ml={'auto'}>
                    <Box display={'flex'} alignItems={'baseline'}>
                      <Text
                        as={'span'}
                        color={'#fff'}
                        fontStyle={' var(--foundersGrotesk-regular)'}
                        fontSize={[
                          '18px',
                          '18px',
                          '18px',
                          '24px',
                          '24px',
                          '24px',
                        ]}
                        paddingBottom={[
                          '25px',
                          '25px',
                          '25px',
                          '30px',
                          '30px',
                          '32px',
                        ]}
                        lineHeight={['normal']}
                        width={['100%', '100%', '100%', '90%', '90%', '90%']}
                      >
                        Like what you see? Let’s get on a call to discuss the
                        new growth opportunities.
                      </Text>
                    </Box>
                    <Box>
                      <Link
                        href="/contact-us"
                        display={'inline-flex'}
                        bg={'#E0005E'}
                        paddingTop={[
                          '8px',
                          '8px',
                          '8px',
                          '10px',
                          '10px',
                          '10px',
                        ]}
                        paddingBottom={[
                          '5px',
                          '5px',
                          '5px',
                          '7px',
                          '7px',
                          '7px',
                        ]}
                        paddingLeft={[
                          '30px',
                          '30px',
                          '30px',
                          '38px',
                          '38px',
                          '38px',
                        ]}
                        paddingRight={[
                          '30px',
                          '30px',
                          '30px',
                          '38px',
                          '38px',
                          '38px',
                        ]}
                        lineHeight={'normal'}
                        color={'#fff'}
                        fontSize={[
                          '15px',
                          '15px',
                          '15px',
                          '20px',
                          '20px',
                          '20px',
                        ]}
                        fontWeight={'400'}
                        fontFamily={'var(--foundersGrotesk-regular)'}
                        _hover={{ transform: 'translate3d(0, -3px, 0)' }}
                        transition="transform 0.5s ease"
                      >
                        Get in Touch
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box
        padding={[
          '70px 0 0 0',
          '70px 0 0 0',
          '70px 0 0 0',
          '80px 0 0 0',
          '80px 0 0 0',
          '80px 0 0 0',
        ]}
        width={['100%', '100%', '100%', '100%', '100%', '100%']}
        display="flex"
        margin="0 auto"
        paddingBottom={['40px', '40px', '50px', '80px', '80px', '80px']}
      >
        <Container
          maxWidth="100%"
          margin="0 auto"
          padding={[0]}
          ref={containerRef}
          id="TartTabsAgain"
        >
          <Tabs onChange={index => handleTabChange(index)}>
            <Box
              display="flex"
              justifyContent="center"
              borderBottom="1px solid"
              gap={'14px'}
              borderBottomColor="#D9D9D9"
              background="#fff"
              boxShadow="0px 4px 13px 0px rgba(0, 0, 0, 0.08)"
              ref={stickyBoxRef}
              height={['38px', '48px', '95px', '95px']}
            >
              <Tab
                width={['119px', '119px', '272px', '272px', '272px']}
                _selected={{
                  bg: 'rgba(191, 200, 248, 0.18)',
                  color: '#0036C2',
                  borderBottom: '5px solid #0036C2',
                }}
                fontSize={['14px', '14px', '20px', '26px', '32px', '32px']}
                color={['#000000']}
                fontFamily="var(--garnett-regular);"
                padding={'0'}
              >
                Case Studies
              </Tab>
              <Tab
                width={['119px', '119px', '272px', '272px', '272px']}
                _selected={{
                  bg: 'rgba(191, 200, 248, 0.18)',
                  color: '#0036C2',
                  borderBottom: '5px solid #0036C2',
                }}
                fontSize={['14px', '14px', '20px', '26px', '32px', '32px']}
                color={['#000000']}
                fontFamily="var(--garnett-regular);"
                padding={'0'}
              >
                Projects
              </Tab>
            </Box>
            {/* Tab Pannels */}
            <TabPanels>
              <TabPanel
                width={['100%', '100%', '100%', '90%', '80%', '80%']}
                margin={['0 auto']}
                paddingTop={['40px', '40px', '40px', '80px', '80px', '100px']}
              >
                {/* Case Studies content */}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={[
                    'center',
                    'center',
                    'center',
                    'flex-end',
                    'flex-end',
                    'flex-end',
                  ]}
                  gap={['40px', '40px', '45px', '55px', '65px', '75px']}
                >
                  {/* Add Iros ace + */}
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    {/* Iros And ace+  */}
                    {/* Iros */}
                    <Box flex="1">
                      <Link href="/work/iros">
                        <Flex
                          alignItems="flex-start"
                          gap={['20px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            aspectRatio={'1.78'}
                            src={irosimage}
                            w={'100%'}
                            alt="Project 01"
                          />

                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as={'span'}
                              href="/work/iros"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              IROS:
                            </Text>{' '}
                            Brand Strategy, Identity, and Website Transformation
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '15px',
                            '15px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Leading healthcare innovation with comprehensive
                          digital solutions.
                        </Text>
                      </Link>
                    </Box>
                    {/* Ace + */}
                    <Box flex="1">
                      <Link>
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/ace_plus.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as="span"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              ACE Plus:
                            </Text>{' '}
                            Product Development & Digital Transformation
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Crafting a future-ready educational platform for
                          dynamic learning.
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                  {/* g42 and strongr */}
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    {/* G42 */}
                    <Box flex="1">
                      <Link href="#">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            aspectRatio={'1.78'}
                            src={g42image}
                            w={'100%'}
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as="span"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              G42 Healthcare :
                            </Text>{' '}
                            Digital Transformation and Website Management
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Pioneering advanced healthcare solutions in the
                          digital realm.
                        </Text>
                      </Link>
                    </Box>
                    {/* Strongr */}
                    <Box flex="1">
                      <Link href="/work/strongr">
                        <Flex
                          alignItems="flex-start"
                          gap={['20px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/1.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as={'span'}
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              Strongr:
                            </Text>{' '}
                            Product Development & Strategy for a Leading Fitness
                            Startup
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '15px',
                            '15px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Shaping the future of fitness with innovative digital
                          strategies.
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                  {/*asdav and  Pazzez  */}
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    {/* Asdav */}
                    <Box flex="1">
                      <Link href="#">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/2.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as="span"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              ASDAV:
                            </Text>{' '}
                            Brand Transformation and Digital Communication
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Elevating creative education through strategic digital
                          excellence.
                        </Text>
                      </Link>
                    </Box>
                    {/* Pazzz */}
                    <Box flex="1">
                      <Link href="/work/pazazz">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/3.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as={'span'}
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              Pazazz:
                            </Text>{' '}
                            Branding, Visual Identity, and E-Commerce Innovation
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Setting new trends in athleisure with stylish and
                          strategic design.
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                  {/* angels and  Think Vastu  */}
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    {/* Angels */}
                    <Box flex="1">
                      <Link href="/work/angles">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/4.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as={'span'}
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              Angles:
                            </Text>{' '}
                            E-commerce Excellence, Web Design, and Data-Driven
                            Insights
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Transforming lifestyle retail with sophisticated
                          digital platforms.
                        </Text>
                      </Link>
                    </Box>
                    {/* Think Vastu */}
                    <Box flex="1">
                      <Link href="/work/think-vastu">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/5.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as={'span'}
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              Think Vastu:
                            </Text>{' '}
                            Strategic Positioning and Digital Mastery for
                            Harmonious Living
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Delivering customized Vastu solutions through digital
                          innovation.
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                  {/* mbb and Algo Enginners  */}
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                    marginBottom={['0', '0', '68px', '48px', '60px', '80px']}
                  >
                    {/* mbb */}
                    <Box flex="1">
                      <Link href="#">
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/6.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as="span"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              MBB:
                            </Text>{' '}
                            E-commerce Excellence, Web Design, and Data
                            Analytics
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Enhancing the online shopping experience for baby
                          products.
                        </Text>
                      </Link>
                    </Box>
                    {/* algo Enginneers */}
                    <Box flex="1">
                      <Link>
                        <Flex
                          alignItems="flex-start"
                          gap={['25px', '25px', '25px', '30px', '30px', '34px']}
                          flexDirection="column"
                        >
                          <Image
                            src="https://d3us4ynq1qsik6.cloudfront.net/work/7.webp"
                            alt="Project 01"
                          />
                          <Text
                            as="p"
                            m="0px"
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '24px',
                              '26px',
                              '30px',
                            ]}
                            color={'#333333'}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '90%',
                              '90%',
                              '90%',
                            ]}
                            lineHeight={['normal']}
                          >
                            <Text
                              as="span"
                              fontFamily="var(--garnett-semibold)"
                              fontWeight="600"
                            >
                              {' '}
                              Algo Engineers:
                            </Text>{' '}
                            Visual Identity Creation, Website Design, and
                            Development
                          </Text>
                        </Flex>
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '16px',
                            '16px',
                            '18px',
                            '18px',
                            '20px',
                            '20px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          paddingTop={[
                            '15px',
                            '15px',
                            '10px',
                            '10px',
                            '15px',
                            '20px',
                          ]}
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Enhancing the online shopping experience for baby
                          products.
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box display={['flex']} justifyContent={['center']}>
                    <Text
                      display={['flex']}
                      alignItems={['center']}
                      gap={['5px']}
                      fontSize={[
                        '16px',
                        '16px',
                        '18px',
                        '20px',
                        '22px',
                        '24px',
                      ]}
                      color={'#333333'}
                      fontFamily="var(--garnett-regular)"
                      fontWeight={['400']}
                      border={['1px solid #333333']}
                      paddingTop={['12px']}
                      paddingBottom={['12px']}
                      paddingLeft={['10px']}
                      paddingRight={['10px']}
                      cursor={['pointer']}
                      onClick={handleShowMoreClick} // Open popup on click
                    >
                      Show more
                      <svg
                        width="18"
                        height="11"
                        viewBox="0 0 18 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 2L9 9.5L1.5 2"
                          stroke="#333333"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Text>
                  </Box>

                  {isPopupOpen && (
                    <WorkPageInteraction
                      isOpen={isPopupOpen}
                      onClose={handleClosePopup}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel
                width={['100%', '100%', '100%', '90%', '80%', '80%']}
                margin={['0 auto']}
                paddingTop={['40px', '40px', '40px', '80px', '80px', '100px']}
              >
                {/* Case Studies content */}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={[
                    'center',
                    'center',
                    'center',
                    'flex-end',
                    'flex-end',
                    'flex-end',
                  ]}
                  gap={['40px', '40px', '45px', '55px', '65px', '75px']}
                >
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '15px', '15px', '15px', '15px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    <Box flex="1">
                      <Flex
                        alignItems="flex-start"
                        gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                        flexDirection="column"
                      >
                        <Image
                          src="https://d3us4ynq1qsik6.cloudfront.net/work/8.webp"
                          alt="Project 01"
                        />
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '20px',
                            '20px',
                            '20px',
                            '24px',
                            '26px',
                            '30px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Driving Business Excellence with Marketing and Finance
                          Strategies
                        </Text>
                      </Flex>
                      <Text
                        as="p"
                        m="0px"
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '20px',
                          '20px',
                        ]}
                        color={'#333333'}
                        fontFamily="var(--garnett-regular)"
                        paddingTop={[
                          '15px',
                          '15px',
                          '10px',
                          '10px',
                          '15px',
                          '20px',
                        ]}
                        fontWeight="400"
                        width={['100%', '100%', '100%', '90%', '90%', '90%']}
                        lineHeight={['normal']}
                      >
                        Innovative strategies powering superior market
                        performance.
                      </Text>
                    </Box>
                    <Box flex="1">
                      <Flex
                        alignItems="flex-start"
                        gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                        flexDirection="column"
                      >
                        <Image
                          src="https://d3us4ynq1qsik6.cloudfront.net/work/9.webp"
                          alt="Project 01"
                        />
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '20px',
                            '20px',
                            '20px',
                            '24px',
                            '26px',
                            '30px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Website and App Development for Complete Booking
                          Solutions
                        </Text>
                      </Flex>
                      <Text
                        as="p"
                        m="0px"
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '20px',
                          '20px',
                        ]}
                        color={'#333333'}
                        fontFamily="var(--garnett-regular)"
                        paddingTop={[
                          '15px',
                          '15px',
                          '10px',
                          '10px',
                          '15px',
                          '20px',
                        ]}
                        fontWeight="400"
                        width={['100%', '100%', '100%', '90%', '90%', '90%']}
                        lineHeight={['normal']}
                      >
                        Revolutionizing travel experiences with innovative
                        booking solutions, creating a seamless and intuitive
                        platform for global adventurers.
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={[
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                      'row',
                    ]}
                    width="100%"
                    maxWidth="100%"
                    gap={['40px', '40px', '15px', '15px', '15px', '15px']}
                    marginBottom={['0', '0', '68px', '48px', '40px', '40px']}
                  >
                    <Box flex="1">
                      <Flex
                        alignItems="flex-start"
                        gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                        flexDirection="column"
                      >
                        <Image
                          src="https://d3us4ynq1qsik6.cloudfront.net/work/10.webp"
                          alt="Project 01"
                        />
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '20px',
                            '20px',
                            '20px',
                            '24px',
                            '26px',
                            '30px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Unveiling New Online Ordering App for Enhanced Dining
                          Convenience
                        </Text>
                      </Flex>
                      <Text
                        as="p"
                        m="0px"
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '20px',
                          '20px',
                        ]}
                        color={'#333333'}
                        fontFamily="var(--garnett-regular)"
                        paddingTop={[
                          '15px',
                          '15px',
                          '10px',
                          '10px',
                          '15px',
                          '20px',
                        ]}
                        fontWeight="400"
                        width={['100%', '100%', '100%', '90%', '90%', '90%']}
                        lineHeight={['normal']}
                      >
                        Digital solutions that transform traditional dining
                        experiences.
                      </Text>
                    </Box>
                    <Box flex="1">
                      <Flex
                        alignItems="flex-start"
                        gap={['40px', '40px', '16px', '16px', '16px', '16px']}
                        flexDirection="column"
                      >
                        <Image
                          src="https://d3us4ynq1qsik6.cloudfront.net/work/11.webp"
                          alt="Project 01"
                        />
                        <Text
                          as="p"
                          m="0px"
                          fontSize={[
                            '20px',
                            '20px',
                            '20px',
                            '24px',
                            '26px',
                            '30px',
                          ]}
                          color={'#333333'}
                          fontFamily="var(--garnett-regular)"
                          fontWeight="400"
                          width={['100%', '100%', '100%', '90%', '90%', '90%']}
                          lineHeight={['normal']}
                        >
                          Innovative NFT Website and Mobile App for Digital
                          Collectors
                        </Text>
                      </Flex>
                      <Text
                        as="p"
                        m="0px"
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '20px',
                          '20px',
                        ]}
                        color={'#333333'}
                        fontFamily="var(--garnett-regular)"
                        paddingTop={[
                          '15px',
                          '15px',
                          '10px',
                          '10px',
                          '15px',
                          '20px',
                        ]}
                        fontWeight="400"
                        width={['100%', '100%', '100%', '90%', '90%', '90%']}
                        lineHeight={['normal']}
                      >
                        Pioneering the digital art space with blockchain
                        technology.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Box>
    </Box>
  );
};
export default OurWork;
