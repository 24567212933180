import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const OurLeading = ({
  // Section
  sectionPaddingTopBottom = [
    '40px 0 33px',
    '40px 0 33px',
    '40px 0 33px',
    '40px 0 33px',
    '66px 0 80px',
    '66px 0 80px',
  ],
  // Props added for title text customization
  titleText = 'Engage with Our Leading User Experience Specialists',
  titleFontSize = ['20px', '28px', '28px', '28px', '38px', '42px'],
  titleFontFaimly = [
    'var(--garnett-regular)',
    'var(--garnett-regular)',
    'var(--garnett-regular)',
    'var(--garnett-regular)',
    'var(--garnett-semibold)',
    'var(--garnett-semibold)',
  ],
  titleFontWeight = ['400', '400', '400', '600', '600', '600'],
  titleTextAlign = ['center', 'center', 'center', 'center', 'left'],
  titleWrapMaxWith = ['561px', '561px', '561px', '561px', '550px', '561px'],
  titleWrapMargin = ['0 auto', '0 auto', '0 auto', '0 auto', '0'],
  titleLineHeight = ['normal'],

  // Props added for paragraph text customization
  paragraphText = 'Elevate your service with our exceptional user experience expertise. Reach out to our specialists to enhance your digital solutions today.',
  paragraphFontSize = ['14px', '16px', '20px', '20px', '20px', '20px'],
  paragraphTextAlign = ['center', 'center', 'center', 'center', 'left'],
  paragraphTopPadding = ['40px', '40px', '40px', '40px', '18px', '18px'],
  paragraphBottomPadding = ['43px', '43px', '43px', '43px', '18px', '18px'],
  paragraphMargin = ['0 auto'],
  paragraphLineHeight = 'normal',

  // Existing props for link button text, URL, background color, etc.
  linkBtnText = 'Get in Touch',
  linkUrl = '#',
  linkBgColor = '#7610B5',
  linkBoder = '0px',
  containerBgColor = '#30054A',
  linkButtonAlignment = ['center', 'center', 'center', 'center', 'left'],
  //
  paragraphAndLinkMaxWith = ['350px'],
  paragraphAndLinkMargin = ['0 auto', '0 auto', '0 auto', '0 auto', '0'],
  containerMarginBottom = ['0', '0', '150px', '150px', '150px', '150px'],
}) => {
  const title = {
    color: '#fff',
    fontFamily: titleFontFaimly,
    fontSize: titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
    textAlign: titleTextAlign,
  };

  const paragraph = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#fff',
    fontSize: paragraphFontSize,
    fontWeight: '400',
    lineHeight: paragraphLineHeight,
    paddingBottom: paragraphBottomPadding,
    paddingTop: paragraphTopPadding,
    textAlign: paragraphTextAlign,
    maxW: ['317px', '100%'],
    margin: paragraphMargin,
  };

  const linkDesign = {
    backgroundColor: linkBgColor,
    fontFamily: 'var(--foundersGrotesk-regular)',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: '24px',
    padding: '0px 12px',
    border: linkBoder,
    minW: ['129px'],
    height: ['42px'],
    paddingTop: '4px',
  };

  return (
    // Container
    <Box
      maxW={['100%', '100%', '100%', '80%', '80%']}
      margin={['0 auto']}
      marginBottom={containerMarginBottom}
    >
      <Box bg={containerBgColor} padding={sectionPaddingTopBottom}>
        <Box maxW={['86%']} margin={['0 auto']}>
          {/* Row */}
          <Box
            display={['block', 'block', 'block', 'block', 'flex', 'flex']}
            justifyContent={['space-between']}
          >
            {/* Col 1 title */}
            <Box maxW={titleWrapMaxWith} margin={titleWrapMargin}>
              {/* Added titleText prop for customizable title */}
              <Heading as={'h2'} sx={title}>
                {titleText}
              </Heading>
            </Box>
            {/* Col 2 text with button */}
            <Box
              maxWidth={paragraphAndLinkMaxWith}
              margin={paragraphAndLinkMargin}
              textAlign={linkButtonAlignment}
            >
              {/* Added paragraphText prop for customizable paragraph */}
              <Text as={'p'} sx={paragraph}>
                {paragraphText}
              </Text>
              <Link to={linkUrl} style={linkDesign}>
                {linkBtnText}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OurLeading;
