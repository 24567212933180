import {
    AspectRatio,
    Box,
    Grid,
    GridItem,
    Heading,
    Image,
    Text,
  } from '@chakra-ui/react';
  import axios from 'axios';
  import React, { useEffect, useState } from 'react';
  
  const convertParagraphToText = paragraph => {
    if (!paragraph || !paragraph.length) return '';
  
    return paragraph
      .map(p => {
        if (p.type === 'paragraph') {
          return p.children
            .map(child => {
              if (child.type === 'text') {
                return child.text;
              }
              return '';
            })
            .join('');
        }
        return '';
      })
      .join('\n');
  };
  
  const fetchCombinedData = async query => {
    try {
      const baseUrl = 'https://cms.wearetenet.com';
      const insightEndpoint = '/api/general-blogs?populate=*';
      const category = 'updates'; // The category filter value
  
      // Construct the URL with both filters
      const insightBlogsUrl = `${baseUrl}${insightEndpoint}&filters[category][$eq]=${encodeURIComponent(
        category
      )}&filters[title][$containsi]=${encodeURIComponent(query)}`;
  
      // Fetch data from the API
      const insightResponse = await axios.get(insightBlogsUrl);
  
      // Combine data from the API response
      const combinedData = insightResponse.data.data;
  
      // Map the combined data to the desired structure
      const filteredData = combinedData.map(item => {
        const slug = item.attributes.slug;
        const category = item.attributes.category || 'Guides'; // Default category if not present
  
        return {
          id: item.id,
          title: item.attributes.title,
          description: convertParagraphToText(item.attributes.paragraph),
          image: item.attributes.image
            ? `${baseUrl}${item.attributes.image.data.attributes.url}`
            : '', // Ensure image URL exists
          linkText: category,
          linkUrl: `/resources/${category}/${slug}`,
        };
      });
  
      return filteredData;
    } catch (error) {
      console.error('Error fetching data', error);
      return [];
    }
  };
  
  const Search = ({ searchQuery }) => {
    const [slidesData, setSlidesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const loadData = async () => {
        if (!searchQuery) {
          setSlidesData([]);
          setLoading(false);
          return;
        }
  
        try {
          setLoading(true); // Set loading to true before fetching data
          const data = await fetchCombinedData(searchQuery);
          if (data.length > 0) {
            setSlidesData(data);
            setError(null);  // Clear error state if data is found
          } else {
            setSlidesData([]);  // Clear previous data if no new data is found
            setError('No Results Found');
          }
        } catch (err) {
          setError('An error occurred while fetching data');
        } finally {
          setLoading(false);
        }
      };
  
      loadData();
    }, [searchQuery]);
  
    if (!searchQuery) return null; // Hide component when searchQuery is empty
  
    if (loading) return <p>Loading...</p>;
  
    const getCategoryBgColor = category => {
      switch (category) {
        case 'thoughts':
          return '#0057FF';
        case 'insights':
          return '#E0005E';
        case 'guides':
          return '#00763D';
        case 'glossary':
          return '#9B4DFF';
        case 'updates':
          return '#0036C2';
        case 'listen':
          return '#FF813A';
        default:
          return '#0057ff';
      }
    };
  
    const gridCss = {
      '@media (max-width: 599px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      '@media (min-width: 600px) and (max-width: 1023px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '@media (min-width: 1024px)': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      columnGap: ['24px'],
      rowGap: ['43px', '43px', '43px', '60px', '60px', '60px'],
    };
  
    return (
      <Box
        padding={[
          '80px 0 40px',
          '80px 0 40px',
          '80px 0 40px',
          '100px 0 60px',
          '100px 0 60px',
          '100px 0 60px',
        ]}
      >
        <Box maxW={['90%']} margin={'auto'}>
          {!error && (
            <Box marginBottom={['30px', '30px', '30px', '60px', '60px', '60px']}>
              <Heading
                as={'h2'}
                fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
                color={'#000'}
                fontWeight={400}
                fontFamily={'var(--garnett-regular);'}
              >
                Search Results
              </Heading>
            </Box>
          )}
        </Box>
  
        <Box maxW={['90%']} margin={'auto'}>
          {error ? (
            <Heading
              as={'h2'}
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              color={'#000'}
              fontWeight={400}
              fontFamily={'var(--garnett-regular);'}
            >
              {error}
            </Heading>
          ) : (
            <Grid sx={gridCss}>
              {slidesData.slice(0, 8).map(story => (
                <GridItem key={story.id}>
                  <AspectRatio
                    paddingBottom={'12px!important'}
                    ratio={
                      story.imageHeight === 330 / 220
                        ? 1.5
                        : story.imageHeight === 330 / 275
                        ? 1.2
                        : story.imageHeight === 330 / 382
                        ? 0.863874
                        : story.imageHeight === 330 / 247
                        ? 1.336032
                        : 1 // default ratio if none match
                    }
                    className="resourcesAspectRatioPaddingIssue"
                  >
                    <Image
                      src={story.image}
                      w={['100%']}
                      height={'auto!important'}
                      objectFit={'cover'}
                      alt="story Image"
                      position={'relative!important'}
                    />
                  </AspectRatio>
                  <Box paddingBottom={'12px'}>
                    <Text
                      as={'span'}
                      fontSize={['16px']}
                      fontWeight={400}
                      lineHeight={'normal'}
                      color={'#fff'}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      display={'inline-block'}
                      backgroundColor={getCategoryBgColor(story.linkText)}
                      padding={['10px 10px 7px']}
                      textTransform={['capitalize']}
                    >
                      {story.linkText}
                    </Text>
                  </Box>
                  <Box>
                    <Heading
                      as={'h3'}
                      fontSize={['20px']}
                      fontWeight={500}
                      lineHeight={'normal'}
                      color={'#333'}
                      fontFamily={'var(--garnett-medium);'}
                      paddingBottom={'12px'}
                    >
                      {story.title}
                    </Heading>
                    <Text
                      as={'p'}
                      fontSize={['14px']}
                      fontWeight={400}
                      lineHeight={'normal'}
                      color={'#333'}
                      noOfLines={['4']}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                    >
                      {story.description}
                    </Text>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    );
  };
  
  export default Search;
  