import { Box, Button, Container, Flex, Image, Link, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import img1 from "../../../assets/Service/1.webp";
import img2 from "../../../assets/Service/2.webp";
import img3 from "../../../assets/Service/3.webp";
import Breadcrumb from "./Breadcrumb";


const HeroBanner = () => {
  const [images, setImages] = useState([img1, img2, img3]);

  useEffect(() => {
    const interval = setInterval(() => {
      setImages((prevImages) => [prevImages[2], prevImages[0], prevImages[1]]);
    }, 1000); 
    return () => clearInterval(interval);
  }, []);
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  const bannerHeading = {
    fontSize: ["24px", "24px", "28px", "28px", "40px", "50px"],
    color: "#fff",
    fontFamily: "var(--garnett-semibold)",
    fontWeight: "600",
    pt: ["0px", "0px", "65px", "0px", "0px", "0px"],
  };
  const bannerParagraph = {
    fontSize: ["14px", "14px", "14px", "14px", "18px", "18px"],
    color: "#fff",
    fontFamily: "var(--foundersGrotesk-regular)",
    fontWeight: "400",
    p: ["22px 0"],
  };
  const bannerButtonLink = {
    fontSize: ["20px", "20px", "20px", "20px", "20px", "20px"],
    fontFamily: "var(--foundersGrotesk-regular)",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#fff",
    bg: "#0057FF",
    display: "inline-block",
    width: ["100%", "100%", "100%", "146px", "146px", "180px"],
height: "42px",
    };

  return (
    <Box ref={heroSectionRef} id="hero-section"
      bg={"#0D0E4D"}
      padding={[
        "66px 0 50px",
        "66px 0 50px",
        "66px 0 100px",
        "14px 0 50px",
        "14px 0 20px",
        "14px 0 20px",
      ]}
      overflow={"hidden"}
    >
      <Container
        maxWidth={["100%", "100%", "100%", "100%", "100%", "100%"]}
        m={"0 auto"}
        padding={["0"]}
      >

        <Flex
          display={["flex", "flex", "flex", "flex", "flex", "flex"]}
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "row",
            "row",
            "row",
            "row",
          ]}
        >

          {/* Text Part */}
          <Box
            width={["100%", "100%", "100%", "50%", "52%", "52%"]}
            display={"flex"}
            alignItems={"baseline"}
            flexDirection={["column"]}
            paddingTop={["100px", "100px", "50px", "50px", "50px", "50px"]}
            paddingLeft={["5%", "5%", "5%", "5%", "5%", "5%"]}
            paddingBottom={["20px", "20px", "50px", "70px", "80px", "130px"]}
            className='serviceHeroPart'
          >
            <Breadcrumb />
            <Box pr={["0px", "0px", "0px", "55px", "5px", "5px"]} paddingTop={["15px", "15px", "40px", "50px", "60px", "63px"]}>
              <Text as={"h1"} sx={bannerHeading} >
              Insightful. User-Centric. <br />Performance-Driven.
              </Text>
              <Text as={"p"} sx={bannerParagraph}>
              Tenet transforms insights into outcomes with research, design, and technology. We create user-centered, aesthetically pleasing, and growth-optimized experiences.              </Text>
              <Box maxW={["100%", "100%", "100%", "60%", "60%", "60%"]}>
                <Flex
                  direction={["row", "row", "row", "row", "row", "row"]}
                  alignItems="center"
                >
                  <Link href="/contact-us">
                    <Button
                      w={["100%", "100%", "100%", "146px", "146px", "180px"]}
                      fontSize={["18px", "18px", "18px", "16px", "16px", "16px"]}
                      bg="#0057FF"
                      color="#ffffff"
                      borderRadius="0"
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      justifyContent="center"
                      display={["inline-block", "inline-block", "inline-block", "flex", "flex", "flex"]}
                      alignItems="center"
                      textAlign="center"
                      height="42px"
                      _hover={{ bg: "#0057FF", color: "#ffffff", transform: "translate3d(0, -3px, 0)" }}
                      transition="transform 0.5s ease"
                    >
                      Let's work together
                    </Button>
                  </Link>
                </Flex>
              </Box>            </Box>
          </Box>
          <Box
            width={["100%", "100%", "100%", "60%", "60%", "60%"]}
            display={["flex"]}
            alignItems={["center"]}
            justifyContent={["center"]}
            position="relative"
          >
            <Image
              src={images[0]}
              w={"40%"}
              objectFit={"fill"}
              position="absolute"
              left="-1%"
              bottom={["-50px", "-50px", "50px", "50px", "50px", "50px"]}
              opacity={"0.3"}
              zIndex={0}
              transition="all 0.8s ease"
            />
            {/* Image 2 */}
            <Image
              src={images[1]}
              width={["70%", "70%", "70%", "80%", "80%", "80%"]}
              objectFit={"fill"}
              zIndex={1}
              transition="all 0.8s ease"
            />
            {/* Image 3 */}
            <Image
              src={images[2]}
              w={"40%"}
              objectFit={"fill"}
              position="absolute"
              right="0%"
              top={["-20%", "-20%", "5%", "5%", "5%", "5%"]}
              opacity={"0.3"}
              zIndex={0}
              transition="all 0.8s ease"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default HeroBanner;
