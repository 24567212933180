import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import leftIcon from '../../../assets/Icons/Slider/leftIcon.svg';
import rightIcon from '../../../assets/Icons/Slider/rightIcon.svg';
import axios from 'axios';
const Stories = () => {
  const [sliderData, setSliderData] = useState([]);
  const swiperRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from both APIs
        const generalResponse = await axios.get(
          'https://cms.wearetenet.com/api/general-blogs?populate=*&filters[$and][0][is_homepage][$eq]=true'
        );
        const insightResponse = await axios.get(
          'https://cms.wearetenet.com/api/insight-blogs?populate=*&filters[$and][0][is_homepage][$eq]=true'
        );

        // Log the responses for debugging
        console.log('General Blogs Response:', generalResponse.data);
        console.log('Insight Blogs Response:', insightResponse.data);

        // Extract data from responses
        const generalBlogs = generalResponse.data.data;
        const insightBlogs = insightResponse.data.data;

        // Function to determine image height based on API value
        const getImageHeight = size => {
          switch (size) {
            case 'size1':
              return 220;
            case 'size2':
              return 275;
            case 'size3':
              return 247;
            case 'size4':
              return 381;
            default:
              return 200; // Default height if no matching size is found
          }
        };

        // Function to determine background color based on category
        const getBgColor = category => {
          switch (category.toLowerCase()) {
            case 'thoughts':
              return '#0057FF';
            case 'insights':
              return '#E0005E';
            case 'guides':
              return '#00763D';
            case 'glossary':
              return '#9B4DFF';
            case 'updates':
              return '#0036C2';
            case 'listen':
              return '#FF813A';
            default:
              return '#E0005E'; // Default color if no matching category is found
          }
        };

        // Combine and transform data
        const combinedData = [
          ...generalBlogs.map(blog => ({
            category: blog.attributes?.category || 'Unknown Category',
            title: blog.attributes?.title || 'No Title',
            description:
              blog.attributes?.paragraph?.[0]?.children?.[0]?.text ||
              'No Description',
            image: `https://cms.wearetenet.com${blog.attributes?.image?.data?.attributes?.url}`,
            height: {
              320: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              480: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              768: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              992: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              default:
                getImageHeight(blog.attributes?.image_height?.size1) || 200,
            },
            slug: `/resources/${blog.attributes.slug}`,
            bgColor: getBgColor(blog.attributes?.category || 'insights'), // Use category to determine background color
          })),
          ...insightBlogs.map(blog => ({
            category: blog.attributes?.category || 'Unknown Category',
            title: blog.attributes?.title || 'No Title',
            description:
              blog.attributes?.paragraph?.[0]?.children?.[0]?.text ||
              'No Description',
            image: `https://cms.wearetenet.com${blog.attributes?.image?.data?.attributes?.url}`,
            height: {
              320: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              480: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              768: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              992: getImageHeight(blog.attributes?.image_height?.size1) || 200,
              default:
                getImageHeight(blog.attributes?.image_height?.size1) || 200,
            },
            slug: `/resources/${blog.attributes.category}/${blog.attributes.slug}`,
            bgColor: getBgColor(blog.attributes?.category || 'insights'), // Use category to determine background color
          })),
        ];

        // Set the combined data to the slider
        setSliderData(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1
  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  // Sample data for slider
  return (
    <Box
      padding={[
        '80px 0 80px 0',
        '80px 0 80px 0',
        '80px 0 80px  0',
        '100px 0 100px 0',
        '100px 0 120px 0 ',
        '100px 0  150px 0',
      ]}
      overflow={'hidden'}
    >
      {/* Sec Heading */}
      <Container
        padding={[
          '0 20px  0px',
          '0 20px  0px',
          '0 20px  0px',
          '0 0px  80px',
          '0 0px  80px',
          '0 0px  80px',
        ]}
        marginBottom={['0px', '0px', 0, 0, 0, 0]}
        maxW={['100%', '100%', '100%', '78%', '78%', '78%']}
      >
        {/* HrLine */}
        {/* <Box
          height={'1px'}
          background={'#B5B5B5'}
          width={'100%'}
          color={'#'}
          marginBottom={['76px']}
          display={['none', 'none', 'none', 'block']}
        ></Box> */}
        <Flex
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={[
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
          alignItems={['baseline']}
          margin={['0 auto']}
          gap={['25px', '25px', '25px', '0']}
        >
          <Text
            as="h1"
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            fontFamily="var(--garnett-regular)"
            color={['#333']}
            fontWeight={['400']}
            lineHeight={'normal'}
          >
            What’s new
          </Text>
          <Link href="/upcoming">
            <Button
              fontSize={['16px', '16px', '16px', '20px']}
              backgroundColor={['#0057FF']}
              display={['flex']}
              alignItems={'center'}
              justifyContent={'center'}
              color={['#fff']}
              fontFamily="var(--foundersGrotesk-regular)"
              height={'41px'}
              width={['92px']}
              paddingTop={'3px'}
              fontWeight="400"
              borderRadius={['0']}
              _hover={{
                backgroundColor: ['#0057FF'],
                color: ['#fff'],
                textDecor: ['none'],
                transform: 'translate3d(0, -3px, 0)',
              }}
              transition="transform 0.5s ease"
            >
              View All
            </Button>
          </Link>
        </Flex>
      </Container>
      <Box maxW={['100%', '100%', '100%', '78%', '78%', '78%']} m="0 auto">
        {/* Navigation For Slider */}
        <Box
          padding={['0px 20px']}
          display={['block', 'block', 'block', 'none']}
        >
          <Flex
            justifyContent={['space-between']}
            paddingTop={['31px']}
            paddingBottom={['40px']}
            margin={['0 auto']}
          >
            <Text
              fontSize={['14px']}
              color={['#333']}
              fontFamily="var(--garnett-regular)"
              visibility={'hidden'}
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 4}`}
            </Text>
            <Box display={['flex']}>
              <Box onClick={() => goPrev()} mr={'20px'}>
                <Image src={leftIcon} cursor={'pointer'} />
              </Box>
              <Box onClick={() => goNext()}>
                <Image src={rightIcon} cursor={'pointer'} />
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Slider */}
        <Box paddingLeft={['20px', '20px', '20px', '0px']}>
          <Swiper
            ref={swiperRef}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            onSlideChange={swiper => handleSlideChange(swiper)}
            modules={[Navigation, FreeMode]}
            breakpoints={{
              320: {
                slidesPerView: 1.5, // Show 1 and a half slides
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2.5, // Show 2 and a half slides
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3.5, // Show 3 and a half slides
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 4, // Show 4 slides
                spaceBetween: 24,
              },
              1280: {
                slidesPerView: 4, // Keep 4 slides for screens larger than 1024px
                spaceBetween: 24,
              },
            }}
            style={{ overflow: 'visible!important' }}
            className="StoriesSectionSlider"
          >
            {sliderData.map((slide, index) => (
              <SwiperSlide key={index}>
                <Container maxW="100%" padding={'0'}>
                  <Box>
                    <a href={slide.slug} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Image
                        src={slide.image}
                        alt={slide.category}
                        mb="10px"
                        maxW={slide.width}
                        height={slide.height}
                        w={'100%'}
                        loading="lazy"
                        fetchpriority="low"
                      />
                      <Text
                        as="span"
                        display="inline-flex"
                        alignItems={'center'}
                        p="3px 9px 0px"
                        fontSize="16px"
                        height={['24px', '24px', '24px', '29px', '29px', '29px']}
                        color="#fff"
                        fontFamily="var(--foundersGrotesk-regular)"
                        fontWeight="400"
                        textTransform={["capitalize"]}
                        bg={slide.bgColor}
                      >
                        {slide.category}
                      </Text>
                      <Text
                        as="h3"
                        m="10px 0"
                        fontFamily="var(--garnett-medium)"
                        fontSize={[
                          '16px',
                          '16px',
                          '16px',
                          '20px',
                          '20px',
                          '20px',
                        ]}
                        fontWeight="500"
                        noOfLines={3} // Limit to 3 lines and add "..."
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {slide.title}
                      </Text>
                      <Text
                        as="p"
                        fontSize={[
                          '13px',
                          '13px',
                          '13px',
                          '18px',
                          '18px',
                          '18px',
                        ]}
                        fontFamily="var(--foundersGrotesk-regular)"
                        fontWeight="400"
                        lineHeight={['normal']}
                        noOfLines={3} // Limit to 3 lines and add "..."
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {slide.description}
                      </Text>
                    </a>
                  </Box>
                </Container>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};
export default Stories;
