import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CuratedServices = ({ linkSvgColor, services }) => {

    const serviceListTitle = {
        fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
        fontWeight: '400',
        fontFamily: 'var(--garnett-regular)',
        color: '#3E001A',
        lineHeight: 'normal',
        p: [
            '0px 0 0px',
            '0px 0 0px',
            '0px 0 0px',
            '12px 0 15px',
            '20px 0 18px',
            '26px 0 24px',
        ],
    };
    const serviceListNumber = {
        fontSize: ['20px'],
        fontWeight: '300',
        color: '#3E001A',
        fontFamily: 'var(--garnett-light)',
        mr: ['5px', '5px', '5px', '0px', '0px', '0px'],
        display: 'block',
    };
    const serviceListDescription = {
        fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
        fontFamily: 'var(--foundersGrotesk-regular)',
        fontWeight: '400',
        lineHeight: 'normal',
        color: '#333',
    };
    const boxDesign = {
        bg: '#F5F5F5',
        p: [
            '13px 20px 26px 20px',
            '13px 20px 26px 20px',
            '13px 20px 26px 20px',
            '20px 20px 40px 20px',
            '20px 20px 40px 20px',
            '20px 20px 40px 20px',
        ],
        position: 'relative',
    };
    // Show More

    const [visibleItems, setVisibleItems] = useState(3);

    const handleShowMore = () => {
        setVisibleItems(prevCount => prevCount + 3);
    };

    const isMobile = window.innerWidth <= 992;

    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [curatedPoints, setCuratedPoints] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [blogResponse, curatedPointsResponse] = await Promise.all([
                    axios.get(
                        `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
                    ),
                    axios.get(
                        `https://cms.wearetenet.com/api/outcome-curated-points?filters[slug][$eq]=${slug}&populate=*`
                    ),
                ]);

                if (blogResponse.data.data.length > 0) {
                    setData(blogResponse.data.data[0]);
                } else {
                    setError('No data found for industry solution details');
                }

                if (curatedPointsResponse.data.data.length > 0) {
                    setCuratedPoints(curatedPointsResponse.data.data);
                } else {
                    setError('No data found for curated points');
                }
            } catch (err) {
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <Box
            padding={['80px 0 80px', '80px 0 80px', '80px 0 80px', '150px 0 120px']}
        >
            <Box
                maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
                margin={'0 auto'}
                padding={['0px 20px', '0px 20px', '0px 20px', '0']}
            >
                {/* SectionHeadings */}
                <Box
                    display={['block', 'block', 'flex', 'flex', 'flex']}
                    justifyContent={'space-between'}
                    gap={'20px'}
                >
                    <Heading
                        as={'h2'}
                        fontSize={['32px', '32px', '32px', '35px', '48px', '48px']}
                        color={'#000'}
                        fontFamily={'var(--garnett-regular);'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        paddingBottom={['22px', '22px', '0px']}
                    >
                        {data?.attributes?.curate_heading}
                    </Heading>
                    <Text
                        as="p"
                        fontFamily={'var(--foundersGrotesk-regular);'}
                        fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                        color={'#333'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        maxW={['430px', '430px', '430px', '450px', '512px', '512px']}
                    >
                        {data?.attributes?.curated_description}
                    </Text>
                </Box>

                {/* Display Curated Services */}
                <Box
                    paddingTop={['22px', '22px', '22px', '61px']}
                    display={'grid'}
                    gridTemplateColumns={[
                        'repeat(1,1fr)',
                        'repeat(1,1fr)',
                        'repeat(1,1fr)',
                        'repeat(3,1fr)',
                        'repeat(3,1fr)',
                        'repeat(3,1fr)',
                    ]}
                    gridGap={[
                        '16px 20px',
                        '16px 20px',
                        '40px 20px',
                        '40px 20px',
                        '40px 20px',
                        '40px 20px',
                    ]}
                >
                    {curatedPoints && curatedPoints.map((point, index) => (

                        <Box
                            key={point.id}
                            sx={{
                                ...boxDesign,
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor:
                                        linkSvgColor === 'red' ? '#f8b7c7' : '#d0e3ff',
                                    '& h4': {
                                        color: '#0D0E4D', // h4 color on hover
                                    },
                                    '& p': {
                                        color: '#333', // p color on hover
                                    },
                                    '& span': {
                                        color: '#0D0E4D', // span color on hover
                                    },
                                },
                            }}
                        >
                            <Box as="span">
                                {point.attributes.link ? (
                                    <Link
                                        href={point.attributes.link.startsWith('http') ? point.attributes.link : `https://${point.attributes.link}`}
                                        position="absolute"
                                        top="0"
                                        right="0"
                                        target="_blank" // Optional: Open link in a new tab
                                        rel="noopener noreferrer" // Recommended for external links
                                    >
                                        {linkSvgColor === 'red' ? (
                                            <svg
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="40" height="40" fill="#E0005E" />
                                                <g clipPath="url(#clip0_4036_73)">
                                                    <path
                                                        d="M31 16.7305L30.9988 8.73172L23 8.73047"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                    />
                                                    <path
                                                        d="M21 19L30 10"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M27 20.7305V29.7305C27 29.9957 26.8946 30.25 26.7071 30.4376C26.5196 30.6251 26.2652 30.7305 26 30.7305H10C9.73478 30.7305 9.48043 30.6251 9.29289 30.4376C9.10536 30.25 9 29.9957 9 29.7305V13.7305C9 13.4653 9.10536 13.2109 9.29289 13.0234C9.48043 12.8358 9.73478 12.7305 10 12.7305H19"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4036_73">
                                                        <rect
                                                            width="32"
                                                            height="32"
                                                            fill="white"
                                                            transform="translate(4 3.73047)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        ) : (
                                            <svg
                                                width="40"
                                                height="40"
                                                viewBox="0 0 40 40"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="40" height="40" fill="#0057FF" />
                                                <g clipPath="url(#clip0_4018_766)">
                                                    <path
                                                        d="M31 16.7305L30.9988 8.73172L23 8.73047"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                    />
                                                    <path
                                                        d="M21 19L30 10"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M27 20.7305V29.7305C27 29.9957 26.8946 30.25 26.7071 30.4376C26.5196 30.6251 26.2652 30.7305 26 30.7305H10C9.73478 30.7305 9.48043 30.6251 9.29289 30.4376C9.10536 30.25 9 29.9957 9 29.7305V13.7305C9 13.4653 9.10536 13.2109 9.29289 13.0234C9.48043 12.8358 9.73478 12.7305 10 12.7305H19"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="square"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4018_766">
                                                        <rect
                                                            width="32"
                                                            height="32"
                                                            fill="white"
                                                            transform="translate(4 3.73047)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        )}
                                    </Link>
                                ) : null}
                            </Box>

                            <Flex
                                pb={['15px', '15px', '15px', '0px', '0px', '0px']}
                                alignItems={[
                                    'baseline',
                                    'baseline',
                                    'baseline',
                                    'start',
                                    'start',
                                    'start',
                                ]}
                                flexDirection={'column'}
                            >
                                <Box>
                                    <Text as={'span'} sx={serviceListNumber}>
                                        0{index + 1}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text as={'h4'} sx={serviceListTitle}>
                                        {point.attributes.heading}
                                    </Text>
                                </Box>
                            </Flex>
                            <Box>
                                <Text as={'p'} sx={serviceListDescription}>
                                    {point.attributes.description}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
                {/* Show More Only For Mobile */}
                {/* Show More Only For Mobile */}
                {isMobile && visibleItems < services.length && (
                    <Box
                        textAlign={'center'}
                        display={['block', 'block', 'block', 'none', 'none']}
                    >
                        <Button
                            onClick={handleShowMore}
                            fontFamily={'var(--foundersGrotesk-regular);'}
                            fontWeight={'400'}
                            color={'#E0005E'}
                            background={'transparent'}
                            boxShadow={'none'}
                            lineHeight={'normal'}
                            textDecoration={'underline'}
                            fontSize={'20px'}
                            paddingTop={['24px']}
                            _hover={{ backgroundColor: 'transparent' }}
                        >
                            Show More
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default CuratedServices;
