import { Box, Container, Flex, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import Content from './Content';
import DataDownloadForm from './DataDownloadForm';

const ContentLayout = () => {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  return (
    <Box
      pt={['22px', '22px', '50px', '80px', '110px', '110px']}
      pb={['60px', '60px', '60px', '50px', '50px', '50px']}
      margin={['0px 16px', '0px 16px', '0px 16px', '0']}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
        padding={'0'}
      >
        <Flex
          display={['flex']}
          flexDirection={isSmallerThan768 ? 'column-reverse' : 'row'}
          gap={['40px', '40px', '40px', '40px', '40px', '74px']}
        >
          <Box w={isSmallerThan768 ? '100%' : '66%'}>
            <Content />
          </Box>
          <Box w={isSmallerThan768 ? '100%' : '34%'}>
            <DataDownloadForm />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ContentLayout;
