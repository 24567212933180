import {
  Box,
  Image,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
// import LeftCaret from '../../../assets/Service/left.svg';
// import RightCaret from '../../../assets/Service/right.svg';

const Slider = () => {
  const eowData = [
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/1.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/2.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/3.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/4.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/5.webp',
    },
  ];
  const swipperSliderCustomeStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };
  const swiperRef = useRef(null);
  // const goNext = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slideNext();
  //   }
  //   // console.log(swiperRef.current.swiper);
  // };

  // const goPrev = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.slidePrev();
  //   }
  // };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box
      marginTop={['40px', '40px', '60px', '80px', '80px']}
      paddingTop={['45px', '45px', '65px', '85px', '105px', '115px']}
      paddingBottom={['45px', '45px', '65px', '85px', '105px', '115px']}
      _hover={{ cursor: `url(${arrowImage}), auto` }}
      background={['#F7CED0']}
    >
      <Swiper
        ref={swiperRef}
        observeParents={true}
        observer={true}
        modules={[Navigation, FreeMode]}
        speed={750}
        slidesPerView={'auto'}
        spaceBetween={14}
        style={swipperSliderCustomeStyle}
        onSlideChange={swiper => handleSlideChange(swiper)}
        className="SliderForAngelsShowAppImage"
      >
        {eowData.map((data, index) => (
          <SwiperSlide key={index}>
            <Box
              height={['481px']}
              minH={'481px'}
              maxH={'481px'}
              width={['220px', '250px', '235px', '255px', '255px']}
            >
              <Image
                src={data.image}
                w={'100%'}
                height="100%"
                // objectFit={'cover'}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default Slider;
