import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Desktop from '../../assets/BgPatterns/Career/BannerGif.gif';
import Mobile from '../../assets/BgPatterns/Career/Mobile.gif';
import BannerMobile from '../../assets/Career/banner/bannermobile.webp';
import Breadcrumb from './Breadcrumb';

const Banner = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-pages?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  const bannerHeading = {
    fontSize: ['36px', '36px', '36px', '58px', '58px', '58px'],
    color: '#fff',
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: '600',
    lineHeight: 'normal',
  };
  const bannerParagraph = {
    fontSize: ['14px', '16px', '18px', '20px', '24px', '24px'],
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    p: ['30px 0', '30px 0', '26px 0', '26px 0', '26px 0'],
    maxW: ['355px', '355px', '380px', '480px', '580px', '580px'],
    lineHeight: 'normal',
    letterSpacing: ['-0.308px', '-0.308px', '-0.308px', ''],
  };
  return (
    <Box
      minH={['553px', '553px', '553px', '450px', '450px']}
      padding={[
        '95px 0 27px',
        '95px 0 27px',
        '95px 0 27px',
        '55px 0 45px',
        '55px 0 45px',
        '55px 0 45px',
      ]}
      background={'#000'}
      overflow={'hidden'}
      display={'flex'}
      alignItems={'center'}
      position={'relative'}
      ref={heroSectionRef}
      id="hero-section"
    >
      {/* BackGround Video */}
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          src={Desktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          objectPosition={'right bottom'}
          height={'390'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* BreadCrum */}
        <Breadcrumb />
        {/* BanneR Content */}
        <Flex
          display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
          alignItems={'center'}
        >
          {/* Text Part */}
          <Box w={['100%', '100%', '100%', '60%', '60%', '60%']}>
            <Text as="h1" sx={bannerHeading}>
              {firstItem?.main_heading}
            </Text>
            <Text as="p" sx={bannerParagraph}>
              {firstItem?.main_description}
            </Text>
            <Box paddingBottom={['20px', '20px', '20px', '0px']}>
              <svg
                width="175"
                height="9"
                viewBox="0 0 175 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.820312"
                  y="0.516357"
                  width="174.18"
                  height="8"
                  fill="#B882FF"
                />
              </svg>
            </Box>
          </Box>
          {/* Image */}
          <Box w={['100%', '100%', '100%', '40%', '40%', '40%']}>
            {/* Desktop */}
            <Image
              src={`https://cms.wearetenet.com${
                firstItem?.image?.data?.attributes?.url || ''
              }`}
              w={'100%'}
              h={'100%'}
              objectFit={'cover'}
              display={['none', 'none', 'block', 'block', 'block']}
            />
            {/* Mobile */}
            <Image
              src={BannerMobile}
              w={'100%'}
              h={'100%'}
              objectFit={'cover'}
              display={['block', 'block', 'none', 'none', 'none']}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default Banner;
