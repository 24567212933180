import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import conClusionFullWidthImage from '../../../assets/CaseStudy/Iros/Conclusion/FullWidthImage.webp';

const conclusionContent = () => {
  return (
    <>
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        paddingTop={['80px', '80px', '80px', '183px']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              CONCLUSION
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              as="h3"
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
              paddingBottom={['16px', '16px', '16px', '24px']}
            >
              The strategic rebranding and website design exercises have
              received widespread appreciation, adoption, and acceptance from
              the market and stakeholders.
            </Text>
            <Text
              as="p"
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              The new identity reflects their core values of Innovation,
              Collaboration, Commitment, & Precision. IROS has transformed for
              merely another player in the market, to a strategic brand that
              it’s audience can relate to and identify with.
              <br />
              <br />
              In a significant development G42, the leading Abu Dhabi-based AI
              technology company, and Mubadala Investment Company, an Abu
              Dhabi-based global sovereign investor - announced the launch of
              M42, an integrated healthcare company formed through the merger of
              G42 Healthcare and Mubadala Health. IROS is now proudly part of
              M42, further enhancing its capabilities and reach.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        width={['100%']}
        margin={['0 auto']}
        paddingTop={['24px', '24px', '41px', '41px']}
      >
        {/* Desktop */}
        <Box>
          <Image
            width={'100%'}
            height={'auto'}
            src={conClusionFullWidthImage}
          />
        </Box>
      </Box>
    </>
  );
};
export default conclusionContent;
