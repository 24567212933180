import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import img1 from '../../../assets/Solution/industries/1.webp';
import img2 from '../../../assets/Solution/industries/2.webp';
import img3 from '../../../assets/Solution/industries/3.webp';
import img4 from '../../../assets/Solution/industries/4.webp';
import img5 from '../../../assets/Solution/industries/5.webp';
import img6 from '../../../assets/Solution/industries/6.webp';
import img7 from '../../../assets/Solution/industries/7.webp';
import i1 from '../../../assets/Solution/industries/icon1.svg';
import i10 from '../../../assets/Solution/industries/icon10.svg';
import i11 from '../../../assets/Solution/industries/icon11.svg';
import i12 from '../../../assets/Solution/industries/icon12.svg';
import i2 from '../../../assets/Solution/industries/icon2.svg';
import i3 from '../../../assets/Solution/industries/icon3.svg';
import i4 from '../../../assets/Solution/industries/icon4.svg';
import i5 from '../../../assets/Solution/industries/icon5.svg';
import i6 from '../../../assets/Solution/industries/icon6.svg';
import i7 from '../../../assets/Solution/industries/icon7.svg';
import i8 from '../../../assets/Solution/industries/icon8.svg';
import i9 from '../../../assets/Solution/industries/icon9.svg';
import './Custom.css';

const PoweringSection = () => {
  const containerRef = useRef(null);
  const stickyBoxRef = useRef(null);
  let desktopOffset = 68;
  let mobileOffset = 60;
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const stickyBox = stickyBoxRef.current;
      if (!container || !stickyBox) return;

      const containerBottom = container.getBoundingClientRect().bottom;
      const stickyBoxHeight = stickyBox.offsetHeight;
      let offset = window.innerWidth <= 768 ? mobileOffset : desktopOffset;

      if (containerBottom <= stickyBoxHeight + offset) {
        stickyBox.style.position = 'static';
        stickyBox.style.bottom = '0';
        stickyBox.style.top = 'auto';
      } else {
        stickyBox.style.position = 'sticky';
        stickyBox.style.top = `${offset}px`;
        stickyBox.style.bottom = 'auto';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [activeTab, setActiveTab] = useState('outcomes');
  const outcomesRef = useRef(null);
  const industriesRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const outcomesSection = outcomesRef.current;
      const industriesSection = industriesRef.current;
      const scrollPosition = window.scrollY;

      if (outcomesSection && industriesSection) {
        const outcomesTop = outcomesSection.offsetTop;
        const outcomesHeight = outcomesSection.offsetHeight;
        const industriesTop = industriesSection.offsetTop;
        const industriesHeight = industriesSection.offsetHeight;

        const outcomesBottom = outcomesTop + outcomesHeight;

        // Calculate the 95% scroll position of the outcomes section
        const outcomes95Percent = outcomesTop + 0.95 * outcomesHeight;

        if (
          scrollPosition >= outcomesTop &&
          scrollPosition < outcomes95Percent
        ) {
          setActiveTab('outcomes');
        } else if (
          scrollPosition >= outcomes95Percent &&
          scrollPosition < industriesTop + industriesHeight
        ) {
          setActiveTab('industries');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Box
      //  paddingTop={["60px", "60px", "60px", "80px", "80px", "80px"]}
      paddingBottom={['80px', '80px', '80px', '80px', '120px', '150px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
    >
      <Container
        ref={containerRef}
        maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
        padding={[0]}
      >
        <Box
          ref={stickyBoxRef}
          zIndex="999"
          background={['#ffffff']}
          boxShadow={'0px 4px 13px 0px rgba(0, 0, 0, 0.08)'}
        >
          <Flex
            justifyContent={['center']}
            gap={['0px', '40px', '40px', '55px', '55px', '55px']}
            alignItems={['center']}
          >
            <Text
              fontSize={['16px', '16px', '20px', '25px', '30px', '32px']}
              fontFamily={'var(--garnett-regular)'}
              fontWeight={400}
              color={activeTab === 'outcomes' ? '#00763D' : '#000'}
              padding={['15px']}
              style={{
                background:
                  activeTab === 'outcomes'
                    ? 'rgba(0, 186, 97, 0.1)'
                    : 'transparent',

                borderBottom:
                  activeTab === 'outcomes' ? '4px solid #00763D' : 'none',
                transition:
                  'background-color 0.3s, padding 0.3s, border-bottom 0.3s',
              }}
              onClick={() => {
                const outcomesSection = outcomesRef.current;
                const stickyHeaderHeight = 60;
                if (outcomesSection) {
                  window.scrollTo({
                    top: outcomesSection.offsetTop - stickyHeaderHeight,
                    behavior: 'smooth',
                  });
                }
                setActiveTab('outcomes');
              }}
            >
              Based on Outcomes
            </Text>
            <Text
              fontSize={['16px', '16px', '20px', '25px', '30px', '32px']}
              fontFamily={'var(--garnett-regular)'}
              fontWeight={400}
              padding={['15px']}
              color={activeTab === 'industries' ? '#00763D' : '#000'}
              background={
                activeTab === 'industries' ? 'rgba(0, 186, 97, 0.1)' : undefined
              }
              borderBottom={
                activeTab === 'industries' ? '4px solid #00763D' : undefined
              }
              onClick={() => {
                const industriesSection = industriesRef.current;
                const stickyHeaderHeight = 55; // Replace with the height of your sticky header

                if (industriesSection) {
                  window.scrollTo({
                    top: industriesSection.offsetTop - stickyHeaderHeight,
                    behavior: 'smooth',
                  });
                }
              }}
              
            >
              Industries
            </Text>
          </Flex>
        </Box>
        <Box
          maxW={['90%', '90%', '90%', '90%', '83%', '83%']}
          margin={['0 auto']}
          paddingTop={['65px', '65px', '75px', '114px', '114px', '114px']}
          ref={outcomesRef}
        >
          <Box marginBottom={['25px', '25px', '25px', '35px', '40px', '65px']}>
            <Text
              fontSize={['32px', '32px', '40px', '40px', '48px', '48px']}
              fontFamily={'var(--garnett-regular)'}
              fontWeight={400}
              lineHeight={'normal'}
              width={['100%', '100%', '100%', '70%', '70%', '70%']}
              paddingBottom={['22px', '22px', '22px', '15px', '15px', '15px']}
            >
              Our Solutions Based on Outcomes
            </Text>
            <Text
              lineHeight={['normal']}
              width={['100%', '100%', '80%', '80%', '80%', '70%']}
              fontSize={['16px', '16px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight="400"
              color={'#333333'}
            >
              Delivering What Counts: Tangible Outcomes That Matter.
            </Text>
          </Box>
          <Box
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            ml={'auto'}
            marginBottom={['20px', '20px', '20px', '25px', '25px', '25px']}
          >
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              gap={['0']}
            >
              <Box width={['100%', '100%', '100%', '100%', '100%', '100%']}>
                <Box>
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column',
                          'column',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['22px', '22px', '22px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img1} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img1}
                        />

                        <Box
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Brand Growth & Recognition
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Drive brand growth, brand recall, and brand
                            recognition through our strategic design solutions
                            backed by research and market insights.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              'auto',
                              'auto',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Define what you stand for, your proposition, vision
                            and much more. Get a clear roadmap that navigates
                            and supports the entire ecosystem surrounding your
                            brand making it meaningful, intentional, and
                            memorable.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column-reverse',
                          'column-reverse',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Digital Transformation
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Elevate your operations, strategy, & processes.
                            Transition from legacy systems to a future ready
                            environment.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Stay ahead of the industry by levaraging latest
                            technology, data, and insights that fuel Digital
                            Transformation (DX). Elevate your bottom line,
                            operations, digital experience, and processes.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img2} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img2}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column',
                          'column',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img3} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img3}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Mobile App Development
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Launch mobile apps that deliver impact. Tailored for
                            diverse industries, ensuring seamless integration
                            and profitable interactions.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              'auto',
                              'auto',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            The mobile platform is your brand's gateway to
                            engaging directly with users. Don't wait for your
                            audience to reach out to you. Delivery personalised
                            experiences, simplify consumer journey, and offer
                            much more through an app that lives on their most
                            used device.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column-reverse',
                          'column-reverse',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Ideation & Design Strategy
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Turn vision into reality. Design strategies that
                            captivate and convert.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              'auto',
                              'auto',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Experience our research backed design practices that
                            go beyond creating just good looking visuals. We
                            design not only to delight, but also to drive
                            real-world conversion and user loyalty.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img4} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img4}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column',
                          'column',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img5} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img5}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Data Science & Analytics
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Make smarter decisions. Harness data to gain
                            insights and stay ahead of the curve.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Data is a powerful storyteller. We decipher its
                            complex narratives to uncover insights that empower
                            your business decisions, ensuring you're not just
                            data-rich but also insight-driven, paving the way
                            for strategic growth.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column-reverse',
                          'column-reverse',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            AI-ML Integration
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Automate processes, improve performance, and gain
                            actionable insights with our AI and machine learning
                            solutions. Be future ready and stay ahead of the
                            curve.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            AI and ML are transforming the business landscape.
                            Our integration strategies embed these technologies
                            into your operations, creating systems that not only
                            think but learn, giving you a formidable edge in
                            efficiency and innovation.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img6} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img6}
                        />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Box
                  paddingTop={['40px', '40px', '40px', '40px', '40px', '40px']}
                  paddingBottom={[
                    '20px',
                    '25px',
                    '30px',
                    '35px',
                    '40px',
                    '75px',
                  ]}
                  borderBottom={['1px solid #D9D9D9']}
                >
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                  >
                    <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
                      <Box
                        display={['flex']}
                        flexDirection={[
                          'column',
                          'column',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '70px', '85px', '100px']}
                        alignItems={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'center',
                          'center',
                          'center',
                        ]}
                      >
                        <Box
                          width={[
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                            '100%',
                          ]}
                          display={[
                            'flex',
                            'flex',
                            'flex',
                            'flex',
                            'none',
                            'none',
                          ]}
                        >
                          <Image src={img7} />
                        </Box>
                        <Image
                          display={[
                            'none',
                            'none',
                            'none',
                            'none',
                            'flex',
                            'flex',
                          ]}
                          src={img7}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          width={['100%', '100%', '90%', '80%', '80%', '80%']}
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '32px',
                              '36px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            fontWeight="400"
                            color={'#000000'}
                          >
                            Brand Visibility
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '18px',
                              '18px',
                              '18px',
                              '20px',
                              '22px',
                              '24px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            Elevate your brand's market presence, ensuring it
                            captures attention and builds trust among target
                            audiences.
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--foundersGrotesk-regular)"
                            fontWeight="400"
                            color={'#333333'}
                            // minHeight={[
                            //     "auto",
                            //     "auto",
                            //     "95px",
                            //     "105px",
                            //     "80px",
                            //     "95px",
                            // ]}
                          >
                            In today’s crowded digital marketplace, visibility
                            is about strategic placement and impact. Our
                            targeted strategies ensure your brand captures the
                            spotlight, engages effectively, and creates enduring
                            connections that translate into a commanding market
                            presence.
                          </Text>
                          <Link href="/upcoming">
                            <Button
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              background={['#0057FF']}
                              color={'#ffffff'}
                              width={[
                                '30%',
                                '30%',
                                '30%',
                                '30%',
                                '25%',
                                '25%',
                                '21.5%',
                              ]}
                              borderRadius={['0']}
                              css={{
                                '&:hover': {
                                  background: '#0057FF',
                                  color: '#ffffff',
                                  transform: 'translate3d(0, -3px, 0)',
                                },
                              }}
                              transition="transform 0.5s ease"
                              className="solutionButton"
                            >
                              Learn more
                            </Button>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          maxW={['90%', '90%', '90%', '90%', '83%', '83%']}
          margin={['0 auto']}
          paddingTop={['65px', '65px', '75px', '114px', '114px', '150px']}
          ref={industriesRef}
        >
          <Box marginBottom={['72px', '72px', '60px', '55px', '40px', '65px']}>
            <Text
              fontSize={['32px', '32px', '40px', '40px', '48px', '48px']}
              fontFamily={'var(--garnett-regular)'}
              fontWeight={400}
              lineHeight={'normal'}
              width={['100%', '100%', '100%', '70%', '70%', '70%']}
              paddingBottom={['22px', '22px', '10px', '15px', '15px', '15px']}
            >
              Industries We Serve
            </Text>
            <Text
              lineHeight={['normal']}
              width={['100%', '100%', '80%', '80%', '80%', '70%']}
              fontSize={['16px', '16px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--founders-grotesk)"
              fontWeight="400"
              color={'#333333'}
            >
              Leverage our experience across diverse industries, to get the best
              service and advisory.
            </Text>
          </Box>
          <Box
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            ml={'auto'}
            marginBottom={['20px', '20px', '20px', '25px', '25px', '25px']}
          >
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              gap={['0']}
            >
              <Box width={['100%', '100%', '100%', '100%', '100%', '100%']}>
                <Box>
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                    flexDirection={[
                      'column',
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                    ]}
                  >
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //   paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i1}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            E-Commerce
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Boost your e-commerce brand with strategies that
                            optimize Lifetime Value (LTV) and Annual Recurring
                            Revenue (ARR). We enhance conversion rates through
                            user-centric shopping experiences that turn visitors
                            into loyal customers.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        // paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i2}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Healthcare & Fitness
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Revolutionize healthcare and fitness with digital
                            solutions that enhance user engagement and
                            experience. Our solutions are designed to improve
                            outcomes that streamline wellness & care
                            coordination in this dynamic sector.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //  paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i3}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Events
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '145px',
                              '150px',
                              '168px',
                            ]}
                          >
                            Transform the way you create, manage, and experience
                            events. Our digital solutions make every occasion
                            memorable & efficient, from planning to execution.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            ml={'auto'}
            marginBottom={['20px', '20px', '20px', '25px', '25px', '25px']}
          >
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              gap={['0']}
            >
              <Box width={['100%', '100%', '100%', '100%', '100%', '100%']}>
                <Box>
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                    flexDirection={[
                      'column',
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                    ]}
                  >
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //   paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i4}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Social & Dating
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '145px',
                              '190px',
                            ]}
                          >
                            Navigate the complex world of social interactions
                            and online dating with intuitive platforms that
                            emphasize user safety and authentic interactions. We
                            focus on creating meaningful experiences that
                            resonate with users.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        // paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i5}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Gaming
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '145px',
                              '190px',
                            ]}
                          >
                            Advance your gaming ventures with digital solutions
                            that drive player engagement and retention. Our
                            innovative approaches create captivating gaming
                            experiences that keep players returning for more.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //  paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i6}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Finance
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Redefine financial services with secure, intuitive,
                            & user-centric digital services that simplify
                            investing, banking, and personal finance management.
                            We focus on enhancing user trust and financial
                            inclusion through reliable fintech solutions.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            ml={'auto'}
            marginBottom={['20px', '20px', '20px', '25px', '25px', '25px']}
          >
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              gap={['0']}
            >
              <Box width={['100%', '100%', '100%', '100%', '100%', '100%']}>
                <Box>
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                    flexDirection={[
                      'column',
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                    ]}
                  >
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //   paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i7}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            SaaS
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Elevate your SaaS offerings with agile, scalable
                            solutions that focus on user engagement and
                            retention. Our expertise in cloud services and
                            platform integration drives growth and innovation in
                            the SaaS ecosystem.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        // paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i8}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Education
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '115px',
                              '165px',
                            ]}
                          >
                            Revolutionize learning experiences through digital
                            platforms designed for maximum engagement and
                            educational impact. We create tools that engage
                            learners, empower educators, and expand knowledge.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //  paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i9}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Entertainment
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Engage your audience with digital entertainment
                            solutions that enhance content delivery and user
                            interaction. From streaming platforms to interactive
                            media, we craft experiences that captivate and
                            entertain.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            ml={'auto'}
          >
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              gap={['0']}
            >
              <Box width={['100%', '100%', '100%', '100%', '100%', '100%']}>
                <Box>
                  <Flex
                    // justifyContent={"space-between"}
                    gap={['20px', '20px', '20px', '25px', '25px', '30px']}
                    flexDirection={[
                      'column',
                      'column',
                      'column',
                      'row',
                      'row',
                      'row',
                    ]}
                  >
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //   paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i10}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Travel & Hospitality
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '80px',
                              '95px',
                            ]}
                          >
                            Revolutionize travel and hospitality with digital
                            booking systems and guest management solutions that
                            elevate the user journey. We deliver seamless
                            discovery & booking experiences, personalized guest
                            services, and innovative management tools.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        // paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i11}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            On-Demand
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '140px',
                              '185px',
                            ]}
                          >
                            Optimize your on-demand services with responsive
                            digital solutions that deliver speed, efficiency,
                            and scalability. We ensure real-time service
                            fulfillment and customer satisfaction through
                            innovative app development.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={['100%', '100%', '100%', '45%', '45%', '35%']}
                      background={['#F5F5F5']}
                    >
                      <Box
                        //  paddingBottom={["20px", "20px", "30px", "30px", "50px", "60px"]}
                        display={['flex']}
                        flexDirection={[
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                          'row',
                        ]}
                        gap={['20px', '20px', '20px', '20px', '20px', '20px']}
                        alignItems={['flex-start']}
                      >
                        <Image
                          src={i12}
                          paddingBottom={[
                            '5px',
                            '5px',
                            '5px',
                            '10px',
                            '15px',
                            '15px',
                          ]}
                        />
                        <Box
                          display={['flex']}
                          flexDirection={[
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                            'column',
                          ]}
                          gap={['20px']}
                          paddingRight={['5%']}
                          paddingTop={['15px']}
                          height={[
                            'auto',
                            'auto',
                            'auto',
                            '350px',
                            '390px',
                            '350px',
                          ]}
                          justifyContent={['space-between']}
                          className="industriesHeight"
                        >
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '24px',
                              '24px',
                              '24px',
                              '25px',
                              '26px',
                              '26px',
                            ]}
                            fontFamily="var(--garnett-regular)"
                            width={[
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                              '100%',
                            ]}
                            fontWeight="400"
                            color={'#333333'}
                          >
                            Food & Restaurant
                          </Text>
                          <Text
                            lineHeight={['normal']}
                            fontSize={[
                              '16px',
                              '16px',
                              '18px',
                              '18px',
                              '18px',
                              '18px',
                            ]}
                            fontFamily="var(--founders-grotesk)"
                            fontWeight="400"
                            color={'#333333'}
                            minHeight={[
                              '95px',
                              '95px',
                              '95px',
                              '105px',
                              '140px',
                              '185px',
                            ]}
                          >
                            Transform your food and restaurant services with
                            digital ordering systems, table management tools,
                            and customer loyalty programs. We blend culinary art
                            with digital convenience, offering a taste of
                            innovation.
                          </Text>
                          <Link href="/upcoming">
                            <Text
                              lineHeight={['normal']}
                              fontSize={[
                                '16px',
                                '16px',
                                '18px',
                                '18px',
                                '18px',
                                '18px',
                              ]}
                              fontFamily="var(--founders-grotesk)"
                              fontWeight="400"
                              color={'#0057FF'}
                              minHeight={[
                                '45px',
                                '45px',
                                '45px',
                                '45px',
                                '50px',
                                '55px',
                              ]}
                            >
                              Learn more
                            </Text>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PoweringSection;
