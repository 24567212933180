import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Usps = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/abouts?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const valueSubtitle = {
    color: '#000',
    fontSize: ['18px', '18px', '18px', '30px', '30px', '30px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: ['15px', '15px', '18px', '30px', '30px', '30px'],
  };
  const valueSubText = {
    fontSize: ['16px', '16px', '16px', '18px', '19px', '19px'],
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };

  const valuesData = [
    {
      imageUrl: `https://cms.wearetenet.com${
        firstItem?.work_image_1?.data?.attributes?.url || ''
      }`,
      heading: firstItem?.work_heading_1,
      paragraph: firstItem?.work_paragraph_1,
    },
    {
      imageUrl: `https://cms.wearetenet.com${
        firstItem?.work_image_2?.data?.attributes?.url || ''
      }`,
      heading: firstItem?.work_heading_2,
      paragraph: firstItem?.work_paragraph_2,
    },
    {
      imageUrl: `https://cms.wearetenet.com${
        firstItem?.work_image_3?.data?.attributes?.url || ''
      }`,
      heading: firstItem?.work_heading_3,
      paragraph: firstItem?.work_paragraph_3,
    },
    {
      imageUrl: `https://cms.wearetenet.com${
        firstItem?.work_image_4?.data?.attributes?.url || ''
      }`,
      heading: firstItem?.work_heading_4,
      paragraph: firstItem?.work_paragraph_4,
    },
  ];

  return (
    <Box
      p={[
        '80px 0 0px 0',
        '80px 0 0px 0',
        '80px 0 0px 0',
        '150px 0 150px 0',
        '150px 0 150px 0',
        '150px 0 150px 0',
      ]}
      css={{
        '@media (min-width: 769px)': {
          background:
            'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%)',
        },
      }}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '80%', '80%']}
        m={'0 auto'}
      >
        {/* Section Headings */}
        <Box maxW={'862px'}>
          <Text
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            mb={['22px', '22px', '22px', '20px', '20px', '20px']}
            lineHeight={'normal'}
            fontWeight={'400'}
            fontFamily={'var(--garnett-regular)'}
          >
            Want to know what it’s like to work with us?
          </Text>
          <Text
            as={'p'}
            color={'#0057FF'}
            fontSize={['18px', '18px', '18px', '32px', '32px', '32px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular)'}
          >
            It’s a mix of these things:
          </Text>
        </Box>
        {/*  */}
        <Box>
          <Box
            maxW={['100%', '100%', '100%', '85%', '83%', '83%']}
            ml={['auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
            pt={['40px', '40px', '40px', '100px']}
          >
            {/* First Row */}
            <Box
              display={['grid']}
              gridTemplateColumns={[
                'repeat(1,1fr)',
                'repeat(2,1fr)',
                'repeat(2,1fr)',
              ]}
              columnGap={['20px', '20px', '20px', '47px']}
              rowGap={['20px', '20px', '20px', '75px', '75px']}
            >
              {valuesData.map((value, index) => (
                <Flex
                  key={index}
                  mb={['40px', '40px', '40px', '0px', '0px', '0px']}
                  gap={['36px', '36px', '36px', '0px']}
                >
                  <Box
                    mr={['0px', '0px', '0px', '26px', '26px', '26px']}
                    pt={'5px'}
                    w={['42px']}
                  >
                    <Image
                      src={value.imageUrl}
                      w={'100%'}
                      h={'auto'}
                      objectFit={'cover'}
                    />
                  </Box>
                  <Box width={['calc(100% - 42px)']}>
                    <Text as={'h3'} sx={valueSubtitle}>
                      {value.heading}
                    </Text>
                    <Text as={'p'} sx={valueSubText}>
                      {value.paragraph}
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Usps;
