import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const WorkPageInteraction = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isThankYouOpen, setIsThankYouOpen] = useState(false);
  const toast = useToast();

  // Function to close both modals
  const handleCloseAll = () => {
    setIsThankYouOpen(false);
    onClose();
  };

  // Handle form submission
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      // Simulate file download
      setTimeout(() => {
        setIsThankYouOpen(true); // Open Thank You modal
      }, 2000); // Simulate a 2-second download delay
    } else {
      toast({
        title: 'Please enter your email.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {/* Main Modal */}
      <Modal isOpen={isOpen} onClose={handleCloseAll}>
        <ModalOverlay />
        <ModalContent
          maxW={['600px']}
          height={['auto', 'auto', '382px', '382px']}
          borderRadius={'0'}
          background={'#fff'}
          padding={['50px 10px 50px']}
        >
          <Box maxW={['478px']} margin={'0 auto'}>
            <ModalHeader
              fontSize={['32px', '40px', '45px', '45px', '45px']}
              color={'#111'}
              lineHeight={'normal'}
              textAlign={'center'}
              fontFamily={' var(--garnett-semibold);'}
              padding={'0'}
            >
              Want a Deeper Dive into Our Work?
            </ModalHeader>
          </Box>
          <ModalCloseButton onClick={handleCloseAll} />
          <ModalBody padding={['0']}>
            <Box
              maxW={['427px']}
              margin={'0 auto'}
              textAlign={'center'}
              paddingTop={['26px']}
              paddingBottom={['26px']}
            >
              <Text
                as={'p'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                color={'#333'}
                fontWeight={'400'}
                lineHeight={'normal'}
              >
                Download our capabilities deck to gain a comprehensive
                understanding of what we do, how we do it, and why it works.
                Discover the full scope of our expertise and how we can help
                your business thrive.
              </Text>
            </Box>

            {/* Form */}
            <Box
              display={'flex'}
              maxW={['292px']}
              margin={'0 auto'}
              height={['28px']}
            >
              <Input
                type="text"
                placeholder="Email Address"
                color="#333"
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontSize="13px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="16px"
                border="0.668px solid  #0057FF"
                borderRadius={'0'}
                position={'relative'}
                _placeholder={{
                  color: '#333',
                  fontSize: ['13px'],
                  position: 'absolute',
                  top: '7px',
                }}
                w={['193px']}
                height={'100%'}
                onChange={e => setEmail(e.target.value)}
              />
              <Button
                background="#0D0E4D"
                color="#FFF"
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontSize="13px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="16.042px"
                borderRadius={'0'}
                width={['99px']}
                height={'100%'}
                paddingTop={['5px']}
                onClick={handleSubmit}
                _hover={{ background: '#0D0E4D' }}
              >
                Download now
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Thank You Modal */}
      <Modal isOpen={isThankYouOpen} onClose={handleCloseAll}>
        <ModalOverlay />
        <ModalContent
          maxW={['600px']}
          height={['auto', 'auto', '328px']}
          borderRadius={'0'}
          background={'#fff'}
          padding={['50px 10px 110px']}
        >
          <Box textAlign={'center'}>
            <ModalHeader
              fontSize={['32px', '32px', '48px', '48px', '48px', '48px']}
              color={'#111'}
              fontFamily={'var(--garnett-regular);'}
              fontWeight={'400'}
              lineHeight={'normal'}
              padding={'0'}
            >
              Thank you
            </ModalHeader>
          </Box>
          <ModalCloseButton onClick={handleCloseAll} />
          <ModalBody padding={['0']}>
            <Box
              maxW={['500px']}
              margin={'0 auto'}
              textAlign={'center'}
              paddingTop={['20px']}
            >
              <Text
                fontSize={['18px', '18px', '18px', '18px', '18px', '18px']}
                color={'#333'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontWeight={'400'}
                lineHeight={'normal'}
              >
                The capabilities deck will be sent to your entered email
                address. Please check your inbox.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WorkPageInteraction;
