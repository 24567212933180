import { Box } from '@chakra-ui/react';
import React from 'react';
import Marquee from 'react-fast-marquee';
import image1 from '../../assets/Home/Icon/letWork.svg';
import letWorkMob from '../../assets/Home/Icon/letWorkMob.svg';
import icon from '../../assets/Home/Icon/shakingHand.svg';
import shakingHandMob from '../../assets/Home/Icon/shakingHandMob.svg';

const LogoMarquee = () => {
  return (
    <Box>
      <Box
        bg="#0D0E4D"
        borderBottom="1px solid #fff"
        height={['70px', '70px', '70px', '80px', '80px', '80px']}
        display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
      >
        <Marquee style={{ justifyContent: 'space-between' }}>
          {[...Array(4)].map((_, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="100%"
            >
              <img src={image1} alt={`Marquee ${index + 1}`} height="100%" />
              <Box ml={45} mr={45}>
                <img src={icon} alt="Icon" height="100%" />
              </Box>
            </Box>
          ))}
        </Marquee>
      </Box>
      <Box
        bg="#0D0E4D"
        borderBottom="1px solid #fff"
        height={['70px', '70px', '70px', '80px', '80px', '80px']}
        display={['flex', 'flex', 'none', 'none', 'none', 'none']}
      >
        <Marquee style={{ justifyContent: 'space-between' }}>
          {[...Array(4)].map((_, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="100%"
            >
              <img
                src={letWorkMob}
                alt={`Marquee ${index + 1}`}
                height="100%"
              />
              <Box ml={11} mr={11}>
                <img src={shakingHandMob} alt="Icon" height="100%" />
              </Box>
            </Box>
          ))}
        </Marquee>
      </Box>
    </Box>
  );
};

export default LogoMarquee;
