import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const backgroundContent = () => {
  return (
    <Box
      maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
      margin={['0 auto']}
      padding={['0px 20px', '0px 20px', '0px 20px', '0']}
    >
      <Box
        display={['block', 'block', 'block', 'flex']}
        direction={['column', 'column', 'row', 'row', 'row', 'row']}
        justifyContent={['space-between']}
        alignItems={['baseline']}
        gap={['10%']}
        margin={['0 auto']}
        paddingBottom={['24px', '24px', '24px', '40px', '40px']}
      >
        {/* <Box minW={['100%', '100%', '100%', '19%', '19%', '19%']}> */}
        <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
          <Text
            as="h1"
            fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
          >
            CHALLENGE
          </Text>
        </Box>
        <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
          <Text
            as="p"
            fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
          >
            During our early conversations, it became clear that the team needed
            to define their value system and develop a cohesive messaging
            strategy to unify it. They also required a uniquely tailored visual
            identity system which can reflect what the brand stands for. IROS’s
            team has some of the best minds in the world working on ground
            breaking research and processes, and our goals was to capture,
            refine, and project that excellence through a strategic branding
            exercise.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default backgroundContent;
