import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import minus from '../../assets/Reusable/FAQ/minus.svg';
import plus from '../../assets/Reusable/FAQ/plus.svg';
const FaqUse = () => {
  const [visibleItems, setVisibleItem] = useState(5);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = index => {
    setActiveIndex(prevIndex => (prevIndex === index ? -1 : index));
  };

  const showMore = () => {
    setVisibleItem(prev => prev + 5);
  };

  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/service-detail-faqs?filters[slug][$eq]=${slug}&filters[service_type][$eq]=growth&populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // Accordion Styling
  const accordianIndex = {
    fontSize: ['20px', '20px', '20px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
  };
  const accordianTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
  };
  const accordianParagraph = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    fontSize: ['16px', '16px', '16px', '18px', '18px', '18px'],
    lineHeight: 'normal',
    ml: ['54px', '54px', '54px', '83px'],
    mt: '24px',
  };
  const accordianItemBox = {
    p: '8px 0',
    borderTop: 'none',
    borderBottom: '1px',
    borderBottomColor: '#B5B5B5',
  };
  return (
    <Box
      padding={[
        '115px 0 80px',
        '115px 0 80px',
        '115px 0 80px',
        '120px 0 120px',
        '150px 0 150px',
        '150px 0 150px',
      ]}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%%', '100%', '80%', '80%', '80%']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0', '0']}
        margin={'0 auto'}
      >
        {/* FAQ AND Section Heading  Wrapper */}
        <Box
          display={['block', 'block', 'block', 'block', 'flex', 'flex']}
          justifyContent={['space-between']}
          gap={['133px']}
        >
          {/* Section Heading */}
          <Box maxW={['100%', '100%', '100%', '100%', '271px']}>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              color={'#860038'}
              fontFamily={'var(--foundersGrotesk-medium);'}
              fontWeight={'500'}
              lineHeight={'normal'}
              textTransform={'uppercase'}
              paddingBottom={['18px', '18px', '18px', '44px', '44px', '44px']}
            >
              FAQ’S
            </Text>
            <Heading
              as={'h2'}
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              color={'#333'}
              fontFamily={'var(--garnett-regular);'}
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              Commom Questions
            </Heading>
          </Box>
          {/* Faq */}
          <Box
            width={['100%', '100%', '100%', '100%', '900px']}
            mt={['45px', '45px', '45px', '45px', '65px']}
          >
            {/*  */}
            <Accordion
              allowToggle={false}
              defaultIndex={[0]}
              allowMultiple={false}
            >
              {data.slice(0, visibleItems).map((item, index) => (
                <AccordionItem key={index} sx={accordianItemBox}>
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          onClick={() => handleAccordionClick(index)}
                          px={'0'}
                          _hover={{ backgroundColor: 'transparent!important' }}
                        >
                          <Box as="span" flex="1" textAlign="left">
                            <Flex alignItems={'center'}>
                              {/* Accordion Index Number */}
                              <Box
                                mr={['24px', '24px', '24px', '53px', '53px']}
                                minW={['45px']}
                              >
                                <Text as={'span'} sx={accordianIndex}>
                                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                </Text>
                              </Box>
                              {/* Accordion Title */}
                              <Box>
                                <Text as={'h3'} sx={accordianTitle}>
                                  {item.attributes.faq_heading}
                                </Text>
                              </Box>
                            </Flex>
                          </Box>
                          <Image
                            src={isExpanded ? minus : plus}
                            width={['24px', '24px', '24px', '32px', '32px']}
                            height={['24px', '24px', '24px', '32px', '32px']}
                          />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {item.attributes.faq_description.map((desc, idx) => (
                          <React.Fragment key={idx}>
                            {desc.children.map((child, childIdx) => (
                              <Text
                                as="p"
                                key={childIdx}
                                sx={accordianParagraph}
                              >
                                {child.text}
                              </Text>
                            ))}
                          </React.Fragment>
                        ))}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
            {/* More Questions Pagination Button */}
            {visibleItems < data.length && (
              <Box
                paddingTop={['24px', '24px', '24px', '40px', '40px', '40px']}
              >
                <Button
                  fontSize={['20px', '20px', '20px', '20px', '20px', '20px']}
                  color={'#fff'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  backgroundColor={'#9B4DFF'}
                  borderRadius={'0'}
                  border={'none'}
                  padding={['16px 14px 14px']}
                  _hover={{ backgroundColor: '#000' }}
                  onClick={showMore}
                >
                  More Questions
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaqUse;
