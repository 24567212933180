import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import LetsWorkTogether from '../About/LetsWorksToGether';
import Banner from './Banner';
import Solution from './Solution';
import ToolsTabbing from './ToolsTabbing';

const ToolsAndTech = () => {
    const [seoData, setSeoData] = useState(null);

    useEffect(() => {
        const fetchSeoData = async () => {
            try {
                const response = await fetch('https://cms.wearetenet.com/api/tools-and-technologies?populate=*');
                const result = await response.json();
                const seo = result.data[0]?.attributes?.seo;
                setSeoData(seo);
            } catch (error) {
                console.error('Error fetching SEO data:', error);
            }
        };

        fetchSeoData();
    }, []);
    return (
        <Box>
            {seoData && (
                <Helmet>
                    {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

                    {seoData.metaDescription && (
                        <meta name="description" content={seoData.metaDescription} />
                    )}

                    {seoData.keywords && (
                        <meta name="keywords" content={seoData.keywords} />
                    )}

                    <meta name="robots" content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'} />

                    {seoData.canonicalURL && (
                        <link rel="canonical" href={seoData.canonicalURL} />
                    )}

                    {seoData.metaViewport && (
                        <meta name="viewport" content={seoData.metaViewport} />
                    )}
                </Helmet>
            )}
            <Box>
                <Banner />
            </Box>
            <Box>
                <Solution />
            </Box>
            <Box>
                <ToolsTabbing />
            </Box>
            <Box>
                <LetsWorkTogether />
            </Box>


        </Box>
    )
}

export default ToolsAndTech
