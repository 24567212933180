import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const outcomeSection = () => {
  // Section Heading
  const sectionHeading = {
    fontSize: ['16px', '16px', '20px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-medium)',
    color: '#860038',
    fontWeight: '500',
    lineHeight: 'normal',
    width: ['100%', '25%', '25%', '25%', '25%', '25%'],
  };

  const numberStyle = {
    fontSize: ['25px', '25px', '25px', '22px', '24px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    width: '45px',
  };

  const gridTitle = {
    fontSize: ['18px', '18px', '18px', '20px', '24px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: ['15px'],
  };

  const gridParagraph = {
    fontSize: ['14px', '14px', '14px', '16px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['20px', '20px', '25px', '30px', '55px', '60px'],
  };

  return (
    <Box>
      <Container
        width={['90%', '90%', '90%', '94%', '90%', '83%']}
        maxW={['90%', '90%', '90%', '94%', '90%', '83%']}
        padding={'0'}
        marginTop={['80px', '80px', '78px', '85px', '93px', '100px']}
        // paddingTop={["35px", "35px", "48px", "55px", "63px", "63px"]}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        // paddingBottom={["35px", "35px", "48px", "55px", "63px", "69px"]}
      >
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          {/* Main Section Heading */}
          <Text as="h1" sx={sectionHeading}>
            OUTCOMES
          </Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              01
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Expertise That Elevates
              </Text>
              <Text as="p" sx={gridParagraph}>
                With Tenet, you gain access to a team whose expertise is both
                broad and deep. From UX/UI to web & mobile app development, to
                growth marketing. We've spent years honing our craft to offer
                you knowledge that transforms and delivers tangible success.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              02
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                One Stop Shop
              </Text>
              <Text as="p" sx={gridParagraph}>
                With Tenet, say goodbye to the logistical nightmare of juggling
                multiple agencies. Our comprehensive suite of services caters to
                every digital need, ensuring a seamless, integrated strategy
                from start to finish.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '28px', '32px', '32px', '32px']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            sx={sectionHeading}
            opacity={'0'}
            visibility={'hidden'}
            height={'1px'}
          >
            OUTCOMES
          </Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              03
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Responsibility as a Deliverable (RaaD)
              </Text>
              <Text as="p" sx={gridParagraph}>
                Know that feeling when you’re out, and your kid is safe at home
                with a responsible, trusted friend? It's this level of
                responsibility we provide to your business, offering you the
                freedom to focus on what matters with complete peace of mind.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              04
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                White Glove Servicing
              </Text>
              <Text as="p" sx={gridParagraph}>
                Our commitment to servicing excellence means you get the VIP
                treatment at every turn. Expect proactive solutions, meticulous
                attention to detail, and personalised care that makes your
                business feel like the only business that matters.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '28px', '32px', '32px', '32px']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          {/* Space */}
          <Text
            as="h1"
            sx={sectionHeading}
            opacity={'0'}
            visibility={'hidden'}
            height={'1px'}
          >
            OUTCOMES
          </Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              05
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Projects? nah. Partnerships.
              </Text>
              <Text as="p" sx={gridParagraph}>
                We don't just build projects; we build partnerships. At Tenet,
                we create a symbiotic relationship where your goals become ours,
                and success is a shared vision. Together, we create legacies,
                not just deliverables.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '35px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              06
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Envious Competitors
              </Text>
              <Text as="" sx={gridParagraph}>
                Collaborating with Tenet isn't just about achieving your
                goals—it's about setting a benchmark. Our strategic prowess and
                innovative solutions will have your competitors looking over
                with envy, wondering how you're always two steps ahead.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default outcomeSection;
