import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';  // Import useLocation for extracting the region from URL
import GrowthJourneyEXP from '../Reusable/GrowthJourneyEXP';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import OurRating from '../Reusable/OurRatings';
import OurWorksNew from '../Reusable/OurWorksNew';
import BeyondLimitations from './BeyondLimitation';
import BusinessTabSection from './BusinessTabSection';
import ClientSaysLoveSlider from './CleintSaysSliderUse';
import CientSaysAboutUsUse from './ClientSaysAboutUsUse';
import ClientsLogo from './ClientsLogo';
import ContactForm from './ContactForm';
import Counter from './Counter';
import FaqUse from './FaqUse';
import HeroSection from './HeroSection';
import HomeNewTabbing from './HomeNewTabbing';
import LetsWorkTogether from './LetsWorkTogether';
import OurServices from './OurServices';
import Stories from './Stories';
import WeWorkWith from './WeWorkWith';
import WhyChooseUs from './WhyChooseUs';

const Home = () => {
  const [seoData, setSeoData] = useState(null);
  const [heroData, setHeroData] = useState(null);
  const heroSectionRef = useRef(null);
  
  const location = useLocation();  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        const region = pathParts[1] || 'global';  
        const apiUrl = `https://cms.wearetenet.com/api/homepages?filters[region][$eq]=${region}&populate=*`;

        const response = await fetch(apiUrl);
        const result = await response.json();

        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);

        const heroSectionData = result.data[0]?.attributes;
        setHeroData(heroSectionData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [location]);  

  useEffect(() => {
    if (heroSectionRef.current) {
      const handleResize = () => {
        const height = heroSectionRef.current.clientHeight;
        console.log('Hero Section Height:', height);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [heroSectionRef]);

  return (
    <>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}
          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}
          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}
          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />
          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}
          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <HeroSection data={heroData} ref={heroSectionRef} />
      <LogoMarqueeReuse />
      <Counter />
      <OurServices />
      <BeyondLimitations />
      <OurWorksNew />
      <GrowthJourneyEXP />
      <CientSaysAboutUsUse />
      <HomeNewTabbing />
      <WeWorkWith />
      <BusinessTabSection />
      <WhyChooseUs />
      <OurRating SecbgColor={'#f5f5f5'} />
      <FaqUse />
      <ClientsLogo />
      <ClientSaysLoveSlider />
      <Stories />
      <LetsWorkTogether />
      <ContactForm />
    </>
  );
};

export default Home;
