import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
} from '@chakra-ui/react';
import React from 'react';

const BreadCrum = () => {
  return (
    <Container
      width={['100%', '100%', '100%', '94%', '90%', '90%']}
      maxW={['100%', '100%', '100%', '94%', '90%', '90%']}
      marginBottom={['50px', '50px', 0, 0, 0, 0]}
      alignItems={['baseline']}
    >
      <Breadcrumb
        spacing="8px"
        separator=">"
        color={['#ffffff']}
        alignItems={['baseline']}
        display={['none', 'none', 'none', 'block', 'block', 'block']}
      >
        <BreadcrumbItem alignItems={['baseline']}>
          <BreadcrumbLink
            href="/"
            fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
            color={['#ffffff']}
            as="p"
            fontFamily="var(--foundersGrotesk-light)"
            fontWeight={'400'}
          >
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem alignItems={['baseline']}>
          <BreadcrumbLink
            href="#"
            fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
            color={['#ffffff']}
            as="p"
            fontFamily="var(--foundersGrotesk-light)"
            className="breadCrumb1"
            fontWeight={'400'}
          >
            Our Strategy
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Container>
  );
};

export default BreadCrum;
