import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import image3 from '../../../assets/Strategy/1.webp';
import image1 from '../../../assets/Strategy/ourP.webp';
import image2 from '../../../assets/Strategy/ourPMob.webp';
import image from '../../../assets/Strategy/ourProcess.webp';

const ourProcess = () => {
  return (
    <Box>
      <Container
        width={['100%', '100%', '100%', '94%', '90%', '83%']}
        maxW={['100%', '100%', '100%', '94%', '90%', '83%']}
        padding={'0'}
        marginTop={['90px', '90px', '90px', '85px', '93px', '150px']}
        // paddingTop={["35px", "35px", "48px", "55px", "63px", "63px"]}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        // paddingBottom={["35px", "35px", "48px", "55px", "63px", "69px"]}
      >
        <Flex
          width={['90%', '90%', '90%', '100%', '100%', '100%']}
          maxW={['90%', '90%', '90%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['flex-start']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['32px', '32px', '42px', '50px', '60px', '64px']}
            fontFamily="var(--foundersGrotesk-regular)"
            color={['#000000']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '35%', '35%', '25%', '25%']}
          >
            Our Process
          </Text>
        </Flex>
        <Box
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          marginTop={['30px', '35px', '38px', '25px', '33px', '35px']}
        >
          <img src={image1} width={['100%']} />
        </Box>
        <Box
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          marginTop={['30px', '35px', '38px', '25px', '33px', '35px']}
        >
          <img src={image2} width={['100%']} />
        </Box>
        <Flex
          width={['90%', '90%', '90%', '100%', '100%', '100%']}
          maxW={['90%', '90%', '90%', '100%', '100%', '100%']}
          marginTop={['20px', '25px', '38px', '45px', '53px', '60px']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['15px', '15px', '15px', '180px', '200px', '260px']}
          alignItems={['flex-start']}
          margin={['0 auto']}
          paddingTop={['80px', '80px', 0, 0, 0, 0]}
        >
          <Text
            as="h1"
            fontSize={['20px', '20px', '30px', '30px', '30px', '36px']}
            fontFamily="var(--garnett-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '50%', '50%', '40%', '30%']}
          >
            Crafted for your growth.
          </Text>
          <Text
            as="h1"
            fontSize={['16px', '16px', '16px', '18px', '18px', '20px']}
            fontFamily="var(--foundersGrotesk-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '50%', '50%', '60%', '70%']}
          >
            We exist to accelerate your business. To do that we have crafted a
            process which ensures that all time and $ spent with us, is
            attributed to success. Each phase in the process builds on the
            previous step which ensures nothing is left to imagination, and
            we’re able to get to the chequered flag , collaboratively. We take
            care of pre and post delivery stages as well, so that you always
            have a dependable success partner.
          </Text>
        </Flex>
        <Box
          marginTop={['50px', '65px', '78px', '95px', '153px', '200px']}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
        >
          <img src={image} width={['100%']} />
        </Box>
        <Box
          paddingTop={['40px', '50px', '68px', '95px', '153px', '200px']}
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          width={['90%']}
          margin={['0 auto']}
        >
          <img src={image3} width={['100%']} />
        </Box>
      </Container>
    </Box>
  );
};

export default ourProcess;
