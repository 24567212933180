import { Box, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const extractText = description => {
  return description
    .map(paragraph => {
      // Extract and join text from each paragraph's children, if any
      const textContent = paragraph.children
        .filter(child => child.text.trim() !== '') // Filter out empty text
        .map(child => child.text)
        .join('');

      // Return the paragraph content with a newline character if it's not empty
      return textContent.trim() !== '' ? textContent : null;
    })
    .filter(paragraph => paragraph !== null) // Remove null paragraphs
    .join('\n\n'); // Join paragraphs with double newlines to create spacing between them
};

const ApproachContent = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const introductionDescription = firstItem?.approach_description || [];
  const descriptionText = extractText(introductionDescription);

  const backgroundText = [
    {
      title:
        'Our journey with Pazazz began with brand discovery workshops and a deep dive into market analysis. We aimed to uncover their unique story and brand attributes, as well as the latest trends and consumer demands in the athleisure segment. We pinpointed key challenges in brand differentiation, user engagement, and digital presence. Armed with these insights, we crafted a strategy to build a distinctive brand identity and a dynamic digital ecosystem tailored to their target audience.',
      description:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
      description2:
        'While Zuora achieved global renown — a publicly traded company powering revenue models for some of the world’s largest enterprises — it was also in a state of rapid innovation. Like other established brands at a turning point in their growth, Zuora’s story had become fragmented. Revisiting the brand’s verbal identity was critical to support its bold new look with messaging to match.<br><br>The team’s vision: to get people excited about the brand again, and tell their story with clarity, consistency, and conviction.<br><br>The team’s vision: to get people excited about the brand again, and tell their story with clarity, consistency, and conviction',
    },
  ];
  return (
    <Container
      //  paddingTop={["60px", "60px", "60px", "80px", "100px", "120px"]}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['50px', '50px', 0, 0, 0, 0]}
      padding={['0']}
    >
      {firstItem?.introduction_desktop_image?.data?.attributes?.url && (
        <Image
          paddingTop={['40px', '60px', '70px', '80px', '90px', '150px']}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
          src={`https://cms.wearetenet.com${firstItem.introduction_desktop_image.data.attributes.url}`}
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          margin={['0 auto']}
        />
      )}

      {firstItem?.introduction_mobile_image?.data?.attributes?.url && (
        <Image
          paddingTop={['40px', '60px', '70px', '80px', '90px', '150px']}
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
          src={`https://cms.wearetenet.com${firstItem.introduction_mobile_image.data.attributes.url}`}
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          margin={['0 auto']}
        />
      )}

      {descriptionText && (
        <Box
          maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
          margin={['0 auto']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        >
          <Box
            display={['block', 'block', 'block', 'flex']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={['space-between']}
            alignItems={['baseline']}
            gap={['10%']}
            margin={['0 auto']}
          >
            <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
              <Text
                as="h1"
                fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-medium)"
                color={['#860038']}
                fontWeight={['500']}
                lineHeight={'normal'}
              >
                APPROACH
              </Text>
            </Box>

            <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
              <Text
                as="h1"
                fontSize={['18px', '20px', '24px', '24px', '24px', '24px']}
                fontFamily="var(--garnett-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                {descriptionText.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Box>
          </Box>
        </Box>
      )}

      {firstItem?.approach_desktop_image?.data?.attributes?.url && (
        <Image
          paddingTop={['80px', '80px', '80px', '80px', '100px', '150px']}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
          src={`https://cms.wearetenet.com${firstItem.approach_desktop_image.data.attributes.url}`}
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          margin={['0 auto']}
        />
      )}

      {firstItem?.approach_mobile_image?.data?.attributes?.url && (
        <Image
          paddingTop={['80px', '80px', '80px', '80px', '100px', '150px']}
          display={['block', 'block', 'none', 'none', 'none', 'none']}
          paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
          src={`https://cms.wearetenet.com${firstItem.approach_mobile_image.data.attributes.url}`}
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          margin={['0 auto']}
        />
      )}

      {/* <Flex
                width={["100%", "100%", "100%", "80%", "70%", "72%"]}
                maxW={["100%", "100%", "100%", "80%", "70%", "72%"]}
                direction={['column', 'column', 'row', 'row', 'row', 'row']}
                gap={["55px", "55px", "55px", "80px", "140px", "160px"]}
                alignItems={["baseline"]}
                margin={["0 auto"]}
            // paddingTop={["16px", "18px", "20px", "26px", "30px", "32px"]}
            // marginBottom={["40px", "40px", "40px", "80px", "80px", "80px"]}
            >
                <Text
                    visibility={["hidden"]}
                    as="h1"
                    fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
                    fontFamily='var(--foundersGrotesk-medium)'
                    color={["#860038"]}
                    fontWeight={["500"]}
                    lineHeight={"normal"} >
                    APPROACH
                </Text>
                <Text
                    //  width={['100%', '100%', '100%', '90%', '80%', '75%']}
                    fontSize={['14px', '14px', '16px', '18px', '18px', '18px']}
                    as='p'
                    fontFamily='var(--garnett-regular)'
                    fontWeight={"400"}
                    lineHeight={"normal"}
                    dangerouslySetInnerHTML={{ __html: backgroundText[0].description2.replace(/<br\s*\/?>/gi, '<br>') }}
                >
                </Text>
            </Flex> */}
    </Container>
  );
};
export default ApproachContent;
