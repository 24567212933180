import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import imagetechuse from '../../../assets/CaseStudy/Angels/technologyuseAfter.webp';
import reacticon from '../../../assets/CaseStudy/CaseStudyThinkVastu/reactIcon.svg';

const TechnologyContent = () => {
  return (
    <Box>
      <Container
        paddingTop={['60px', '60px', '60px', '80px', '80px', '80px']}
        width={['100%', '100%', '100%', '100%', '100%', '100%']}
        maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
        marginBottom={['0px', '0px', 0, 0, 0, 0]}
        padding={['0']}
      >
        <Flex
          width={['90%', '90%', '90%', '80%', '70%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '70%', '72%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['40px', '50px', '50px', '60px', '70px', '70px']}
          alignItems={['start', 'start', 'start', 'start', 'start', 'start']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
          >
            TECHNOLOGY USED
          </Text>
          <Flex flexDirection={['row']} gap={['40px']}>
            <Box width={['60px', '60px', '60px', '86px', '86px', '86px']}>
              <img
                src="https://raw.githubusercontent.com/digitallamp/svg-upload/0074818693c2005ce9c589aacffc9bc07d551839/amazon-web-services-2.svg"
                alt="awsicon"
                width={'100%'}
                height={'auto'}
              />
            </Box>
            <Box width={['70px', '70px', '70px', '99px', '99px', '99px']}>
              <img
                src={reacticon}
                width={'100%'}
                height={'auto'}
                alt="react icon"
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
      {/* Image Fter Icons */}
      <Box
        width={['100%', '100%', '100%', '94%', '94%', '94%']}
        maxW={['100%', '100%', '100%', '94%', '94%', '94%']}
        margin={'0 auto'}
        padding={[
          '60px 20px 0 20px',
          '60px 20px 0 20px',
          '60px 20px 0 20px',
          '120px 0 0 0',
        ]}
      >
        <Image width={'100%'} height={'auto'} src={imagetechuse} />
      </Box>
    </Box>
  );
};

export default TechnologyContent;
