import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import IndustriesWeAreWorkingWith from '../Home/WeWorkWith';
import Counter from '../Reusable/CaseStudy/Counter';
import Banner from './Banner';
import Ctc from './Ctc';
import OurProcess from './OurProcess';
import PPCService from './PPCService';
import Service from './Service';
import WhyChoosTenetAsWhiteLabel from './WhyChoosTenetAsWhiteLabel';

import LetsWorkTogether from '../About/LetsWorksToGether';
import ClientSayReuseSlider from './CleintSaysSliderUse';
import FaqUse from './FaqUse';
const WhiteLabel = () => {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          'https://cms.wearetenet.com/api/white-labels?populate=*'
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, []);
  return (
    <>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Box>
        <Banner />
      </Box>
      <Box>
        <PPCService />
      </Box>
      <Box>
        <Counter customMarginTop={'0'} />
      </Box>
      <Box>
        <Service />
      </Box>
      <Box>
        <Ctc />
      </Box>
      <Box>
        <WhyChoosTenetAsWhiteLabel />
      </Box>
      <Box>
        <OurProcess />
      </Box>
      <Box>
        <IndustriesWeAreWorkingWith />
      </Box>
      <Box>
        <ClientSayReuseSlider />
      </Box>
      <Box>
        <FaqUse />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </>
  );
};

export default WhiteLabel;
