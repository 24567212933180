import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import Faq from '../Reusable/FAQ';

const FaqUse = () => {
  return (
    <Box
      padding={[
        '115px 0 80px',
        '115px 0 80px',
        '115px 0 80px',
        '120px 0 120px',
        '150px 0 150px',
        '150px 0 150px',
      ]}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%%', '100%', '80%', '80%', '80%']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0', '0']}
        margin={'0 auto'}
      >
        {/* FAQ AND Section Heading  Wrapper */}
        <Box
          display={['block', 'block', 'block', 'block', 'flex', 'flex']}
          justifyContent={['space-between']}
          gap={['133px']}
        >
          {/* Section Heading */}
          <Box maxW={['100%', '100%', '100%', '100%', '271px']}>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              color={'#860038'}
              fontFamily={'var(--foundersGrotesk-medium);'}
              fontWeight={'500'}
              lineHeight={'normal'}
              textTransform={'uppercase'}
              paddingBottom={['18px', '18px', '18px', '44px', '44px', '44px']}
            >
              FAQ’S
            </Text>
            <Heading
              as={'h2'}
              fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
              color={'#333'}
              fontFamily={'var(--garnett-regular);'}
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              Commom Questions
            </Heading>
          </Box>
          {/* Faq */}
          <Box
            width={['100%', '100%', '100%', '100%', '900px']}
            mt={['45px', '45px', '45px', '45px', '65px']}
          >
            {/*  */}
            <Faq />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FaqUse;
