
import React, { useRef, useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

const YouTubeVideo = ({ videoId }) => {
  const [isVideoPlayerReady, setIsVideoPlayerReady] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    console.log('Loading YouTube IFrame API...');
    
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.head.appendChild(tag);

    window.onYouTubeIframeAPIReady = () => {
      console.log('YouTube IFrame API is ready');
      
      playerRef.current = new window.YT.Player('player', {
        videoId: videoId,
        height: Math.min(720, window.innerHeight - 100),
        events: {
          'onReady': () => {
            console.log('YouTube Player is ready');
            setIsVideoPlayerReady(true);
            playerRef.current.mute();
          },
          'onError': (event) => {
            console.error('YouTube Player Error:', event.data);
          }
        },
        playerVars: {
          autoplay: 0, // Video will autoplay when it's in the viewport
          controls: 0, // Hide controls
          modestbranding: 1, // Minimize branding
          loop: 1, // Loop the video
          fs: 0, // Disable full-screen button
          cc_load_policy: 0, // Disable closed captions
          iv_load_policy: 3, // Disable annotations
          autohide: 1, // Hide controls when playing
          rel: 0, // Disable showing related videos
          start: 0, // Start from the beginning
        },
      });
    };

    const handleScroll = () => {
      if (isVideoPlayerReady && playerRef.current) {
        const playerElement = document.getElementById('player');
        const rect = playerElement.getBoundingClientRect();
        const isInViewport = rect.top < window.innerHeight && rect.bottom > 0;

        if (isInViewport) {
          console.log('Video is in viewport. Playing video...');
          playerRef.current.playVideo();
          playerElement.style.opacity = '1';
        } else {
          console.log('Video is out of viewport. Pausing video...');
          playerRef.current.pauseVideo();
          playerElement.style.opacity = '.6';
        }
      }
    };

    console.log('Adding scroll and resize event listeners');
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      console.log('Removing scroll and resize event listeners');
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [isVideoPlayerReady, videoId]);

  return (
    <Box
      margin="0 auto"
      maxWidth="100%"
      position="relative"
      paddingBottom="56.25%" // 16:9 Aspect Ratio
      height="0"
      overflow="hidden"
    >
      <div
        id="player"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
};

const App = () => {
  return (
    <YouTubeVideo videoId="ZINeYzUz6gM" />
  );
};

export default App;