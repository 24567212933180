import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Input,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [sendNDA, setSendNDA] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);

  const toast = useToast();

  const handleFirstNameChange = e => setFirstName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);
  const handlePhoneChange = e => setPhone(e.target.value);
  const handleSubjectChange = e => setSubject(e.target.value);
  const handleMessageChange = e => setMessage(e.target.value);
  const handleOrganizationNameChange = e => setOrganizationName(e.target.value);
  const handleSendNDAChange = e => setSendNDA(e.target.checked);
  const handleReceiveUpdatesChange = e => setReceiveUpdates(e.target.checked);

  const handleSubmit = async e => {
    e.preventDefault();

    // Validate the form
    if (
      !firstName ||
      !email ||
      !phone ||
      !subject ||
      !message ||
      !organizationName ||
      !receiveUpdates
    ) {
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const formData = {
      data: {
        full_name: firstName, // Send only the first name as full_name
        email,
        phone,
        subject,
        message,
        organization_name: organizationName,
        receive_updates: receiveUpdates,
        send_nda: sendNDA,
      },
    };

    try {
      const response = await fetch(
        'https://cms.wearetenet.com/api/contact-forms',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Bearer 8979fca7f4441861ed50a2a4afc1acfd819a5d2055c8682566dc7518ec29cb082b8ae7202644a8f350f5368e4d35207bf05cb0256e98a7fb9467f0a2749bff434d650b7dd3ed1714abef6b49734878b0eb9276b052cc31ceecbbe7dd510e3102efa2e14ef0cc7326ebb2743ddd5c1457c8b815db1626d7a09c1c42dccf199216',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast({
          title: 'Form Submitted Successfully',
          description: "We'll get back to you shortly",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // Clear form fields
        setFirstName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
        setOrganizationName('');
        setSendNDA(false);
        setReceiveUpdates(false);
        console.log('Success:', responseData);
        // Optionally handle success response
      } else {
        const errorData = await response.json();
        console.error('Error:', response.status, errorData);
        toast({
          title: 'Submission Failed',
          description:
            'There was a problem with your submission. Please try again.',
          status: 'error',
          isClosable: true,
        });
        // Handle error response
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Network Error',
        description:
          'There was a problem connecting to the server. Please try again later.',
        status: 'error',
        isClosable: true,
      });
      // Handle network or other errors
    }
  };
  return (
    <Box
      w={['90%', '90%', '90%', '90%', '90%', '90%']}
      bg={['#0D0E4D', '#0D0E4D', '#0D0E4D', '#fff']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
      alignItems={['center', 'center', 'center', 'center', 'center', 'center']}
      justifyContent={[
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
      ]}
      margin={['0 auto']}
      marginTop={['45px', '45px', '45px', 0, 0, 0]}
    >
      <Box width={['100%', '100%', '100%', '80%', '80%', '80%']}>
        <form onSubmit={handleSubmit}>
          <Box bg={'#fff'} maxW={['511px']} margin={'0 auto'}>
            <Box
              mb={['26px', '26px', '26px', '26px', '26px', '40px']}
              display={['block', 'block', 'block', 'block', 'block', 'block']}
            >
              <Text
                as={'p'}
                mb={'20px'}
                fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                fontWeight={'400'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                color={'#333'}
                paddingTop={['20px', '30px', '40px', '50px', '60px', '100px']}
              >
                We're always eager to meet cool people, like you!
              </Text>
              <Text
                as={'p'}
                fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                fontWeight={'400'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                color={'#333'}
              >
                Please drop an email with your details & requirements to
                <Link
                  href="mailto:hello@wearetenet.com"
                  style={{ color: '#9B4DFF', margin: '0px 5px' }}
                >
                  hello@wearetenet.com.
                </Link>
                You can also fill this form & we'll get back in less than 24
                hours. Guaranteed.
              </Text>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box w={'100%'}>
                <FormControl id="FirstName">
                  <Input
                    placeholder="Full Name"
                    bg="#f5f5f5"
                    borderBottom="1px solid #b5b5b5"
                    borderRadius="0"
                    marginBottom="20px"
                    _placeholder={{
                      color: '#333333',
                      fontFamily: 'var(--foundersGrotesk-regular)',
                      fontSize: '20px',
                    }}
                    value={firstName}
                    onChange={handleFirstNameChange}
                    color={firstName ? '#000000' : '#333333'}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Organization's Name"
                  bg="#f5f5f5"
                  borderBottom="1px solid #b5b5b5"
                  borderRadius="0"
                  marginBottom="20px"
                  _placeholder={{
                    color: '#333333',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                    fontSize: '20px',
                  }}
                  value={organizationName}
                  onChange={handleOrganizationNameChange}
                  color={organizationName ? '#000000' : '#333333'}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <Input
                  type="email"
                  placeholder="Email Address"
                  bg="#f5f5f5"
                  _placeholder={{
                    color: '#333333',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                    fontSize: '20px',
                  }}
                  borderBottom="1px solid #b5b5b5"
                  borderRadius="0"
                  marginBottom="20px"
                  value={email}
                  onChange={handleEmailChange}
                  color={email ? '#000000' : '#333333'}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <Input
                  type="number"
                  placeholder="Phone"
                  bg="#f5f5f5"
                  _placeholder={{
                    color: '#333333',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                    fontSize: '20px',
                  }}
                  borderBottom="1px solid #b5b5b5"
                  borderRadius="0"
                  marginBottom="20px"
                  value={phone}
                  onChange={handlePhoneChange}
                  color={phone ? '#000000' : '#333333'}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <Input
                  type="Text"
                  placeholder="Subject"
                  bg="#f5f5f5"
                  _placeholder={{
                    color: '#333333',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                    fontSize: '20px',
                  }}
                  borderBottom="1px solid #b5b5b5"
                  borderRadius="0"
                  marginBottom="20px"
                  value={subject}
                  onChange={handleSubjectChange}
                  color={subject ? '#000000' : '#333333'}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <Textarea
                  rows={'5'}
                  cols={'40'}
                  _placeholder={{
                    color: '#333333',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                    fontSize: '20px',
                  }}
                  placeholder="Message"
                  bg="#f5f5f5"
                  borderBottom="1px solid #b5b5b5"
                  borderRadius="0"
                  marginBottom="20px"
                  value={message}
                  onChange={handleMessageChange}
                  color={message ? '#000000' : '#333333'}
                />
              </FormControl>
            </Box>
            <Box mb={'30px'}>
              <Box display={'flex'} alignItems={'flex-start'} mb={'18px'}>
                <Checkbox
                  checked={sendNDA}
                  onChange={handleSendNDAChange}
                  mr={'13px'}
                />
                <Text
                  as="p"
                  color={'#333'}
                  mt={'2px'}
                  fontSize={'16px'}
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight={'400'}
                >
                  Send me a Non-Disclosure Agreement
                </Text>
              </Box>
              <Box display={'flex'} alignItems={'flex-start'}>
                <Checkbox
                  checked={receiveUpdates}
                  onChange={handleReceiveUpdatesChange}
                  mr={'13px'}
                />
                <Text
                  as="p"
                  color={'#333'}
                  mt={'0px'}
                  fontSize={'16px'}
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight={'400'}
                >
                  Please check the box to receive our marketing communications
                  and stay up-to-date with our latest updates. Thank you!
                </Text>
              </Box>
            </Box>
            <Box>
              <Button
                lineHeight={'normal'}
                p={'24px 80px'}
                borderRadius={'none'}
                bg={'#00763D'}
                fontSize={'20px'}
                _hover={{
                  bg: '#00763D',
                  color: '#fff',
                  transform: 'translate3d(0, -3px, 0)',
                }}
                fontWeight={'400'}
                color={'#fff'}
                fontFamily="var(--foundersGrotesk-regular);"
                type="submit"
                className="contactButton"
                transition="transform 0.5s ease"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ContactForm;
