import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box, Heading,
    Text
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CustomDropdownIcon = (props) => (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="38.0602" height="39" rx="6" fill="white" />
        <line x1="3.73077" y1="8.73065" x2="11.0385" y2="8.73065" stroke="#0057FF" stroke-width="1.46154" stroke-linecap="round" />
        <line x1="3.73077" y1="14.5768" x2="16.8847" y2="14.5768" stroke="#0057FF" stroke-width="1.46154" stroke-linecap="round" />
        <line x1="3.73077" y1="20.423" x2="21.2693" y2="20.423" stroke="#0057FF" stroke-width="1.46154" stroke-linecap="round" />
        <line x1="3.73077" y1="26.2692" x2="28.577" y2="26.2692" stroke="#0057FF" stroke-width="1.46154" stroke-linecap="round" />
        <line x1="3.73077" y1="32.2692" x2="34.2692" y2="32.2692" stroke="#0057FF" stroke-width="1.46154" stroke-linecap="round" />
        <path d="M33.9702 17.5977L30.0152 22.6031L26 17.6457L33.9702 17.5977Z" fill="#0057FF" />
        <path d="M26.0005 13.9814L29.9857 8.99999L33.9708 13.9814H26.0005Z" fill="#0057FF" />
    </svg>

);
const generateId = (text) => text.toLowerCase().replace(/[^a-z0-9]+/g, '-');

const Content = () => {

    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://cms.wearetenet.com/api/insight-blogs?filters[slug][$eq]=${slug}&populate=*`
                );
                if (response.data.data.length > 0) {
                    setData(response.data.data[0]);
                } else {
                    setError('No data found');
                }
            } catch (err) {
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);
    const blogHeading = {
        fontFamily: 'var(--garnett-regular)',
        fontSize: ['18px', "20px", "25px", "30px", "30px", "32px"],
        fontWeight: 'normal',
        lineHeight: 'normal',
        pb: ['28px', "28px", "28px", "30px", "30px", "32px"],
        color: '#333333'
    }

    const contentText = {
        fontFamily: 'var(--foundersGrotesk-regular)',
        fontSize: ['14px', "16px", "18px", "20px", "22px", '24px'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        pt: ['24px', "24px", "26px", "28px", "30px", "32px"],
        pb: '24px',
        color: '#333333'
    }

    const contentText1 = {
        fontFamily: 'var(--foundersGrotesk-regular)',
        fontSize: ['14px', "16px", "18px", "20px", "22px", '24px'],
        fontWeight: 'normal',
        lineHeight: 'normal',
        pb: '24px',
        color: '#333333'
    }
    const DownloadLink = {
        color: '#E0005E',
        fontFamily: 'var(--garnett-medium)',
        fontWeight: 'normal',
        fontSize: ['18px', '20px', '20px', '22px', '24px', '24px'],
        textDecoration: 'underline',
        paddingTop: ['0', '0', '15px', '18px', '24px', '29px'],
        paddingBottom: ['4px', '4px', '12px', '18px', '30px', '33px'],
        display: 'inline-block'
    }
    const firstItem = data ? data.attributes : null;
    const extractText = (paragraphs) => {
        if (!paragraphs || !Array.isArray(paragraphs)) return '';

        return paragraphs
            .map(paragraph =>
                paragraph.children
                    .map(child => child.text) // Extract text from each child
                    .join('') // Join text within the same paragraph
            )
            .join('\n'); // Join multiple paragraphs with a newline
    };
    const paragraphText = extractText(firstItem?.paragraph);
    const buttonHeading = firstItem?.button_heading;
    const buttonFileUrl = firstItem?.button_file?.data?.attributes?.url || '';

    const { attributes } = data || {};
    const handleScrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const elementRect = element.getBoundingClientRect();
            const offset = 80; // Offset in pixels
            const top = elementRect.top + window.pageYOffset - offset;

            window.scrollTo({
                top: top,
                behavior: 'smooth'
            });

            // Update the URL with the hash
            window.history.pushState(null, '', `${window.location.pathname}#${id}`);
        }
    };

    const { table_of_content, content } = firstItem || {};

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Box>
            {/* Display blog Title */}
            <Box>
                <Heading as={'h2'} sx={blogHeading}>{firstItem?.heading}</Heading>
            </Box>
            {/* DownloAD lINK */}
            {/* <Box textAlign={'center'}>
                {buttonHeading ? (
                    <Box
                        display={["flex"]}
                        justifyContent={["center"]}
                        alignItems={["baseline"]}
                        gap={["10px"]}
                    >
                        <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_1138_3969)">
                                <path
                                    d="M14.625 14.1772V16.4272H3.375V14.1772H2.25V16.4272C2.25 16.7256 2.36853 17.0118 2.5795 17.2227C2.79048 17.4337 3.07663 17.5522 3.375 17.5522H14.625C14.9234 17.5522 15.2095 17.4337 15.4205 17.2227C15.6315 17.0118 15.75 16.7256 15.75 16.4272V14.1772H14.625Z"
                                    fill="#E0005E"
                                />
                                <path
                                    d="M14.625 8.55225L13.8319 7.75912L9.5625 12.0229V1.80225H8.4375V12.0229L4.16812 7.75912L3.375 8.55225L9 14.1772L14.625 8.55225Z"
                                    fill="#E0005E"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1138_3969">
                                    <rect width="18" height="18" fill="white" transform="translate(0 0.677246)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <a
                            href={`https://cms.wearetenet.com${buttonFileUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Text sx={DownloadLink}>
                                {buttonHeading}
                            </Text>
                        </a>
                    </Box>
                ) : null}
            </Box> */}

            <Box>
                <Text as="p" sx={contentText}>
                    {paragraphText}
                </Text>
            </Box>
            <Box backgroundColor={["#F5F5F5"]} marginBottom={["32px"]}>
                <Accordion allowToggle>
                    <AccordionItem>
                        <h2
                        >
                            <Box marginBottom={["22px"]}>
                                <AccordionButton height={["55px", "60px", "65px", "68px", "70px", "74px"]} paddingLeft={["0"]} paddingRight={["0"]} fontFamily='var(--garnett-regular)' fontSize={["16px", "16px", "18px", "18px", "20px", "20px"]} color={["#000000"]} borderBottom={["1px solid #B5B5B5"]} width={["90%"]} margin={["0 auto"]}>
                                    <Box flex="1" textAlign="left" >
                                        Page Contents
                                    </Box>
                                    <CustomDropdownIcon boxSize={["22px", "24px", "26px", "30px", "35px", "39px"]} />
                                </AccordionButton>
                            </Box>

                        </h2>
                        <AccordionPanel paddingBottom={["60px"]}>
                            <Box>
                                {table_of_content?.map((item, index) => {
                                    const id = generateId(item.children[0]?.text || `heading-${index}`);
                                    if (item.type === 'heading') {
                                        const level = item.level;
                                        if (level === 1) {
                                            return (
                                                <Text
                                                    key={id}
                                                    width={["90%", "90%", "90%", "90%", "93%", "93%"]}
                                                    as="h1"
                                                    fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
                                                    fontFamily='var(--foundersGrotesk-regular)'
                                                    paddingTop={["2px"]}
                                                    margin={["0 auto"]}
                                                    color={["#333333"]}
                                                    fontWeight={["400"]}
                                                    lineHeight={"normal"}
                                                    onClick={() => handleScrollTo(id)}
                                                    style={{ cursor: 'pointer' }}

                                                >
                                                    {item.children[0]?.text}
                                                </Text>
                                            );
                                        } else if (level === 2) {
                                            return (
                                                <Text
                                                    key={id}
                                                    width={["90%", "90%", "90%", "90%", "93%", "93%"]}
                                                    as="h2"
                                                    fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
                                                    fontFamily='var(--foundersGrotesk-regular)'
                                                    paddingTop={["9px"]}
                                                    margin={["0 auto"]}
                                                    paddingBottom={["15px"]}
                                                    color={["#333333"]}
                                                    fontWeight={["400"]}
                                                    lineHeight={"normal"}
                                                    onClick={() => handleScrollTo(id)}
                                                    style={{ cursor: 'pointer' }}

                                                >
                                                    {item.children[0]?.text}
                                                </Text>
                                            );
                                        } else if (level === 3) {
                                            return (
                                                <Box width={["90%", "90%", "90%", "90%", "87%", "87%"]} margin={["0 auto"]} >
                                                    <Text
                                                        key={id}
                                                        display={["flex"]}
                                                        alignItems={["baseline"]}
                                                        gap={["10px"]}
                                                        fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                                                        color={["#4B4B4B"]}
                                                        paddingBottom={["8px"]}
                                                        fontFamily='var(--foundersGrotesk-regular)'
                                                        onClick={() => handleScrollTo(id)}
                                                        style={{ cursor: 'pointer' }}

                                                    >
                                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.265625 0.509277L7.1522 6.01854L0.265625 11.5278V0.509277Z" fill="#4B4B4B" />
                                                        </svg>
                                                        {item.children[0]?.text}
                                                    </Text>
                                                </Box>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
            <Box mt={4}>
                {table_of_content?.map((item, index) => {
                    if (item.type === 'heading' || item.type === 'paragraph') {
                        const id = generateId(item.children[0]?.text || `section-${index}`);
                        if (item.type === 'heading') {
                            return (
                                <Box key={id} id={id} >
                                    {item.level === 1 && (
                                        <Text as="h1" sx={blogHeading}>
                                            {item.children[0]?.text}
                                        </Text>
                                    )}
                                    {item.level === 2 && (
                                        <Text as="h2" sx={blogHeading}>
                                            {item.children[0]?.text}
                                        </Text>
                                    )}
                                    {item.level === 3 && (
                                        <Text as="h3" sx={blogHeading}>
                                            {item.children[0]?.text}
                                        </Text>
                                    )}
                                </Box>
                            );
                        }
                        if (item.type === 'paragraph') {
                            return (
                                <Box key={id} id={id}>
                                    <Text as="p" sx={contentText1}>
                                        {item.children[0]?.text}
                                    </Text>
                                </Box>
                            );
                        }
                    }
                    return null;
                })}
            </Box>
        </Box>
    )
}

export default Content;
