import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import axios from 'axios';

const PartnerProgram = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/partner-programmes?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    pb: '30px',
  };
  const sectionParagraph = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '688px',
    m: '0 auto',
  };
  // Program
  const programTitle = {
    fontSize: ['18px', '18px', '18px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.704px',
    mb: '15px',
  };
  const programText = {
    fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
  };
  const programmeImg = {};
  return (
    <Box
      p={['80px 0', '80px 0', '80px 0', '150px 0', '150px 0', '150px 0']}
      overflow={'hidden'}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
      >
        {/* Section Heading */}
        <Box textAlign={'center'} pb={'60px'}>
          <Text as={'h2'} sx={sectionH2Heading}>
            The Partner Programme
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            Our partners offer a range of tools, services and solutions to help
            teams reach their productivity potential.
          </Text>
        </Box>

        {/* parners  programme */}
        <Box
          display={'grid'}
          gridTemplateColumns={[
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(3,1fr)',
            'repeat(3,1fr)',
            'repeat(3,1fr)',
          ]}
          gap={'20px'}
        >
          {data.map(item => (
            <Box
              key={item.id}
              bg={'#F5F5F5'}
              minHeight={[
                '127px',
                '127px',
                '127px',
                '230px',
                '230px',
                '230px',
                '230px',
              ]}
            >
              <Flex>
                <Box>
                  <Image
                    objectFit="cover"
                    background={["#0854fc"]}
                    padding={["15px"]}
                    w={'100%'}
                    minW={['61px', '61px', '61px', '61px', '89px']}
                    maxW={['61px', '61px', '61px', '61px', '89px']}
                    h={['auto']}
                    aspectRatio={1}
                    src={`https://cms.wearetenet.com${item.attributes.image.data.attributes.url}`}
                    sx={programmeImg}
                  />{' '}
                  {/* Assuming image URL is in item.attributes.image */}
                </Box>
                <Box
                  m={[
                    '21px 5px 19px 5px',
                    '21px 5px 19px 5px',
                    '21px 5px 19px 5px',
                    '21px 18px 19px 19px',
                  ]}
                >
                  <Text as={'h3'} sx={programTitle}>
                    {item.attributes.heading}
                  </Text>
                  <Text as={'p'} sx={programText}>
                    {item.attributes.description}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};
export default PartnerProgram;
