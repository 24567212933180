import {
  Box,
  Container,
  Flex,
  Image,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Desktop from '../../../assets/BgPatterns/Partners/banner.gif';
import Mobile from '../../../assets/BgPatterns/Partners/Mobile.gif';
import CopyLink from '../../../assets/Career/DetailPage/ShareLink/copyLink.svg';
import LinkDn from '../../../assets/Career/DetailPage/ShareLink/linkdn.svg';
import twiter from '../../../assets/Career/DetailPage/ShareLink/x.svg';
import Breadcrumb from './Bradcrumb';

const HeroBanner = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false); // State for copy functionality
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-openings?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  // Share Functionality
  const shareOnTwitter = () => {
    const url = window.location.href;
    const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
    window.open(twitterShareURL, '_blank', 'width=600,height=400');
  };
  const shareOnLinkedIn = () => {
    const url = window.location.href; // The URL to be shared
    const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`;
    window.open(linkedInShareURL, '_blank', 'width=600,height=400'); // Opens the share dialog in a new window
  };

  const copyUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false); // Hide tooltip after 2 seconds
      }, 2000);
    });
  };
  const bannerTitle = {
    fontSize: ['36px', '37px', '40px', '48px', '58px', '58px'],
    color: '#fff',
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: 600,
    padding: '0',
    lineHeight: 'normal',
  };
  const bannerParaGraph = {
    fontSize: ['20px', '20px', '22px', '22px', '24px', '24px'],
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    maxW: ['353px', '353px', '425px', '570px', '625px', '625px'],
    lineHeight: 'normal',
    paddingTop: ['20px', '20px', '20px', '20px', '20px', '20px'],
    paddingBottom: ['22px', '22px', '22px', '35px', '35px', '35px', '35px'],
  };
  return (
    <Box
      minH={['553px', '553px', '553px', '450px', '450px']}
      padding={[
        '95px 0 50px',
        '95px 0 50px',
        '95px 0 50px',
        '55px 0 95px',
        '55px 0 95px',
        '55px 0 95px',
      ]}
      background={'#000'}
      display={['block', 'block', 'block', 'flex']}
      alignItems={'center'}
      overflow={'hidden'}
      position="relative"
      ref={heroSectionRef}
      id="hero-section"
    >
      {/* BackGround Video */}
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', '0', '0', '0']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          src={Desktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          objectPosition={'right bottom'}
          height={'346px'}
          objectFit={'contain'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
        padding={['0 16px', '0 16px', '0 16px', '0']}
      >
        {/* BreadCrum */}
        <Box
          pb={['0', '0', '51px', '71px', '71px']}
          display={['none', 'none', 'block', 'block']}
        >
          <Breadcrumb />
        </Box>
        {/* BanneR Content */}
        <Flex display={['block', 'block', 'block', 'flex', 'flex', 'flex']}>
          {/* Text Part */}
          <Box display={'flex'} alignItems={'center'}>
            <Box
              pr={['0px', '0px', '0px', '55px', '55px', '55px']}
              mb={['90px', '90px', '90px', '0px', '0px', '0px']}
              maxW={['400px', '500px', '600px', '700px', '804px', '804px']}
            >
              <Text as="h1" sx={bannerTitle}>
                {firstItem?.main_heading}
              </Text>
              <Text as="p" sx={bannerParaGraph}>
                {firstItem?.main_description}
              </Text>
              {/* Job Detail Share Buttons */}
              <Box display={['flex']} gap={['22px']}>
                <Box onClick={shareOnLinkedIn} cursor={'pointer'}>
                  <Image src={LinkDn} alt="LinkDn" />
                </Box>
                <Tooltip label="Copied!" isOpen={copied}>
                  <Box onClick={copyUrl} title="copy" cursor={'pointer'}>
                    <Image src={CopyLink} alt="CopyLink" />
                  </Box>
                </Tooltip>
                <Box onClick={shareOnTwitter} cursor={'pointer'}>
                  <Image src={twiter} alt="twiter" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default HeroBanner;
