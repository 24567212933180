import { Box, Container, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import './Custom.css';

const checkoutSection = () => {
  const numberStyle = {
    fontSize: ['25px', '25px', '25px', '24px', '28px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    color: ['#333333'],
    fontWeight: ['400'],
    lineHeight: 'normal',
    width: '45px',
  };
  const gridTitle = {
    fontSize: ['18px', '18px', '18px', '26px', '28px', '36px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    width: ['100%'],
  };

  const gridParagraph = {
    fontSize: ['14px', '14px', '16px', '18px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['29px', '29px', '29px', '30px', '35px', '35px'],
  };

  return (
    <Box>
      <Container
        width={['90%', '90%', '90%', '94%', '90%', '83%']}
        maxW={['90%', '90%', '90%', '94%', '90%', '83%']}
        padding={'0'}
        marginTop={['27px', '30px', '48px', '55px', '60px', '65px']}
        // paddingTop={["35px", "35px", "48px", "55px", "63px", "63px"]}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
      // paddingBottom={["35px", "35px", "48px", "55px", "63px", "69px"]}
      >
        {/* Heading Section Learn More IntroDuction */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          marginTop={['20px', '25px', '38px', '45px', '53px', '60px']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['15px', '15px', '15px', '20px', '20px', '20px']}
          alignItems={['flex-start']}
          margin={['0 auto']}
          justifyContent={['space-between']}
        >
          <Text
            as="h1"
            fontSize={['24px', '24px', '30px', '30px', '30px', '36px']}
            fontFamily="var(--garnett-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '50%', '50%', '50%', '35%']}
          >
            Our services; curated for your success.
            <br />
            <Link
              href="/upcoming"

            >
              <span
                style={{
                  color: '#FFFFFF',
                  background: '#E0005E',
                  paddingTop: '11px',
                  paddingBottom: '7px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  fontFamily: 'var(--foundersGrotesk-regular)',
                  fontSize: '20px',
                  marginTop: '15px',
                  display: 'inline-block',
                }}
                _hover={{ transform: "translate3d(0, -3px, 0)" }}
                transition="transform 0.5s ease"
              >
                Learn More
              </span>
            </Link>
          </Text>
          <Text
            as="h1"
            fontSize={['16px', '16px', '16px', '18px', '18px', '20px']}
            fontFamily="var(--foundersGrotesk-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '50%', '50%', '55%', '55%']}
            paddingTop={['40px', '40px', '5px', 0, 0, 0]}
            paddingBottom={['80px', '80px', '80px', 0, 0, 0]}
          >
            Embark on a journey where every digital touchpoint is an
            opportunity. Discover how our services can elevate your brand and
            redefine your market position. Why settle for ordinary when the
            extraordinary is within reach? With Tenet, your brand's potential is
            limitless, and we offer more than services; we provide a strategic
            springboard to launch your success.
          </Text>
        </Flex>
        {/* Grid Header */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          marginTop={['30px', '45px', '68px', '95px', '123px', '140px']}
          direction={[
            'column',
            'column',
            'column',
            'column',
            'column',
            'column',
          ]}
          gap={['15px', '15px', '15px', '20px', '20px', '20px']}
          alignItems={['flex-start']}
          margin={['0 auto']}
          justifyContent={['space-between']}
        >
          <Text
            as="h1"
            fontSize={['32px', '32px', '38px', '40px', '50px', '58px']}
            fontFamily="var(--garnett-regular)"
            color={['#333333']}
            fontWeight={['400']}
            lineHeight={'normal'}
            width={['100%', '100%', '50%', '50%', '60%', '70%']}
          >
            Results delivered, Impact created, life transformed.
          </Text>
          <span
            style={{
              color: '#0057FF',
              fontFamily: 'var(--garnett-regular)',
              fontSize: '32px',
              marginTop: '15px',
              display: 'inline-block',
            }}
            className="checkoutSection"
          >
            How? It’s a mix of these things:
          </span>
        </Flex>
        {/* Two Typ Grid */}
        {/* Grid One */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '40px', '50px', '55px', '60px']}
          gap={['25px', '35px', '45px', '60px', '100px', '158px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
            width={['100%', '100%', '7%', '7%', '7%', '7%']}
          ></Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '15px', '20px', '30px', '65px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              01
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '15px', '20px', '22px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Personalised Strategies
              </Text>
              <Text as="h1" sx={gridParagraph}>
                Bespoke paths are carved for your unique vision, as we craft
                strategies that are as individual as your goals, ensuring your
                journey through the digital terrain is one of personal triumph.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '15px', '20px', '30px', '65px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              02
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '15px', '22px', '22px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Seamless Integration
              </Text>
              <Text as="h1" sx={gridParagraph}>
                We fluidly integrate cutting-edge technology with profound human
                insights, speaking the language of digital fluency to create
                experiences that resonate deeply with users.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Grid Two */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '28px', '32px', '32px', '32px']}
          gap={['25px', '35px', '45px', '60px', '100px', '158px']}
          alignItems={['stretch']}
          margin={['0 auto']}
          paddingBottom={['20px', '20px', '40px', '60px', '100px', '150px']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
            width={['100%', '100%', '7%', '7%', '7%', '7%']}
          ></Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '15px', '20px', '30px', '65px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              03
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '15px', '20px', '22px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Creative Brilliance
              </Text>
              <Text as="h1" sx={gridParagraph}>
                Our creative process redefines the conventional, turning the
                digital canvas into a narrative of your brand’s vibrancy,
                capturing attention, and invoking action.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['50px', '50px', '15px', '20px', '30px', '65px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              04
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '15px', '22px', '22px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Dynamic Growth
              </Text>
              <Text as="h1" sx={gridParagraph}>
                Experience growth as a dynamic force; adaptive, proactive, and
                always leading. With strategies that evolve alongside your
                business, you’ll navigate change as a true industry frontrunner.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box >
  );
};

export default checkoutSection;
