import { Box, Button, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Define button styles
const buttonStyles = {
  lineHeight: 'normal',
  p: '8px 23px 0',
  borderRadius: 'none',
  bg: '#00763D',
  fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
  fontWeight: '400',
  color: '#fff',
  fontFamily: 'var(--foundersGrotesk-regular);',
  height: ['33px', '33px', '33px', '41px', '41px', '41px'],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  _hover: {
    bg: '#00763D',
    color: '#fff',
  },
};

const formHeading = {
  color: '#00342B',
  fontFamily: 'var(--garnett-regular)',
  fontSize: ['26px', '26px', '23px', '28px', '32px', '32px'],
  fontWeight: '400',
  lineHeight: 'normal',
  paddingBottom: ['32px', '32px', '32px', '36px', '38px', '40px'],
};

const DataDownloadForm = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-openings?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // Render job details dynamically
  const renderJobDetails = jobDetails => {
    return jobDetails.map((detail, index) => (
      <Text
        key={index}
        fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
        fontFamily="var(--foundersGrotesk-regular)"
        color="#00342B"
        fontWeight="400"
        lineHeight="normal"
        mb={3}
      >
        {detail.children.map((child, i) => (
          <span
            key={i}
            style={{
              fontSize: 'inherit',
              fontFamily: child.bold
                ? 'var(--foundersGrotesk-medium)'
                : 'inherit',
              color: 'inherit',
              fontWeight: child.bold ? '500' : '400',
              lineHeight: 'normal',
            }}
          >
            {child.text}
          </span>
        ))}
      </Text>
    ));
  };

  return (
    <Box position={'sticky'} top={'10%'}>
      <Box
        bg={'#CCF1DFA1'}
        p={[
          '32px 29px 38px',
          '32px 29px 38px',
          '32px 29px 38px',
          '40px 36px 46px',
        ]}
      >
        {/* Form Heading */}
        <Box>
          <Heading as={'h4'} sx={formHeading}>
            Key Job Details
          </Heading>
        </Box>

        {/* Job Details Section */}
        <Box>
          {data && renderJobDetails(data.attributes.job_details)}
          <Box paddingTop={['8px']}>
            <Button
              width={['100%']}
              {...buttonStyles}
              onClick={() => window.open(data.attributes.apply_link)}
            >
              Apply Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DataDownloadForm;
