import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import DesktopFullImage from '../../../assets/CaseStudy/Iros/BrandIdea/DesktopFullImage.webp';
import MobileFullImage from '../../../assets/CaseStudy/Iros/BrandIdea/DesktopFullImageMobile.webp';
import AfterGridDesktopFullImage from '../../../assets/CaseStudy/Iros/BrandIdea/gridFullWidthDesktop.webp';
import AfterGridMobileFullImage from '../../../assets/CaseStudy/Iros/BrandIdea/gridFullWidthMobile.webp';

const BrandIDEA = () => {
  return (
    <>
      {/* Brand Idia Text */}

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              BRAND IDEA
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              as="h1"
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
              paddingBottom={['16px', '16px', '16px', '24px']}
            >
              Catalyst
            </Text>
            <Text
              as="p"
              fontSize={['16px', '16px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              Aligned with the brand positioning, the core theme for messaging
              and visuals elements is "Catalyst". This concept uniquely
              positions IROS as a catalytic force for transformative
              breakthroughs, drug development advancements, and meeting needs in
              underserved regions, accelerating global healthcare
              transformation.
              <br />
              <br />
              This led to the creation of "Formix", a visual element that
              captures the brand's essence, highlighting collaboration,
              innovation, discovery, and progress.
            </Text>
          </Box>
        </Box>
      </Box>
      {/* Brand Idia Full Width Image */}
      <Box
        width={['100%', '100%', '80%', '80%']}
        margin={['0 auto']}
        paddingTop={['24px', '24px', '24px', '40px']}
      >
        {/* Desktop */}
        <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
          <Image width={'100%'} height={'auto'} src={DesktopFullImage} />
        </Box>
        {/* Mobile */}
        <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
          <Image width={'100%'} height={'auto'} src={MobileFullImage} />
        </Box>
      </Box>
      {/* Gird */}

      <Box>
        <Box width={['100%', '100%', '80%', '80%']} margin={['0 auto']}>
          <Box
            display={['grid']}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(2,1fr)',
            ]}
            rowGap={['12px', '12px', '12px', '47px', '47px']}
            gap={['21px', '21px']}
            paddingTop={['17px']}
          >
            <Box>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/iros/14.webp"
                h={'auto'}
                w={'100%'}
              />
            </Box>

            <Box>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/iros/15.webp"
                h={'auto'}
                w={'100%'}
              />
            </Box>
            <Box>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/iros/16.webp"
                h={'auto'}
                w={'100%'}
              />
            </Box>

            <Box>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/iros/17.webp"
                h={'auto'}
                w={'100%'}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Full Width Image After Grid */}
      {/* Brand Idia Full Width Image */}
      <Box
        width={['100%', '100%', '94%', '94%']}
        margin={['0 auto']}
        paddingTop={['12px', '12px', '48px', '48px']}
      >
        {/* Desktop */}
        <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
          <Image
            width={'100%'}
            height={'auto'}
            src={AfterGridDesktopFullImage}
          />
        </Box>
        {/* Mobile */}
        <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
          <Image
            width={'100%'}
            height={'auto'}
            src={AfterGridMobileFullImage}
          />
        </Box>
      </Box>
    </>
  );
};

export default BrandIDEA;
