import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
} from '@chakra-ui/react';
import React from 'react';

const BreadCrum = () => {
  return (
    <Breadcrumb
      spacing="8px"
      separator=">"
      color={['#ffffff']}
      alignItems={['baseline']}
      display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
    >
      <BreadcrumbItem alignItems={['baseline']}>
        <BreadcrumbLink
          href="/"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>Home</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem alignItems={['baseline']}>
        <BreadcrumbLink
          href="/work"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>Case Studies</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem
        isCurrentPage
        alignItems={['baseline']}
        className="breadCrumb1"
      >
        <BreadcrumbLink
          href="#"
          fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
          color={['#ffffff']}
          as="p"
          fontFamily="var(--foundersGrotesk-light)"
          fontWeight={'400'}
        >
          <Center>IROS</Center>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default BreadCrum;
