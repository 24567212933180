import {
  AspectRatio,
  Box,
  Button,
  Container,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import gifMobile from '../../assets/BgPatterns/solution-detail page-pattern/outcomes/Solution-By-Outcomes-Detail-page-pattern-mobile.gif';
import gifDesktop from '../../assets/BgPatterns/solution-detail page-pattern/outcomes/Solution-By-Outcomes-Detail-page-pattern.gif';
import Breadcrumb from './BreadCrum';

const HeroBanner = () => {

  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;

  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  return (
    <Box ref={heroSectionRef} id="hero-section"
      bg="#0c0849"
      padding={['41px 0 133px', '41px 0 133px', '41px 0 133px', '55px 0 155px']}
      overflow={'hidden'}
      position="relative"
    >
      {/* Video Background */}
      {/*  */}
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'none', 'block', 'block', 'block']}
          src={gifDesktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'block', 'none']}
          src={gifMobile}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        width={['100%', '100%', '90%', '90%', '90%', '90%']}
        maxW={['100%', '100%', '100%', '90%', '90%', '90%']}
        marginBottom={['30px', '30px', 0, 0, 0, 0]}
        position={'relative'}
        zIndex={'3'}
      >
        {/* BreadCrum */}
        <Breadcrumb />
        <Box
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          gap={['51px', '51px', '51px', '51px', '51px', '51px']}
        >
          <Box
            w={['100%', '100%', '100%', '55%', '55%', '55%']}
            color="#FFFFFF"
            display="flex"
            flexDirection="column"
            paddingTop={['0px', '0px', '0px', '37px', '47px', '57px']}
          >
            <Box>
              {/* Banner Title And Banner Paragraph */}
              <Box maxW={['695px']}>
                {/* Display Solution Type */}
                <Text
                  as={'p'}
                  letterSpacing={'-0.44px'}
                  lineHeight={'normal'}
                  fontWeight={'400'}
                  fontSize={['14px', '14px', '14px', '20px']}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  paddingBottom={['4px']}
                >
                  {firstItem?.breadcrumb_heading}
                </Text>
                {/* Solution Banner Title */}
                <Text
                  as="h1"
                  fontSize={['36px', '36px', '36px', '36px', '42px', '56px']}
                  fontFamily="var(--garnett-semibold)"
                  fontWeight={600}
                  lineHeight={'normal'}
                  color={'#fff'}
                >
                  {firstItem?.main_heading}
                </Text>
                <Text
                  as="p"
                  fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  padding={[
                    '23px 0 23px',
                    '23px 0 23px',
                    '23px 0 23px',
                    '24px 0 40px',
                    '24px 0 40px',
                    '24px 0 40px',
                  ]}
                  letterSpacing={'-0.44px'}
                  maxW={['695px']}
                >
                  {firstItem?.main_description}
                </Text>
              </Box>
              {/* Request consultation Button */}
              <Box
                maxW={['488px']}
                marginBottom={['40px', '40px', '40px']}
                display={['flex']}
                border={'2px solid #fff'}
              >
                <Input
                  placeholder="Email Address"
                  color={'#fff'}
                  borderRadius={'0'}
                  border={'0'}
                  width={['59%']}
                  fontSize={['14px', '16px', '20px', '20px', '20px']}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  paddingTop={['12px']}
                  fontFamily="var(--foundersGrotesk-regular)"
                  _placeholder={{
                    color: '#fff',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                  }}
                  paddingBottom={'8px'}
                />
                <Button
                  width={['41%']}
                  bg="#0057FF"
                  color="#ffffff"
                  borderRadius="0"
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight="400"
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  position="relative"
                  borderLeft="2px"
                  borderLeftColor="#fff"
                  paddingTop={['5px']}
                  _hover={{
                    bg: 'blue',
                    color: '#ffffff',
                  }}
                  fontSize={['14px', '16px', '20px', '20px', '20px']}
                >
                  Request Consultation
                </Button>
              </Box>
            </Box>
          </Box>

          <Box w={['100%', '100%', '100%', '45%', '45%', '45%']}>
            <AspectRatio ratio={1.37}>
              <Image
                src={`https://cms.wearetenet.com${firstItem?.main_image?.data?.attributes?.url || ''
                  }`}
                w={['100%']}
                height={['100%']}
                objectFit={'cover'}
                alt="Hero Image"
              />
            </AspectRatio>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default HeroBanner;
