import React from 'react';
import { Box, Container, Flex } from '@chakra-ui/react';
import ceo from '../../assets/About/ourT/ceo.svg';
import ceoDesktop from '../../assets/About/ourT/ceoDesktop.svg';

import './Custom.css';

const ceoSection = () => {
  return (
    <Box>
      <Container
        width={['100%', '100%', '100%', '100%', '100%', '100%']}
        maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
        padding={'0'}
        marginTop={['80px', '80px', '80px', '85px', '93px', '100px']}
        marginBottom={['50px', '60px', '80px', '105px', '120px', '150px']}
      >
        <Flex
          display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
          width={['100%', '100%', '85%', '94%', '90%', '83%']}
          maxW={['100%', '100%', '85%', '94%', '90%', '83%']}
          direction={[
            'column',
            'column',
            'column',
            'column',
            'column',
            'column',
          ]}
          gap={['25px', '30px', '35px', '40px', '50px', '50px']}
          margin={['0 auto']}
          justifyContent={['center']}
          alignItems={['center']}
          className="ceoSection"
        >
          <img src={ceoDesktop} alt="CEO of the company"/>

          {/* <Text
                        as="h1"
                        fontSize={['22px', '24px', '24px', '30px', '30px', '36px']}
                        fontFamily='var(--garnett-regular-italic)'
                        color={["#000000"]}
                        fontWeight={["400"]}
                        width={["100%", "100%", "100%", "70%", "58%", "54%"]}
                        fontStyle={["italic"]}
                        textAlign={"center"}
                        lineHeight={"normal"}
                    >
                        <span>
                            <span data-hasquotes="true">
                                More than anything, we want people to succeed, professionally and personally.
                            </span>
                        </span>
                    </Text>
                    <Text
                        fontSize={['15px', '15px', '15px', '18px', '20px', '20px']}
                        fontFamily='var(--garnett-regular)'
                        color={["#333333"]}
                        fontWeight={["400"]}
                        width={["100%", "100%", "100%", "80%", "80%", "80%"]}
                        textAlign={"center"}
                        lineHeight={"normal"}
                    >
                        <span>
                            -Shantanu Pandey, CEO @ Tenet
                        </span>
                    </Text> */}
        </Flex>

        <Flex
          display={['flex', 'flex', 'none', 'none', 'none', 'none']}
          width={['90%', '90%', '90%', '94%', '90%', '83%']}
          maxW={['90%', '90%', '90%', '94%', '90%', '83%']}
          direction={[
            'column',
            'column',
            'column',
            'column',
            'column',
            'column',
          ]}
          gap={['25px', '30px', '35px', '40px', '50px', '50px']}
          margin={['0 auto']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <img src={ceo} alt="CEO of the company" />
        </Flex>
      </Container>
    </Box>
  );
};

export default ceoSection;
