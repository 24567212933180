import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const solutionContent = () => {
  const backgroundText = [
    {
      title:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
    },
  ];
  return (
    <Container
      paddingTop={['10px', '10px', '10px', '10px', '0px', '10px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['80px', '80px', 0, 0, 0, 0]}
      padding={['0']}
    >
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              SOLUTION
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={['column']}
              gap={['30px', '30px', '30px', '30px', '30px', '30px']}
              margin={['0 auto']}
            >
              <Text
                as="h1"
                fontSize={['32px', '32px', '32px', '32px', '36px', '36px']}
                fontFamily="var(--garnett-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                Empowering Personal Fitness Journeys
              </Text>
              <Text
                as="h1"
                fontSize={['16px', '16px', '18px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                We crafted a holistic branding strategy that included a new
                logo, packaging, and a cohesive design language, all reflecting
                Pazazz’s core brand attributes: confidence, impact,
                attentiveness, and motivation.
                <br />
                <br />
                Central to our approach was creating a robust, user-friendly
                e-commerce platform. This platform features advanced AI
                personalization for product recommendations and uses conversion
                rate optimization practices to maximize engagement and
                conversions. We also set up and customized GA4 for comprehensive
                data analytics, capturing all consumer insights. This helps in
                making better decisions and crafting more profitable consumer
                journeys for Pazazz, while providing an optimized experience for
                its customers.
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default solutionContent;
