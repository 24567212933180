import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const whatToExpect = () => {
  const numberStyle = {
    fontSize: ['25px', '25px', '25px', '25px', '28px', '28px'],
    width: ['45px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridTitle = {
    fontSize: ['18px', '18px', '18px', '20px', '28px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridParagraph = {
    fontSize: ['14px', '14px', '14px', '16px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['20px', '20px', '25px', '30px', '60px', '60px'],
  };

  return (
    <Box>
      <Container
        width={['90%', '90%', '90%', '94%', '90%', '83%']}
        maxW={['90%', '90%', '90%', '94%', '90%', '83%']}
        padding={'0'}
        marginTop={['35px', '35px', '48px', '95px', '123px', '153px']}
        // paddingTop={["35px", "35px", "48px", "55px", "63px", "63px"]}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        // paddingBottom={["35px", "35px", "48px", "55px", "63px", "69px"]}
      >
        {/* 01 And 02 */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          {/* What To Expect Totle */}
          <Text
            as="h1"
            fontSize={['16px', '26px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
            width={['100%', '100%', '25%', '25%', '25%', '25%']}
          >
            WHAT TO EXPECT
          </Text>
          {/* 01 */}
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              01
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                After more than a decade in business
              </Text>
              <Text as="p" sx={gridParagraph}>
                A roadmap is only as good as the discovery it’s built on. You
                receive tailored and tested prep work so that we understand
                where you're coming from, comprehensively. We then craft a
                roadmap aligned with your needs & goals.
              </Text>
            </Flex>
          </Flex>

          {/* 02 */}
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              02
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '15px', '15px', '15px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Guided Processes and Inputs
              </Text>
              <Text as="p" sx={gridParagraph}>
                Simplifying complexities is our aim. Experience guided processes
                and tailored inputs throughout the project lifecycle, ensuring
                your voice is heard and your goals achieved with expert
                guidance.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* 03 and 0 4 */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '28px', '32px', '32px', '32px']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
            width={['100%', '100%', '25%', '25%', '25%', '25%']}
          ></Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              03
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Feel The Pulse, Feel The Sync.
              </Text>
              <Text as="p" sx={gridParagraph}>
                Experience our dependable working cadence, with weekly meetings
                and deliverables. Stay in sync with what’s happening, every step
                of the way. Know exactly when and what to expect, with no room
                for unpleasant surprises.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              04
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                With you, for you, by us.
              </Text>
              <Text as="p" sx={gridParagraph}>
                Think of us as an extension of your team, not an external
                agency. We work closely with you and for you, through every
                iteration, every milestone, and beyond - to deliver outcomes
                that matter.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        {/* 05 and 06 */}
        <Flex
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          paddingTop={['0px', '0px', '28px', '32px', '32px', '32px']}
          gap={['25px', '35px', '45px', '60px', '70px', '78px']}
          alignItems={['stretch']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
            width={['100%', '100%', '25%', '25%', '25%', '25%']}
          ></Text>
          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              05
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Guided Deliveries and Training
              </Text>
              <Text as="p" sx={gridParagraph}>
                You hate confusions. We do too. Guided deliveries and videos let
                you asses the work at your own schedule, collaborate internally
                with your team, and get buy-in from stakeholders.
              </Text>
            </Flex>
          </Flex>

          <Flex
            width={['100%', '100%', '100%', '70%', '50%', '50%']}
            maxW={['100%', '100%', '100%', '70%', '50%', '50%']}
            direction={['row', 'row', 'row', 'row', 'row', 'row']}
            gap={['43px', '43px', '15px', '20px', '30px', '35px']}
            alignItems={['flex-start']}
            margin={['0 auto']}
            borderBottom={'1px solid #B5B5B5'}
          >
            <Text as="h1" sx={numberStyle}>
              06
            </Text>
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={[
                'column',
                'column',
                'column',
                'column',
                'column',
                'column',
              ]}
              gap={['15px', '15px', '15px', '10px', '10px', '10px']}
              alignItems={['flex-start']}
              margin={['0 auto']}
            >
              <Text as="h1" sx={gridTitle}>
                Iterate, forge, then create gold.
              </Text>
              <Text as="p" sx={gridParagraph}>
                Regular feedbacks, and sprint based iterations keep the
                deliverables relevant, & in sync with your end goals. Your
                feedback is heard regularly and your solutions are built
                incrementally.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default whatToExpect;
