import { Box, Container, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const WhyWorkWithUs = () => {
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });
  const sectionHeading = {
    color: '#000',
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: ['22px', '22px', '22px', '40px', '40px', '40px'],
  };
  const sectionParagraph = {
    color: '#000',
    fontSize: ['14px', '16px', '20px', '32px', '32px', '32px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    maxW: '688px',
    m: '0 auto',
  };
  // Section Styling
  const boxWrapper = {
    display: ['block', 'block', 'block', 'flex', 'flex', 'flex'],
    flexWrap: 'wrap',
    width: '100%',
    pt: '60px',
  };
  const boxDesign = {
    position: 'relative',
    width: ['100%', '100%', '100%', '33.33%', '33.33%', '33.33%'],
  };
  const stratagyNumber = {
    fontFamily: 'var(--garnett-light)',
    color: '#B882FF',
    fontSize: ['48px', '48px', '48px', '64px', '64px', '64px'],
    fontWeight: '300',
    lineHeight: 'normal',
    display: 'inline-block',
  };
  const stratagyTitle = {
    fontFamily: 'var(--garnett-regular)',
    color: '#000',
    fontSize: ['20px', '20px', '20px', '28px', '28px', '28px'],
    fontWeight: '400',
    lineHeight: 'normal',
    paddingTop: ['35px', '35px', '35px', '35px', '40px', '40px'],
    pb: ['17px', '17px', '30px', '30px', '30px', '30px'],
  };
  const stratagyDescription = {
    fontSize: ['15px', '15px', '15px', '20px', '20px', '20px'],
    color: '#333333',
    fontFamily: 'var(--foundersGrotesk-regular);',
    fontWeight: '400',
    lineHeight: 'normal',
  };
  const sliderSettings = {
    observeParents: true,
    observer: true,
    modules: [Navigation, FreeMode],
    speed: 750,
    spaceBetween: 12,
    slidesPerView: 1.3, // default value for mobile

    // Responsive breakpoints
    breakpoints: {
      768: {
        slidesPerView: 1.8, // for tablet
      },
      1024: {
        slidesPerView: 2.1, // for desktop
      },
    },
  };

  // Next Slide
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box
      p={[
        '80px 0 0',
        '80px 0 0',
        '80px 0 80px',
        '195px 0 150',
        '195px 0 150',
        '195px 0 150',
      ]}
    >
      {/* Why Work With Us Section Headings */}
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        position={'relative'}
        pb={['40px', '40px', '40px', '100px', '127px', '127px']}
      >
        {/* Section Headings */}
        <Box
          maxW={['350px', '450px', '450', '688px']}
          m={'0 auto'}
          textAlign={'center'}
        >
          <Text as={'h2'} sx={sectionHeading}>
            Why work with us?
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            We simplify complex problem statements & turn them into holistic
            experiences.
          </Text>
        </Box>
      </Container>
      {/* Stratagy */}
      {/* SliderWrapper And Slides */}
      {!isMobile ? (
        <Container
          maxWidth={['100%', '100%', '100%', '80%', '80%', '80%']}
          m={'0 auto'}
          position={'relative'}
        >
          <Box sx={boxWrapper}>
            <Box
              sx={boxDesign}
              borderBottom={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderRight={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderBottomColor={'#D0D5DD!important;'}
              borderRightColor={'#D0D5DD!important'}
              pt={'0'}
              px={['40px', '40px', '40px', '24px', '24px', '24px']}
              pb={'60px'}
            >
              <Text as="span" sx={stratagyNumber}>
                01
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                Proven Expertise
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                With years of experience in the industry, our team brings a
                wealth of knowledge and creativity to every project. We’ve
                worked with a diverse range of clients, delivering successful UX
                solutions that drive real results.
              </Text>
            </Box>
            <Box
              sx={boxDesign}
              borderBottom={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderRight={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderBottomColor={'#D0D5DD!important;'}
              borderRightColor={'#D0D5DD!important'}
              pt={'0'}
              px={'40px'}
              pb={'60px'}
            >
              <Text as="span" sx={stratagyNumber}>
                02
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                User-Centric Approach
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                We prioritize understanding your users and their needs. Our
                empathy-driven design ensures that every decision is made with
                the user in mind, resulting in intuitive and engaging digital
                experiences.
              </Text>
            </Box>
            <Box
              sx={boxDesign}
              borderBottom={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderBottomColor={'#D0D5DD!important;'}
              pt={'0'}
              px={'40px'}
              pb={'60px'}
            >
              <Text as="span" sx={stratagyNumber}>
                03
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                Collaborative Process
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                Our approach is highly collaborative, involving your team at
                every stage of the design process. We believe in transparent
                communication and value your input to ensure the final product
                aligns with your vision.
              </Text>
            </Box>
            {/*  */}
            <Box
              sx={boxDesign}
              borderRight={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderRightColor={'#D0D5DD!important'}
              pb={'0'}
              pt={'60px'}
              px={'40px'}
            >
              <Text as="span" sx={stratagyNumber}>
                04
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                Innovative Solutions
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                We stay ahead of industry trends and leverage cutting-edge
                technology to provide innovative solutions that meet the
                evolving needs of your users. Our designs are not only
                functional but also future-proof.
              </Text>
            </Box>
            <Box
              sx={boxDesign}
              borderRight={['0px', '0px', '0px', '1px', '1px', '1px']}
              borderRightColor={'#D0D5DD!important'}
              pb={'0'}
              pt={'60px'}
              px={'40px'}
            >
              <Text as="span" sx={stratagyNumber}>
                05
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                Commitment to Excellence
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                Each project is a unique journey; our bespoke solutions are
                tailored to your brand's needs, ensuring fluid user journeys and
                impactful results.
              </Text>
            </Box>
            <Box sx={boxDesign} pb={'0'} pt={'60px'} px={'40px'}>
              <Text as="span" sx={stratagyNumber}>
                06
              </Text>
              <Text as={'h3'} sx={stratagyTitle}>
                Reliable Partnership
              </Text>
              <Text as={'p'} sx={stratagyDescription}>
                Trust and reliability are at the core of our work. We understand
                the importance of deadlines and quality standards, ensuring that
                we deliver projects on time and to your satisfaction. Our team
                is always ready to support your needs, making us a dependable
                partner for your business growth.
              </Text>
            </Box>
          </Box>
        </Container>
      ) : (
        <>
          {/* Slider Navigation */}
          {/* Slider Nav Is not In Design If Need Then You Will Be Display Block */}
          <Container
            display={'none'}
            maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
            m={'0 auto'}
          >
            {/* Button */}
            <Flex justifyContent={'space-between'} mb={'25px'}>
              <Box>
                <Text as={'p'}>{`${currentSlide}/${
                  swiperRef.current?.slides?.length ?? 6
                }`}</Text>
              </Box>
              <Flex>
                <Box mr={'20px'} onClick={() => goPrev()}>
                  <svg
                    cursor={'pointer'}
                    width="29"
                    height="28"
                    viewBox="0 0 29 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2475_28134)">
                      <path
                        d="M13.643 7.82971L14.5842 8.74656L9.59517 13.7533H22.8575V15.0697H9.59517L14.5842 20.0541L13.643 20.9933L7.06117 14.4115L13.643 7.82971Z"
                        fill="#333333"
                      />
                    </g>
                    <circle cx="14.959" cy="14" r="13.5" stroke="#333333" />
                    <defs>
                      <clipPath id="clip0_2475_28134">
                        <rect
                          width="21.0618"
                          height="21.0618"
                          fill="white"
                          transform="matrix(-1 0 0 1 25.4902 3.88062)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
                <Box onClick={() => goNext()}>
                  <svg
                    cursor={'pointer'}
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2475_28139)">
                      <path
                        d="M15.316 7.82971L14.3748 8.74656L19.3638 13.7533H6.10147V15.0697H19.3638L14.3748 20.0541L15.316 20.9933L21.8978 14.4115L15.316 7.82971Z"
                        fill="#333333"
                      />
                    </g>
                    <circle cx="14" cy="14" r="13.5" stroke="#333333" />
                    <defs>
                      <clipPath id="clip0_2475_28139">
                        <rect
                          width="21.0618"
                          height="21.0618"
                          fill="white"
                          transform="translate(3.46875 3.88062)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
              </Flex>
            </Flex>
          </Container>
          {/* Slider */}
          <Container maxWidth={['95%']} mr={'0'} position={'relative'} p={'0'}>
            <Swiper
              style={{ boxWrapper }}
              {...sliderSettings}
              ref={swiperRef}
              onSlideChange={swiper => handleSlideChange(swiper)}
              className="whyWorkWithUsSlider"
            >
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  01
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  Proven Expertise
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  With years of experience in the industry, our team brings a
                  wealth of knowledge and creativity to every project. We’ve
                  worked with a diverse range of clients, delivering successful
                  UX solutions that drive real results.
                </Text>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  02
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  User-Centric Approach
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  We prioritize understanding your users and their needs. Our
                  empathy-driven design ensures that every decision is made with
                  the user in mind, resulting in intuitive and engaging digital
                  experiences.
                </Text>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  03
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  Collaborative Process
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  Our approach is highly collaborative, involving your team at
                  every stage of the design process. We believe in transparent
                  communication and value your input to ensure the final product
                  aligns with your vision.
                </Text>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  04
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  Innovative Solutions
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  We stay ahead of industry trends and leverage cutting-edge
                  technology to provide innovative solutions that meet the
                  evolving needs of your users. Our designs are not only
                  functional but also future-proof.
                </Text>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  05
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  Commitment to Excellence
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  Each project is a unique journey; our bespoke solutions are
                  tailored to your brand's needs, ensuring fluid user journeys
                  and impactful results.
                </Text>
              </SwiperSlide>
              <SwiperSlide
                style={{
                  ...boxDesign,
                  background: ['#F5F5F5'],
                  padding: '38px 18px 90px',
                }}
              >
                <Text as="span" sx={stratagyNumber}>
                  06
                </Text>
                <Text as={'h3'} sx={stratagyTitle}>
                  Reliable Partnership
                </Text>
                <Text as={'p'} sx={stratagyDescription}>
                  Trust and reliability are at the core of our work. We
                  understand the importance of deadlines and quality standards,
                  ensuring that we deliver projects on time and to your
                  satisfaction. Our team is always ready to support your needs,
                  making us a dependable partner for your business growth.
                </Text>
              </SwiperSlide>
            </Swiper>
          </Container>
        </>
      )}
    </Box>
  );
};
export default WhyWorkWithUs;
