import { Box, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TrustedSolutionText = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  return (
    <Box
      padding={['80px 0 85px', '80px 0 85px', '80px 0 85px', '128px 0 110px']}
      backgroundColor={'#000000'}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '84%', '84%', '84%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
      >
        {/* Text Wrpper */}
        <Box maxW={['1072px']} margin={'0 auto'} textAlign={'center'}>
          <Heading
            as={'h2'}
            fontSize={['24px', '24px', '24px', '48px', '48px', '48px']}
            color={'#FFF'}
            fontFamily={'var(--garnett-regular);'}
            fontWeight={'400'}
            lineHeight={'normal'}
            paddingBottom={['22px', '22px', '22px', '30px']}
          >
            {firstItem?.heading}
          </Heading>
          <Text
            as="p"
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['18px', '18px', '18px', '32px', '32px', '32px']}
            color={'#fff'}
            fontWeight={'400'}
            lineHeight={'normal'}
            maxW={['688px']}
            margin={'0 auto'}
          >
            {firstItem?.description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default TrustedSolutionText;
