import { AspectRatio, Box, Heading, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const AdvanceTechUseInEcoo = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [curatedPoints, setCuratedPoints] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [blogResponse, curatedPointsResponse] = await Promise.all([
          axios.get(
            `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
          ),
          axios.get(
            `https://cms.wearetenet.com/api/industry-solution-points?filters[slug][$eq]=${slug}&populate=*`
          ),
        ]);

        if (blogResponse.data.data.length > 0) {
          setData(blogResponse.data.data[0]);
        } else {
          setError('No data found for industry solution details');
        }

        if (curatedPointsResponse.data.data.length > 0) {
          setCuratedPoints(curatedPointsResponse.data.data);
        } else {
          setError('No data found for curated points');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  const advancedTechData = [
    {
      Image: '',
      title: 'AI/ML',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: '',
      title: 'Metaverse',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: '',
      title: 'Data Analytics ',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: '',
      title: 'Cloud Computing',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
    {
      Image: '',
      title: 'Blockchain',
      description:
        'When it comes to E-commerce Solutions, KodeGlobe Technologies creates incredibly effective and actionable strategies to maximize your brand visibility. We also offer our best end-to-end consulting from market research to business model to marketing strategies.',
    },
  ];
  return (
    <Box
      padding={[
        '80px 0 58px',
        '80px 0 58px',
        '80px 0 58px',
        '100px 0 130px',
        '100px 0 130px',
        '100px 0 130px',
      ]}
      background={
        'linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%);'
      }
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px 0px']}
      >
        {/* Heading Section */}
        <Box paddingBottom={['63px', '63px', '63px', '92px']}>
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular);'}
            color={'#000'}
            maxW={['792px']}
          >
            {data?.attributes?.single_heading}
          </Heading>
        </Box>
        {/* Grid Points */}
        <Box maxW={['1127px']} ml={'auto'}>
          {/*  */}
          <Box
            display={'grid'}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(2,1fr)',
            ]}
            columnGap={['20px', '20px', '60px', '80px', '105px', '105px']}
            rowGap={['40px']}
          >
            {curatedPoints && curatedPoints.map((point, index) => {
              const imageUrl = `https://cms.wearetenet.com${point?.attributes?.image?.data?.attributes?.url || ''}`;
              return (
                <Box
                  key={index}
                  display={['flex']}
                  alignItems={'start'}
                  gap={['40px', '40px', '40px', '17px']}
                >
                  {/* Image */}
                  <AspectRatio ratio={1} minW={'49px'}>
                    <Image
                      src={imageUrl}
                      w={['100%']}
                      height={['100%']}
                      objectFit={'cover'}
                      alt="Hero Image"
                    />
                  </AspectRatio>
                  {/* Title And Paragraphs */}
                  <Box>
                    <Heading
                      as={'h3'}
                      fontSize={['24px', '24px', '24px', '36px', '36px', '36px']}
                      color={'#000'}
                      fontFamily={'var(--garnett-regular);'}
                      fontWeight={'400'}
                      lineHeight={'normal'}
                      paddingBottom={['20px', '20px', '20px', '30px']}
                    >
                      {point.attributes.heading}
                    </Heading>
                    <Text
                      as={'p'}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      fontSize={['14px', '14px', '14px', '20px', '20px', '20px']}
                      color={'#333'}
                      fontWeight={'400'}
                      lineHeight={'normal'}
                    >
                      {point.attributes.description}
                    </Text>
                  </Box>
                </Box>
              );
            })}

          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdvanceTechUseInEcoo;
