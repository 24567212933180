import { Box, Container, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PPCService = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/white-labels?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const sectionH2Heading = {
    fontSize: ['24px', '24px', '24px', '36px', '36px', '36px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: ['33.43px', '33.43px', '33.43px', '50.15px'],
    pb: ['22px', '22px', '22px', '40px', '40px', '40px'],
  };
  const sectionParagraph = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: ['16px', '16px', '16px', '24px'],
    maxW: ['100%', '100%', '100%', '50%', '50%', '50%'],
    ml: 'auto',
  };
  return (
    <Box p={['80px 0', '80px 0', '80px 0', '100px 0', '100px 0', '100px 0']}>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Section Heading */}
        <Box>
          <Text as={'h2'} sx={sectionH2Heading}>
            {firstItem?.heading}
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            {firstItem?.description}
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default PPCService;
