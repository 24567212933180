import React from 'react';
import green1 from '../../assets/Reusable/LetsWorkToGether/green1.svg';
import green2 from '../../assets/Reusable/LetsWorkToGether/green2.svg';
import LetsWorktogether from '../Reusable/LetsWorkToGether';

const LetsWorkToGether = () => {
  return (
    <div>
      <LetsWorktogether bgColor="#00342B" hand1Img={green1} hand2Img={green2} />
    </div>
  );
};

export default LetsWorkToGether;
