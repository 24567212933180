import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import reacticon from '../../../assets/CaseStudy/CaseStudyThinkVastu/reactIcon.svg';

const TechnologyContent = () => {
  return (
    <Container
      paddingTop={['60px', '60px', '60px', '80px', '80px', '80px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['0px', '0px', 0, 0, 0, 0]}
      padding={['0']}
    >
      <Flex
        width={['90%', '90%', '90%', '80%', '70%', '72%']}
        maxW={['90%', '90%', '90%', '80%', '70%', '72%']}
        direction={['column', 'column', 'row', 'row', 'row', 'row']}
        gap={['40px', '50px', '50px', '60px', '70px', '70px']}
        alignItems={[
          'baseline',
          'baseline',
          'baseline',
          'center',
          'center',
          'center',
        ]}
        margin={['0 auto']}
      >
        <Text
          as="h1"
          fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
          fontFamily="var(--foundersGrotesk-medium)"
          color={['#860038']}
          fontWeight={['500']}
          lineHeight={'normal'}
        >
          TECHNOLOGY USED
        </Text>
        <Flex flexDirection={['row']} gap={['40px']}>
          <Box width={['60px', '60px', '60px', '86px', '86px', '86px']}>
            <img
              src="https://raw.githubusercontent.com/digitallamp/svg-upload/0074818693c2005ce9c589aacffc9bc07d551839/amazon-web-services-2.svg"
              alt="awsicon"
              width={'100%'}
              height={'auto'}
            />
          </Box>
          <Box width={['70px', '70px', '70px', '99px', '99px', '99px']}>
            <img
              src={reacticon}
              width={'100%'}
              height={'auto'}
              alt="react icon"
            />
          </Box>
        </Flex>
      </Flex>
      <Image
        paddingTop={['30px', '40px', '50px', '80px', '100px', '150px']}
        paddingBottom={['30px', '40px', '50px', '80px', '80px', '80px']}
        src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/4.png"
        width={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={['0 auto']}
      />
      <Flex
        width={['100%', '100%', '100%', '96%', '96%', '96%']}
        maxWidth={['100%', '100%', '100%', '96%', '96%', '96%']}
        direction={['column', 'column', 'column', 'row', 'row', 'row']}
        gap={['20px', '20px', '20px', '30px', '30px', '30px']}
        alignItems={['baseline']}
        margin={['0 auto']}
        justifyContent={[
          'flex-start',
          'flex-start',
          'flex-start',
          'space-evenly',
          'space-evenly',
          'space-evenly',
        ]}
      >
        <Image
          src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/5.png"
          width={['100%', '100%', '100%', '50%', '50%', '50%']}
          height="auto"
          style={{ objectFit: 'cover', height: '100%' }}
        />
        <Image
          src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/6.png"
          width={['100%', '100%', '100%', '50%', '50%', '50%']}
          height="auto"
          style={{ objectFit: 'cover', height: '100%' }}
        />
      </Flex>
    </Container>
  );
};

export default TechnologyContent;
