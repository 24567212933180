import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import BreadCrum from './BreadCrum';
import './Custom.css';
import Introduction from './introduction';

const heroSection = () => {
  const bannerText = [
    {
      title: 'Innovating Athleisure with Style and Substance',
      description:
        "At Kodeglobe, we blend empathy with expertise to deliver exceptional service and innovative solutions, ensuring your brand thrives in today's dynamic world.",
    },
  ];

  const styles = {
    title: {
      fontSize: ['32px', '32px', '36px', '54px', '60px', '64px'],
      fontFamily: 'var(--garnett-semibold)',
      color: '#FFFFFF',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    industryText: {
      fontSize: ['16px', '16px', '16px', '18px', '20px', '20px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      marginBottom: ['6px', '6px', '6px', '8px', '8px', '8px'],
    },
    tagText: {
      fontSize: ['12px', '12px', '12px', '12px', '12px', '12px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      border: '1px solid #D70A20',
      width: 'fit-content',
      paddingTop: '5px',
      paddingBottom: '2px',
      paddingLeft: '8px',
      paddingRight: '8px',
      marginBottom: '7px',
      lineHeight: 'normal',
    },
  };

  return (
    <Box position="relative" id="hero-section">
      <Box
        position={[
          'relative',
          'relative',
          'relative',
          'absolute',
          'absolute',
          'absolute',
        ]}
        left={'0'}
        right={'0'}
        top={'0'}
        bottom={['0%', '0%', '0%', '43%', '43%', '43%']}
        bg="#2C021D"
        zIndex={'-1'}
      ></Box>
      <Box
        background={[
          '#2C021D',
          '#2C021D',
          '#2C021D',
          'transparent',
          'transparent',
          'transparent',
        ]}
        paddingTop={['95px', '75px', '65px', '56px', '56px', '56px']}
      >
        <Container
          width={['100%', '100%', '100%', '94%', '94%', '94%']}
          maxW={['100%', '100%', '100%', '94%', '94%', '94%']}
          marginBottom={['0px', '0px', 0, 0, 0, 0]}
          padding={['0', '0', '0', '0 20px', '0 20px']}
        >
          <BreadCrum />
          {/* This is a Case Study Banner Title And Tags of Services and Inustry */}
          <Box
            padding={[
              '0px 20px 80px',
              '0px 20px 80px',
              '0px 20px 80px',
              '66px 0 137px',
              '66px 0 137px',
              '66px 0 137px',
            ]}
          >
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['40px', '40px', '40px', '0', '0', '0']}
              justifyContent={'space-between'}
            >
              <Box
                w={['100%', '100%', '70%', '78%', '83%', '83%']}
                color="#FFFFFF"
                display="flex"
                flexDirection="column"
              >
                {/* Banner Title */}
                <Box>
                  <Box maxW={['100%', '100%', '100%', '100%', '90%', '90%']}>
                    <Text as="h1" sx={styles.title}>
                      {bannerText[0].title}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {/* Start Tags And Industry Services */}
              <Box w={['185px']}>
                <Box
                  marginBottom={[
                    '30px',
                    '30px',
                    '30px',
                    '34px',
                    '34px',
                    '34px',
                  ]}
                >
                  <Text sx={styles.industryText}>Industry</Text>
                  <Text sx={styles.tagText}>Fitness</Text>
                </Box>
                <Box>
                  <Text sx={styles.industryText}>Services Provided</Text>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Brand Strategy</Text>
                    <Text sx={styles.tagText}>Brand Identity</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Logo Design</Text>
                    <Text sx={styles.tagText}>UX Research</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>UX Research</Text>

                    <Text sx={styles.tagText}>UI Design</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Web Development</Text>
                  </Flex>
                </Box>
                <Flex gap={['6px']}>
                  <Text sx={styles.tagText}>Ecommerce Enablement</Text>
                </Flex>
              </Box>
              {/* End Tags And Industry Services */}
            </Flex>
          </Box>
          {/* Image Display Only For Mobile Not Desktop tHIS IS A Introduction Image */}
          <Box display={['block', 'block', 'block', 'none', 'none', 'none']}>
            <Image
              src="https://d3us4ynq1qsik6.cloudfront.net/work/pazazz/main.webp"
              width={['100%', '100%', '100%', '72%', '72%', '72%']}
              margin={['0 auto']}
            />
          </Box>
        </Container>
      </Box>
      <Box>
        <Introduction />
      </Box>
    </Box>
  );
};

export default heroSection;
