import { Box, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const OurProcess = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/white-labels?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: [
      '44.58px',
      '44.58px',
      '44.58px',
      '66.86px',
      '66.86px',
      '66.86px',
      '66.86px',
    ],
    pb: ['40px', '40px', '40px', '66px', '86px', '86px'],
    textAlign: ['center', 'center', 'center', 'left'],
  };
  return (
    <Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Section Heading */}
        <Box>
          <Text as={'h2'} sx={sectionH2Heading}>
            Our Process
          </Text>
        </Box>

        {/* Process */}
        <Box
          bg={'#0D0E4D'}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
        >
          <Image
            src={`https://cms.wearetenet.com${
              firstItem?.process_image_desktop?.data?.attributes?.url || ''
            }`}
            w={'100%'}
            h={'100%'}
            objectFit={'cover'}
          />
        </Box>
        <Box
          bg={'#0D0E4D'}
          display={['block', 'block', 'none', 'none', 'none', 'none']}
        >
          <Image
            src={`https://cms.wearetenet.com${
              firstItem?.process_image_mobile?.data?.attributes?.url || ''
            }`}
            w={'100%'}
            h={'100%'}
            objectFit={'cover'}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default OurProcess;
