import { Box, Container, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Culture = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-pages?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const sectionHeading = {
    color: '#000',
    fontSize: ['32px', '32px', '34px', '38px', '44px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: ['21px', '21px', '21px', '40px', '40px', '40px'],
  };
  const sectionParagraph = {
    color: '#333',
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    maxW: '872px',
    m: '0 auto',
  };

  // Section Styling
  const boxDesign = {};
  const boxWrapperGrid = {
    display: ['grid'],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      'repeat(2,1fr)',
      'repeat(2,1fr)',
      'repeat(3,1fr)',
      'repeat(3,1fr)',
    ],
    rowGap: ['40px'],
    columnGap: ['20px', '40px', '50px', '60px', '120px', '120px'],
  };
  const stratagyNumber = {
    color: '#00BA61',

    fontSize: ['48px', '48px', '48px', '60px', '60px', '60px'],
    fontWeight: '300',
    lineHeight: 'normal',
    fontFamily: ' var(--garnett-light);',
    display: 'inline-block',
    mb: '10px',
  };
  const stratagyTitle = {
    color: '#00BA61',
    fontFamily: 'var(--garnett-regular)',
    fontSize: ['32px', '32px', '34px', '38px', '44px', '48px'],
    fontWeight: '400',
    lineHeight: [
      '35.2px',
      '35.2px',
      '35.2px',
      '52.8px',
      '52.8px',
      '52.8px',
      '52.8px',
    ],
    display: 'inline',
  };
  const stratagyDescription = {
    fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
    color: '#333',
    fontFamily: 'var(--foundersGrotesk-regular);',
    fontWeight: '400',
    lineHeight: 'normal',
    mt: ['22px', '22px', '22px', '24px', '24px'],
  };
  return (
    <Box p={['80px 0', '80px 0', '80px 0', '150px 0', '150px 0', '150px 0']}>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/*  */}
        <Box
          maxW={'864px'}
          m={'0 auto'}
          textAlign={'center'}
          pb={['40px', '40px', '40px', '60px', '60px', '60px']}
        >
          <Text as={'h2'} sx={sectionHeading}>
            Our Culture
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            {firstItem?.culture_description}
          </Text>
        </Box>

        {/* Stratagy */}
        {/* SliderWrapper And Slides */}
        <Box sx={boxWrapperGrid}>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              01
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_1}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_1}
            </Text>
          </Box>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              02
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_2}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_2}
            </Text>
          </Box>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              03
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_3}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_3}
            </Text>
          </Box>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              04
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_4}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_4}
            </Text>
          </Box>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              05
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_5}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_5}
            </Text>
          </Box>
          <Box sx={boxDesign}>
            <Text as="h4" sx={stratagyNumber}>
              06
            </Text>
            <Box>
              <Text as={'span'} sx={stratagyTitle}>
                {firstItem?.culture_heading_6}
              </Text>
            </Box>
            <Text as={'p'} sx={stratagyDescription}>
              {firstItem?.culture_description_6}
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Culture;
