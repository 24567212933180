import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import downicon from '../../assets/Home/HomeNewTabbing/downicon.svg';
import upicon from '../../assets/Home/HomeNewTabbing/upicon.svg';
import rightActiveWhite from '../../assets/Home/HomeNewTabbing/rightWhiteActice.svg';

const HomeNewTabbing = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/home-navigatings?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // const tabsData = [
  //   {
  //     label: 'Brand Growth & Recognition',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image1,
  //   },
  //   {
  //     label: 'Digital Transformation',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image2,
  //   },
  //   {
  //     label: 'Mobile App Development',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image3,
  //   },
  //   {
  //     label: 'Ideation & Design Strategy',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image4,
  //   },
  //   {
  //     label: 'Data Science & Analytics',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image5,
  //   },
  //   {
  //     label: 'AI-ML Integration',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image6,
  //   },
  //   {
  //     label: 'Brand Visibility',
  //     title:
  //       'Is your brand getting lost in the noise? Struggling to enhance brand recall and recognition?',
  //     shortparagraph:
  //       'Our research-backed brand design solutions can boost your brand growth and help you stand out.',
  //     Image: image7,
  //   },
  // ];

  const tabsData = data.map(item => ({
    label: item.attributes.heading,
    title: item.attributes.description,
    shortparagraph: item.attributes.description_1,
    Image: item.attributes.image.data.attributes.url,
  }));

  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);

  const handleTabChange = index => {
    setActiveIndex(index);
    setHoverIndex(null); // Reset hover index after click
  };

  const handleAccordionClick = index => {
    if (index !== 0) {
      setActiveIndex(index);
    } else if (activeIndex !== index) {
      setActiveIndex(index);
    }
  };
  const [isLargerThan600] = useMediaQuery('(min-width: 992px)');

  return (
    <Box paddingTop={['80px', '80px', '80px', '150px']}>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '79%', '79%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        {/* Section Heading */}
        <Box textAlign={'center'} paddingBottom={['55px']}>
          <Heading
            as={'h2'}
            color={'#333'}
            fontSize={['32px', '32px', '32px', '72px', '72px', '72px']}
            fontFamily={'var(--garnett-regular);'}
            fontWeight={'400'}
            lineHeight={'normal'}
          >
            <Text
              as={'span'}
              fontFamily={'var(--garnett-semibold);'}
              fontWeight={'600'}
              color="#0057FF"
            >
              {' '}
              Navigating{' '}
            </Text>{' '}
            the Complexities of....
          </Heading>
        </Box>
        {/* Tabbing */}
        {/* For Desktop */}
        {isLargerThan600 ? (
          <Box
            display={'grid'}
            backgroundColor={'#f5f5f5'}
            gridTemplateColumns={['repeat(2,1fr)']}
            gap={4}
          >
            {/* Tab List */}
            <Box>
              <Tabs index={activeIndex} onChange={handleTabChange}>
                <TabList display={'block'} border={'0'}>
                  {tabsData.map((tab, index) => (
                    <Tab
                      key={index}
                      sx={{
                        display: 'flex!important',

                        justifyContent: 'space-between',
                        w: '100%',
                        color:
                          hoverIndex === index || activeIndex === index
                            ? '#B882FF'
                            : 'var(--Grey, #333)',
                        fontFamily: 'var(--garnett-regular);',
                        fontSize: '28px',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        letterSpacing: '-0.616px',
                        padding: '20px',
                        borderBottom:
                          index !== tabsData.length - 1
                            ? '1px solid #C1C1C1'
                            : 'none',
                        backgroundColor:
                          activeIndex === index ? '#30054A' : 'transparent',
                      }}
                      textAlign={'left'}
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      onClick={() => setActiveIndex(index)} // Activating the tab on click
                    >
                      {tab.label}
                      <Box
                        as="span"
                        // transform={
                        //   activeIndex === index
                        //     ? 'rotate(180deg)'
                        //     : 'rotate(0deg)'
                        // }
                        transition="transform 0.2s"
                      >
                        {activeIndex === index && (
                          <Image src={rightActiveWhite} alt="Open Icon" />
                        )}
                      </Box>
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </Box>
            {/* Tab Content */}
            <Box padding={['24px 17px 27px']}>
              <Tabs index={hoverIndex !== null ? hoverIndex : activeIndex}>
                <TabPanels>
                  {tabsData.map((tab, index) => (
                    <TabPanel key={index} padding={'0'}>
                      <Box>
                        {/* Title And Short Paragraph */}
                        <Box>
                          <Heading
                            as={'h3'}
                            fontSize={['20px', '20px', '20px', '20px']}
                            letterSpacing={['-0.44px', '-0.44px']}
                            lineHeight={'normal'}
                            fontWeight={'400'}
                            color={'#7610B5'}
                            fontFamily={'var(--garnett-regular);'}
                            padding={['0px 0px 32px']}
                          >
                            {tab.title}
                          </Heading>
                        </Box>
                        <Box>
                          <Image
                            src={`https://cms.wearetenet.com${tab.Image || ''}`}
                            loading="lazy"
                            fetchpriority="low"
                          />
                        </Box>
                      </Box>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        ) : (
          /* Accordion For Mobile */
          <Box backgroundColor={'#f5f5f5'}>
            <Accordion
              allowToggle={false}
              defaultIndex={[0]}
              allowMultiple={false}
            >
              {tabsData.map((item, index) => (
                <AccordionItem key={index} border="none">
                  <AccordionButton
                    onClick={() => handleAccordionClick(index)} // Active tab ko set karne ke liye
                    sx={{
                      display: 'flex!important',
                      alignItems: 'center!important',
                      w: '100%',
                      color:
                        hoverIndex === index || activeIndex === index
                          ? '#B882FF'
                          : '#333',
                      fontFamily: 'var(--garnett-regular);',
                      fontSize: '20px',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      letterSpacing: '-0.616px',
                      padding: ['15px', '15px', '15px', '20px', '20px'],
                      borderBottom:
                        index !== tabsData.length - 1
                          ? '1px solid #C1C1C1'
                          : 'none',
                      backgroundColor:
                        activeIndex === index ? '#30054A' : 'transparent',
                    }}
                    _hover={{
                      backgroundColor:
                        activeIndex === index ? '#30054A' : 'transparent', // Agar active hai to background color ko change na karein
                      color: '#B882FF',
                    }}
                  >
                    <Box flex="1" textAlign="left">
                      {item.label}
                    </Box>
                    {/* Custom Accordion Icon */}
                    <Box
                      as="span"
                      // transform={
                      //   activeIndex === index
                      //     ? 'rotate(0deg)'
                      //     : 'rotate(180deg)'
                      // }
                      transition="transform 0.2s"
                    >
                      {activeIndex === index ? (
                        <Image src={upicon} alt="Open Icon" />
                      ) : (
                        <Image src={downicon} alt="Close Icon" />
                      )}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel
                    padding={[
                      '12px 0px 27px',
                      '12px 0px 27px',
                      '12px 0px 27px',
                      '24px 60px 27px',
                      '24px 60px 27px',
                      '24px 60px 27px',
                    ]}
                    pb={4}
                  >
                    <Box>
                      {/* Title And Short Paragraph */}
                      <Box>
                        <Heading
                          as={'h3'}
                          fontSize={['16px', '16px', '20px', '20px']}
                          letterSpacing={['-0.352px', '-0.352px']}
                          lineHeight={'normal'}
                          fontWeight={'400'}
                          color={'#7610B5'}
                          fontFamily={'var(--garnett-regular);'}
                          padding={['0px 16px 32px']}
                        >
                          {item.title}
                        </Heading>
                      </Box>
                      <Box>
                        <Image
                          src={`https://cms.wearetenet.com${item.Image || ''}`}
                        />
                      </Box>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default HomeNewTabbing;
