import { Box, Button, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const MeetOurPartners = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Set initial items to show based on screen width
  const [itemsToShow, setItemsToShow] = useState(
    window.innerWidth <= 768 ? 3 : 6
  );

  // Set a flag for mobile view based on screen width
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/our-partners?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data); // Set data array directly
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Update the mobile state and items to show based on window width
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
      setItemsToShow(isMobileView ? 3 : 6); // Adjust items to show based on screen width
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [slug]);

  // Handle load more button click to show 3 more items
  const handleLoadMore = () => {
    setItemsToShow(prev => prev + 3);
  };

  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    pb: '30px',
  };
  const sectionParagraph = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '864px',
    m: '0 auto',
  };
  const partnerTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    p: '20px 0 18px',
  };
  const partnerText = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
  };
  const partnersImage = {
    objectFit: 'cover',
    maxW: ['186px', '186px', '100%', '100%'],
    h: ['auto'],
  };

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>{error}</Text>;

  return (
    <Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Section Heading */}
        <Box textAlign={'center'}>
          <Text as={'h2'} sx={sectionH2Heading}>
            Meet our partners
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            Tenet works wonders with the tools that your teams already use. From
            finance and marketing automation to productivity and video
            conferencing, start seamlessly streamlining your projects all in one
            place.
          </Text>
        </Box>
        {/* Meet Partners Grid */}
        <Box
          p={[
            '40px 0 80px',
            '40px 0 80px',
            '40px 0 80px',
            '60px 0 80px',
            '60px 0 80px',
            '60px 0 80px',
          ]}
        >
          <Box
            display={'grid'}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(3,1fr)',
              'repeat(3,1fr)',
              'repeat(3,1fr)',
            ]}
            columnGap={'20px'}
            rowGap={'31px'}
          >
            {data.slice(0, itemsToShow).map((partner, index) => (
              <Box
                key={index}
                bg={'#F5F5F5'}
                p={['14px', '14px', '14px', '24px', '24px', '24px']}
              >
                {/* Partners Name And Logo */}
                <Box
                  bg={'#fff'}
                  p={[
                    '26px 31px',
                    '26px 31px',
                    '26px 31px',
                    '38px 22px',
                    '38px 22px',
                    '38px 22px',
                  ]}
                >
                  <Image
                    src={`https://cms.wearetenet.com${
                      partner.attributes.icon.data.attributes.url || ''
                    }`}
                    sx={partnersImage}
                    alt={partner.attributes.name}
                  />
                </Box>
                {/* About Partners */}
                <Box pb={['19px', '19px', '19px', '80px', '80px', '80px']}>
                  <Text as={'h3'} sx={partnerTitle}>
                    {partner.attributes.heading}
                  </Text>
                  <Text as={'p'} sx={partnerText}>
                    {partner.attributes.description}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Load More Button */}
          {itemsToShow < data.length && (
            <Box
              textAlign={'center'}
              paddingTop={['59px', '59px', '59px', '75px']}
            >
              <Button
                fontSize={['20px']}
                lineHeight={['24px']}
                color={'#333'}
                fontWeight={'400'}
                borderRadius={'0'}
                border={'1px solid #B5B5B5'}
                w={['153px']}
                height={'39px'}
                background={'#fff'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                _hover={{ backgroundColor: '#fff' }}
                onClick={handleLoadMore} // Load more functionality added
                paddingTop={['5px']}
              >
                Load more
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default MeetOurPartners;
