import { Box } from '@chakra-ui/react';
import React from 'react';
import ClientLogoAbout from '../../Home/ClientsLogo';
import ExploreOtherWork from '../Service/ExploreOtherWorkUse';
import ServiceBannerSingle from '../SingleService/SingleBanner';
import Accordian from './Accordian';
import Ctc from './Ctc';
import LetsWorkTogether from './LetsWorksToGether';
import OurProces from './OurProces';
import Solution from './Solution';
import TextWithImgs from './TextWithImg';
import Workshop from './workshop';

const SingleService = () => {
  return (
    <Box>
      <Box>
        <ServiceBannerSingle />
      </Box>
      <Box>
        <Solution />
      </Box>
      <Box>
        <TextWithImgs />
      </Box>
      <Box>
        <OurProces />
      </Box>
      <Box>
        <Accordian />
      </Box>
      <Box>
        <Ctc />
      </Box>
      <Box>
        <Workshop />
      </Box>
      <Box>
        <ClientLogoAbout />
      </Box>
      <Box>
        <ExploreOtherWork />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default SingleService;
