import {
  Box,
  Container,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

const tabsData = [
  { name: 'All', filter: '' },
  { name: 'Web', filter: 'web' },
  { name: 'Mobile', filter: 'mobile' },
  { name: 'Virtual Reality', filter: 'virtual%20reality' },
  { name: 'Data Bases', filter: 'databases' },
  { name: 'Cloud', filter: 'cloud' },
  { name: 'Design', filter: 'design' },
  { name: 'Testing', filter: 'testing' },
];

const ToolsTabbing = () => {
  const [dataCache, setDataCache] = useState({}); // Cache for data per tab
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('All');
  const [isSticky, setIsSticky] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [tabListHeight, setTabListHeight] = useState(0); // To store dynamic tab list height
  const sectionRef = useRef(null);

  const mobileHeaderClass = 'css-1toztb7'; // Replace with your mobile header class
  const desktopHeaderClass = 'css-n0q6yq'; // Replace with your desktop header class
  const tabListRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const filter = tabsData.find(tab => tab.name === selectedTab)?.filter || '';
        if (dataCache[selectedTab]) {
          setLoading(false); // If data is already cached, no need to refetch
          return;
        }

        const url = filter
          ? `https://cms.wearetenet.com/api/technologies?pagination[pageSize]=100&populate=*&filters[$and][0][type][$eq]=${filter}`
          : `https://cms.wearetenet.com/api/technologies?pagination[pageSize]=100&populate=*`;

        const response = await axios.get(url);
        setDataCache(prevCache => ({
          ...prevCache,
          [selectedTab]: response.data.data, // Cache the data for this tab
        }));
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedTab, dataCache]);

  useEffect(() => {
    const handleScroll = () => {
      const sectionElement = sectionRef.current;
      const headerElement = document.querySelector(
        `.${window.innerWidth < 768 ? mobileHeaderClass : desktopHeaderClass}`
      );
      const tabListElement = tabListRef.current;

      if (sectionElement && headerElement && tabListElement) {
        const sectionTop = sectionElement.getBoundingClientRect().top;
        const sectionHeight = sectionElement.getBoundingClientRect().height;
        const headerHeight = headerElement.offsetHeight;
        const currentTabListHeight = tabListElement.offsetHeight; // Get dynamic height

        setTabListHeight(currentTabListHeight); // Set the tab list height to state

        // Check if the section is in the viewport and hasn't reached 90% of its height
        if (
          sectionTop <= headerHeight &&
          sectionTop + sectionHeight * 0.9 > headerHeight
        ) {
          setIsSticky(true);
          setIsEnd(false);
        } else if (sectionTop + sectionHeight * 0.9 <= headerHeight) {
          setIsEnd(true); // If 90% of the section is scrolled, stop the sticky behavior
          setIsSticky(false);
        } else {
          setIsSticky(false);
          setIsEnd(false);
        }
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call on initial render

    return () => window.removeEventListener('scroll', handleScroll);
  }, [mobileHeaderClass, desktopHeaderClass]);

  const handleTabChange = index => {
    setSelectedTab(tabsData[index].name);
    const sectionElement = sectionRef.current;
    const tabListElement = tabListRef.current;
    if (sectionElement && tabListElement) {
      // Scroll to the section and add an offset to avoid overlap
      window.scrollTo({
        top: sectionElement.offsetTop - tabListElement.offsetHeight - -5,
        behavior: 'smooth',
      });
    }
  };

  const TabsBoxDesign = {
    borderBottom: '1px solid #D9D9D9',
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.08)',
    position: isSticky && !isEnd ? 'fixed' : 'relative',
    top:
      isSticky && !isEnd
        ? `${document.querySelector(
          `.${window.innerWidth < 768 ? mobileHeaderClass : desktopHeaderClass
          }`
        )?.offsetHeight
        }px`
        : 'unset',
    width: '100%',
    zIndex: 999,
    transition: 'top 0.3s ease-in-out',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingBottom: isSticky && !isEnd ? '0px' : '0', // Add extra space when sticky
  };

  const tabsDesign = {
    fontSize: ['16px', '16px', '16px', '16px', '16px', '16px'],
    fontWeight: '400',
    letterSpacing: '-0.704px',
    fontFamily: 'garnett-regular',
    p: '25px 20px 20px 20px',
  };

  const TabTitle = {
    fontSize: ['16px', '16px', '16px', '48px', '48px', '48px'],
    fontWeight: '400',
    lineHeight: 'normal',
    fontFamily: 'garnett-regular',
  };

  const iconTitle = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '-0.396px',
    pt: '19px',
  };

  return (
    <>
      <Box ref={sectionRef}>
        <Tabs onChange={handleTabChange}>
          <TabList
            ref={tabListRef}
            justifyContent={['start', 'start', 'center', 'center', 'center']}
            sx={TabsBoxDesign}
          >
            {tabsData.map(tab => (
              <Tab
                key={tab.name}
                sx={{
                  ...tabsDesign,
                  _selected: {
                    bg: 'rgba(0, 186, 97, 0.10)',
                    color: '#00763D',
                    borderBottom: '5px solid #00763D',
                  },
                }}
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>
          <Container
            maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
            m={'0 auto'}
          >
            <TabPanels>
              {tabsData.map(tab => (
                <TabPanel
                  key={tab.name}
                  p={
                    isSticky
                      ? `${90 + tabListHeight}px 0 150px`
                      : '90px 0 150px'
                  } // Add extra padding when sticky, else use normal padding
                >
                  <Box pb={'35px'}>
                    <Text as={'h3'} sx={TabTitle}>
                      {tab.name} Tools
                    </Text>
                  </Box>
                  {loading && !dataCache[selectedTab] ? (
                    <Spinner />
                  ) : error ? (
                    <Text>{error}</Text>
                  ) : (
                    <Box
                      display={'grid'}
                      gridTemplateColumns={[
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                        'repeat(4, 1fr)',
                        'repeat(6, 1fr)',
                      ]}
                      columnGap={'16px'}
                      rowGap={'60px'}
                    >
                      {dataCache[selectedTab]?.map(tool => (
                        <Box key={tool?.id}>
                          <Image
                            src={`https://cms.wearetenet.com${
                              tool?.attributes?.icon?.data?.attributes?.url ||
                              ''
                            }`}
                            width={['100%']}
                            height={['auto']}
                          />
                          <Text sx={iconTitle}>
                            {tool?.attributes?.name || 'No Name'}
                          </Text>
                        </Box>
                      ))}

                    </Box>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Container>
        </Tabs>
      </Box>
    </>
  );
};

export default ToolsTabbing;
