import {
  Box,
  Heading,
  Text,
  Image,
  Flex,
  Container,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import LeftCaret from '../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../assets/Icons/Slider/rightIcon.svg';
import logo1 from '../../assets/Reusable/ClientSAySlider/logo/1.svg';
import logo2 from '../../assets/Reusable/ClientSAySlider/logo/2.svg';
import logo3 from '../../assets/Reusable/ClientSAySlider/logo/3.svg';
import commaGreen from '../../assets/Reusable/ClientSAySlider/comaSvg/green.svg';
import commaOrrange from '../../assets/Reusable/ClientSAySlider/comaSvg/orrange.svg';
import commaPurple from '../../assets/Reusable/ClientSAySlider/comaSvg/purple.svg';

import peopleIcon1 from '../../assets/Reusable/ClientSAySlider/peopleIcon/1.svg';
import peopleIcon2 from '../../assets/Reusable/ClientSAySlider/peopleIcon/2.svg';
import peopleIcon3 from '../../assets/Reusable/ClientSAySlider/peopleIcon/3.svg';

const ClientSaysSlider = () => {
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const slidesPerViewValue = isLargerThan1024 ? 3 : 3;

  // Determine maxWidth based on screen size
  let maxWidth;
  if (isLargerThan1280) {
    maxWidth = '382px';
  } else if (isLargerThan1024) {
    maxWidth = '350px';
  } else {
    maxWidth = '279px';
  }
  const goNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const goPrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1);
  };

  const secHeadingStyles = {
    color: '#000',
    fontFamily: 'var(--garnett-regular)',
    fontSize: ['32px', '32px', '32px', '36px', '36px', '36px'],
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };

  const swiperStyles = {
    padding: [
      '20px 0 0',
      '20px 0 0 ',
      '20px 0 0',
      '47px 0 0',
      '47px 0 0',
      '47px 0 0',
    ],
  };

  const data = [
    {
      logo: logo1,
      comma: commaPurple,
      clientName: 'Danish Rahman',
      clientDesignation: 'Marketing Officer, M42',
      peopleIcon: peopleIcon1,
      description:
        'Tenet has been our go-to partner for almost everything digital. From brand strategy & UX research to development, marketing, & much more. We’ve had a wonderful experience with the team.',
    },
    {
      logo: logo2,
      comma: commaOrrange,
      clientName: 'Shruti Joshi',
      clientDesignation: 'Senior Marketing Manager, Gartner',
      peopleIcon: peopleIcon2,
      description:
        "We have many agencies in our partner network, but Tenet holds a special place for us. They've been with us for years, and they really know how to service well and deliver results that we're looking for.",
    },
    {
      logo: logo3,
      comma: commaGreen,
      clientName: "Derek O'Brien",
      clientDesignation:
        'Member of Parliament, Indian Govt.Ex Creative Head, Ogilvy',
      peopleIcon: peopleIcon3,
      description:
        "I've headed Oligvy and I've worked with many renowned agencies, but I am always eager to work with young and impactful agencies. Tenet (formerly KodeGlobe) is the right choice for us.",
    },
  ];

  return (
    <Box
      padding={[
        '40px 0 55px',
        '40px 0 55px',
        '40px 0 55px',
        '97px 0 102px',
        '97px 0 102px',
        '97px 0 102px',
      ]}
      background="#F5F5F5"
      overflow={'hidden'}
    >
      {/* Section Heading */}
      <Box
        maxW={['100%', '100%', '100%', '91.5%', '91.5%', '91.5%']}
        marginLeft="auto"
        paddingBottom={['55px', '55px', '55px', '43px']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Heading
          as="h2"
          sx={secHeadingStyles}
          maxW={['364px', '364px', '500px', '100%']}
          margin="0 auto"
          textAlign={['center', 'center', 'center', 'left']}
        >
          What our clients have to say about us
        </Heading>
      </Box>

      {/* Slider Navigation */}
      <Container
        maxWidth={['100%', '100%', '100%', '86%', '86%', '86%']}
        m="0 auto"
        display={['block', 'block', 'block', 'none', 'none', 'none']}
        padding="0"
      >
        <Flex
          justifyContent="space-between"
          paddingTop="20px"
          width="90%"
          display={['flex', 'flex', 'flex', 'none', 'none', 'none']}
          margin="0 auto"
        >
          <Text
            fontSize="14px"
            color="#333"
            fontFamily="var(--garnett-regular)"
            visibility={'hidden'}
          >
            {`${currentSlide}/${
              swiperRef.current?.swiper?.slides?.length ?? 4
            }`}
          </Text>
          <Box display="flex">
            <Box onClick={goPrev} mr="20px">
              <Image src={LeftCaret} cursor="pointer" />
            </Box>
            <Box onClick={goNext}>
              <Image src={RightCaret} cursor="pointer" />
            </Box>
          </Box>
        </Flex>
      </Container>
      {/* Slider Container */}
      <Box
        maxWidth={['100%', '100%', '100%', '86%', '86%', '86%']}
        margin={'0 auto'}
        paddingBottom={['55px', '55px', '55px', '43px']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px', '0']}
      >
        {!isLargerThan1024 ? (
          <Box sx={swiperStyles}>
            {/* Swiper Slider */}
            <Swiper
              ref={swiperRef}
              slidesPerView={'auto'}
              spaceBetween={'20px'}
              onSlideChange={handleSlideChange}
              className="clientSaysSlider"
              autoHeight={'100%!important'}
              style={{ overflow: 'visible' }}
            >
              {data.map((item, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    backgroundColor:
                      item.comma === commaPurple
                        ? '#EBDBFF'
                        : item.comma === commaGreen
                        ? '#DEF6EB'
                        : item.comma === commaOrrange
                        ? '#FFE1D8'
                        : '#FFFFFF', // Default color
                    maxWidth: maxWidth, // Add a fallback value; breakpoints can't be used directly in style

                    padding: '25px 25px 37px 25px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                    className: 'ClienTSaySlide',
                  }}
                >
                  <Box>
                    {/* Logo And Comma */}
                    <Box
                      display={['flex']}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      minH={['61px']}
                    >
                      <Box>
                        <Image src={item.comma} alt="Comma" />
                      </Box>
                      <Box>
                        <Image src={item.logo} alt="Logo" />
                      </Box>
                    </Box>

                    {/* Display Description */}
                    <Box
                      paddingTop={['36px']}
                      color={
                        item.comma === commaPurple
                          ? '#30054A'
                          : item.comma === commaGreen
                          ? '#00342B'
                          : item.comma === commaOrrange
                          ? '#860038'
                          : '#30054A' // Default color if none of the conditions match
                      }
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      fontSize={[
                        '16px',
                        '18px',
                        '18px',
                        '18px',
                        '24px',
                        '24px',
                      ]}
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      <Text as="p">{item.description}</Text>
                    </Box>
                  </Box>

                  {/* Client Name And Designation */}
                  <Box paddingTop={['20px']} maxW={'80%'}>
                    <Heading
                      as="h4"
                      color={
                        item.comma === commaPurple
                          ? '#7610B5'
                          : item.comma === commaGreen
                          ? '#00342B'
                          : item.comma === commaOrrange
                          ? '#860038'
                          : '#30054A' // Default color if none of the conditions match
                      }
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      fontSize={[
                        '16px',
                        '16px',
                        '16px',
                        '24px',
                        '24px',
                        '24px',
                      ]}
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                      paddingBottom={['2px']}
                    >
                      {item.clientName}
                    </Heading>
                    <Text
                      as="p"
                      color={
                        item.comma === commaPurple
                          ? '#7610B5'
                          : item.comma === commaGreen
                          ? '#00342B'
                          : item.comma === commaOrrange
                          ? '#860038'
                          : '#30054A' // Default color if none of the conditions match
                      }
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      fontSize={[
                        '14px',
                        '14px',
                        '14px',
                        '16px',
                        '16px',
                        '16px',
                      ]}
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                      maxW={['223px']}
                    >
                      {item.clientDesignation}
                    </Text>
                    <Box
                      position={'absolute'}
                      height={['auto']}
                      w={['82px', '82px', '82px', '111px', '111px', '111px']}
                      bottom={
                        index === 0
                          ? '-22px'
                          : index === 1
                          ? '-22px'
                          : index === 2
                          ? '-22px'
                          : '0' // Default value if none of the conditions match
                      }
                      right={[
                        index === 0
                          ? '-14px'
                          : index === 1
                          ? '-14px'
                          : index === 2
                          ? '-14px'
                          : '0',
                        index === 0
                          ? '-14px'
                          : index === 1
                          ? '-14px'
                          : index === 2
                          ? '-14px'
                          : '0',
                        index === 0
                          ? '-14px'
                          : index === 1
                          ? '-14px'
                          : index === 2
                          ? '-14px'
                          : '0',
                      ]}
                    >
                      <Image
                        src={item.peopleIcon}
                        w={'100%'}
                        height={'100%'}
                        objectFit={'cover'}
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        ) : (
          <Box
            sx={swiperStyles}
            display={'flex'}
            gap={['30px', '30px', '30px', '45px', '65px', '65px']}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                style={{
                  backgroundColor:
                    item.comma === commaPurple
                      ? '#EBDBFF'
                      : item.comma === commaGreen
                      ? '#DEF6EB'
                      : item.comma === commaOrrange
                      ? '#FFE1D8'
                      : '#FFFFFF', // Default color
                  width: '33.33%',
                  maxWidth: maxWidth,
                  padding: '25px 25px 37px 25px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  position: 'relative',
                  className: 'ClienTSaySlide',
                }}
              >
                <Box>
                  {/* Logo And Comma */}
                  <Box
                    display={['flex']}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    minH={['61px']}
                  >
                    <Box>
                      <Image src={item.comma} alt="Comma" />
                    </Box>
                    <Box>
                      <Image src={item.logo} alt="Logo" />
                    </Box>
                  </Box>

                  {/* Display Description */}
                  <Box
                    paddingTop={['36px']}
                    color={
                      item.comma === commaPurple
                        ? '#30054A'
                        : item.comma === commaGreen
                        ? '#00342B'
                        : item.comma === commaOrrange
                        ? '#860038'
                        : '#30054A' // Default color if none of the conditions match
                    }
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    fontSize={['16px', '18px', '18px', '18px', '24px', '24px']}
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                  >
                    <Text as="p">{item.description}</Text>
                  </Box>
                </Box>

                {/* Client Name And Designation */}
                <Box paddingTop={['20px']} maxW={'80%'}>
                  <Heading
                    as="h4"
                    color={
                      item.comma === commaPurple
                        ? '#7610B5'
                        : item.comma === commaGreen
                        ? '#00342B'
                        : item.comma === commaOrrange
                        ? '#860038'
                        : '#30054A' // Default color if none of the conditions match
                    }
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    fontSize={['16px', '16px', '16px', '24px', '24px', '24px']}
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                    paddingBottom={['2px']}
                  >
                    {item.clientName}
                  </Heading>
                  <Text
                    as="p"
                    color={
                      item.comma === commaPurple
                        ? '#7610B5'
                        : item.comma === commaGreen
                        ? '#00342B'
                        : item.comma === commaOrrange
                        ? '#860038'
                        : '#30054A' // Default color if none of the conditions match
                    }
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    fontSize={['14px', '14px', '14px', '16px', '16px', '16px']}
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="normal"
                    maxW={['223px']}
                  >
                    {item.clientDesignation}
                  </Text>
                  <Box
                    position={'absolute'}
                    height={['auto']}
                    w={['82px', '82px', '82px', '111px', '111px', '111px']}
                    bottom={
                      index === 0
                        ? '-22px'
                        : index === 1
                        ? '-22px'
                        : index === 2
                        ? '-22px'
                        : '0' // Default value if none of the conditions match
                    }
                    right={[
                      index === 0
                        ? '-14px'
                        : index === 1
                        ? '-14px'
                        : index === 2
                        ? '-14px'
                        : '0',
                      index === 0
                        ? '-14px'
                        : index === 1
                        ? '-14px'
                        : index === 2
                        ? '-14px'
                        : '0',
                      index === 0
                        ? '-14px'
                        : index === 1
                        ? '-14px'
                        : index === 2
                        ? '-14px'
                        : '0',
                    ]}
                  >
                    <Image
                      src={item.peopleIcon}
                      w={'100%'}
                      height={'100%'}
                      objectFit={'cover'}
                      alt=""
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ClientSaysSlider;
