import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const GreatUxPoints = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=research&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;

  const points = [];

  if (firstItem) {
    for (let i = 1; i <= 4; i++) {
      points.push({
        number: i,
        title: firstItem[`point_${i}_heading`],
        description: firstItem[`point_${i}_description`],
      });
    }
  }

  const numberStyle = {
    fontSize: ['25px', '25px', '25px', '25px', '28px', '28px'],
    width: ['45px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridTitle = {
    fontSize: ['18px', '18px', '18px', '20px', '28px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    paddingBottom: ['15px'],
    color: '#333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridParagraph = {
    fontSize: ['14px', '14px', '14px', '16px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['36px', '36px', '36px', '36px', '36px', '36px'],
  };

  const uxPoints = [
    {
      number: '01',
      title: 'Usability',
      description:
        'Ensures that users can navigate and interact with your product effortlessly, enhancing satisfaction and retention.Ensures that users can navigate and interact with your product effortlessly, enhancing satisfaction and retention.',
    },
    {
      number: '02',
      title: 'Aesthetics',
      description:
        "A visually appealing design captures attention and creates an emotional connection with users, reflecting your brand's personality.A visually appealing design captures attention and creates an emotional connection with users, reflecting your brand's personality.",
    },
    {
      number: '03',
      title: 'User-Centered Strategies',
      description:
        'Focus on understanding and addressing user needs and pain points, ensuring that your product resonates with its target audience.Focus on understanding and addressing user needs and pain points, ensuring that your product resonates with its target audience.',
    },
    {
      number: '04',
      title: 'Accessibility',
      description:
        'Design that is inclusive and usable by people with varying abilities and disabilities, ensuring equal access and opportunity for all users.Design that is inclusive and usable by people with varying abilities and disabilities, ensuring equal access and opportunity for all users.',
    },
    // Add more points as needed
  ];

  return (
    <Box
      padding={[
        '80px 0 62px',
        '80px 0 62px',
        '80px 0 62px',
        '150px 0 80px',
        '150px 0 80px',
      ]}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px']}
      >
        {/* Section Heading */}
        <Box maxW={['730px']} paddingBottom={['56px']}>
          <Heading
            as={'h2'}
            color={['#333']}
            fontSize={['32px', '32px', '35px', '48px', '48px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular);'}
            maxW={['616px']}
            marginBottom={['32px']}
          >
            {/* What Makes a{' '}
            <span
              style={{
                color: '#B882FF',
                fontFamily: 'var(--garnett-semibold);',
              }}
            >
              Great User Experience
            </span>{' '}
            Design? */}
            {firstItem?.second_heading}

          </Heading>
          <Text
            as={'p'}
            fontSize={['20px']}
            fontWeight={'400'}
            color={'#333'}
            lineHeight={'normal'}
            fontFamily={'var(--foundersGrotesk-regular);'}
          >
            {firstItem?.second_paragraph}

          </Text>
        </Box>
        {/* Great UX Points */}
        <Box
          display={['grid']}
          gridTemplateColumns={[
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(2,1fr)',
            'repeat(2,1fr)',
            'repeat(2,1fr)',
          ]}
          columnGap={['46px']}
          rowGap={['24px', '24px', '24px', '32px', '32px', '32px']}
        >
          {points.map((point, index) => (
            <Box key={index} borderBottom={'1px solid #B5B5B5'}>
              <Flex
                direction={['row']}
                gap={['43px', '55px']}
                alignItems={['flex-start']}
                maxW={['538px']}
              >
                <Text as="h1" sx={numberStyle}>
                  0{point.number}
                </Text>
                <Flex
                  width={['100%']}
                  maxW={['100%']}
                  direction={['column']}
                  gap={['0px', '10px']}
                  alignItems={['flex-start']}
                  margin={['0 auto']}
                >
                  <Text as="h1" sx={gridTitle}>
                    {point.title}
                  </Text>
                  <Text as="p" sx={gridParagraph}>
                    {point.description}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          ))}

        </Box>
      </Box>
    </Box>
  );
};

export default GreatUxPoints;
