import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Typewriter from 'typewriter-effect';
import DesktopBg from '../../../assets/BgPatterns/Strategy/strategyDesktop.gif';
import MobileBg from '../../../assets/BgPatterns/Strategy/strategyMob.gif';
import Youtube from './YouTubePlayer';
import Youtube2 from './YouTubePlayer2';

import BreadCrum from './BreadCrum';

const HeroSection = () => {
  const heroSectionRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(
    window.innerWidth >= 992 ? DesktopBg : MobileBg
  );

  return (
    <>
      <Box position={['relative']} ref={heroSectionRef} id="hero-section">
        {/* Show Onliy For Tab  */}
        <Box
          backgroundImage={`url(${backgroundImage})`}
          backgroundRepeat={['no-repeat']}
          backgroundSize={['cover']}
          position={'absolute'}
          top={'0'}
          left={0}
          right={'0'}
          bottom={['0', '0px', '0px', '-360px', '-360px', '-360px']}
          zIndex={'-1'}
        ></Box>
        <Box
          p={[
            '23px 0',
            '23px 0',
            '33px 0',
            '45px 0 53px',
            '50px 0 53px',
            '56px 0 53px',
          ]}
          paddingBottom={['93px', '93px', '93px', 0, 0, 0]}
        >
          {/* BreadCrum */}
          <BreadCrum />

          {/* Hero Section */}
          <Container
            width={['100%', '100%', '100%', '94%', '90%', '85%']}
            maxW={['100%', '100%', '100%', '94%', '90%', '85%']}
            marginBottom={['50px', '50px', 0, 0, 0, 0]}
            alignItems={['baseline']}
          >
            {/* Banner Texts And TypeRighter */}
            <Flex
              direction={[
                'column-reverse',
                'column-reverse',
                'column-reverse',
                'row',
                'row',
                'row',
              ]}
              gap={['55px', '55px', '55px', '0', '0', '0']}
              paddingTop={['26px', '30px', '40px', '46px', '56px', '66px']}
              marginBottom={['40px', '40px', '40px', '80px', '80px', '80px']}
            >
              <Box
                color="#FFFFFF"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box>
                  <Box>
                    <Text
                      as="h1"
                      fontSize={[
                        '36px',
                        '36px',
                        '46px',
                        '100px',
                        '128px',
                        '128px',
                      ]}
                      m="0 0 23px 0"
                      fontFamily="var(--garnett-semibold)"
                      color={['#ffffff']}
                      fontWeight={['400']}
                      lineHeight={'normal'}
                      display={[
                        'none',
                        'none',
                        'none',
                        'block',
                        'block',
                        'block',
                      ]}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#E0005E',
                            fontFamily: 'var(--garnett-semibold)',
                            marginLeft: '5px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typewriter
                            options={{
                              strings: [
                                'A Process',
                                'An Action',
                                'An Outcome',
                                'A Solution',
                              ],
                              autoStart: true,
                              loop: true,
                              pauseFor: 400,
                              cursor: '',
                              delay: 50,
                            }}
                          />

                          <svg
                            width="18"
                            height="123"
                            viewBox="0 0 18 123"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            display={[
                              'none',
                              'none',
                              'none',
                              'block',
                              'block',
                              'block',
                            ]}
                          >
                            <path
                              d="M10.5 15L17.6603 3.78552e-07L0.339746 -3.78552e-07L7.5 15L10.5 15ZM7.5 108L0.33974 123L17.6602 123L10.5 108L7.5 108ZM7.5 13.5L7.5 109.5L10.5 109.5L10.5 13.5L7.5 13.5Z"
                              fill="#E0005E"
                            />
                          </svg>
                        </span>
                        &nbsp;with
                      </span>
                      you in mind.
                    </Text>
                    <Text
                      as="h1"
                      fontSize={[
                        '40px',
                        '50px',
                        '60px',
                        '80px',
                        '100px',
                        '118px',
                      ]}
                      m="0 0 20px 0"
                      fontFamily="var(--garnett-semibold)"
                      color={['#ffffff']}
                      fontWeight={['400']}
                      lineHeight={'normal'}
                      display={[
                        'block',
                        'block',
                        'block',
                        'none',
                        'none',
                        'none',
                      ]}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#E0005E',
                            fontFamily: 'var(--garnett-semibold)',
                            marginLeft: '5px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typewriter
                            options={{
                              strings: [
                                'A Process',
                                'An Action',
                                'An Outcome',
                                'A Solution',
                              ],
                              autoStart: true,
                              loop: true,
                              pauseFor: 1500,
                              cursor: '',
                            }}
                          />
                          <svg
                            width="18"
                            height="43"
                            viewBox="0 0 18 123"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            display={[
                              'none',
                              'none',
                              'none',
                              'block',
                              'block',
                              'block',
                            ]}
                          >
                            <path
                              d="M10.5 15L17.6603 3.78552e-07L0.339746 -3.78552e-07L7.5 15L10.5 15ZM7.5 108L0.33974 123L17.6602 123L10.5 108L7.5 108ZM7.5 13.5L7.5 109.5L10.5 109.5L10.5 13.5L7.5 13.5Z"
                              fill="#E0005E"
                            />
                          </svg>
                        </span>
                        &nbsp;
                      </span>
                      with you in mind.
                    </Text>
                    <Text
                      paddingTop={['0px', '0px', '0px', '15px', '15px', '15px']}
                      maxW={[
                        '353px',
                        '353px',
                        '453px',
                        '698px',
                        '698px',
                        '698px',
                      ]}
                      fontSize={[
                        '16px',
                        '16px',
                        '16px',
                        '18px',
                        '20px',
                        '23px',
                      ]}
                      as="p"
                      fontFamily="var(--foundersGrotesk-light)"
                      fontWeight={'400'}
                      lineHeight={'normal'}
                    >
                      We get the challenges of growing a business. With proven
                      strategies, industry know-how, and years of digital
                      transformation experience, we're the go-to choice for
                      partners seeking success.
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Flex>
            {/* Banner Video */}
            <Flex
              justifyContent="center"
              display={['block', 'block', 'block', 'none', 'none', 'none']}
            >
              <Box
                margin="0 auto"
                maxWidth="100%"
                position="relative"
                paddingBottom="56.25%" // 16:9 Aspect Ratio
                height="0"
                overflow="hidden"
              >
                <Youtube />
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
      {/* Our Role */}
      <Box>
        <Container
          width={['100%', '100%', '100%', '94%', '88%', '83%']}
          padding={['0', '0', '0']}
          maxW={['100%', '100%', '100%', '94%', '88%', '83%']}
          margin={['0 auto']}
        >
          {/* Video Section */}
          <Flex
            justifyContent="center"
            display={['none', 'none', 'none', 'block', 'block', 'block']}
          >
            <Box
              margin="0 auto"
              maxWidth="100%"
              position="relative"
              paddingBottom="56.25%" // 16:9 Aspect Ratio
              height="0"
              overflow="hidden"
            >
              <Youtube2 />
              
            </Box>
          </Flex>
          {/* Text Section */}
          <Flex
            width={['100%', '100%', '100%', '100%', '100%', '100%']}
            maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            gap={['16px', '16px', '16px', '20px', '20px', '20px']}
            alignItems={['flex-start']}
            paddingTop={['80px', '80px', '80px', '55px', '63px', '63px']}
            paddingBottom={['80px', '80px', '80px', '55px', '63px', '79px']}
            marginBottom={['75px', '75px', 0, 0, 0, 0]}
            background={['#F5F5F5']}
            paddingLeft={['25px', '35px', '35px', '40px', '45px', '49px']}
            paddingRight={['25px', '35px', '35px', '40px', '45px', '49px']}
            position={['relative']}
          >
            <Text
              as="h1"
              fontSize={['32px', '32px', '34px', '40px', '40px', '40px']}
              fontFamily="var(--garnett-regular)"
              color={['#000000']}
              fontWeight={['400']}
              lineHeight={'normal'}
              width={['100%', '100%', '50%', '50%', '50%', '50%']}
            >
              Crafting Clarity in Complexity.
            </Text>
            <Text
              as="h1"
              fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
              width={['100%', '100%', '50%', '50%', '50%', '50%']}
            >
              At Tenet, we’re more than strategists. We’re architects of
              simplicity.
              <br />
              <br />
              Standing at the intersection of creativity and customer service
              excellence, we craft clear pathways tailored to guide you toward
              your goals. Benefit from our expertise in Brand Strategy, Design,
              Digital Transformation, and Marketing, guiding your business
              forward with unparalleled value.
            </Text>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
export default HeroSection;
