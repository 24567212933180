import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import minus from '../../assets/Reusable/FAQ/minus.svg';
import plus from '../../assets/Reusable/FAQ/plus.svg';

const FAQ = () => {
  const [visibleItems, setVisibleItem] = useState(5);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = index => {
    setActiveIndex(prevIndex => (prevIndex === index ? -1 : index));
  };

  const showMore = () => {
    setVisibleItem(prev => prev + 5);
  };

  // Accordion Styling
  const accordianIndex = {
    fontSize: ['20px', '20px', '20px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
  };
  const accordianTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
  };
  const accordianParagraph = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    fontSize: ['16px', '16px', '16px', '18px', '18px', '18px'],
    lineHeight: 'normal',
    ml: ['54px', '54px', '54px', '83px'],
    mt: '24px',
  };
  const accordianItemBox = {
    p: '8px 0',
    borderTop: 'none',
    borderBottom: '1px',
    borderBottomColor: '#B5B5B5',
  };

  // // Accordion Data
  // const faqData = [
  //   {
  //     index: '01',
  //     title: 'What is UX design?',
  //     content: `UX design, or User Experience design, focuses on creating products that provide meaningful and relevant experiences to users. It involves the design of the entire process of acquiring and integrating the product, including aspects of branding, design, usability, and function.`,
  //   },
  //   {
  //     index: '02',
  //     title: 'Why is UX important?',
  //     content: `UX is crucial because it directly impacts how users perceive and interact with your product. A good UX design leads to higher user satisfaction, better engagement, and ultimately, increased conversions and customer loyalty.`,
  //   },
  //   {
  //     index: '03',
  //     title: 'How long does a UX project take?',
  //     content: `The duration of a UX project can vary depending on its complexity. Generally, it can take anywhere from a few weeks to several months, depending on the scope and specific requirements.`,
  //   },
  //   {
  //     index: '04',
  //     title: 'What deliverables can I expect?',
  //     content: `Typical UX deliverables include user personas, journey maps, wireframes, prototypes, and usability testing reports. The exact deliverables will depend on the project requirements.`,
  //   },
  //   {
  //     index: '05',
  //     title: 'How do payment terms work for UX design projects?',
  //     content: `Payment terms for UX design projects are usually based on milestones or project phases. Common arrangements include an initial deposit, followed by payments at specific stages of the project, with the final payment due upon completion.`,
  //   },
  //   {
  //     index: '06',
  //     title: 'How do payment terms work for UX design projects?',
  //     content: `Payment terms for UX design projects are usually based on milestones or project phases. Common arrangements include an initial deposit, followed by payments at specific stages of the project, with the final payment due upon completion.`,
  //   },
  // ];

  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/home-faqs?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <Box padding={['80px 0', '80px 0', '80px 0', '150px 0', '150px 0']}>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '68%', '68%', '68%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
      >
        {/* Section Heading */}
        <Box>
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px']}
            lineHeight={'normal'}
            fontWeight={'normal'}
            color={'#000'}
            fontFamily={'var(--garnett-regular)'}
          >
            Frequently Asked Question
          </Heading>
        </Box>
        <Box mt={['45px', '45px', '45px', '45px', '65px']}>
          <Accordion
            allowToggle={false}
            defaultIndex={[0]}
            allowMultiple={false}
          >
            {data.slice(0, visibleItems).map((item, index) => (
              <AccordionItem key={index} sx={accordianItemBox}>
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        onClick={() => handleAccordionClick(index)}
                        px={'0'}
                        _hover={{ backgroundColor: 'transparent!important' }}
                      >
                        <Box as="span" flex="1" textAlign="left">
                          <Flex alignItems={'center'}>
                            {/* Accordion Index Number */}
                            <Box
                              mr={['24px', '24px', '24px', '53px', '53px']}
                              minW={['45px']}
                            >
                              <Text as={'span'} sx={accordianIndex}>
                                {index + 1 < 10 ? `0${index + 1}` : index + 1}
                              </Text>
                            </Box>
                            {/* Accordion Title */}
                            <Box>
                              <Text as={'h3'} sx={accordianTitle}>
                                {item.attributes.heading}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                        <Image
                          src={isExpanded ? minus : plus}
                          width={['24px', '24px', '24px', '32px', '32px']}
                          height={['24px', '24px', '24px', '32px', '32px']}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text as={'p'} sx={accordianParagraph}>
                        {item.attributes.description}
                      </Text>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
          {/* More Questions Pagination Button */}
          {visibleItems < data.length && (
            <Box paddingTop={['24px', '24px', '24px', '40px', '40px', '40px']}>
              <Button
                fontSize={['20px', '20px', '20px', '20px', '20px', '20px']}
                color={'#fff'}
                fontFamily={'var(--foundersGrotesk-regular)'}
                fontWeight={'400'}
                lineHeight={'normal'}
                backgroundColor={'#9B4DFF'}
                borderRadius={'0'}
                border={'none'}
                padding={['16px 14px 14px']}
                _hover={{ backgroundColor: '#000' }}
                onClick={showMore}
              >
                More Questions
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FAQ;
