import { Box, Link } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Breadcrumb = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-openings?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const breadCrumb = {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '16px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#fff',
    display: 'inline-flex',
    letterSpacing: '-0.352px',
  };
  return (
    <Box display={['none', 'none', 'Flex', 'Flex', 'Flex', 'Flex']}>
      <Link sx={breadCrumb} href="/">Home</Link>
      <Box mx={'6px'} pb={'4px'}>
        <svg
          mx={'6px'}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M6 12L10 8L6 4" stroke="white" stroke-linecap="square" />
        </svg>
      </Box>
      <Link sx={breadCrumb} href="/career">Career</Link>
      <Box mx={'6px'} pb={'4px'}>
        <svg
          mx={'6px'}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M6 12L10 8L6 4" stroke="white" stroke-linecap="square" />
        </svg>
      </Box>
      <Link sx={breadCrumb}> {firstItem?.main_heading}</Link>
    </Box>
  );
};
export default Breadcrumb;
