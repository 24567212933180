import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TechnologyContent = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const technologyUsed = firstItem?.technology_used?.data || [];
  const otherImages = firstItem?.other_images?.data || [];

  return (
    <Container
      paddingTop={['60px', '60px', '60px', '80px', '80px', '80px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['0px', '0px', 0, 0, 0, 0]}
      padding={['0']}
    >
      {technologyUsed.length > 0 && (
        <Flex
          width={['90%', '90%', '90%', '80%', '70%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '70%', '72%']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['40px', '50px', '50px', '60px', '70px', '70px']}
          alignItems={['baseline', 'baseline', 'baseline', 'center', 'center', 'center']}
          margin={['0 auto']}
        >
          <Text
            as="h1"
            fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
            fontFamily="var(--foundersGrotesk-medium)"
            color={['#860038']}
            fontWeight={['500']}
            lineHeight={'normal'}
          >
            TECHNOLOGY USED
          </Text>
          <Flex flexDirection={['row']} gap={['40px']}>
            <Box width={['60px', '60px', '60px', '86px', '86px', '86px']} display={["flex"]} gap={["40px"]}>
              {technologyUsed.map((item) => (
                <Image
                  key={item.id}
                  src={`https://cms.wearetenet.com${item.attributes.url}`}
                  alt={item.attributes.name}
                  width="100%"
                  height="auto"
                />
              ))}
            </Box>
            {/* <Box width={['70px', '70px', '70px', '99px', '99px', '99px']}>
        <img
          src={reacticon}
          width={'100%'}
          height={'auto'}
          alt="react icon"
        />
      </Box> */}
          </Flex>
        </Flex>
      )}

      {firstItem?.main_image?.data?.attributes?.url && (
        <Image
          paddingTop={['30px', '40px', '50px', '80px', '100px', '150px']}
          paddingBottom={['30px', '40px', '50px', '80px', '80px', '80px']}
          src={`https://cms.wearetenet.com${firstItem.main_image.data.attributes.url}`}
          width={['100%', '100%', '100%', '80%', '80%', '80%']}
          margin={['0 auto']}
        />
      )}

      {otherImages.length > 0 && (
        <Flex
          width={['100%', '100%', '100%', '96%', '96%', '96%']}
          maxWidth={['100%', '100%', '100%', '96%', '96%', '96%']}
          direction={['column', 'column', 'column', 'row', 'row', 'row']}
          gap={['20px', '20px', '20px', '30px', '30px', '30px']}
          alignItems={['baseline']}
          margin={['0 auto']}
          justifyContent={[
            'flex-start',
            'flex-start',
            'flex-start',
            'space-evenly',
            'space-evenly',
            'space-evenly',
          ]}
        >
          {otherImages.map((item) => (
            <Image
              key={item.id}
              src={`https://cms.wearetenet.com${item.attributes.url}`}
              alt={item.attributes.name}
              width={['100%', '100%', '100%', '50%', '50%', '50%']}
              height="auto"
              style={{ objectFit: 'cover' }}
            />
          ))}
        </Flex>
      )}

    </Container>
  );
};

export default TechnologyContent;
