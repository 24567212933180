import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import LeftCaret from '../../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../../assets/Icons/Slider/rightIcon.svg';

const StrategySolution = () => {
  const eowData = [
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/iros/6.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/iros/7.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/iros/8.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/iros/9.webp',
    },
  ];

  // Custom style for the Swiper slider with dynamic padding based on breakpoints
  const swipperSliderCustomStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };

  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };

  return (
    <Box
      paddingTop={['80px', '80px', '80px', '185px', '185px', '185px']}
      paddingBottom={['24px', '24px', '24px', '150px', '150px', '150px']}
    >
      {/* Strategy Solution Headings */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          marginBottom={['24px', '24px', '24px', '40px', '40px', '40px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color="#860038"
              fontWeight="500"
              lineHeight="normal"
            >
              STRATEGY SOLUTION
            </Text>
          </Box>
          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            <Text
              width={['100%', '100%', '100%', '90%', '80%', '75%']}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              as="p"
              fontFamily="var(--garnett-regular)"
              fontWeight="400"
              lineHeight="normal"
            >
              Our solution is deeply rooted in the core brand values identified
              through workshops, and stakeholder interviews.
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Slider Start */}
      <Box
        padding={['53px 0', '53px 0', '53px 0', '90px 0', '90px 0', '90px 0']}
        backgroundColor={'#F5F5F5'}
      >
        {/* Hide Slider Navigations if need then display block */}
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
          m="0 auto"
          display={'none'}
          padding="0"
        >
          <Flex
            justifyContent="space-between"
            paddingTop="20px"
            width="90%"
            display={['flex', 'flex', 'none', 'none', 'none', 'none']}
            margin="0 auto"
            paddingBottom="31px"
          >
            <Text
              fontSize="14px"
              color="#333"
              fontFamily="var(--garnett-regular)"
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text>
            <Box display="flex">
              <Box onClick={goPrev} mr="20px">
                <Image src={LeftCaret} cursor="pointer" />
              </Box>
              <Box onClick={goNext}>
                <Image src={RightCaret} cursor="pointer" />
              </Box>
            </Box>
          </Flex>
        </Container>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, FreeMode]}
          speed={750}
          slidesPerView={'auto'}
          spaceBetween={24}
          style={swipperSliderCustomStyle}
          onSlideChange={handleSlideChange}
          className="stategySoltionSlider"
        >
          {eowData.map((data, index) => (
            <SwiperSlide key={index}>
              <Box
                width={['303px', '303px', '303px', '512px', '512px', '512px']}
              >
                <Image src={data.image} w={'100%'} height={'auto'} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {/* Slider End */}

      {/* Text after Slider */}

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          paddingTop={['24px', '24px', '24px', '40px', '40px', '40px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color="#860038"
              visibility="hidden"
              fontWeight="500"
              lineHeight="normal"
            >
              STRATEGY SOLUTION
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              width={['100%', '100%', '100%', '90%', '80%', '75%']}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              as="p"
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight="400"
              lineHeight="normal"
            >
              Through extensive workshops, interviews, and research, we
              collected a wealth of information. We then distilled this data to
              identify IROS's core attributes, purpose, USPs, beliefs,
              behaviors, personality, positioning, and much more.
              <br />
              <br />
              We defined the brand's foundational values, paving the way for a
              new positioning with the tagline 'Catalyzing Breakthroughs.' This
              emphasizes IROS's unique role as a transformative force in the UAE
              healthcare industry.
              <br />
              <br />
              The creation of a comprehensive brand strategy centered on values
              ensures a clear, unified brand message, fostering a strong
              connection with the audience and guiding consistent
              decision-making for a cohesive brand identity.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StrategySolution;
