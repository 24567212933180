import React from 'react';
import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import whatsNew from '../../../assets/Temp/whatsNewServices.webp';
const ServiceMegaNew = () => {
  const services = [
    {
      id: 1,
      title: 'Research',
      serviceList: [
        { name: 'Brand Research', link: '/' },
        { name: 'Brand Strategy', link: '/' },
        { name: 'Design Audit', link: '/' },
        { name: 'UX Research', link: '/' },
        { name: 'Conversion Optimization Audit', link: '/' },
        { name: 'Competitive Benchmarking', link: '/' },
      ],
    },
    {
      id: 2,
      title: 'Design',
      serviceList: [
        { name: 'Product Design', link: '/' },
        { name: 'UX Design', link: '/' },
        { name: 'UI Design', link: '/' },
        { name: 'Brand Design & Rebranding', link: '/' },
        { name: 'Brand Style Guides', link: '/' },
        { name: 'Collateral, Print & Packaging', link: '/' },
      ],
    },
    {
      id: 3,
      title: 'Build',
      serviceList: [
        { name: 'Website Development', link: '/' },
        { name: 'Android App Development', link: '/' },
        { name: 'iOS App Development', link: '/' },
        { name: 'SaaS Development', link: '/' },
        { name: 'Custom CMS Development', link: '/' },
        { name: 'Cross Platform App Development', link: '/' },
      ],
    },
    {
      id: 3,
      title: 'Growth',
      serviceList: [
        { name: 'Search Engine Optimization (SEO)', link: '/' },
        { name: 'Conversion Rate Optimizations', link: '/' },
        { name: 'PPC Advertising', link: '/' },
        { name: 'Email Marketing', link: '/' },
        { name: 'Content Marketing', link: '/' },
        { name: 'B2B Marketing', link: '/' },
      ],
    },
  ];
  return (
    <Box
      margin={['0px 10px', '0px 10px', '0px 10px', '0px 10px']}
      position={'relative'}
    >
      <Box
        position="absolute"
        top="12px"
        left={0}
        right={0}
        width="100%"
        zIndex={1000}
        maxW={['1072px', '1072px', '1072px', '1072px', '1072px', '1072px']}
        margin={'0 auto'}
        background={'#FFF'}
        borderRight={'1px solid #F5F5F5'}
        borderBottom={'1px solid #F5F5F5'}
        borderLeft={'1px solid #F5F5F5'}
        boxShadow={
          '-339px 462px 160px 0px rgba(0, 0, 0, 0.00), ' +
          '-217px 296px 147px 0px rgba(0, 0, 0, 0.01), ' +
          '-122px 166px 124px 0px rgba(0, 0, 0, 0.05), ' +
          '-54px 74px 92px 0px rgba(0, 0, 0, 0.09), ' +
          '-14px 18px 50px 0px rgba(0, 0, 0, 0.10)'
        }
      >
        {/* Wrapper */}
        <Box display={'flex'}>
          <Box width={['79%']} padding={['26px 30px 44px']}>
            {/* Section Headings */}
            <Box
              paddingBottom={['28px', '28px', '28px', '28px', '35px', '35px']}
            >
              {/* Section Heading */}
              <Link
                href="/our-services"
                display={'inline-flex'}
                fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
                color={'#333'}
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontWeight={'400'}
                lineHeight={'normal'}
                gap={'6px'}
                alignItems={'center'}
                background={'#EBDBFF'}
                padding={['10px']}
                letterSpacing={'-0.396px'}
                height={['44px']}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_2973_1492)">
                    <path
                      d="M19.5 21H4.5C4.10234 20.9995 3.72112 20.8413 3.43993 20.5601C3.15874 20.2789 3.00054 19.8977 3 19.5V4.5C3.00054 4.10234 3.15874 3.72112 3.43993 3.43993C3.72112 3.15874 4.10234 3.00054 4.5 3H12V4.5H4.5V19.5H19.5V12H21V19.5C20.9995 19.8977 20.8413 20.2789 20.5601 20.5601C20.2789 20.8413 19.8977 20.9995 19.5 21Z"
                      fill="#7610B5"
                    />
                    <path
                      d="M15 1.5V3H19.9395L13.5 9.4395L14.5605 10.5L21 4.0605V9H22.5V1.5H15Z"
                      fill="#7610B5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2973_1492">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Text paddingTop={'6px'}>All Services</Text>
              </Link>
            </Box>
            {/* Links */}
            <Box
              display={['flex']}
              gap={['10px', '10px', '10px', '10px', '39px', '39px', '39px']}
            >
              {/* Item */}
              {services.map((data, index) => (
                <Box key={index} w={'25%'}>
                  {/* Service heading */}
                  <Box paddingBottom={['14px', '14px', '14px', '17px', '17px']}>
                    <Text
                      as={'p'}
                      fontFamily={'var(--garnett-semibold);'}
                      fontSize={[
                        '14px',
                        '14px',
                        '16px',
                        '16px',
                        '16px',
                        '16px',
                      ]}
                      color={'#333'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                    >
                      {data.title}
                    </Text>
                  </Box>
                  {/* Service List */}
                  <Box
                    display={['flex']}
                    flexDirection={'column'}
                    gap={['16px', '16px', '16px', '16px', '22px', '22px']}
                  >
                    {data.serviceList.map((listItem, indexService) => (
                      <Link
                        key={indexService}
                        href={listItem.link}
                        fontFamily={'var(--foundersGrotesk-regular);'}
                        fontSize={[
                          '16px',
                          '16px',
                          '16px',
                          '16px',
                          '16px',
                          '16px',
                        ]}
                        color={'#333'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                      >
                        <Text
                          _hover={{
                            color: '#0057FF',
                            textDecorationLine: 'underline',
                          }}
                        >
                          {listItem.name}
                        </Text>
                      </Link>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            {/* Contact Us */}
            <Box>
              {/* Hr Line */}
              <Box
                marginBottom={['23px']}
                marginTop={['53px', '53px', '53px', '63px', '63px']}
                backgroundColor={'#BDBDBD'}
                height={'1px'}
                w={'100%'}
              ></Box>
              {/* Link */}
              <Box display={['flex']} gap={'7px'}>
                {/* Label */}
                <Text
                  as={'p'}
                  fontFamily={'var(--foundersGrotesk-light);'}
                  fontSize={['16px']}
                  fontWeight={'300'}
                  lineHeight={'normal'}
                  color={'#333'}
                >
                  Need more help?
                </Text>
                <Link
                  href="/contact-us"
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  letterSpacing={'-0.396px'}
                  fontSize={['18px']}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  color={'#333'}
                >
                  <Text
                    textDecoration={'underline'}
                    textDecorationColor={'#0057FF'}
                  >
                    Contact us
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
          {/* Whatus New */}
          <Box
            width={['21%']}
            padding={['102px 24px 37px', '102px 24px 37px', '102px 24px 37px']}
            background={'#EBDBFF'}
          >
            {/* Whats new */}
            <Box>
              {/* Title Section */}
              <Box
                display={['flex']}
                alignItems={'center'}
                gap={'3px'}
                paddingBottom={['17px']}
              >
                <Text
                  as="p"
                  fontSize={['14px', '14px', '14px', '14px', '16px', '16px']}
                  color={'#333'}
                  fontFamily={'var(--garnett-regular);'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                >
                  What’s New
                </Text>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clip-path="url(#clip0_2866_3546)">
                    <path
                      d="M15.825 2.54421L17.25 5.86577C17.2852 5.94699 17.3419 6.01711 17.4139 6.06863C17.4858 6.12015 17.5705 6.15113 17.6587 6.15827L21.3028 6.46858C21.3981 6.4747 21.4894 6.50911 21.565 6.5674C21.6407 6.62569 21.6972 6.70521 21.7274 6.7958C21.7576 6.88639 21.7601 6.98393 21.7345 7.07594C21.709 7.16795 21.6566 7.25026 21.584 7.31233L18.8156 9.67202C18.7494 9.72852 18.6999 9.80207 18.6724 9.88469C18.645 9.96732 18.6407 10.0559 18.66 10.1408L19.4887 13.6526C19.5093 13.7458 19.5021 13.843 19.468 13.9321C19.4338 14.0212 19.3743 14.0984 19.2967 14.154C19.2192 14.2096 19.127 14.2412 19.0316 14.2449C18.9363 14.2486 18.8419 14.2242 18.7603 14.1748L15.6272 12.312C15.5508 12.2669 15.4637 12.2431 15.375 12.2431C15.2863 12.2431 15.1992 12.2669 15.1228 12.312L11.9897 14.1748C11.908 14.2242 11.8137 14.2486 11.7183 14.2449C11.623 14.2412 11.5308 14.2096 11.4532 14.154C11.3757 14.0984 11.3161 14.0212 11.282 13.9321C11.2479 13.843 11.2407 13.7458 11.2612 13.6526L12.0937 10.1455C12.113 10.0606 12.1087 9.972 12.0813 9.88938C12.0539 9.80675 12.0043 9.73321 11.9381 9.67671L9.16967 7.31233C9.09711 7.25026 9.04472 7.16795 9.01919 7.07594C8.99365 6.98393 8.99614 6.88639 9.02634 6.7958C9.05654 6.70521 9.11307 6.62569 9.1887 6.5674C9.26433 6.50911 9.35563 6.4747 9.45092 6.46858L13.0959 6.16014C13.1835 6.15202 13.2673 6.12038 13.3385 6.06857C13.4096 6.01675 13.4654 5.94668 13.5 5.86577L14.925 2.54421C14.9633 2.45674 15.0262 2.38233 15.1062 2.33008C15.1861 2.27783 15.2795 2.25 15.375 2.25C15.4705 2.25 15.5639 2.27783 15.6438 2.33008C15.7237 2.38233 15.7867 2.45674 15.825 2.54421Z"
                      stroke="#0057FF"
                      stroke-linecap="round"
                    />
                    <path
                      d="M7.5 11.25L2.25 16.5"
                      stroke="#0057FF"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 16.5L3.75 21.75"
                      stroke="#0057FF"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.75 16.5L10.5 21.75"
                      stroke="#0057FF"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2866_3546">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
              <Box>
                <Image src={whatsNew} alt="whatsNew" />
              </Box>
              {/* Whats New Title */}
              <Box>
                <Heading
                  as={'h2'}
                  fontSize={['16px']}
                  color={'#333'}
                  fontFamily={'var(--garnett-medium);'}
                  fontWeight={'500'}
                  lineHeight={'normal'}
                  padding={['17px 0 13px']}
                >
                  Secrets for Collaboration: Design X Engineering
                </Heading>
                <Text
                  as={'p'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  fontSize={['14px']}
                  color={'#333'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                >
                  There’s something very powerful about working in
                  interdisciplinary teams to build and evolve....
                </Text>
                <Link
                  href="#"
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  fontSize={['14px']}
                  color={'#333'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  paddingTop={'11px'}
                  display={'inline-block'}
                >
                  <Text
                    as={'p'}
                    textDecorationLine={'underline'}
                    textDecorationColor={'#0057FF'}
                    textDecorationThickness={'2px'}
                  >
                    Read more
                  </Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ServiceMegaNew;
