import {
  Box,
  chakra,
  Container,
  Heading,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const splitServices = services => {
  const midpoint = Math.ceil(services.length / 2);
  return [services.slice(0, midpoint), services.slice(midpoint)];
};

const BusinessTab = () => {
  const { slug } = useParams();
  const location = useLocation(); // Use location to extract region from the URL
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState('Research');

  // Move all hooks to the top level of the component
  const sectionRefs = useRef([]);
  const containerRef = useRef(null);
  const stickyBoxRef = useRef(null);

  const desktopOffset = 68;
  const mobileOffset = 63;

  const [isBelow992] = useMediaQuery('(max-width: 991px)');
  const [is992to1100] = useMediaQuery(
    '(min-width: 992px) and (max-width: 1099px)'
  );
  const [is1100to1280] = useMediaQuery(
    '(min-width: 1100px) and (max-width: 1279px)'
  );
  const [is1280to1536] = useMediaQuery(
    '(min-width: 1280px) and (max-width: 1535px)'
  );
  const [isAbove1536] = useMediaQuery('(min-width: 1536px)');

  // Fetch data using useEffect hook
  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        let region = pathParts[1]; 

        if (!['us', 'in', 'ae'].includes(region)) {
          region = 'global';
        }

        const response = await axios.get(
          `https://cms.wearetenet.com/api/homepages?filters[region][$eq]=${region}&populate=*`
        );
        if (response.data.data.length > 0) {
          const fetchedData = response.data.data[0].attributes;

          const capabilities = [
            {
              id: 0,
              label: 'Research',
              title: fetchedData.research_heading,
              desc: fetchedData.research_description,
              img: fetchedData.research_image.data.attributes.url,
              services: [
                'Brand Research',
                'Brand Strategy',
                'Heuristic Evaluation',
                'Design Audit',
                'Usability Testing',
                'Ethnographic research',
                'Emerging trends',
                'UX Research',
                'Conversion Optimization Audit',
                'Competitive Benchmarking',
                'Discovery & Innovation Workshop',
              ],
            },
            {
              id: 1,
              label: 'Design',
              title: fetchedData.design_heading,
              desc: fetchedData.design_description,
              img: fetchedData.design_image.data.attributes.url,
              services: [
                'Product Design',
                'UX Design',
                'UI Design',
                'Interaction Design',
                'Digital Prototyping',
                'Brand Design & Rebranding',
                'Motion Graphics',
                'Digital Illustrations',
                'Brand Style Guides',
                'Collateral, Print & Packaging',
                'Iconography',
              ],
            },
            {
              id: 2,
              label: 'Build',
              title: fetchedData.build_heading,
              desc: fetchedData.build_description,
              img: fetchedData.build_image.data.attributes.url,
              services: [
                'Website Development',
                'Android App Development',
                'iOS App Development',
                'Saas Development',
                'Custom CMS Development',
                'Cross Platform App Development',
              ],
            },
            {
              id: 3,
              label: 'Growth',
              title: fetchedData.growth_heading,
              desc: fetchedData.growth_description,
              img: fetchedData.growth_image.data.attributes.url,
              services: [
                'Search Engine Optimization (SEO)',
                'Conversion Rate Optimizations',
                'PPC Advertising',
                'Email Marketing',
                'Analytics, Reporting & Insights',
                'Engagement & Growth Strategy',
                'Content Marketing',
                'B2B Marketing',
              ],
            },
          ];

          setData(capabilities);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug, location]);

  // Sticky navigation effect
  // Sticky navigation effect
// Sticky navigation effect
useEffect(() => {
  const handleScroll = () => {
    const container = containerRef.current;
    const stickyBox = stickyBoxRef.current;
    const tabbingWrapper = document.querySelector('.TabbinGwrapper');

    if (!container || !stickyBox || !tabbingWrapper) return;

    const containerRect = container.getBoundingClientRect();
    const stickyBoxHeight = stickyBox.offsetHeight;
    const offset = window.innerWidth <= 768 ? mobileOffset : desktopOffset;

    // Adjust the containerRect to account for padding
    const sectionPaddingTop = parseFloat(window.getComputedStyle(container).paddingTop);
    const adjustedContainerTop = containerRect.top + sectionPaddingTop;

    const stickyShouldBeFixed =
      adjustedContainerTop <= offset &&
      containerRect.bottom - stickyBoxHeight - offset >= 0;

    const stickyShouldBeAbsolute =
      containerRect.bottom - stickyBoxHeight <= offset;

    if (stickyShouldBeFixed) {
      stickyBox.style.position = 'fixed';
      stickyBox.style.top = `${offset}px`;
      stickyBox.style.bottom = 'auto';
      tabbingWrapper.style.paddingTop = `${stickyBoxHeight}px`;
    } else if (stickyShouldBeAbsolute) {
      stickyBox.style.position = 'relative';
      stickyBox.style.top = 'auto';
      stickyBox.style.bottom = '0';
      tabbingWrapper.style.paddingTop = '0';
    } else {
      stickyBox.style.position = 'static';
      stickyBox.style.top = 'auto';
      stickyBox.style.bottom = 'auto';
      tabbingWrapper.style.paddingTop = '0';
    }
  };

  window.addEventListener('scroll', handleScroll);
  handleScroll(); // Initialize the position
  return () => window.removeEventListener('scroll', handleScroll);
}, [mobileOffset, desktopOffset]);
  // Observer
  const prevActiveRef = useRef(null); // Ref to store the previous active section
  const clickInProgress = useRef(false); // Flag to track if a click event is in progress

  useEffect(() => {
    const handleScroll = () => {
      // Exit if a click is in progress
      if (clickInProgress.current) return;

      const topOffset = window.innerWidth <= 768 ? 100 : 100;
      sectionRefs.current.forEach(ref => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const id = ref.getAttribute('id');
          const capability = data.find(cap => cap.label === id);

          // Check if the section has reached the top of the viewport
          if (capability && rect.top <= topOffset && rect.bottom > 0) {
            if (prevActiveRef.current !== capability.label) {
              // Update the active section only if it has changed
              setActive(capability.label);
              prevActiveRef.current = capability.label;
            }
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [data]);

  // Handle click on section link
  const handleActive = label => {
    clickInProgress.current = true; // Mark click in progress
    setActive(label); // Manually set the active section

    // Scroll to the section smoothly
    document.getElementById(label).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    // Allow scroll event to take over after some delay
    setTimeout(() => {
      clickInProgress.current = false;
    }, 500); // Delay to allow scroll to complete
  };

  const activeLinkColor = {
    background: '#0057FF',
    color: '#FFF',
  };
  // document.documentElement.style.scrollBehavior = 'fast';

  const tabLinkWrapper = {
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderBottomColor: '#D9D9D9',
    bg: '#fff',
    boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.08)',
    textAlign: 'center',
    display: 'flex',
    gap: ['0px', '0px', '0px', '30px', '30px', '30px'],
    height: ['72px', '72px', '75px', '95px'],
  };

  const tabsDesign = {
    fontFamily: [
      'var(--foundersGrotesk-regular)',
      'var(--foundersGrotesk-regular);',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
    ],
    display: 'flex',
    alignItems: 'center',
    fontWeight: '400',
    padding: [
      '8px 8px 0px 8px',
      '8px 8px 0px 8px',
      '8px 8px 0px 8px',
      '0px 30px 0px 30px',
    ],
    fontSize: ['25px', '25px', '25px', '25px', '32px', '32px'],
    textDecoration: 'none',
    color: '#000',
    letterSpacing: '-0.704px',

    '&:hover': {
      textDecoration: 'none',
    },
  };

  // Define styles based on media queries
  const listStyle = {
    fontSize: isBelow992
      ? '16px'
      : is992to1100
      ? '19px'
      : is1100to1280
      ? '22px'
      : is1280to1536
      ? '24px'
      : isAbove1536
      ? '28px'
      : '16px', // Fallback
    fontWeight: '400',
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    lineHeight: 'normal',
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!data) return <p>No data available.</p>;

  return (
    <Container
      paddingTop={['80px', '80px', '100px', '100px', '115px', '127px']}
      width={['100%']}
      maxWidth={['100%']}
      paddingLeft={'0'}
      paddingRight={'0'}
    >
      {/* Section Heading */}
      <Box
        margin={['0 auto']}
        width={['87%', '87%', '87%', '100%', '100%', '100%']}
        paddingBottom={['30px', '40px', '40px', '50px', '55px', '70px']}
      >
        <Text
          as="h2"
          fontSize={['32px', '32px', '35px', '40px', '60px', '64px']}
          color="#000000"
          textAlign={'center'}
          fontFamily="var(--garnett-regular)"
          fontWeight="400"
          lineHeight={['normal']}
        >
          <span style={{ color: '#0057FF' }}>Our Spectrum</span> of
          <chakra.span
            fontFamily="var(--garnett-regular)"
            fontWeight="400"
            color="#000000"
          >
            <br />
          </chakra.span>
          <chakra.span
            fontFamily="var(--garnett-regular)"
            fontWeight="400"
            color="#000000"
          >
            Capabilities
          </chakra.span>
        </Text>
      </Box>

      {/* Our Tabbing */}
      <Box ref={containerRef}>
        <Box
          sx={tabLinkWrapper}
          ref={stickyBoxRef}
          zIndex="500"
          width={['100%']}
          top="0" // make it sticky
        >
          {data.map(capability => (
            <Link
              key={capability.id}
              href={`#${capability.label}`}
              sx={{
                ...tabsDesign,
                ...(active === capability.label && activeLinkColor),
              }}
              onClick={() => handleActive(capability.label)}
            >
              {capability.label}
            </Link>
          ))}
        </Box>

        {/* Tabbing Pannel */}
        <Container
          width={['100%', '100%', '100%', '90%', '90%', '90%']}
          maxW={['100%', '100%', '100%', '90%', '90%', '90%']}
          margin={'0 auto'}
          className="TabbinGwrapper"
        >
          {/* Data Services */}
          <Box>
            {data.map((capability, index) => {
              const [firstHalf, secondHalf] = splitServices(
                capability.services
              );
              return (
                <Box
                  key={capability.id}
                  p={'0'}
                  id={capability.label}
                  ref={el => (sectionRefs.current[index] = el)} // Attach section refs
                  paddingTop={['50px', '50px', '50px', '80px']}
                  scrollMarginTop={['100px', '80px', '80px', '80px']}
                >
                  <Box>
                    <Box
                      display={['block', 'block', 'block', 'flex', 'flex']}
                      justifyContent={'space-between'}
                      gap={'20px'}
                      background={'#F5F5F5'}
                      padding={[
                        '19px 14px 23px',
                        '19px 14px 23px',
                        '56px 34px 50px',
                      ]}
                    >
                      <Box maxW={['508px']}>
                        <Heading
                          as={'h3'}
                          fontSize={[
                            '20px',
                            '20px',
                            '25px',
                            '30px',
                            '36px',
                            '36px',
                          ]}
                          fontWeight={400}
                          fontFamily={'var(--garnett-regular);'}
                          lineHeight={'normal'}
                          letterSpacing={'-0.792px'}
                          color={'#333'}
                        >
                          {capability.title}
                        </Heading>
                      </Box>
                      <Box maxW={['528px']}>
                        <Text
                          as={'p'}
                          fontSize={[
                            '16px',
                            '16px',
                            '16px',
                            '20px',
                            '20px',
                            '20px',
                          ]}
                          fontWeight={400}
                          lineHeight={'normal'}
                          color={'#333'}
                          marginBottom={['16px', '16px', '16px', '24px']}
                          marginTop={['16px', '16px', '16px', '0']}
                          fontFamily={'var(--foundersGrotesk-regular);'}
                        >
                          {capability.desc}
                        </Text>
                        <Link
                          href="#"
                          display={'inline-block'}
                          alignItems={'center'}
                          fontSize={[
                            '16px',
                            '16px',
                            '16px',
                            '20px',
                            '20px',
                            '20px',
                          ]}
                          color={'#fff'}
                          lineHeight={'normal'}
                          fontWeight={400}
                          fontFamily={'var(--foundersGrotesk-regular);'}
                          background={'#0057FF'}
                          padding={[
                            '11px 14px 8px 14px;',
                            '11px 14px 8px 14px;',
                            '11px 14px 8px 14px;',
                            '11px 14px 6px 14px;',
                            '11px 14px 6px 14px;',
                            '11px 14px 6px 14px;',
                          ]}
                          _hover={{
                            textDecoration: 'none',
                            transform: 'translate3d(0, -3px, 0)',
                          }}
                          transition="transform 0.5s ease"
                        >
                          Learn More
                        </Link>
                      </Box>
                    </Box>
                    <Box
                      background={'#0D0E4D'}
                      padding={[
                        '16px 15px 41px',
                        '16px 15px 41px',
                        '44px 34px 63px',
                      ]}
                      display={[
                        'block',
                        'block',
                        'block',
                        'flex',
                        'flex',
                        'flex',
                      ]}
                    >
                      <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                        <Image
                          src={`https://cms.wearetenet.com${
                            capability.img || ''
                          }`}
                          w={'100%'}
                          h={'auto'}
                          loading="lazy"
                          fetchpriority="low"
                        />
                      </Box>
                      {/* Lists */}
                      <Box
                        w={['100%', '100%', '100%', '50%', '50%', '50%']}
                        display={['grid']}
                        gridTemplateColumns={'repeat(2, 1fr)'}
                        columnGap={'20px'}
                        paddingLeft={['0px', '0px', '0px', '15px', '15px']}
                        paddingTop={['36px', '36px', '36px', '0']}
                      >
                        <Box>
                          <UnorderedList sx={listStyle}>
                            {firstHalf.map((service, idx) => (
                              <ListItem key={idx} marginBottom={'12px'}>
                                {service}
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </Box>
                        <Box>
                          <UnorderedList sx={listStyle}>
                            {secondHalf.map((service, idx) => (
                              <ListItem key={idx} marginBottom={'12px'}>
                                {service}
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default BusinessTab;
