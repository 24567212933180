import React from 'react';
import HandImg from '../../assets/Career/JobPostIcon/noJobPostHandIcon.svg';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';

const nojobpostsection = () => {
  const paragraphStyle = {
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const inputStyle = {
    borderBottom: '1px solid #B5B5B5',
    background: '#F5F5F5',
    color: '#333',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
    borderRadius: '0',
    height: '47px',
    _placeholder: {
      color: '#333',
      fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    },
  };

  return (
    <Box>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '90%', '90%', '90%']}
        margin={'0 auto'}
      >
        {/* Wrapper */}
        <Box display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}>
          {/* Box One */}
          <Box
            width={['100%', '100%', '100%', '50%', '50%', '50%']}
            background={'#30054A'}
            padding={[
              '33px 0px 0 20px',
              '33px 0px 0 20px',
              '33px 0px 0 20px',
              '86px 0px 0 61px',
              '86px 0px 0 61px',
              '86px 0px 0 61px',
            ]}
            display={['flex']}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Box
              paddingBottom={['50px']}
              paddingRight={['32px', '32px', '32px', '32px', '100px', '100px']}
            >
              {/* Heading */}
              <Heading
                as={'h3'}
                color={'#B882FF'}
                fontSize={['28px', '28px', '28px', '32px', '48px', '48px']}
                fontFamily={'var(--garnett-regular)'}
                fontWeight={'400'}
                lineHeight={'normal'}
                paddingBottom={['32px', '32px', '32px', '40px', '60px', '60px']}
              >
                We’re Not Hiring Right Now, But We’re Always Looking Ahead!
              </Heading>
              {/* Paragraph */}
              <Box>
                <Text as="p" sx={paragraphStyle} paddingBottom={['20px']}>
                  While we don't have any openings at the moment, we’re always
                  excited to connect with talented individuals who share our
                  vision. If you'd like to be considered for future
                  opportunities, please fill out the form and submit your CV. In
                  the meantime, stay connected with us!
                </Text>

                <Text as="p" sx={paragraphStyle}>
                  Follow us on LinkedIn or sign up for our newsletter to be the
                  first to hear about new openings and industry insights.
                </Text>
              </Box>
            </Box>
            {/* Image */}
            <Box display={'flex'} justifyContent={'end'}>
              <Box width={['200px', '200px', '250px', '431px', '431px']}>
                <Image
                  width={'100%'}
                  objectFit={'cover'}
                  src={HandImg}
                  alt=""
                />
              </Box>
            </Box>
          </Box>
          {/* Box Two */}
          <Box
            width={['100%', '100%', '100%', '50%', '50%', '50%']}
            padding={[
              '40px 34px 80px',
              '40px 34px 80px',
              '40px 34px 80px',
              '78px 50px 85px',
              '78px 50px 85px',
              '78px 50px 85px',
            ]}
          >
            {/* Wrapper */}
            <Box maxW={['563px']} margin={'0 auto'}>
              {/* Heading Form */}
              <Box paddingBottom={['32px', '32px', '32px', '35px']}>
                <Heading
                  as={'h4'}
                  letterSpacing={['-0.528px']}
                  lineHeight={'normal'}
                  textDecoration={'underline'}
                  fontSize={['24px']}
                  color={'#333'}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  paddingBottom={'25px'}
                >
                  Submit your CV
                </Heading>
                <Text
                  as={'p'}
                  letterSpacing={['-0.506px']}
                  lineHeight={'normal'}
                  fontSize={['16px', '16px', '16px', '23px', '23px']}
                  color={'#333'}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                >
                  We’re excited to learn more about you. Please fill out the
                  form below to{' '}
                  <Text as={'span'} color={'#B882FF'} display={'inline'}>
                    share your details with us.
                  </Text>
                </Text>
              </Box>
              {/* Form */}
              <Box>
                {' '}
                <Box as="form">
                  <Box display={['flex']} flexDirection={'column'} gap={'20px'}>
                    {/* Inputs */}
                    <FormControl isRequired>
                      <Input
                        type="text"
                        placeholder="Full Name"
                        sx={inputStyle}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <Input
                        type="email"
                        placeholder="Email Address"
                        sx={inputStyle}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <Input
                        type="number"
                        placeholder="Phone"
                        sx={inputStyle}
                      />
                    </FormControl>

                    <FormControl minH={['140px']}>
                      <Textarea
                        placeholder="Message"
                        sx={inputStyle}
                        minH={['140px']}
                      />
                    </FormControl>
                  </Box>

                  {/* UploadCV Link */}
                  <Box
                    mt={['20px']}
                    mb={['30px']}
                    background={'#F5F5F5'}
                    borderBottom="1.127px solid #B5B5B5"
                    display={['flex']}
                    flexDirection={['column', 'column', 'column', 'row']}
                    alignItems={'start'}
                    justifyContent={'start'}
                    gap={'9px'}
                    padding={['17px 10px 13px']}
                  >
                    {/* Add Upload CV component or link here */}
                    <Button
                      color={'#333'}
                      fontSize={['16px', '16px', '16px', '20px']}
                      lineHeight={'normal'}
                      fontWeight={'400'}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      background={'#E9F0FF'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'start'}
                      borderRadius={'none'}
                      whiteSpace={'normal'}
                      px={'10px'}
                      paddingTop={'3px'}
                      minW={'132px'}
                    >
                      <Text as={'span'} paddingTop={['10px']}>
                        Upload CV
                      </Text>

                      <Box paddingTop={['8px']}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_2721_766)">
                            <path
                              d="M3.75 11.25L4.63125 12.1312L9.375 7.39375V18.75H10.625V7.39375L15.3687 12.1312L16.25 11.25L10 5L3.75 11.25Z"
                              fill="#0057FF"
                            />
                            <path
                              d="M3.75 5V2.5H16.25V5H17.5V2.5C17.5 2.16848 17.3683 1.85054 17.1339 1.61612C16.8995 1.3817 16.5815 1.25 16.25 1.25H3.75C3.41848 1.25 3.10054 1.3817 2.86612 1.61612C2.6317 1.85054 2.5 2.16848 2.5 2.5V5H3.75Z"
                              fill="#0057FF"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2721_766">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Box>
                    </Button>
                    <Text
                      as={'p'}
                      fontSize={[
                        '12px',
                        '12px',
                        '12px',
                        '16px',
                        '16px',
                        '16px',
                      ]}
                      color={'#565656'}
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      fontWeight={'400'}
                      lineHeight={'normal'}
                    >
                      Please upload your file in PDF, DOCX, or PNG format. The
                      file size should not exceed 10 MB.
                    </Text>
                  </Box>

                  {/* Box For Check Box */}
                  <Box>
                    <Box
                      display={'flex'}
                      alignItems={'flex-start'}
                      mb={['30px']}
                    >
                      <Checkbox mr={'13px'} mt={'2px'} />
                      <Text
                        as="p"
                        color={'#000'}
                        mt={'2px'}
                        fontSize={['15px']}
                        fontFamily="var(--foundersGrotesk-regular)"
                        fontWeight={'400'}
                      >
                        Please check the box to receive our marketing
                        communications and stay up-to-date with our latest
                        updates. Thank you!
                      </Text>
                    </Box>
                  </Box>

                  {/* Submit */}
                  <Button
                    type="submit"
                    background={'#00763D'}
                    color={'#fff'}
                    fontSize={['20px']}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    fontWeight={'400'}
                    lineHeight={'normal'}
                    borderRadius={'none'}
                    border={'0'}
                    _hover={{ background: '#00763D' }}
                    width={['100%', '322px', '322px', '270px', '270px']}
                    height={['47px']}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default nojobpostsection;
