import React from 'react';
import ExploreOtherWorkUse from '../../Reusable/CaseStudy/ExploreOtherWorkSlider';

const ExploreOtherWorkReuse = () => {
  return (
    <div>
      <ExploreOtherWorkUse />
    </div>
  );
};

export default ExploreOtherWorkReuse;
