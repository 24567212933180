import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import axios from 'axios';

const convertParagraphToText = paragraph => {
  if (!paragraph || !paragraph.length) return '';

  return paragraph
    .map(p => {
      if (p.type === 'paragraph') {
        return p.children
          .map(child => {
            if (child.type === 'text') {
              return child.text;
            }
            return '';
          })
          .join('');
      }
      return '';
    })
    .join('\n');
};

const fetchCombinedData = async () => {
  try {
    const [insightResponse] = await Promise.all([
      axios.get(
        'https://cms.wearetenet.com/api/insight-blogs?filters[category][$eq]=guides&populate=*'
      ),
    ]);
    const baseUrl = 'https://cms.wearetenet.com';

    const insightData = insightResponse.data.data.map(item => {
      const category = item.attributes.category;
      const slug = item.attributes.slug;
      const linkUrl = `/resources/${category}/${slug}`;
      const imageHeight = item.attributes.image_height || '';

      return {
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        imageHeight: getImageHeight(imageHeight),

        linkText: item.attributes.category,
        linkUrl,
      };
    });

    return [...insightData];
  } catch (error) {
    console.error('Error fetching data', error);
    return [];
  }
};
const getImageHeight = imageHeight => {
  const heightMatch = imageHeight.match(/size\d=(\d+)/);
  return heightMatch ? `${heightMatch[1]}px` : '';
};
const ExploreAllStories = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchCombinedData();
        if (data.length > 0) {
          setSlidesData(data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const getCategoryBgColor = category => {
    switch (category) {
      case 'thoughts':
        return '#0057FF';
      case 'insights':
        return '#E0005E';
      case 'guides':
        return '#00763D';
      case 'glossary':
        return '#9B4DFF';
      case 'updates':
        return '#0036C2';
      case 'listen':
        return '#FF813A';
      default:
        return '#0057ff';
    }
  };

  const gridCss = {
    '@media (max-width: 599px)': {
      // 0px se 599px tak 1 column
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@media (min-width: 600px) and (max-width: 1023px)': {
      // 600px se 1023px tak 2 columns
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (min-width: 1024px)': {
      // 1024px se upar 4 columns
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    columnGap: ['24px'],
    rowGap: ['43px', '43px', '43px', '60px', '60px', '60px'],
  };

  return (
    // Main Wrapper
    <Box
      padding={[
        '80px 0 40px',
        '80px 0 40px',
        '80px 0 40px',
        '100px 0 60px',
        '100px 0 60px',
        '100px 0 60px',
      ]}
    >
      {/* Heading Container */}
      <Box maxW={['90%']} margin={'auto'}>
        <Box marginBottom={['30px', '30px', '30px', '60px', '60px', '60px']}>
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            color={'#000'}
            fontWeight={400}
            fontFamily={'var(--garnett-regular);'}
          >
            Explore All Guides
          </Heading>
        </Box>
      </Box>
      {/* Grid */}
      <Box maxW={['92%']} margin={'auto'}>
        {/* Story Grid Container */}
        <Grid sx={gridCss}>
          {slidesData.slice(0, 8).map(story => (
            <GridItem key={story.id}>
              <Link href={story.linkUrl} _hover={{ textDecoration: 'none' }}>
                {/* Story Image */}
                <AspectRatio
                  paddingBottom={'12px!important'}
                  // height={story.imageHeight}
                  ratio={
                    story.imageHeight === 330 / 220
                      ? 1.5
                      : story.imageHeight === 330 / 275
                      ? 1.2
                      : story.imageHeight === 330 / 382
                      ? 0.863874
                      : story.imageHeight === 330 / 247
                      ? 1.336032
                      : 1 // default ratio if none match
                  }
                  className="resourcesAspectRatioPaddingIssue"
                >
                  <Image
                    src={story.image}
                    w={['100%']}
                    height={'auto!important'}
                    objectFit={'cover'}
                    alt="story Image"
                    position={'relative!important'}
                  />
                </AspectRatio>
                {/* Story Category */}
                <Box paddingBottom={'12px'}>
                  <Text
                    as={'span'}
                    fontSize={['16px']}
                    fontWeight={400}
                    lineHeight={'normal'}
                    textTransform={['capitalize']}
                    color={'#fff'}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    display={'inline-block'}
                    backgroundColor={getCategoryBgColor(story.linkText)}
                    padding={['10px 10px 7px']}
                  >
                    {story.linkText}
                  </Text>
                </Box>
                {/* Story Title and Short Description */}
                <Box>
                  <Heading
                    as={'h3'}
                    fontSize={['20px']}
                    fontWeight={500}
                    lineHeight={'normal'}
                    color={'#333'}
                    fontFamily={'var(--garnett-medium);'}
                    paddingBottom={'12px'}
                  >
                    {story.title}
                  </Heading>
                  <Text
                    as={'p'}
                    fontSize={['14px']}
                    fontWeight={400}
                    lineHeight={'normal'}
                    color={'#333'}
                    noOfLines={['4']}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                  >
                    {story.description}
                  </Text>
                </Box>
              </Link>
            </GridItem>
          ))}
        </Grid>
        {/* <ImageWithText />
        <ExploreAllStoryTwo /> */}
      </Box>
    </Box>
  );
};

export default ExploreAllStories;
