import React from 'react';
import blue1 from '../../assets/Reusable/LetsWorkToGether/Blue1.svg';
import blue2 from '../../assets/Reusable/LetsWorkToGether/blue2.svg';
import LetsWorktogether from '../Reusable/LetsWorkToGether';

const LetsWorkToGether = () => {
  return (
    <div>
      <LetsWorktogether bgColor="#0D0E4D" hand1Img={blue1} hand2Img={blue2} />
    </div>
  );
};

export default LetsWorkToGether;
