import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Home from '../components/Home/Home';
import About from '../components/About/About';
import Error404 from '../components/Error404/Error404';
import CaseStudyPazazz from '../components/CaseStudy - Pazazz/CaseStudy';
import CaseStudyStrongr from '../components/CaseStudy - Strongr/CaseStudy';
import CaseStudyAngles from '../components/CaseStudy - Angles/CaseStudy';
import CaseStudyThinkVastu from '../components/CaseStudy - ThinkVastu/CaseStudy';
import CaseStudyIROS from '../components/CaseStudy - IROS/CaseStudy';
import Strategy from '../components/Strategy/strategy';
import Solution from '../components/Solutions/Solutions';
import Work from '../components/Work/Work';
import Resources from '../components/Resources/Resources';
import Insights from '../components/Insights/Resources';
import Guides from '../components/Guides/Resources';
import Thoughts from '../components/Thoughts/Resources';
import Glossary from '../components/Glossary/Resources';
import Updates from '../components/Updates/Resources';
import Listenin from '../components/Listenin/Resources';
import Service from '../components/Service/Service/Service';
import ContactUs from '../components/ContactUs/ContactUs';
import Upcoming from '../components/Upcoming/Upcoming';
import SingleInsight from '../components/Blogs - Insight/SingleInsights/InsightSingle';
import GeneralBlog from '../components/Blogs - General/SingleBlog/BlogSingle';
import Partners from '../components/Partners/Partners';
import ToolsAndTech from '../components/ToolsAndTechnologies/ToolsAndTech';
import WhiteLabel from '../components/WhiteLabel/WhiteLabel';
import Career from '../components/Career/Career';
import JobDetail from '../components/Career/JobDetails/JobDetails';
import ThankYouPage from '../components/ThankYouPage/ThankYouPage';
import SingleServicePage from '../components/Service/SingleService/Single';
import UnderConstruction from '../components/UnderConstruction/Construction';
import CaseStudy from '../components/CaseStudy - Strapi/CaseStudy';
import SingleDetailPageNew from '../components/ServicesSingleDetailsPage/SingleService';
import Research from '../components/Research - ServicesDetailPage/SingleService';
import Design from '../components/Design - ServicesDetailPage/SingleService';
import Build from '../components/Build - ServicesDetailPage/SingleService';
import Growth from '../components/Growth - ServicesDetailPage/SingleService';
import SolutionsByOutComesDetailsPage from '../components/SolutionsByOutComesDetailPage/SolutionsByOutComesDetailsPage';
import SolutionByIndustryDetailPage from '../components/SolutionByIndustryDetailPage/SolutionByIndustryDetailPage';
import ServiceXYindustry from '../components/ServiceXYIndustry/ServiceXYindustry';

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/main" element={<Home region="global" />} />
        <Route path="/:region/main" element={<RegionWrapper />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/:region/about-us" element={<RegionWrapperAbout />} />
        <Route path="/work/pazazz" element={<CaseStudyPazazz />} />
        <Route path="/work/think-vastu" element={<CaseStudyThinkVastu />} />
        <Route path="/our-strategy" element={<Strategy />} />
        <Route path="/our-services" element={<Service />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/solutions" element={<Solution />} />
        <Route path="/work" element={<Work />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/insights" element={<Insights />} />
        <Route path="/resources/guides" element={<Guides />} />
        <Route path="/resources/glossary" element={<Glossary />} />
        <Route path="/resources/thoughts" element={<Thoughts />} />
        <Route path="/resources/updates" element={<Updates />} />
        <Route path="/resources/listen" element={<Listenin />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/upcoming" element={<Upcoming />} />
        <Route path="/resources/:category/:slug" element={<SingleInsight />} />
        <Route path="/resources/:slug" element={<GeneralBlog />} />
        <Route path="/work/:slug" element={<CaseStudy />} />
        <Route path="/work/strongr" element={<CaseStudyStrongr />} />
        <Route path="/work/angles" element={<CaseStudyAngles />} />
        <Route path="/work/iros" element={<CaseStudyIROS />} />
        <Route path="/partner-with-us" element={<Partners region="global" />} />
        <Route path="/:region/partner-with-us" element={<RegionWrapperPartners />} />
        <Route path="/tools-and-technologies" element={<ToolsAndTech />} />
        <Route path="/white-label-agency" element={<WhiteLabel />} />
        <Route path="/:region/white-label-agency" element={<RegionWrapperWhiteLabel />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career/:slug" element={<JobDetail />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/service-detail-page" element={<SingleServicePage />} />
        <Route path="/" element={<UnderConstruction />} />
        <Route path="/single-service-new" element={<SingleDetailPageNew />} />
        <Route path="/research/:slug" element={<Research />} />
        <Route path="/design/:slug" element={<Design />} />
        <Route path="/build/:slug" element={<Build />} />
        <Route path="/growth/:slug" element={<Growth />} />
        <Route
          path="/outcome/:slug"
          element={<SolutionsByOutComesDetailsPage />}
        />
        <Route
          path="/industry/:slug"
          element={<SolutionByIndustryDetailPage />}
        />
        <Route path='/serviceX'element={<ServiceXYindustry/>}/>
      </Routes>
    </BrowserRouter>
  );
};

const RegionWrapper = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global'];
  if (validRegions.includes(region)) {
    return <Home region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperAbout = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global'];
  if (validRegions.includes(region)) {
    return <About region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperPartners = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global'];

  if (validRegions.includes(region)) {
    return <Partners region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperWhiteLabel = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global'];

  if (validRegions.includes(region)) {
    return <WhiteLabel region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

export default AllRoutes;
