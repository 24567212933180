import React from 'react'
import OurProcessReuse from '../Reusable/OurProcess/OurProcess'

const OurProcessUse = () => {
  return (
    <div>
      <OurProcessReuse />
    </div>
  );
}

export default OurProcessUse
