import { Box, Container, Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const WhyChooseTenet = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/partner-with-uses?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '48px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    pb: ['22px', '22px', '22px', '30px', '30px', '30px'],
    maxW: '686px',
  };
  const sectionParagraph = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '864px',
  };
  const valueSubtitle = {
    color: '#000',
    fontSize: ['18px', '18px', '18px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: '20px',
  };
  const valueSubText = {
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };
  const valueBox = {
    bg: ['#E9F0FF', '#E9F0FF', '#E9F0FF', '#FFF', 'FFF', '#FFF'],
    p: [
      '25px 19px 30px 8px',
      '25px 19px 30px 8px',
      '25px 19px 30px 8px',
      '0px',
      '0px',
      '0px',
    ],
    w: ['100%', '100%', '100%', '50%', '50%', '50%'],
    mb: ['18px', '18px', '18px', '0px', '0px', '0px'],
  };

  return (
    <Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
      >
        {/* Section Heading */}
        <Box pb={['38px', '38px', '38px', '60px', '60px', '60px']}>
          <Text as={'h2'} sx={sectionH2Heading}>
            {firstItem?.heading}
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            {firstItem?.description}
          </Text>
        </Box>

        {/* Why Choose As Our Tenet */}
        <Box>
          <Box
            maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
            ml={['auto', 'auto', 'auto', 'auto', 'auto', 'auto']}
            p={[
              '0px 0px 0px',
              '0px 0px 0px',
              '0px 0px 0px',
              '60px 0px 0px',
              '60px 0px 0px',
            ]}
          >
            {/* First Row */}
            <Box
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              pb={['0px', '0px', '0px', '60px', '60px', '60px']}
            >
              {/*  Value One */}
              <Flex sx={valueBox}>
                <Box
                  maxW={['424px', '424px', '424px', '424px', '424px', '424px']}
                  ml={['0', '0', '0', 'auto', 'auto', 'auto']}
                >
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.heading_1}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.description_1}
                  </Text>
                </Box>
              </Flex>
              {/* Value Two */}
              <Flex sx={valueBox}>
                <Box
                  maxW={['424px', '424px', '424px', '424px', '424px', '424px']}
                  ml={['0', '0', '0', 'auto', 'auto', 'auto']}
                >
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.heading_2}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.description_2}
                  </Text>
                </Box>
              </Flex>
            </Box>
            {/* Second Row */}
            <Box display={['block', 'block', 'block', 'flex', 'flex', 'flex']}>
              {/*  Value Three */}
              <Flex sx={valueBox}>
                <Box
                  maxW={['424px', '424px', '424px', '424px', '424px', '424px']}
                  ml={['0', '0', '0', 'auto', 'auto', 'auto']}
                >
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.heading_3}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.description_3}
                  </Text>
                </Box>
              </Flex>
              {/* Value Four */}
              <Flex sx={valueBox}>
                <Box
                  maxW={['424px', '424px', '424px', '424px', '424px', '424px']}
                  ml={['0', '0', '0', 'auto', 'auto', 'auto']}
                >
                  <Text as={'h3'} sx={valueSubtitle}>
                    {firstItem?.heading_4}
                  </Text>
                  <Text as={'p'} sx={valueSubText}>
                    {firstItem?.description_4}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WhyChooseTenet;
