import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import fullwidthImage from '../../../assets/CaseStudy/Iros/DesignSolution/FullWithImage.webp';
import colorsDesktop from '../../../assets/CaseStudy/Iros/DesignSolution/colorsDesktop.webp';
import colorsMobile from '../../../assets/CaseStudy/Iros/DesignSolution/colorsMobile.webp';

const DesignSolution = () => {
  return (
    <Box paddingBottom={['80px', '80px', '80px', '150px', '150px', '150px']}>
      {' '}
      {/* Headings and Paragraph Design Solution */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              DESIGN SOLUTION
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={['column']}
              gap={['30px', '30px', '30px', '30px', '30px', '30px']}
              margin={['0 auto']}
            >
              <Text
                as="h1"
                fontSize={['16px', '16px', '24px', '32px', '32px', '32px']}
                fontFamily="var(--garnett-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                New logo that highlights collaboration.
              </Text>
              <Text
                as="h1"
                fontSize={['16px', '16px', '18px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                Focusing on the 4 core values – Innovation, Collaboration,
                Commitment, and Precision – the new IROS brand elements create a
                clear, impactful, and meaningful brand image.
                <br />
                <br />
                Our logo distinctly highlights collaboration at its core,
                blending with our primary brand color of turquoise and the
                typeface Cabinet Grotesk.
                <br />
                <br />
                IROS's visual identity strikingly emphasizes the contemporary
                nature of the brand.
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
      {/* Full Width Image one */}
      <Box
        padding={[
          '40px 0 40px',
          '40px 0 40px',
          '40px 0 40px',
          '24px 0 40px',
          '24px 0 40px',
          '24px 0 40px',
        ]}
      >
        {/* Desktop */}
        <Box>
          <Image width={'100%'} height={'auto'} src={fullwidthImage} />
        </Box>
      </Box>
      {/* Colors Image */}
      <Box maxW={['100%', '100%', '95%', '95%']} margin={'0 auto'}>
        {/* Desktop */}
        <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
          <Image width={'100%'} height={'auto'} src={colorsDesktop} />
        </Box>
        {/* Mobile */}
        <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
          <Image width={'100%'} height={'auto'} src={colorsMobile} />
        </Box>
      </Box>
    </Box>
  );
};

export default DesignSolution;
