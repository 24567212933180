import { Box, Container, Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BenifitsOfwwTnt = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-benefits?populate=*`
        );
        if (response.data.data.length > 0) {
          setData(response.data.data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const sectionH2Heading = {
    fontSize: ['32px', '32px', '32px', '58px', '58px', '58px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    pb: ['22px', '22px', '22px', '22px', '25px', '30px'],
    maxW: ['400px', '400px', '400px', '686px', '686px', '686px'],
    color: '#333',
  };
  const sectionParagraph = {
    fontSize: ['18px', '18px', '18px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: '864px',
    color: '#0057FF',
  };
  const valueNumber = {
    fontSize: ['25px', '25px', '25px', '28px', '28px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#333',
  };
  const valueSubtitle = {
    color: '#000',
    fontSize: ['18px', '24px', '36px', '36px', '36px', '36px'],
    fontFamily: 'var(--garnett-regular)',
    lineHeight: 'normal',
    fontWeight: '400',
    mb: ['12px', '12px', '12px', '18px', '18px'],
  };
  const valueSubText = {
    fontSize: ['14px', '16px', '20px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };
  const valueBox = {
    bg: ['#E9F0FF', '#E9F0FF', '#E9F0FF', '#FFF', 'FFF', '#FFF'],
    // p:['25px 19px 30px 8px','25px 19px 30px 8px','25px 19px 30px 8px','0px','0px','0px'],
    w: ['100%', '100%', '100%', '100%', '100%', '100%'],
    mb: ['18px', '18px', '18px', '0px', '0px', '0px'],
  };

  return (
    <Container
      maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
      m={'0 auto'}
      borderTop={['0px', '0px', '0px', '1px']}
      borderTopColor={'#B5B5B5'}
    >
      <Box
        p={[
          '0px 0 44px',
          '0px 0 44px',
          '0px 0 54px',
          '100px 0 80px',
          '120px 0 90px',
          '150px 0 100px',
        ]}
      >
        {/* Section Heading */}
        <Box pb={['40px', '40px', '40px', '64px', '64px', '64px']}>
          <Text as={'h2'} sx={sectionH2Heading}>
            Benefits of working with Tenet.
          </Text>
          <Text as={'p'} sx={sectionParagraph}>
            It’s a mix of these things:
          </Text>
        </Box>

        {/* Why Choose As Our Tenet */}
        <Box>
          <Box
            maxW={['100%', '100%', '80%', '80%', '80%', '80%']}
            ml={['0', '0', '0', 'auto', 'auto', 'auto']}
          >
            {/* First Row */}
            <Box
              display={['grid', 'grid', 'grid', 'grid']}
              gridTemplateColumns={[
                'repeat(1,1fr)',
                'repeat(2,1fr)',
                'repeat(2,1fr)',
              ]}
              columnGap={['20px', '30px', '68px', '68px']}
              rowGap={['0']}
            >
              {data.map((item, index) => {
                const { heading, description } = item.attributes;
                return (
                  <Flex key={index} sx={{ marginBottom: '36px' }}>
                    <Flex
                      maxW={[
                        '100%',
                        '100%',
                        '424px',
                        '606px',
                        '606px',
                        '606px',
                      ]}
                      ml={'auto'}
                      borderBottom={'1px'}
                      borderBottomColor={'#B5B5B5'}
                      pb={['21px', '21px', '21px', '36px', '36px', '36px']}
                    >
                      <Box w={['82px', '82px', '15%', '15%', '15%', '15%']}>
                        <Text as={'span'} sx={valueNumber}>{`0${
                          index + 1
                        }`}</Text>
                      </Box>
                      <Box w={'85%'}>
                        <Text as={'h3'} sx={valueSubtitle}>
                          {heading}
                        </Text>
                        <Text as={'p'} sx={valueSubText}>
                          {description}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default BenifitsOfwwTnt;
