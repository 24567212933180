import { Box, Heading, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const LetsDisccusion = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  return (
    <Box padding={['107px 0']} bg={'#000000'}>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '68%', '68%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
      >
        <Box textAlign={'center'}>
          <Heading
            as={'h2'}
            fontSize={['24px', '24px', '24px', '48px', '48px', '48px']}
            fontFamily={' var(--garnett-semibold);'}
            fontWeight={'600'}
            lineHeight={'normal'}
            color={'#fff'}
          >
            {firstItem?.heading}
          </Heading>
          <Text
            as="p"
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['14px', '14px', '16px', '20px', '24px', '24px']}
            color={'#fff'}
            fontWeight={'400'}
            lineHeight={'normal'}
            padding={[
              '32px 0 28px',
              '32px 0 28px',
              '32px 0 28px',
              '40px 0 40px',
              '40px 0 40px',
            ]}
          >
            {firstItem?.description}
          </Text>
          <Link
            href="#"
            backgroundColor={'#0057FF'}
            color={'#fff'}
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['14px', '14px', '16px', '18px', '20px', '20px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            padding={['14px 14px 14px 14px']}
          >
            Let’s initiate the Discussion
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default LetsDisccusion;
