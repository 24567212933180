import React from 'react';
import UseClientSaysLoveSlider from '../Reusable/ClientSaysSliderHomeAndWhiteLabel/ClientSaysSliderHomeAndWhiteLabel';

const CleintSaysSliderUse = () => {
  return (
    <div>
      <UseClientSaysLoveSlider
        sectionBgColor={'#0D0E4D'}
        sectionTitle="What our client’s has to say about us"
        sectionParagraph="We’re not just Developers. We’re Solutions Specialists. We create Digital Products, which work as solutions to the client’s requirements."
        cardBgColorGreen="blue"
      />
    </div>
  );
};

export default CleintSaysSliderUse;
