import { Box } from '@chakra-ui/react';
import React from 'react';
import OurLeading from '../Reusable/CTA/OurLeading';

const OurLeadingUse = () => {
  return (
    <Box>
      <OurLeading linkBtnText="Get in Touch" />
    </Box>
  );
};

export default OurLeadingUse;
