import {
  Box,
  Container,
  Image,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';

const Approach = () => {
  const eowData = [
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/6.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/7.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/8.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/9.webp',
    },
    {
      image: 'https://d3us4ynq1qsik6.cloudfront.net/work/angles/slider/10.webp',
    },
  ];
  const swipperSliderCustomeStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };

  return (
    <Container
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['50px', '50px', 0, 0, 0, 0]}
      padding={['0']}
    >
      <Box
        width={['100%', '100%', '90%', '80%', '70%', '72%']}
        maxW={['100%', '100%', '90%', '80%', '70%', '72%']}
        margin={'0 auto'}
      >
        <Box
          paddingTop={['45px', '45px', '65px', '85px', '105px', '115px']}
          paddingBottom={['45px', '45px', '65px', '85px', '105px', '115px']}
          _hover={{ cursor: `url(${arrowImage}), auto` }}
          background={['#F9E5AD']}
        >
          <Swiper
            ref={swiperRef}
            observeParents={true}
            observer={true}
            modules={[Navigation, FreeMode]}
            speed={750}
            slidesPerView={4.5}
            spaceBetween={24}
            style={swipperSliderCustomeStyle}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                spaceBetween: 20,
                freeMode: false,
              },
              480: {
                slidesPerView: 2.6,
                spaceBetween: 20,
                freeMode: false,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
            onSlideChange={swiper => handleSlideChange(swiper)}
          >
            {eowData.map((data, index) => (
              <SwiperSlide key={index}>
                <Box>
                  <Image src={data.image} w={'100%'} objectFit={'contain'} />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
      {/* Text */}

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          paddingTop={['26px', '28px', '30px', '30px', '30px', '32px']}
          marginBottom={['40px', '40px', '40px', '80px', '80px', '80px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              APPROACH
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              as="p"
              fontSize={['16px', '16px', '16px', '28px', '28px', '28px']}
              fontFamily={'var(--foundersGrotesk-regular);'}
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              After our in-depth discovery sessions with the founders, we
              suggested a plan of action which included conducting market
              research, designing and developing their digital platforms,
              setting up strong sales and conversion funnels, ensuring a smooth
              user experience for customers and the backend store management
              team, and integrating data analytics for better business insights.
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default Approach;
