import {
  Box,
  Heading,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Content = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-openings?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const blogHeading = {
    fontFamily: 'var(--garnett-regular)',
    fontSize: ['32px', '32px', '32px', '32px', '34px', '36px'],
    fontWeight: '400',
    lineHeight: 'normal',

    color: '#333333',
  };

  const contentText = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    pb: ['0'],
    // pb: ['40px', '40px', '40px', '32px', '32px', '32px'],
    color: '#333',
  };

  // Function to render content dynamically
  const renderContent = contentArray => {
    return contentArray.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <Text as="p" sx={contentText} key={index}>
            {item.children.map((child, childIndex) => child.text)}
          </Text>
        );
      } else if (item.type === 'list' && item.format === 'unordered') {
        return (
          <UnorderedList sx={contentText} key={index}>
            {item.children.map((listItem, listItemIndex) => (
              <ListItem key={listItemIndex}>
                {listItem.children.map(
                  (listChild, listChildIndex) => listChild.text
                )}
              </ListItem>
            ))}
          </UnorderedList>
        );
      } else {
        return null;
      }
    });
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text color="red">{error}</Text>;
  }

  return (
    <Box>
      {/* Display blog Title */}
      {firstItem && (
        <>
          <Box>
            <Heading
              as={'h2'}
              sx={blogHeading}
              pb={['22px', '22px', '24px', '26px', '28px', '31px']}
            >
              {firstItem.heading_1}
            </Heading>
          </Box>
          <Box paddingBottom={['40px', '40px', '40px', '32px', '32px']}>
            {renderContent(firstItem.description_1)}
          </Box>

          <Box pb={['22px', '22px', '22px', '23px', '23px', '23px']}>
            <Heading as={'h2'} sx={blogHeading}>
              {firstItem.heading_2}
            </Heading>
          </Box>
          <Box paddingBottom={['40px', '40px', '40px', '32px', '32px']}>
            {renderContent(firstItem.description_2)}
          </Box>
          <Box pb={['22px', '22px', '22px', '23px', '23px', '23px']}>
            <Box>
              <Heading
                as={'h2'}
                sx={blogHeading}
                pb={['22px', '22px', '22px', '23px', '23px', '23px']}
              >
                {firstItem.heading_3}
              </Heading>
            </Box>
            <Box>{renderContent(firstItem.description_3)}</Box>
          </Box>

          <Box>
            <Heading
              as={'h2'}
              sx={blogHeading}
              pb={['22px', '22px', '22px', '23px', '23px', '23px']}
            >
              {firstItem.heading_4}
            </Heading>
          </Box>
          <Box>{renderContent(firstItem.description_4)}</Box>
        </>
      )}
    </Box>
  );
};

export default Content;
