import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Banner from './banner';
import Filter from './filter';
// import FeaturedStory from './featuredStory';
import ExploreAllStory from './exploreAllStory';
import LetsWorkTogether from './LetsWorkToGether';
import Search from './search';

const Resources = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showInitialContent, setShowInitialContent] = useState(true); // New state to control UI

  const handleSearch = query => {
    setSearchQuery(query);
    setShowInitialContent(query === ''); // Show initial content if input is cleared
  };

  const handleInputChange = value => {
    setSearchQuery(value);
    setShowInitialContent(value === ''); // Also control UI here
  };
  return (
    <Box>
      <Banner />
      <Filter />
      {/* <FeaturedStory /> */}
      {showInitialContent ? (
        <>
          <ExploreAllStory />
        </>
      ) : (
        <Search searchQuery={searchQuery} />
      )}

      <LetsWorkTogether />
    </Box>
  );
};

export default Resources;
