import { Box } from '@chakra-ui/react';
import React from 'react';
import Details from './Details';
import HeroBanner from './HeroBanner';
import Marquee from './Marquee';

const Partners = () => {
  return (
    <Box>
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <Marquee />
      </Box>
      <Box>
        <Details />
      </Box>
      
    </Box>
  );
};

export default Partners;
