import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import downicon from '../../assets/Icons/SolutionAndIndustryDetailPageIcon/downicon.svg';
import upicon from '../../assets/Icons/SolutionAndIndustryDetailPageIcon/upicon.svg';

// Dynamic data
// const tabData = [
//   {
//     image: image1,
//     title: 'Award Winning Team',
//     content:
//       'Our diverse team of experts, handpicked for their unique skills, is unified under one roof to keep you at the forefront of innovation.Our diverse team of experts, handpicked for their unique skills, is unified under one roof to keep you at the forefront of innovation.',
//   },
//   {
//     image: image2,
//     title: 'Strategic Partnership for Growth',
//     content:
//       'Our diverse team of experts, handpicked for their unique skills, is unified under one roof to keep you at the forefront of innovation.',
//   },
//   {
//     image: image3,
//     title: 'End-to-End White Glove Service',
//     content:
//       'Our diverse team of experts, handpicked for their unique skills, is unified under one roof to keep you at the forefront of innovation.',
//   },
//   {
//     image: image4,
//     title: 'Humanizing the Digital Experience',
//     content:
//       'Our diverse team of experts, handpicked for their unique skills, is unified under one roof to keep you at the forefront of innovation.',
//   },
// ];

const convertRichTextToPlainText = richText => {
  if (!Array.isArray(richText)) return '';

  return richText
    .map(block => block.children.map(child => child.text).join(''))
    .join('\n');
};

const OurCapabilities = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;

  const tabData = firstItem
    ? [
        {
          title: firstItem.capabilities_point_1_heading,
          content: firstItem.capabilities_point_1_description,
          image: firstItem.capabilities_point_1_image?.data?.attributes?.url,
        },
        {
          title: firstItem.capabilities_point_2_heading,
          content: firstItem.capabilities_point_2_description,
          image: firstItem.capabilities_point_2_image?.data?.attributes?.url,
        },
        {
          title: firstItem.capabilities_point_3_heading,
          content: firstItem.capabilities_point_3_description,
          image: firstItem.capabilities_point_3_image?.data?.attributes?.url,
        },
        {
          title: firstItem.capabilities_point_4_heading,
          content: firstItem.capabilities_point_4_description,
          image: firstItem.capabilities_point_4_image?.data?.attributes?.url,
        },
      ]
    : [];

  // Tab
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [prevTabIndex, setPrevTabIndex] = useState(0);

  // Mobile
  const [activeIndex, setActiveIndex] = useState(0); // Start with the first accordion open by default

  const handleToggle = index => {
    // Ensure at least one accordion remains open
    if (index !== activeIndex) {
      setActiveIndex(index);
    }
  };

  const [isLargerThan992] = useMediaQuery('(max-width: 992px)');

  const handleTabClick = index => {
    if (activeTabIndex !== index) {
      setPrevTabIndex(activeTabIndex);
      setActiveTabIndex(index);

      // Delay the state update to allow transition to complete
      setTimeout(() => {
        setPrevTabIndex(index);
      }, 100); // Ensure this matches the duration of the transition
    }
  };

  const capTitle = {
    fontSize: ['20px', '20px', '20px', '20px', '24px', '28px'],
    fontWeight: '600',
    lineHeight: 'normal',
    fontFamily: 'var(--garnett-semibold)',
    textAlign: 'left',
  };

  const tabDescription = {
    fontFamily: 'var(--foundersGrotesk-regular)',
    lineHeight: 'normal',
    color: '#fff',
    fontWeight: '400',
    fontSize: ['16px', '16px', '16px', '16px', '20px', '20px'],
    letterSpacing: ['-0.304px', '-0.38px'],
    padding: ['0 25px', '0 25px', '0 25px', '0 25px', '0'],
    textAlign: 'left',
  };

  return (
    <Box
      bg={'#3E001A'}
      padding={['76px 0 40px', '76px 0 40px', '76px 0 40px', '150px 0 120px']}
    >
      <Box
        maxW={['100%', '100%', '100%', '92%', '92%', '92%']}
        mx={'auto'}
        px={['20px', '20px', '20px', '0px']}
      >
        {/* Section Headings */}
        <Box
          maxW={['660px', '660px', '660px', '1028px', '1028px', '1028px']}
          margin={'0 auto'}
          textAlign={'center'}
          paddingBottom={['10px', '10px', '10px', '50px', '50px']}
        >
          <Heading
            as={'h2'}
            color={'#FFF'}
            fontFamily={'var(--garnett-semibold);'}
            fontSize={['32px', '32px', '40px', '50px', '58px', '58px']}
            fontWeight={600}
            lineHeight={'normal'}
            textTrim={'both'}
            textEdge={'cap'}
            maxW={['640px', '640px', '660px', '862px', '962px', '962px']}
            margin={'0 auto'}
          >
            {firstItem?.capabilities_heading}
          </Heading>
          <Text
            as={'p'}
            color={'#FFF'}
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['18px', '22px', '24px', '28px']}
            fontWeight={400}
            lineHeight={'normal'}
            mt={['35px', '35px', '20px', '20px']}
          >
            {firstItem?.capabilities_description}
          </Text>
          <Box
            textAlign={'center'}
            display={['none', 'none', 'none', 'block', 'block', 'block']}
          >
            <Link
              href="#"
              style={{
                backgroundColor: '#00763D',
                color: '#FFFFFF',
                fontSize: ['12px', '12px', '16px', '16px'],
                fontFamily: 'var(--foundersGrotesk-regular)',
                fontWeight: '400',
                display: 'inline-block',
                lineHeight: 'normal',
                border: '2px solid #fff',
                marginTop: ['32px'],
              }}
              padding={[
                '7px 14px 3px',
                '7px 14px 3px',
                '11px 14px 1px',
                '11px 14px 1px',
              ]}
              height={'42px'}
            >
              Request Consultation
            </Link>
          </Box>
        </Box>

        {/* For Desktp */}
        {/* Tabs */}
        {!isLargerThan992 ? (
          <Tabs index={activeTabIndex} onChange={handleTabClick}>
            <Box
              display={['flex']}
              gap={['10px', '10px', '15px', '30px', '30px', '70px']}
              justifyContent={['space-between']}
              flexDirection={['column', 'row']}
            >
              {/* Image According To Tab Selected */}
              <Box width={['100%', '50%']} maxW={['670px']}>
                <TabPanels padding={'0'}>
                  {tabData.map((tab, index) => (
                    <TabPanel key={index} padding={'0'}>
                      <AspectRatio ratio={1.22}>
                        <Image
                          w={'100%'}
                          h={'100%'}
                          objectFit={'cover'}
                          src={`https://cms.wearetenet.com${tab.image || ''}`}
                        />
                      </AspectRatio>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Box>
              {/* Tab List With Accordian */}
              <Box width={['100%', '50%']} maxW={['648px']}>
                <TabList display={'block'} w={'100%'} border={'0'}>
                  {tabData.map((tab, index) => (
                    <Tab
                      key={index}
                      w={'100%'}
                      border={'0'}
                      padding={'0'}
                      onClick={() => handleTabClick(index)}
                      _focus={{ boxShadow: 'none', outline: 'none' }} // Disable focus outline
                      variant="unstyled"
                      display={'block'}
                    >
                      <Box
                        borderBottom={
                          index !== tabData.length - 1
                            ? '1px solid #C1C1C1'
                            : '1px solid #C1C1C1'
                        }
                        paddingTop={[
                          index === 0 ? '0px' : '5px', // For the smallest viewport size
                          index === 0 ? '0px' : '10px', // For medium viewport sizes
                          index === 0 ? '0px' : '15px', // For larger viewport sizes
                          index === 0 ? '0px' : '20px', // For the largest viewport sizes
                          index === 0 ? '0px' : '32px', // For the largest viewport sizes
                          index === 0 ? '0px' : '32px', // For the largest viewport sizes
                        ]}
                        paddingBottom={[
                          '5px',
                          '10px',
                          '15px',
                          '20px',
                          '32px',
                          '32px',
                        ]}
                        cursor="pointer"
                      >
                        <Flex alignItems={'center'}>
                          <Box
                            mr={['24px', '24px', '24px', '38px']}
                            minW={['45px']}
                          >
                            <Text
                              as={'span'}
                              color={
                                activeTabIndex === index ? '#B882FF' : '#fff'
                              }
                              fontSize={[
                                '20px',
                                '24px',
                                '26px',
                                '28px',
                                '36px',
                                '36px',
                              ]}
                              fontWeight={'400'}
                              lineHeight={'normal'}
                              fontFamily={'var(--garnett-regular);'}
                              letterSpacing={'-0.792px'}
                            >
                              {index + 1}.
                            </Text>
                          </Box>
                          <Box>
                            <Text
                              as={'h3'}
                              sx={capTitle}
                              color={
                                activeTabIndex === index ? '#B882FF' : '#fff'
                              }
                            >
                              {tab.title}
                            </Text>
                          </Box>
                          <Box ml="auto">
                            <Image
                              src={activeTabIndex === index ? upicon : downicon}
                              alt={
                                activeTabIndex === index
                                  ? 'Open Icon'
                                  : 'Close Icon'
                              }
                            />
                          </Box>
                        </Flex>

                        {/* Accordion Panel - Slide Down (Slow) and Slide Up (Fast) */}
                        <Box
                          maxHeight={activeTabIndex === index ? '1000px' : '0'}
                          overflow="hidden"
                          // opacity={activeTabIndex === index ? 1 : 0}
                          display={activeTabIndex === index ? 'block' : 'none'}
                          padding={[
                            activeTabIndex === index
                              ? '10px 0px 0px 88px' // Smallest viewport size
                              : '0px 0px 0px 88px', // Smallest viewport size
                            activeTabIndex === index
                              ? '10px 0px 0px 88px' // Medium viewport size
                              : '0px 0px 0px 88px', // Medium viewport size
                            activeTabIndex === index
                              ? '10px 0px 0px 88px' // Larger viewport size
                              : '0px 0px 0px 88px', // Larger viewport size
                            activeTabIndex === index
                              ? '25 px 0px 0px 88px' // Largest viewport size
                              : '0px 0px 0px 88px', // Largest viewport size
                            activeTabIndex === index
                              ? '25px 0px 0px 88px' // Largest viewport size
                              : '0px 0px 0px 88px', // Largest viewport size
                          ]}
                          width={['100%']}
                        >
                          <Text as={'p'} sx={tabDescription}>
                            {tab.content}
                          </Text>
                        </Box>
                      </Box>
                    </Tab>
                  ))}
                </TabList>
              </Box>
            </Box>
          </Tabs>
        ) : (
          <Box>
            <Accordion
              index={activeIndex}
              onChange={handleToggle}
              allowToggle={false}
            >
              {tabData.map((tab, index) => (
                <AccordionItem
                  key={index}
                  borderBottom={
                    index !== tabData.length - 1
                      ? '1px solid #C1C1C1'
                      : '1px solid #C1C1C1'
                  }
                  padding={['32px 0']}
                >
                  <AccordionButton padding={'0'}>
                    <Flex alignItems={'start'} w="100%" cursor={'pointer'}>
                      <Box
                        mr={['12px', '12px', '12px', '12px']}
                        minW={['15px']}
                      >
                        <Text
                          as={'span'}
                          color={index === activeIndex ? '#B882FF' : '#fff'}
                          fontSize={[
                            '20px',
                            '24px',
                            '26px',
                            '28px',
                            '36px',
                            '36px',
                          ]}
                          fontWeight={'400'}
                          lineHeight={'normal'}
                          fontFamily={'var(--garnett-regular);'}
                          letterSpacing={'-0.792px'}
                        >
                          {index + 1}.
                        </Text>
                      </Box>
                      <Box flex={'1'}>
                        <Text
                          as={'h3'}
                          sx={capTitle}
                          color={index === activeIndex ? '#B882FF' : '#fff'}
                        >
                          {tab.title}
                        </Text>
                      </Box>
                      <Box ml="auto">
                        <Image
                          src={activeIndex === index ? upicon : downicon}
                          alt={
                            activeIndex === index ? 'Open Icon' : 'Close Icon'
                          }
                        />
                      </Box>
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel padding={'0'}>
                    <Flex direction={'column'}>
                      <Text as={'p'} sx={tabDescription} mt={['5px']}>
                        {tab.content}
                      </Text>
                      <AspectRatio ratio={1.22} mt={'15px'}>
                        <Image
                          w={'100%'}
                          h={'100%'}
                          objectFit={'cover'}
                          src={`https://cms.wearetenet.com${tab.image || ''}`}
                        />
                      </AspectRatio>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        )}

        {/* For Mobile == > Display Only Accordin No display mobile */}
      </Box>
    </Box>
  );
};

export default OurCapabilities;
