import {
  Box,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import image1 from '../../assets/SingleServicesPage/TextWithImg/1.webp';
import image2 from '../../assets/SingleServicesPage/TextWithImg/2.webp';

const textWithImgData = [
  {
    heading: 'How Can UX Design Increase User Engagement?',
    paragraphBeforeList:
      "An engaging user experience is essential for capturing and retaining users' attention. When design, functionality, and content harmonize, users are drawn in and eager to explore more. But there's more to a captivating UX than meets the eye.",
    listItems: [
      'Intuitive Navigation: Users can easily find what they need without frustration.',
      'Seamless Interactions: Smooth transitions and actions keep users engaged.',
      'Appealing Visuals: Aesthetic design elements attract and maintain interest.',
      "Personalized Experiences: Tailored content speaks directly to users' needs.",
      'Interactive Features: Engaging tools and elements make the experience memorable.',
    ],
    paragraphAfterList:
      'There are many elements that contribute to effective UX design. A great user experience checks all the boxes, keeping users coming back for more.',
    imageSrc: image1,
  },
  {
    heading: 'Optimized UX Boosts Conversion Rates?',
    paragraphBeforeList: `An engaging user experience is essential for capturing and retaining users' attention. When design, functionality, and content harmonize, users are drawn in and eager to explore more. But there's more to a captivating UX than meets the eye.`,
    listItems: [
      'Intuitive Navigation: Users can easily find what they need without frustration.',
      'Seamless Interactions: Smooth transitions and actions keep users engaged.',
      'Appealing Visuals: Aesthetic design elements attract and maintain interest.',
      `Personalized Experiences: Tailored content speaks directly to users' needs.`,
      'Interactive Features: Engaging tools and elements make the experience memorable.',
    ],
    paragraphAfterList:
      'There are many elements that contribute to effective UX design. A great user experience checks all the boxes, keeping users coming back for more.',
    imageSrc: image2,
  },
];

const TextWithImg = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=growth&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData.attributes);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const textWithImgData = [
    {
      heading: data.main_heading_1,
      paragraphBeforeList: data.main_description_1.find((item) => item.type === 'paragraph').children[0].text,
      listItems: data.main_description_1.find((item) => item.type === 'list').children.map((listItem) => listItem.children[0].text),
      paragraphAfterList: data.main_description_1[2]?.children[0]?.text,
      imageSrc: `https://cms.wearetenet.com${data.main_image_1?.data?.attributes?.url || ''}`
    },
    {
      heading: data.main_heading_2,
      paragraphBeforeList: data.main_description_2.find((item) => item.type === 'paragraph').children[0].text,
      listItems: data.main_description_2.find((item) => item.type === 'list').children.map((listItem) => listItem.children[0].text),
      paragraphAfterList: data.main_description_2[2]?.children[0]?.text,
      imageSrc: `https://cms.wearetenet.com${data.main_image_2?.data?.attributes?.url || ''}`
    }
  ];

  const heading = {
    fontFamily: 'var(--garnett-semibold);',
    color: '#111',
    fontSize: ['32px', '32px', '32px', '26px', '31px', '39px'],
    lineHeight: 'normal',
    fontWeight: '600',
    padding: ['66px 0 0px 0 ', '66px 0 0px 0 ', '66px 0 0px 0 ', '0'],

    position: 'relative', // Ensure the heading is positioned relative for the pseudo-element
    _after: {
      content: '""',
      position: 'absolute',
      width: '85px',
      height: '4px',
      backgroundColor: '#B882FF',
      bottom: '-10px',
      left: '47px',
      transform: 'translateX(-50%)',
    },
  };

  const paragraph = {
    color: '#333',
    fontSize: ['16px', '16px', '16px', '20px', '20px'],
    fontWeight: '400',
    fontFamily: 'var(--foundersGrotesk-regular);',
    lineHeight: 'normal',
    padding: [
      '62px 0 24px',
      '62px 0 24px',
      '62px 0 24px',
      '40px 0 30px',
      '40px 0 30px',
      '40px 0 30px',
    ],
  };
  const paragraph2 = {
    color: '#333',
    fontSize: ['16px', '16px', '16px', '20px', '20px'],
    fontWeight: '400',
    fontFamily: 'var(--foundersGrotesk-regular);',
    lineHeight: 'normal',
  };
  const listStyle = {
    color: '#333',
    fontSize: ['16px', '16px', '16px', '20px', '20px'],
    fontWeight: '400',
    fontFamily: 'var(--foundersGrotesk-regular);',
    lineHeight: 'normal',
  };

  return (
    <Box
      maxW={['100%', '100%', '100%', '84%', '84%', '84%']}
      margin="0 auto"
      padding={['0px 20px', '0px 20px', '0px 20px', '0']}
    >
      {textWithImgData.map((item, index) => (
        <Box
          key={index}
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          flexDirection={index % 2 === 0 ? 'row' : 'row-reverse'}
          justifyContent={['space-between']}
          gap={['20px', '40px', '40px', '50px', '70px', '70px']}
          margin={[
            '80px 0 63px',
            '80px 0 63px',
            '80px 0 63px',
            '100px 0',
            '100px 0',
          ]}
        >
          {/* Image Box */}

          <Box
            w={
              index % 2 === 0
                ? ['100%', '100%', '100%', '49%', '49%', '49%']
                : ['100%', '100%', '100%', '45%', '45%', '45%']
            }
          >
            <Image
              src={item.imageSrc}
              width="100%"
              height="auto"
              objectFit={'cover'}
            />
          </Box>
          {/* Text Box */}
          <Box
            w={
              index % 2 === 0
                ? ['100%', '100%', '100%', '45%', '45%', '45%']
                : ['100%', '100%', '100%', '49%', '49%', '49%']
            }
          >
            {/* Headings Before List */}
            <Box>
              <Heading as="h2" sx={heading}>
                {item.heading}
              </Heading>
              <Text as="p" sx={paragraph}>
                {item.paragraphBeforeList}
              </Text>
            </Box>
            {/* List */}
            <UnorderedList mb={0} sx={listStyle}>
              {item.listItems.map((listItem, i) => (
                <ListItem
                  key={i}
                  paddingBottom={
                    i !== textWithImgData[index].listItems.length - 1
                      ? ['6px', '6px', '6px', '6px', '6px']
                      : ['0']
                  }
                >
                  {listItem}
                </ListItem>
              ))}
            </UnorderedList>
            {/* After List Paragraph */}
            <Box paddingTop={['30px', '30px', '30px', '30px', '30px', '30px']}>
              <Text as="p" sx={paragraph2}>
                {item.paragraphAfterList}
              </Text>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TextWithImg;
