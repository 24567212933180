import React, { useEffect, useRef } from 'react';
import { Box, Text, Container, Image, Flex } from '@chakra-ui/react';
import LeftImg from '../../assets/About/endtoendproject/4.svg';
import RightImg from '../../assets/About/endtoendproject/2.svg';
import img5 from '../../assets/About/endtoendproject/5.svg';
import Mobile from '../../assets/About/endtoendproject/Mobile/mobile.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const EndToEndProductTeam = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.set('.img5', { x: '-120%' });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top 1%',
        end: 'bottom center',
        scrub: true,
      },
    });

    tl.to('.img5', { x: '120%', duration: 3, ease: 'power1.inOut' });
  }, []);

  return (
    <Box
      ref={containerRef}
      bg={'#0057FF'}
      p={[
        '68px 0 68px 0',
        '68px 0 68px 0',
        '68px 0 68px 0',
        '160px 0 270px 0',
        '160px 0 270px 0',
        '160px 0 270px 0',
      ]}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Texts Heading */}
        <Box>
          <Text
            as={'h2'}
            maxW={['309px', '309px', '509px', '1076px', '1076px', '1076px']}
            m={'0 auto'}
            textAlign={'center'}
            color={'#fff'}
            fontSize={['24px', '24px', '24px', '58px', '58px', '58px']}
            fontWeight={'600'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-semibold)'}
          >
            Stop managing multiple agencies for different digital services.
            Tenet serves you End to End.
          </Text>
        </Box>
        <Box>
          <Text
            as={'p'}
            maxW={['341px', '341px', '441px', '622px', '622px', '622px']}
            pt={['27px', '27px', '33px', '43px', '43px', '43px']}
            pb={['40px', '30px', '30px']}
            color={'#fff'}
            m={'0 auto'}
            textAlign={'center'}
            fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular)'}
          >
            From a sketch on a tissue paper, to real shipped product. Have a
            great new product idea and nobody to start building it? We’re your
            team.
          </Text>
        </Box>
        <Box>
          {/* Desktop Image */}
          <Box display={['none', 'none', 'none', 'block', 'block', 'block']}>
            <Flex
              display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
              justifyContent={'space-between'}
              position={[
                'relative',
                'relative',
                'relative',
                'absolute',
                'absolute',
                'absolute',
              ]}
              w={'100%'}
              bottom={['0px', '0px', '0px', '-180px', '-180px', '-180px']}
              left={'0'}
              right={'0'}
              m={'auto'}
              alignItems={['end']}
            >
              <Image src={LeftImg} w={'22%'} />
              <Image src={img5} w={'15%'} className="img5" />
              <Image src={RightImg} w={'25%'} />
            </Flex>
          </Box>
          {/* Mobile Image */}
          <Box display={['block', 'block', 'block', 'none', 'none', 'none']}>
            <Image src={Mobile} w={'100%'} h={'100%'} objectFit={'cover'} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default EndToEndProductTeam;
