import { Box, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import hand1 from '../../assets/Reusable/LetsWorkToGether/Blue1.svg';
import hand2 from '../../assets/Reusable/LetsWorkToGether/blue2.svg';

const LetsWorkToGether = ({
  bgColor = '#30054A',
  hand1Img = hand1,
  hand2Img = hand2,
}) => {
  const title = {
    fontSize: ['24px', '28px', '34px', '38px', '48px', '48px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: ['243px', '340px', '440px', '100%', '100%', '100%'],
    m: '0 auto',
  };

  const paragraph = {
    // orginal
    // p: [
    //   '26px 0 22px',
    //   '26px 0 22px',
    //   '26px 0 22px',
    //   '32px 0 40px',
    //   '32px 0 40px',
    //   '32px 0 40px',
    // ],
    p: [
      '26px 0 13px',
      '26px 0 13px',
      '26px 0 22px',
      '32px 0 56px',
      '32px 0 56px',
      '32px 0 56px',
    ],
    fontSize: ['14px', '14px', '14px', '20px', '20px', '20px'],
    maxW: ['253px', '253px', '253px', '505px', '505px', '505px'],
    m: '0 auto',
    fontWeight: '400',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };

  const link = {
    fontSize: ['16px', '16px', '16px', '66px', '66px', '66px'],
    fontWeight: '600',
    textDecoration: 'underline',
    fontFamily: 'var(--garnett-semibold)',
    lineHeight: 'normal',
    color: '#fff',
    display: 'inline-block',
  };

  return (
    <Box
      bg={bgColor}
      p={[
        '48px 0 35px',
        '48px 0 35px',
        '52px 0 35px',
        '128px 0 104px',
        '128px 0 104px',
        '128px 0 104px',
      ]}
      position={'relative'}
      maxH={['260px', '260px', '475px', '475px', '475px', '475px']}
      minH={['243px']}
    >
      <Box h={'0'}>
        <Image
          src={hand1Img}
          w={['157px', '190px', '257px', '375px', '447px', '447px']}
          h={'auto'}
          position={'absolute'}
          top={'0'}
          left={'0'}
        />
      </Box>
      <Box
        maxW={['100%', '780px']}
        position={'relative'}
        zIndex={'1'}
        m={'0 auto'}
        textAlign={'center'}
        color={'#fff'}
      >
        <Text as={'h2'} sx={title}>
          Got an idea on your mind?
        </Text>
        <Text as={'p'} sx={paragraph}>
          We’d love to hear about your brand, your visions, current challenges,
          even if you’re not sure what your next step is.
        </Text>
        <Link to={'/'} sx={link} className="textDecoration"
        >
          Let’s talk
        </Link>
      </Box>
      <Box h={'0'}>
        <Image
          src={hand2Img}
          w={['83px', '116px', '183px', '275px', '330px', '330px']}
          maxH={['321px']}
          position={'absolute'}
          right={'0'}
          bottom={'0'}
        />
      </Box>
    </Box>
  );
};

export default LetsWorkToGether;
