import { Box, Center, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Countup from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const Counter = ({ customMarginTop }) => {
  const [counterOn, setCounterOn] = useState(false);

  const defaultMarginTop = ['0'];
  const finalMarginTop = customMarginTop || defaultMarginTop;
  const counterNumCss = {
    color: 'white',
    marginBottom: '12px',
    fontFamily: 'var(--garnett-semibold)',
    textAlign: 'Center',
  };
  const conterNumTitle = {
    color: '#fff',
    m: '0px',
    textAlign: 'Center',
  };
  const counterNumDescription = {
    color: '#fff',
    textAlign: 'Center',
  };
  return (
    <Box
      bg="#000000"
      padding={[
        '54px 0 62px 0',
        '54px 0 62px 0',
        '54px 0 62px 0',
        '65px 0 72px 0',
        '75px 0 82px 0',
        '75px 0 82px 0',
      ]}
      marginTop={finalMarginTop}
    >
      <Box marginBottom={['50px', '50px', '45px', '40px', '50px', '50px']}>
        <Center>
          <Text
            as="h2"
            fontSize={['24px', '24px', '24px', '28px', '32px', '36px']}
            color="#fff"
            fontFamily="var(--foundersGrotesk-regular)"
            fontWeight="400"
          >
            Impact Delivered
          </Text>
        </Center>
      </Box>
      <ScrollTrigger onEnter={() => setCounterOn(true)}>
        {counterOn && (
          <Box
            maxW="990px"
            m="0 auto"
            display={['flex']}
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={[
              'space-between',
              'space-between',
              'space-between',
              'space-between',
              'space-between',
              'space-between',
            ]}
            width={['75%', '75%', '75%', '90%', '100%', '100%']}
          >
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['20px', '20px', '20px', '20px', '20px', '20px']}
              flexWrap="wrap"
              alignItems={[
                'flex-start',
                'flex-start',
                'baseline',
                'baseline',
                'baseline',
                'baseline',
              ]}
            >
              <Box flex="1">
                <Text
                  as="h2"
                  sx={counterNumCss}
                  fontSize={['48px', '48px', '48px', '48px', '64px', '64px']}
                >
                  <Countup start={0} end={450} duration={2} delay={0.5} />+
                </Text>
                <Text
                  as="h3"
                  sx={conterNumTitle}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                >
                  Solutions Delivered
                </Text>
                <Text
                  as="p"
                  sx={counterNumDescription}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['16px', '16px', '16px', '16px', '18px', '18px']}
                  position={'relative'}
                  top={['0', '0', '0', '-8px', '-8px', '-8px']}
                >
                  for our clients & partners
                </Text>
              </Box>
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['20px', '20px', '20px', '20px', '20px', '20px']}
              flexWrap="wrap"
              alignItems={[
                'flex-end',
                'flex-end',
                'baseline',
                'baseline',
                'baseline',
                'baseline',
              ]}
            >
              <Box flex="1">
                <Text
                  as="h2"
                  sx={counterNumCss}
                  fontSize={['48px', '48px', '48px', '48px', '64px', '64px']}
                >
                  <Countup start={0} end={20} duration={2} delay={0.5} />
                  M+
                </Text>
                <Text
                  as="h3"
                  sx={conterNumTitle}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                >
                  People Impacted
                </Text>
                <Text
                  as="p"
                  sx={counterNumDescription}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['16px', '16px', '16px', '16px', '18px', '18px']}
                  position={'relative'}
                  top={['0', '0', '0', '-8px', '-8px', '-8px']}
                >
                  through our solutions
                </Text>
              </Box>
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['20px', '20px', '20px', '20px', '20px', '20px']}
              flexWrap="wrap"
              alignItems={[
                'flex-start',
                'flex-start',
                'baseline',
                'baseline',
                'baseline',
                'baseline',
              ]}
            >
              <Box flex="1">
                <Text
                  as="h2"
                  sx={counterNumCss}
                  fontSize={['48px', '48px', '48px', '48px', '64px', '64px']}
                >
                  <Countup start={0} end={15} duration={2} delay={0.5} />+
                </Text>
                <Text
                  as="h3"
                  sx={conterNumTitle}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                >
                  Countries Catered
                </Text>
                <Text
                  as="p"
                  sx={counterNumDescription}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['16px', '16px', '16px', '16px', '18px', '18px']}
                  position={'relative'}
                  top={['0', '0', '0', '-8px', '-8px', '-8px']}
                >
                  for our global clientele
                </Text>
              </Box>
            </Flex>
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['20px', '20px', '20px', '20px', '20px', '20px']}
              flexWrap="wrap"
              alignItems={[
                'flex-end',
                'flex-end',
                'baseline',
                'baseline',
                'baseline',
                'baseline',
              ]}
            >
              <Box flex="1">
                <Text
                  as="h2"
                  sx={counterNumCss}
                  fontSize={['48px', '48px', '48px', '48px', '64px', '64px']}
                >
                  <Countup start={0} end={98} duration={2} delay={0.5} />%
                </Text>
                <Text
                  as="h3"
                  sx={conterNumTitle}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['18px', '18px', '18px', '23px', '23px', '23px']}
                >
                  Client Satisfaction
                </Text>
                <Text
                  as="p"
                  sx={counterNumDescription}
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  fontSize={['16px', '16px', '16px', '16px', '18px', '18px']}
                  position={'relative'}
                  top={['0', '0', '0', '-8px', '-8px', '-8px']}
                >
                  through verified reviews
                </Text>
              </Box>
            </Flex>
          </Box>
        )}
      </ScrollTrigger>
    </Box>
  );
};

export default Counter;
