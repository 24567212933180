import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const introduction = () => {
  const introductionText = [
    {
      title: 'INTRODUCTION',
      paragraph:
        'ThinkVastu is a India’s leading platform dedicated to providing top-tier Vastu consultation from industry experts. Established to bring ancient wisdom into modern living, ThinkVastu connects individuals with personalized Vastu solutions for homes, offices, and commercial spaces.',
    },
  ];
  return (
    <>
      <Box paddingTop={['93px', '93px', '93px', '40px', '40px', '40px']}>
        <Container
          width={['90%', '90%', '90%', '80%', '72%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '72%', '72%']}
          padding={['0']}
        >
          {/* Introduction Image */}
          
          {/* IntroDuction Title */}
          <Box>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              padding={[
                '0 0 22px',
                '0 0 22px',
                '0 0 22px',
                '40px 0 50px',
                '40px 0 50px',
                '40px 0 50px',
              ]}
            >
              {introductionText[0].title}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              m="0 0 23px 0"
              fontFamily="var(--garnett-regular)"
              margin={['0 auto']}
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph}
            </Text>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default introduction;
