import { Box } from '@chakra-ui/react';
import React from 'react';
import FeaturedClientsLogos from '../Home/ClientsLogo';
import Counter from '../Reusable/CaseStudy/Counter';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import ClientSaysSlider from '../Reusable/ClientSaysSlider';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import Banner from './Banner';
import FaqUse from './FaqUse';
import GreatUxPoints from './GreatUxPoints';
import GrowthJourneyEXP from './GrowthJourneyEXP';
import LetsWorkTogether from './LetsWorkToGether';
import OurLeadingUse from './OurLeadingUse';
import OurRating from './OurRatings';
import PowerofUXDesign from './PowerofUXDesign';
import TextWithImgUse from './TextWithImgUse';
import UXDesignProcess from './UXDesignProcess';
import WhyWorkWithUs from './WhyWorkWithUs';

const SingleService = () => {
  return (
    <Box>
      <Box>
        <Banner />
      </Box>
      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <PowerofUXDesign />
      </Box>
      <Box>
        <GreatUxPoints />
      </Box>
      <Box>
        <OurRating />
      </Box>
      <Box>
        <TextWithImgUse />
      </Box>
      <Box>
        <GrowthJourneyEXP />
      </Box>
      <Box>
        <ClientSaysSlider />
      </Box>
      <Box>
        <TextWithImgUse />
      </Box>

      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <Counter customMarginTop={'0'} />
      </Box>
      <Box>
        <WhyWorkWithUs />
      </Box>
      <Box>
        <UXDesignProcess />
      </Box>
      <Box>
        <FaqUse />
      </Box>
      <Box>
        <OurLeadingUse />
      </Box>
      <Box>
        <FeaturedClientsLogos />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default SingleService;
