import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import MobileFullwidthimg1 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/fulllWidthMobile1.webp';
import DesktopFullwidthimg1 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/fullWidthDesktop1.webp';
import DesktopFullwidthimg2 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/fullwidthDesktop2.webp';
import MobileFullwidthimg2 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/fullWidthMobile2.webp';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import LeftCaret from '../../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../../assets/Icons/Slider/rightIcon.svg';

import slide1 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/1.webp';
import slide2 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/2.webp';
import slide3 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/3.webp';
import slide4 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/4.webp';
import slide5 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/5.webp';
import slide6 from '../../../assets/CaseStudy/Iros/websiteDesignAndDevelopment/Slider/6.webp';

const WebsiteDesignDevelopment = () => {
  const eowData = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
    {
      image: slide4,
    },
    {
      image: slide5,
    },
    {
      image: slide6,
    },
  ];

  // Custom style for the Swiper slider with dynamic padding based on breakpoints
  const swipperSliderCustomStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };

  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box paddingTop={['80px', '80px', '80px', '138px', '138px', '138px']}>
      {/* Texts And Headings */}

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              textTransform={['uppercase']}
              lineHeight={'normal'}
              maxW={['100%', '100%', '167px', '167px', '167px', '167px']}
            >
              Website Design & Development
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              as="h1"
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
              paddingBottom={['16px', '16px', '16px', '24px']}
            >
              Transforming Digital Experience
            </Text>
            <Text
              as="p"
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              We identified IROS’s core USPs, value proposition, service
              delivery areas, and their audience’s intent.
              <br />
              <br />
              We began with the creation of an efficient information
              architecture (aka sitemap) that promotes discoverability and
              enhances information adoption. Using previous audits and the
              information architecture as a guiding beacon, we created low
              fidelity wireframes to align stakeholders on the potential
              experience and take their thoughts.
              <br />
              <br />
              After consulting with department heads and taking their inputs, we
              moved on the designing and prototyping stage for their website.
              <br />
              <br />
              Explore the digital essence of IROS through our meticulously
              crafted website screens, seamlessly blending innovative design
              with user-centric functionality for an intuitive online journey
              and improved user experience.
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Images Grid */}
      <Box padding={['40px 0 100px', '40px 0 100px', '31px 0 80px']}>
        {/* Image One */}

        <Box
          width={['96%']}
          margin={['0 auto']}
          paddingBottom={['24px', '24px', '24px', '80px', '80px', '80px']}
        >
          {/* Desktop */}
          <Image
            display={['none', 'none', 'block']}
            src={DesktopFullwidthimg1}
            width={'100%'}
            height={'auto'}
          />
          {/* Mobile */}
          <Image
            display={['block', 'block', 'none']}
            src={MobileFullwidthimg1}
            width={'100%'}
            height={'auto'}
          />
        </Box>
        {/* Image 2 */}
        <Box width={['96%']} margin={['0 auto']}>
          {/* Desktop */}
          <Image
            display={['none', 'none', 'block']}
            src={DesktopFullwidthimg2}
            width={'100%'}
            height={'auto'}
          />
          {/* Mobile */}
          <Image
            display={['block', 'block', 'none']}
            src={MobileFullwidthimg2}
            width={'100%'}
            height={'auto'}
          />
        </Box>
      </Box>
      {/* Slider */}
      <Box
        padding={['53px 0', '53px 0', '53px 0', '90px 0', '90px 0', '90px 0']}
        backgroundColor={'#F5F5F5'}
      >
        {/* Hide Slider Navigations if need then display block */}
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
          m="0 auto"
          display={['block', 'block', 'none', 'none', 'none']}
          padding="0"
        >
          <Flex
            justifyContent="space-between"
            paddingTop="20px"
            width="90%"
            display={['flex', 'flex', 'none', 'none', 'none', 'none']}
            margin="0 auto"
            paddingBottom="31px"
          >
            <Text
              fontSize="14px"
              color="#333"
              fontFamily="var(--garnett-regular)"
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text>
            <Box display="flex">
              <Box onClick={goPrev} mr="20px">
                <Image src={LeftCaret} cursor="pointer" />
              </Box>
              <Box onClick={goNext}>
                <Image src={RightCaret} cursor="pointer" />
              </Box>
            </Box>
          </Flex>
        </Container>
        <Swiper
          ref={swiperRef}
          observeParents
          autoplay
          observer
          modules={[Navigation, FreeMode]}
          speed={750}
          slidesPerView={'auto'}
          spaceBetween={24}
          style={swipperSliderCustomStyle}
          onSlideChange={handleSlideChange}
          className="WebsiteDesign"
        >
          {eowData.map((data, index) => (
            <SwiperSlide key={index}>
              <Box
                height={['235px', '235px', '484px', '484px', '484px', '484px']}
                width={['353px', '353px', '775px']}
              >
                <Image src={data.image} w={'100%'} height={'100%'} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
export default WebsiteDesignDevelopment;
