import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const PowerofUXDesign = () => {
  const heading = {
    fontSize: ['32px', '32px', '32px', '42px', '42px', '42px'],
    paddingBottom: '25px',
    color: ['#000'],
    letterSpacing: '-0.462px',
    lineHeight: 'normal',
    fontFamily: 'var(--garnett-regular);',
  };
  const paragraph = {
    fontSize: ['16px', '16px', '16px', '18px', '18px', '18px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: ['756px'],
    margin: ['0 auto'],
  };
  return (
    <Box>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        padding={['0px 20px']}
        margin={['0 auto']}
        backgroundColor={'#F5F5F5'}
      >
        {/* Content */}
        <Box
          maxW={['766px']}
          margin={['0 auto']}
          textAlign={'center'}
          padding={[
            '44px 0 30px',
            '44px 0 30px',
            '44px 0 30px',
            '80px 0 60px',
            '80px 0 60px',
            '80px 0 60px',
          ]}
        >
          {/* Title */}
          <Heading as={'h2'} sx={heading}>
            The Power of User Experience Design
          </Heading>
          <Text as={'p'} sx={paragraph}>
            User Experience (UX) Design is the cornerstone of successful digital
            products. In today's fast-paced digital landscape, users expect more
            than just functionality—they seek intuitive, engaging, and
            meaningful interactions. A powerful UX design ensures that your
            digital products not only meet these expectations but exceed them.
            By prioritizing user-centric design, businesses can transform their
            digital interactions and achieve significant business growth.
            Whether you're aiming to increase user engagement, improve customer
            satisfaction, or drive conversions, investing in UX design is
            essential for achieving these goals.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PowerofUXDesign;
