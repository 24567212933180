import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Banner from './banner';
import Filter from './filter';
// import FeaturedStory from './featuredStory';
import { useParams } from 'react-router-dom';
import ExploreAllStory from './exploreAllStory';
import LetsWorkTogether from './LetsWorkToGether';
import Search from './search';

const Resources = () => {
  const { slug } = useParams();
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(`https://cms.wearetenet.com/api/insight-blogs?filters[category][$eq]=guides&populate=*`);
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [slug]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showInitialContent, setShowInitialContent] = useState(true); // New state to control UI

  const handleSearch = query => {
    setSearchQuery(query);
    setShowInitialContent(query === ''); // Show initial content if input is cleared
  };

  const handleInputChange = value => {
    setSearchQuery(value);
    setShowInitialContent(value === ''); // Also control UI here
  };
  return (
    <Box>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta name="robots" content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'} />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Banner />
      <Filter onSearch={handleSearch} onInputChange={handleInputChange} />
      {showInitialContent ? (
        <>
          <ExploreAllStory />
        </>
      ) : (
        <Search searchQuery={searchQuery} />
      )}
      <LetsWorkTogether />
    </Box>
  );
};

export default Resources;
