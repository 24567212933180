import { Box, Container, Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const extractText = description => {
  return description
    .map(paragraph => paragraph.children.map(child => child.text).join(''))
    .join('\n');
};

const SolutionContent = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const introductionDescription = firstItem?.solution_description || [];
  const descriptionText = extractText(introductionDescription);

  const backgroundText = [
    {
      title:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
    },
  ];
  return (
    <Container
      paddingTop={['10px', '10px', '10px', '10px', '0px', '10px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['80px', '0px', 0, 0, 0, 0]}
      padding={['0']}
    >
      {descriptionText && (
        <Box
          maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
          margin={['0 auto']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        >
          <Box
            display={['block', 'block', 'block', 'flex']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={['space-between']}
            alignItems={['baseline']}
            gap={['10%']}
            margin={['0 auto']}
          >
            <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
              <Text
                as="h1"
                fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-medium)"
                color={['#860038']}
                fontWeight={['500']}
                lineHeight={'normal'}
              >
                SOLUTION
              </Text>
            </Box>

            <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
              <Flex
                width={['100%', '100%', '100%', '100%', '100%', '100%']}
                maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
                direction={['column']}
                gap={['30px', '30px', '30px', '30px', '30px', '30px']}
                margin={['0 auto']}
                // paddingTop={["16px", "18px", "20px", "26px", "30px", "32px"]}
                // marginBottom={["40px", "40px", "40px", "80px", "80px", "80px"]}
              >
                <Text
                  as="h1"
                  fontSize={['32px', '32px', '32px', '32px', '36px', '36px']}
                  fontFamily="var(--garnett-regular)"
                  color={['#333333']}
                  fontWeight={['400']}
                  lineHeight={'normal'}
                >
                  {firstItem?.solution_heading}
                </Text>
                <Text
                  as="h1"
                  fontSize={['16px', '16px', '18px', '20px', '20px', '20px']}
                  fontFamily="var(--foundersGrotesk-regular)"
                  color={['#333333']}
                  fontWeight={['400']}
                  lineHeight={'normal'}
                >
                  {descriptionText}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};
export default SolutionContent;
