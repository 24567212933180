import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Link,
  Flex,
  Image,
  Heading,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import drawerLogo from '../../../assets/Logo/drawerLogo.svg';
import whatsnew1 from '../../../assets/Temp/WhatsNewSolutionMenu.webp';

const SolutionMobileMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState(null);
  const [activeServiceId, setActiveServiceId] = useState(null);

  const services = [
    {
      id: 1,
      title: 'Based on Outcomes',
      serviceList: [
        {
          name: 'Brand Growth & Recognition',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'Digital Transformation',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'Mobile App Development',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'Ideation & Design Strategy',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'Data Science & Analytics',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'AI-ML Integration',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
        {
          name: 'Brand Visibility',
          link: '/',
          paragraph: 'Drive brand growth, brand',
        },
      ],
    },
    {
      id: 2,
      title: 'Based on Industries',
      serviceList: [
        { name: 'E-commerce', link: '/' },
        { name: 'Healthcare & Fitness', link: '/' },
        { name: 'Events', link: '/' },
        { name: 'Social & Dating', link: '/' },
        { name: 'Gaming', link: '/' },
        { name: 'Finance', link: '/' },
        { name: 'SaaS', link: '/' },
        { name: 'Education', link: '/' },
        { name: 'Entertainment', link: '/' },
        { name: 'Travel & Hospitality', link: '/' },
        { name: 'On-Demand', link: '/' },
        { name: 'Food & Restaurant', link: '/' },
      ],
    },
  ];

  const whatsNewData = [
    {
      Image: whatsnew1,
      title: 'Secrets for Collaboration: Design X Engineering',
      description:
        'There’s something very powerful about working in interdisciplinary teams to build and evolve digital products. This is the way we...',
      readMoreLink: '/',
    },
  ];

  const handleServiceClick = service => {
    setSelectedService(service);
    setActiveServiceId(service.id);
    onOpen(); // Drawer open karne ke liye
  };

  return (
    <Box>
      {/* All Service Direct Link */}
      <Box
        padding={['53px 40px 20px']}
        display={['flex']}
        alignItems={'baseline'}
        gap={'13px'}
      >
        <Link
          href="/solutions"
          fontSize={['24px']}
          color={'#fff'}
          fontWeight={'400'}
          lineHeight={'normal'}
          fontFamily={'var(--foundersGrotesk-regular);'}
        >
          All Solutions
        </Link>
        <Link href="/solutions">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M13 14.9014H3C2.73489 14.901 2.48075 14.7955 2.29329 14.6081C2.10583 14.4206 2.00036 14.1665 2 13.9014V3.90137C2.00036 3.63626 2.10583 3.38211 2.29329 3.19465C2.48075 3.0072 2.73489 2.90172 3 2.90137H8V3.90137H3V13.9014H13V8.90137H14V13.9014C13.9996 14.1665 13.8942 14.4206 13.7067 14.6081C13.5193 14.7955 13.2651 14.901 13 14.9014Z"
              fill="white"
            />
            <path
              d="M10 1.90137V2.90137H13.293L9 7.19437L9.707 7.90137L14 3.60837V6.90137H15V1.90137H10Z"
              fill="white"
            />
          </svg>
        </Link>
      </Box>

      {/* Services List */}
      <Box>
        <ButtonGroup w={'100%'} display={'block!important'}>
          {services.map(service => (
            <Button
              key={service.id}
              display={['flex']}
              justifyContent={'space-between'}
              alignItems={'baseline'}
              w={'100%'}
              padding={['20px 40px 28px']}
              borderRadius={'0'}
              height={'auto!important'}
              onClick={() => handleServiceClick(service)}
              margin={'0!important'}
              // background={
              //   activeServiceId === service.id ? '#0057FF' : 'transparent'
              // }
              background={'transparent'}
              _hover={{ background: '#0057FF' }}
              whiteSpace={'normal'}
              textAlign={'left'}
            >
              <Box>
                <Text
                  as="p"
                  fontSize={['32px', '32px', '32px', '32px', '32px']}
                  color={'#fff'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  fontFamily="var(--garnett-regular)"
                >
                  {service.title}
                </Text>
              </Box>
              <Box>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M7.5 5.25537L13.75 10.2554L7.5 15.2554V5.25537Z"
                    fill="white"
                  />
                </svg>
              </Box>
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      {/* Whats New */}
      <Box height={'1px'} mt={'18px'} background={'#333'}></Box>
      <Box backgroundColor={'#111'} padding={['60px 20px 48px']}>
        {/* Title */}
        <Box
          display={['flex']}
          alignItems={'center'}
          gap={'12px'}
          paddingBottom={['18px']}
        >
          <Text
            as={'p'}
            fontSize={['18px']}
            color={'#fff'}
            fontFamily={'var(--garnett-regular);'}
            fontWeight={'400'}
            lineHeight={'normal'}
          >
            What’s New
          </Text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="25"
            viewBox="0 0 22 25"
            fill="none"
          >
            <g clip-path="url(#clip0_2277_1249)">
              <path
                d="M14.3414 2.74294L15.6328 6.0645C15.6648 6.14572 15.7161 6.21584 15.7813 6.26736C15.8466 6.31888 15.9233 6.34986 16.0033 6.357L19.3057 6.66731C19.392 6.67343 19.4748 6.70784 19.5433 6.76613C19.6119 6.82442 19.6631 6.90394 19.6905 6.99453C19.7178 7.08512 19.7201 7.18266 19.697 7.27467C19.6738 7.36668 19.6263 7.44899 19.5606 7.51106L17.0517 9.87075C16.9917 9.92725 16.9468 10.0008 16.9219 10.0834C16.8971 10.166 16.8932 10.2546 16.9106 10.3395L17.6617 13.8514C17.6803 13.9446 17.6738 14.0417 17.6429 14.1308C17.6119 14.22 17.558 14.2971 17.4877 14.3527C17.4174 14.4083 17.3339 14.4399 17.2475 14.4436C17.161 14.4473 17.0755 14.423 17.0015 14.3736L14.1622 12.5107C14.0929 12.4656 14.014 12.4418 13.9336 12.4418C13.8532 12.4418 13.7743 12.4656 13.7051 12.5107L10.8657 14.3736C10.7917 14.423 10.7062 14.4473 10.6198 14.4436C10.5333 14.4399 10.4498 14.4083 10.3795 14.3527C10.3092 14.2971 10.2553 14.22 10.2244 14.1308C10.1934 14.0417 10.1869 13.9446 10.2055 13.8514L10.96 10.3442C10.9775 10.2593 10.9736 10.1707 10.9487 10.0881C10.9238 10.0055 10.879 9.93194 10.8189 9.87543L8.31004 7.51106C8.24429 7.44899 8.1968 7.36668 8.17367 7.27467C8.15053 7.18266 8.15278 7.08512 8.18015 6.99453C8.20751 6.90394 8.25874 6.82442 8.32729 6.76613C8.39583 6.70784 8.47857 6.67343 8.56493 6.66731L11.8682 6.35887C11.9476 6.35075 12.0236 6.31911 12.088 6.2673C12.1525 6.21548 12.203 6.14541 12.2344 6.0645L13.5258 2.74294C13.5605 2.65547 13.6176 2.58106 13.69 2.52881C13.7624 2.47656 13.8471 2.44873 13.9336 2.44873C14.0201 2.44873 14.1048 2.47656 14.1772 2.52881C14.2497 2.58106 14.3067 2.65547 14.3414 2.74294Z"
                stroke="white"
                stroke-width="0.695711"
                stroke-linecap="round"
              />
              <path
                d="M6.79831 11.4487L2.04049 16.6987"
                stroke="white"
                stroke-width="0.695711"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M8.15606 16.6987L3.39825 21.9487"
                stroke="white"
                stroke-width="0.695711"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M14.2734 16.6987L9.51559 21.9487"
                stroke="white"
                stroke-width="0.695711"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2277_1249">
                <rect
                  width="21.75"
                  height="24"
                  fill="white"
                  transform="translate(0 0.19873)"
                />
              </clipPath>
            </defs>
          </svg>
        </Box>

        {/* Whats New Wrapper */}
        <Box
          display={'grid'}
          gridTemplateColumns={'repeat(1,1fr)'}
          gap={'40px'}
        >
          {whatsNewData.map((data, index) => (
            // JSX code here, such as:
            <Box key={index}>
              {/* Whats New Image */}
              <Box>
                <Image src={data.Image} w={'100%'} height={'auto'} alt="" />
              </Box>
              {/* Whats New Data */}
              <Box paddingTop={['20px']}>
                <Heading
                  as={'h3'}
                  fontSize={['18px']}
                  color={'#fff'}
                  fontFamily={'var(--garnett-medium);'}
                  fontWeight={'500'}
                  lineHeight={'normal'}
                  paddingBottom={'10px'}
                >
                  {data.title}
                </Heading>
                <Text
                  as={'p'}
                  fontSize={['14px']}
                  color={'#fff'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                >
                  {data.description}
                </Text>
                <Link
                  color={'#fff'}
                  href={data.readMoreLink}
                  fontSize={['14px']}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  display={'inline-block'}
                  paddingTop={['10px']}
                  position={'relative'}
                  textDecoration={'none'} // remove default underline
                  _after={{
                    content: '""',
                    position: 'absolute',
                    left: '0',
                    bottom: '1px', // Adjust space between text and underline
                    height: '2px', // Height of the underline
                    width: '100%',
                    backgroundColor: '#0057FF',
                  }}
                >
                  Read more
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Drawer */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="full"
        background="#fff"
        className="CustomeMobMenu"
      >
        <DrawerOverlay>
          <DrawerContent bg="#000000" padding={'0'}>
            <DrawerBody padding={['0']} background={'#fff'}>
              {/* Custome Nav */}
              <Box
                display={['flex']}
                flexDirection={'column'}
                justifyContent={'space-between'}
                height={'100%'}
                background={'#fff'}
              >
                {/* Data And Back Button */}
                <Box>
                  {/* Nav And Back Button */}
                  <Box background={'#000'}>
                    {/* Nav */}
                    <Box
                      position="sticky"
                      top="0"
                      zIndex="1000"
                      marginBottom={['31px']}
                      background={'#000'}
                    >
                      <Flex
                        alignItems="center"
                        padding={['20px']}
                        justifyContent={['space-between']}
                        borderBottom={'1px'}
                        borderBottomColor={'#333'}
                      >
                        <Link href="/main" width={['104px']} height={['40px']}>
                          <img
                            src={drawerLogo}
                            objectFit={'cover'}
                            width={['100%']}
                            height={['100%']}
                            alt="Drawer Logo"
                          />
                        </Link>
                        <Box
                          display={'flex'}
                          gap={'12px'}
                          alignItems={'center'}
                        >
                          <Box>
                            <Link
                              fontSize={['16px']}
                              color={'#fff'}
                              href="/contact-us"
                              background={'#0057FF'}
                              fontFamily={'var(--foundersGrotesk-regular);'}
                              fontWeight={400}
                              lineHeight={'normal'}
                              width={['103px']}
                              height={'38px'}
                              display={'inline-flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              paddingTop={'4px'}
                            >
                              Get Started
                            </Link>
                          </Box>
                          <DrawerCloseButton
                            color="#ffffff"
                            position={['static']}
                          />
                        </Box>
                      </Flex>
                    </Box>
                    {/* BackButton */}
                    <Box>
                      <Box>
                        <Box padding={['0px 20px']} position={'relative'}>
                          <Button
                            background={'none'}
                            borderRadius={'0'}
                            boxShadow={'none'}
                            _hover={{ background: 'transparent' }}
                            padding={'0'}
                            display={['flex']}
                            alignItems={'center'}
                            gap={['6px']}
                            onClick={onClose}
                            height={['18px!important']}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_2277_1134)">
                                <path
                                  d="M6.25052 10L12.5005 3.75L13.3755 4.625L8.00052 10L13.3755 15.375L12.5005 16.25L6.25052 10Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2277_1134">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="matrix(-1 0 0 -1 20 20)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <Text
                              as={'span'}
                              fontSize={['16px']}
                              color={'#fff'}
                              fontWeight={'400'}
                              lineHeight={'normal'}
                              fontFamily={'var(--foundersGrotesk-regular);'}
                              paddingTop={['5px']}
                            >
                              Solutions
                            </Text>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* Service Title Name */}
                    <Box padding={['60px 40px 24px']}>
                      <Text
                        as={'p'}
                        fontSize={['28px', '36px', '36px', '36px']}
                        color={'#fff'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        fontFamily={'var(--garnett-regular);'}
                      >
                        {selectedService?.title}
                      </Text>
                    </Box>
                  </Box>
                  {/* Data Display Lists */}
                  <Box padding={['33px 40px 24px']}>
                    {' '}
                    {selectedService?.serviceList.map((service, index) => (
                      <>
                        <Link
                          href={service.link}
                          isExternal
                          display={['flex']}
                          flexDirection={'column'}
                          alignItems={'left'}
                          paddingBottom={index !== -1 ? '15px' : '0px'}
                          paddingTop={index !== -1 ? '15px' : '0px'}
                          _hover={{
                            '> p': { color: '#0057FF !important' }, // service.name ka color change karega
                            '> p:last-of-type': { color: '#0057FF !important' }, // service.paragraph ka color change karega
                          }}
                        >
                          <Text
                            as={'p'}
                            key={index}
                            fontSize={['18px']}
                            color={'#333'}
                            fontFamily={'var(--foundersGrotesk-regular);'}
                            fontWeight={'400'}
                            lineHeight={'normal'}
                          >
                            {service.name}
                          </Text>
                          {service.paragraph && (
                            <Text
                              as={'p'}
                              fontSize={['18px']}
                              color={'#606060'}
                              fontFamily={'var(--foundersGrotesk-regular);'}
                              fontWeight={'400'}
                              lineHeight={'normal'}
                            >
                              {service.paragraph}
                            </Text>
                          )}
                        </Link>
                      </>
                    ))}
                  </Box>
                </Box>

                {/* Contact Us Button */}
                {/* HrLine */}
                <Box>
                  {/* Hr Line */}
                  <Box
                    backgroundColor={'#BDBDBD'}
                    height={'1px'}
                    w={'100%'}
                    margin={'0px 20px 25px'}
                  ></Box>
                  {/* Link */}
                  <Box display={['flex']} gap={'7px'} padding={'0px 20px 60px'}>
                    {/* Label */}
                    <Text
                      as={'p'}
                      fontFamily={'var(--foundersGrotesk-light);'}
                      fontSize={['16px']}
                      fontWeight={'300'}
                      lineHeight={'normal'}
                      color={'#333'}
                    >
                      Need more help?
                    </Text>
                    <Link
                      href="/contact-us"
                      fontFamily={'var(--foundersGrotesk-regular);'}
                      letterSpacing={'-0.396px'}
                      fontSize={['18px']}
                      fontWeight={'400'}
                      lineHeight={'normal'}
                      color={'#333'}
                    >
                      <Text
                        textDecoration={'underline'}
                        textDecorationColor={'#0057FF'}
                      >
                        Contact us
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default SolutionMobileMenu;
