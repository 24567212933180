import { Box } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CuratedServices from './Curated';

const CuratedServicesUse = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const serviceList = [
    {
      id: 1,
      number: '01',
      title: 'Brand strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 2,
      number: '02',
      title: 'Brand Research',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 3,
      number: '03',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 4,
      number: '04',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 5,
      number: '05',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 6,
      number: '06',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 7,
      number: '07',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 8,
      number: '08',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },
    {
      id: 9,
      number: '09',
      title: 'Experience strategy',
      description:
        'Define the vision, proposition and roadmap across your brand ecosystem to support people while making your brand memorable and meaningful.',
      link: '/upcoming',
    },

    // Add more services as needed
  ];
  return (
    <Box>
      <CuratedServices linkSvgColor="red" services={serviceList} />
    </Box>
  );
};

export default CuratedServicesUse;
