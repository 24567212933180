import React from 'react';
import { Box } from '@chakra-ui/react';

import HeroSection from './DetailPage/HeroSection';

import BackgroundContent from './DetailPage/backgroundContent';
import ApproachContent from './DetailPage/approachContent';
import SolutionContent from './DetailPage/solutionContent';
import TechnologyContent from './DetailPage/technologyContent';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import Counter from '../Reusable/CaseStudy/Counter';
import LetsWorkTogether from './DetailPage/LetsWorkToGether';
import StrongrAppSlider from './DetailPage/StrongrAppSlider';

const CaseStudy = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>
      <Box>
        <BackgroundContent />
      </Box>
      <Box>
        <ApproachContent />
      </Box>
      <Box>
        <StrongrAppSlider />
      </Box>
      <Box>
        <SolutionContent />
      </Box>
      <Box>
        <TechnologyContent />
      </Box>
      <Box>
        <Counter
          customMarginTop={['80px', '80px', '80px', '100px', '120px', '150px']}
        />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </>
  );
};

export default CaseStudy;
