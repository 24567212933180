import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import FeaturedClientsLogos from '../Home/ClientsLogo';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import Banner from './Banner';
import LetsWorkTogether from './LetsWorkToGether';
import OurLeding from './ourLeading';
// import Faq from './Faq';
import { useParams } from 'react-router-dom';
import Counter from '../Reusable/CaseStudy/Counter';
import ClientSaysSlider from '../Reusable/ClientSaysSlider';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import FaqUse from './FaqUse';
import GreatUxPoints from './GreatUxPoints';
import GrowthJourneyEXP from './GrowthJourneyEXP';
import OurRating from './OurRatings';
import PowerofUXDesign from './PowerofUXDesign';
import TextWithImg from './TextWithImg';
import TextWithImg2 from './TextWithImg2';
import UXDesignProcess from './UXDesignProcess';
import WhyWorkWithUs from './WhyWorkWithUs';

const SingleService = () => {
  const { slug } = useParams();
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=design&populate=*`
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [slug]);
  return (
    <Box>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Box>
        <Banner />
      </Box>
      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <PowerofUXDesign />
      </Box>
      <Box>
        <GreatUxPoints />
      </Box>

      <Box>
        <OurRating />
      </Box>

      <Box>
        <TextWithImg />
      </Box>
      <Box>
        <GrowthJourneyEXP />
      </Box>
      <Box>
        <ClientSaysSlider />
      </Box>
      <Box>
        <TextWithImg2 />
      </Box>

      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <Counter customMarginTop={'0'} />
      </Box>
      <Box>
        <WhyWorkWithUs />
      </Box>
      <Box>
        <UXDesignProcess />
      </Box>
      <Box>
        {/* <Faq /> */}
        <FaqUse />
      </Box>
      <Box>
        <OurLeding />
      </Box>
      <Box>
        <FeaturedClientsLogos />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default SingleService;
