import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

const CuratedServices = ({
  linkSvgColor,
  services,
  sectionPadding = [
    '80px 0 80px',
    '80px 0 80px',
    '80px 0 80px',
    '150px 0 120px',
  ],
  showMoreButtonColor = '#E0005E',
}) => {
  const serviceListTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontWeight: '400',
    fontFamily: 'var(--garnett-regular)',
    color: '#3E001A',
    lineHeight: 'normal',
    p: [
      '0px 0 0px',
      '0px 0 0px',
      '0px 0 0px',
      '12px 0 15px',
      '20px 0 18px',
      '26px 0 24px',
    ],
  };
  const serviceListNumber = {
    fontSize: ['20px'],
    fontWeight: '300',
    color: '#3E001A',
    fontFamily: 'var(--garnett-light)',
    mr: ['5px', '5px', '5px', '0px', '0px', '0px'],
    display: 'block',
  };
  const serviceListDescription = {
    fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#333',
  };
  const boxDesign = {
    bg: '#F5F5F5',
    p: [
      '13px 20px 26px 20px',
      '13px 20px 26px 20px',
      '13px 20px 26px 20px',
      '20px 20px 40px 20px',
      '20px 20px 40px 20px',
      '20px 20px 40px 20px',
    ],
    position: 'relative',
  };
  // Show More

  const [visibleItems, setVisibleItems] = useState(3);

  const handleShowMore = () => {
    setVisibleItems(prevCount => prevCount + 3);
  };

  const isMobile = window.innerWidth <= 992;

  return (
    <Box padding={sectionPadding}>
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        {/* SectionHeadings */}
        <Box
          display={['block', 'block', 'flex', 'flex', 'flex']}
          justifyContent={'space-between'}
          gap={'20px'}
        >
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '35px', '48px', '48px']}
            color={'#000'}
            fontFamily={'var(--garnett-regular);'}
            fontWeight={'400'}
            lineHeight={'normal'}
            paddingBottom={['22px', '22px', '0px']}
          >
            Curated Services{' '}
          </Heading>
          <Text
            as="p"
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
            color={'#333'}
            fontWeight={'400'}
            lineHeight={'normal'}
            maxW={['430px', '430px', '430px', '450px', '512px', '512px']}
          >
            Our Brand Growth & Recognition Solutions present a meticulously
            curated suite of services designed to expedite desired outcomes with
            maximum efficiency.
          </Text>
        </Box>

        {/* Display Curated Services */}
        <Box
          paddingTop={['22px', '22px', '22px', '61px']}
          display={'grid'}
          gridTemplateColumns={[
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(3,1fr)',
            'repeat(3,1fr)',
            'repeat(3,1fr)',
          ]}
          gridGap={[
            '16px 20px',
            '16px 20px',
            '40px 20px',
            '40px 20px',
            '40px 20px',
            '40px 20px',
          ]}
        >
          {services
            .slice(0, isMobile ? visibleItems : services.length)
            .map(service => (
              <Box
                key={service.id}
                sx={{
                  ...boxDesign,
                  transition: 'background-color 0.3s ease',
                  '&:hover': {
                    backgroundColor:
                      linkSvgColor === 'red'
                        ? '#f8b7c7'
                        : linkSvgColor === 'green'
                        ? '#d0f5e3'
                        : '#d0e3ff', // green hover color
                    '& h4': {
                      color: '#0D0E4D',
                    },
                    '& p': {
                      color: '#333',
                    },
                    '& span': {
                      color: '#0D0E4D',
                    },
                  },
                }}
              >
                <Box as="span">
                  <Link
                    href={service.link}
                    position="absolute"
                    top="0"
                    right="0"
                  >
                    {linkSvgColor === 'red' ? (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="40" height="40" fill="#E0005E" />
                        <g clipPath="url(#clip0_4036_73)">
                          <path
                            d="M31 16.7305L30.9988 8.73172L23 8.73047"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                          />
                          <path
                            d="M21 19L30 10"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M27 20.7305V29.7305C27 29.9957 26.8946 30.25 26.7071 30.4376C26.5196 30.6251 26.2652 30.7305 26 30.7305H10C9.73478 30.7305 9.48043 30.6251 9.29289 30.4376C9.10536 30.25 9 29.9957 9 29.7305V13.7305C9 13.4653 9.10536 13.2109 9.29289 13.0234C9.48043 12.8358 9.73478 12.7305 10 12.7305H19"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    ) : linkSvgColor === 'green' ? (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="40" height="40" fill="#00BA61" />
                        <g clipPath="url(#clip0_5005_1512)">
                          <path
                            d="M31 16.7305L30.9988 8.73172L23 8.73047"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                          />
                          <path
                            d="M21 19L30 10"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M27 20.7305V29.7305C27 29.9957 26.8946 30.25 26.7071 30.4376C26.5196 30.6251 26.2652 30.7305 26 30.7305H10C9.73478 30.7305 9.48043 30.6251 9.29289 30.4376C9.10536 30.25 9 29.9957 9 29.7305V13.7305C9 13.4653 9.10536 13.2109 9.29289 13.0234C9.48043 12.8358 9.73478 12.7305 10 12.7305H19"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="40" height="40" fill="#0057FF" />
                        <g clipPath="url(#clip0_4018_766)">
                          <path
                            d="M31 16.7305L30.9988 8.73172L23 8.73047"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                          />
                          <path
                            d="M21 19L30 10"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M27 20.7305V29.7305C27 29.9957 26.8946 30.25 26.7071 30.4376C26.5196 30.6251 26.2652 30.7305 26 30.7305H10C9.73478 30.7305 9.48043 30.6251 9.29289 30.4376C9.10536 30.25 9 29.9957 9 29.7305V13.7305C9 13.4653 9.10536 13.2109 9.29289 13.0234C9.48043 12.8358 9.73478 12.7305 10 12.7305H19"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    )}
                  </Link>
                </Box>
                <Flex
                  pb={['15px', '15px', '15px', '0px', '0px', '0px']}
                  alignItems={[
                    'baseline',
                    'baseline',
                    'baseline',
                    'start',
                    'start',
                    'start',
                  ]}
                  flexDirection={'column'}
                >
                  <Box>
                    <Text as={'span'} sx={serviceListNumber}>
                      {service.number}
                    </Text>
                  </Box>
                  <Box>
                    <Text as={'h4'} sx={serviceListTitle}>
                      {service.title}
                    </Text>
                  </Box>
                </Flex>
                <Box>
                  <Text as={'p'} sx={serviceListDescription}>
                    {service.description}
                  </Text>
                </Box>
              </Box>
            ))}
        </Box>
        {/* Show More Only For Mobile */}
        {/* Show More Only For Mobile */}
        {isMobile && visibleItems < services.length && (
          <Box
            textAlign={'center'}
            display={['block', 'block', 'block', 'none', 'none']}
          >
            <Button
              onClick={handleShowMore}
              fontFamily={'var(--foundersGrotesk-regular);'}
              fontWeight={'400'}
              color={showMoreButtonColor}
              background={'transparent'}
              boxShadow={'none'}
              lineHeight={'normal'}
              textDecoration={'underline'}
              fontSize={'20px'}
              paddingTop={['24px']}
              _hover={{ backgroundColor: 'transparent' }}
            >
              Show More
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CuratedServices;
