import { Box, Container, Flex } from '@chakra-ui/react'
import React from 'react'
import Content from './Content'
import DataDownloadForm from './DataDownloadForm'

const ContentLayout = () => {
  return (
    <Box pt={["50px", "60px", "70px", "80px", "100px", "116px"]} pb={["00px", "0px", "30px", "70px", "100px", "100px"]}>
      <Container maxWidth={["100%", "100%", "100%", "90%", "90%", "90%"]} m={'0 auto'} zIndex={2} position={'relative'}>
        <Flex display={['block', 'block', 'block', 'flex', 'flex', 'flex']} flexDir={["column", "column", "column", "row", "row", "row"]} gap={["15px", "20px", "25px", "35px", "40px", "74px"]}>
          <Box w={['100%', '100%', '100%', '65%', '65%', '66%']}>
            <Content />
          </Box>
          <Box w={["100%", "100%", "100%", '35%', '35%', '34%']} display={["none", "none", "block", "block", "block", "block"]}>
            <DataDownloadForm />
          </Box>
        </Flex>
      </Container>
      <Box w={["100%", "100%", "100%", '35%', '35%', '34%']} display={["block", "block", "none", "none", "none", "none"]}>
        <DataDownloadForm />
      </Box>

    </Box>
  )
}

export default ContentLayout
