import { Box, Container, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import Edge1 from '../../assets/ThankYouPgae/edge1.svg';
import Edge2 from '../../assets/ThankYouPgae/edge2.svg';

const Banner = () => {
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  return (
    <Box
      minH={['580px', '580px', '580px', '700px', '700px', '700px']}
      bg={'#000'}
      padding={[
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
        '14px 0 50px',
      ]}
      overflow={'hidden'}
      display={'flex'}
      alignItems={'center'}
      position={'relative'}
      ref={heroSectionRef}
      id="hero-section"
    >
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Text Part */}

        <Box mb={['90px', '90px', '90px', '0px', '0px', '0px']} maxW={'800px'}>
          <Box mt={'16px'}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="89"
              height="90"
              viewBox="0 0 89 90"
              fill="none"
            >
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 16.1836 20.5978)"
                fill="white"
              />
              <rect
                x="16.1836"
                y="69.1434"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 16.1836 69.1434)"
                fill="white"
              />
              <rect
                x="72.8164"
                y="20.5978"
                width="8.09091"
                height="8.09091"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(1 0 0 -1 72.8164 69.1434)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 12.1367 28.689)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 8.08984 36.7794)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 89 36.7794)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 8.08984 44.8706)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 89 44.8706)"
                fill="white"
              />
              <rect
                x="12.1367"
                y="61.0522"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 12.1367 61.0522)"
                fill="white"
              />
              <rect
                x="76.8633"
                y="28.689"
                width="8.09091"
                height="8.09091"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(1 0 0 -1 76.8633 61.0523)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 20.2266 12.5066)"
                fill="white"
              />
              <rect
                x="20.2266"
                y="77.2346"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 20.2266 77.2346)"
                fill="white"
              />
              <rect
                x="68.7734"
                y="12.5066"
                width="8.09091"
                height="8.09091"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(1 0 0 -1 68.7734 77.2346)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 28.3164 8.46183)"
                fill="white"
              />
              <rect
                x="28.3164"
                y="81.2794"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 28.3164 81.2794)"
                fill="white"
              />
              <rect
                x="60.6836"
                y="8.46183"
                width="8.09091"
                height="8.09091"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(1 0 0 -1 60.6836 81.2794)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 36.4102 4.41623)"
                fill="white"
              />
              <rect
                x="36.4102"
                y="85.325"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 36.4102 85.325)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 44.5 0.370621)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 44.5 57.0066)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 36.4102 52.9618)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 36.4102 32.7346)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 60.6836 32.7346)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 36.4102 24.6434)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 60.6836 24.6434)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 28.3164 44.8706)"
                fill="white"
              />
              <rect
                x="44.5"
                y="89.3706"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 44.5 89.3706)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 52.5898 0.370621)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 52.5898 57.0066)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 60.6836 52.9618)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 68.7734 44.8706)"
                fill="white"
              />
              <rect
                x="52.5898"
                y="89.3706"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 52.5898 89.3706)"
                fill="white"
              />
              <rect
                width="8.09091"
                height="8.09091"
                transform="matrix(-1 0 0 1 60.6836 4.41652)"
                fill="white"
              />
              <rect
                x="60.6836"
                y="85.325"
                width="8.09091"
                height="8.09091"
                transform="rotate(180 60.6836 85.325)"
                fill="white"
              />
            </svg>
          </Box>
          <Text
            as="h1"
            fontSize={['35px', '35px', '35px', '58px', '58px', '58px']}
            color={'#fff'}
            fontFamily={'var(--garnett-semibold)'}
            fontWeight={'600'}
            pb={'16px'}
            maxW={'535px'}
          >
            Thank you for reaching out
          </Text>
          <Text
            as="p"
            fontSize={['14px', '14px', '14px', '23px', '23px', '23px']}
            color={'#fff'}
            fontFamily={'var(--foundersGrotesk-regular)'}
            fontWeight={'400'}
          >
            We'll get back you latest in 2 business days.
          </Text>
        </Box>
      </Container>
      {/* Edge */}
      <Image
        src={Edge2}
        alt="Edge Image"
        position="absolute"
        bottom="0"
        right={['-15%', '-15%', '-15%', '0', '0', '0']}
      />
      <Image
        src={Edge1}
        alt="Edge Image"
        position="absolute"
        bottom="0"
        left="0"
        display={['none', 'none', 'none', 'block', 'block', 'block']}
      />
    </Box>
  );
};

export default Banner;
