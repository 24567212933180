import React from 'react';
import { Box } from '@chakra-ui/react';

import HeroSection from './DetailPage/HeroSection';
import BackgroundContent from './DetailPage/backgroundContent';
import ChallengeContent from './DetailPage/challengeContent';
import PainPointDiscoverdSlider from './DetailPage/PainPointDiscoverdSlider';

import ConclusionContent from './DetailPage/conclusionContent';

import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import Counter from '../Reusable/CaseStudy/Counter';
import LetsWorkTogether from './DetailPage/LetsWorkToGether';
import ResearchContent from './DetailPage/ResearchContent';
import StrategySolution from './DetailPage/StrategySolution';
import DesignSolution from './DetailPage/DesignSolution';
import BrandIDEA from './DetailPage/BrandIDEA';
import WebsiteDesignDevelopment from './DetailPage/WebsiteDesignDevelopment';

const CaseStudy = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>
      <Box>
        <BackgroundContent />
      </Box>
      <Box>
        <ChallengeContent />
      </Box>
      <Box>
        <PainPointDiscoverdSlider />
      </Box>
      <Box>
        <ResearchContent />
      </Box>
      <Box>
        <StrategySolution />
      </Box>
      <Box>
        <DesignSolution />
      </Box>
      <Box>
        <BrandIDEA />
      </Box>

      <Box>
        <WebsiteDesignDevelopment />
      </Box>

      <Box>
        <ConclusionContent />
      </Box>

      <Box>
        <Counter customMarginTop={'-1'} />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </>
  );
};

export default CaseStudy;
