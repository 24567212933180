import React, { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import global from '../../../assets/Navbar/LanguageSelecterIcon/Global.svg'
import india from '../../../assets/Navbar/LanguageSelecterIcon/India.svg';
import UAE from '../../../assets/Navbar/LanguageSelecterIcon/UAE.svg';
import USA from '../../../assets/Navbar/LanguageSelecterIcon/USA.svg';

const LanguageDrawer = ({ isOpen, onOpen, onClose, onSelectCountry }) => {
  const [selectedCountry, setselectedCountry] = useState('Global');
  const data = [
    {
      countryLogo: global,
      countryName: 'Global',
    },
    {
      countryLogo: india,
      countryName: 'India',
    },
    {
      countryLogo: UAE,
      countryName: 'UAE',
    },
    {
      countryLogo: USA,
      countryName: 'USA',
    },
  ];
  const handleCountrySelect = countryName => {
    setselectedCountry(countryName);
    onSelectCountry(countryName); // Update the parent component with selected country
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} placement="left">
        <DrawerOverlay   />
        <DrawerContent
          maxW={['100%', '100%', '456px', '456px', '456px']}
          w={['100%', '456px', '456px', '456px', '456px']}
          paddingTop={['102px']}
        >
          <DrawerCloseButton top={'17px'} />
          <DrawerHeader
            color={'#000'}
            fontSize={['36px']}
            lineHeight={'normal'}
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontWeight={'400'}
            padding={['0px 32px 55px']}
          >
            Choose your location
          </DrawerHeader>
          <DrawerBody padding={'0'}>
            {/* Wrapper */}
            <Box>
              {/* Item */}
              {data.map((el, index) => (
                <Box
                  key={index}
                  display={'flex'}
                  alignItems={'center'}
                  gap={['22px', '22px', '37px', '37px']}
                  padding={['19px 32px 21px']}
                  borderBottom={
                    index !== data.length - 1 ? '1px solid #E9E9E9' : '0'
                  }
                  background={
                    selectedCountry === el.countryName
                      ? '#E1ECFF'
                      : 'transparent'
                  }
                  _hover={{
                    background:
                      selectedCountry === el.countryName
                        ? '#C0D9FF'
                        : '#F5F5F5',
                  }}
                  cursor={'pointer'}
                  onClick={() => handleCountrySelect(el.countryName)}
                >
                  <Box width={['91px', '91px', '91px', '104px']}>
                    <Image
                      src={el.countryLogo}
                      width={'100%'}
                      height={'auto'}
                      objectFit={'cover'}
                      alt="globalIcon"
                    />
                  </Box>

                  <Text
                    as={'p'}
                    fontSize={['24px']}
                    color={
                      selectedCountry === el.countryName ? '#0057FF' : '#333'
                    }
                    fontFamily={'var(--garnett-regular);'}
                    fontWeight={'400'}
                    lineHeight={'normal'}
                  >
                    {el.countryName}
                  </Text>
                </Box>
              ))}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default LanguageDrawer;
