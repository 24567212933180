import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Container,
  Flex,
  Text,
  useBreakpointValue,
  Image,
} from '@chakra-ui/react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper/modules';
import fixIcon from '../../assets/About/clientSays/FixIcon.svg';
import HoverImg from '../../assets/Icons/Slider/whiteAboutJourny/sliderhover.svg';

import LeftCaret from '../../assets/Icons/Slider/whiteAboutJourny/left.svg';
import RightCaret from '../../assets/Icons/Slider/whiteAboutJourny/right.svg';
import brandLogo1 from '../../assets/Home/clientAbout/brand1.svg';
import brandLogo2 from '../../assets/Home/clientAbout/brand2.svg';
import brandLogo3 from '../../assets/Home/clientAbout/brand3.svg';
import brandLogo4 from '../../assets/Home/clientAbout/brand4.svg';
import brandLogo5 from '../../assets/Home/clientAbout/brand5.svg';

const ClinetsSays = () => {
  const sectionHeading = {
    color: '#fff',
    fontSize: ['36px', '36px', '36px', '46px', '58px', '58px'],
    fontWeight: '600',
    lineHeight: 'normal',
    maxW: '618px',
    fontFamily: 'var(--garnett-semibold);',
  };
  const sectionParagraphone = {
    color: '#fff',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontFamily: ' var(--foundersGrotesk-regular);',
    fontWeight: '400',
    lineHeight: 'normal',
    mt: ['22px', '22px', '22px', '15px', '15px', '15px'],
  };
  // Slider Slides Design
  const shortparagraph = {
    color: '#fff',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    lineHeight: 'normal',
    fontWeight: '400',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };
  const clientNameStyle = {
    color: '#fff',
    fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
    mb: '6px',
  };
  const designationStyle = {
    color: '#fff',
    fontSize: ['14px', '14px', '14px', '16px', '16px', '16px'],
    fontWeight: '400',
    lineHeight: 'normal',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };
  // Slider Design
  // Swipper Slider
  const swipperSliderCustomeStyle = {
    // paddingLeft: useBreakpointValue({
    //   base: 'calc(5% + 10px)',
    //   sm: 'calc(5% + 10px)',
    //   md: 'calc(5% + 15px)',
    // }),
    // paddingRight: useBreakpointValue({
    //   base: 'calc(5% + 10px)',
    //   sm: 'calc(5% + 10px)',
    //   md: 'calc(5% + 15px)',
    // }),
  };
  // Data
  const [clintSaysData, setClintSaysData] = useState([]);

  useEffect(() => {
    // Fetching data from API
    axios
      .get('https://cms.wearetenet.com/api/testimonials/?populate=*')
      .then((response) => {
        // Extracting data from the response
        const testimonials = response.data.data.map((item) => {
          const { name: designation, designation: clintName, logo, paragraph } =
            item.attributes;

          // Extracting testimonial text from paragraph structure
          const description = paragraph
            .map((p) => p.children.map((child) => child.text).join(' '))
            .join(' ');

          // Constructing each client data object
          return {
            description,
            clintName,
            designation,
            brandLogo: logo
              ? `https://cms.wearetenet.com${logo.data.attributes.url}`
              : '',
          };
        });

        // Updating state with structured client says data
        setClintSaysData(testimonials);
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
      });
  }, []);
  // Swipper Indicator
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box
      bg={'#0D0E4D'}
      p={[
        '80px 0 80px 0',
        '80px 0 80px 0',
        '80px 0 80px 0',
        '110px 0 115px 0',
        '110px 0 115px 0',
        '110px 0 115px 0',
      ]}
      overflow={'hidden'}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '100%', '80%', '80%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* Headings And Paragph Section */}
        <Box pb={['54px', '54px', '40px', '100px', '100px', '100px']}>
          <Flex
            justifyContent={'space-between'}
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          >
            <Box w={['100%', '100%', '100%', '45%', '50%', '50%']}>
              <Text as="h2" sx={sectionHeading}>
                Our clients love us. See what they have to say.
              </Text>
            </Box>
            <Box w={['100%', '100%', '100%', '40%', '50%', '50%']}>
              <Box maxW={'424px'} ml={['0', '0', '0', 'auto', 'auto', 'auto']}>
                <Text as={'p'} sx={sectionParagraphone}>
                  We look at our engagements differently. For us, it's about
                  recognising that all businesses, B2B or B2C, are essentially
                  P2P (Person To Person).
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Slider Slide Count Number */}
      </Container>

      {/* Navigation */}
      <Container
        maxWidth={['100%', '100%', '100%', '100%', '80%', '80%']}
        m={'0 auto'}
        display={['block', 'block', 'block', 'none', 'none', 'none']}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Text
              as={'span'}
              fontFamily={'var(--garnett-regular)'}
              fontSize={'14px'}
              fontWeight={'400'}
              color={'#fff'}
              visibility={'hidden'}
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text>
          </Box>
          <Flex>
            <Box onClick={() => goNext()} mr={'20px'}>
              <Image src={LeftCaret} cursor={'pointer'} />
            </Box>
            <Box onClick={() => goPrev()}>
              <Image src={RightCaret} cursor={'pointer'} />
            </Box>
          </Flex>
        </Box>
      </Container>
      {/* Slider */}
      <Container
        maxWidth={['100%', '100%', '100%', '100%', '92%', '92%']}
        m={'0 auto'}
      >
        <Box _hover={{ cursor: `url(${HoverImg}), auto` }}>
          <Swiper
            ref={swiperRef}
            observeParents={true}
            observer={true}
            modules={[Navigation, FreeMode]}
            speed={750}
            slidesPerView={'auto'}
            spaceBetween={24}
            style={swipperSliderCustomeStyle}
            onSlideChange={swiper => handleSlideChange(swiper)}
            className="ClientSaysAboutSlider"
          >
            {clintSaysData.map((data, index) => (
              <SwiperSlide key={index}>
                <Box
                  bg={'#0036C2'}
                  p={'20px'}
                  mt={['60px', '60px', '60px', '90px', '90px', '90px']}
                  display={['flex']}
                  flexDirection={['column']}
                  justifyContent={['space-between']}
                  height={[
                    '310px',
                    '310px',
                    '310px',
                    '342px',
                    '350px',
                    '350px',
                  ]}
                  w={['256px', '256px', '300x', '330px']}
                >
                  {/* Client Icons */}
                  <Box>
                    <Flex
                      justifyContent={'space-between'}
                      pb={['0px', '0px', '0px', '0px', '0px', '0px']}
                    >
                      <Box>
                        <Box
                          position={'relative'}
                          top={[
                            '-44px',
                            '-44px',
                            '-44px',
                            '-50px',
                            '-50px',
                            '-50px',
                          ]}
                        >
                          <Image
                            src={fixIcon}
                            w={['58px', '58px', '58px', '89px', '89px', '89px']}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Image
                          src={data.brandLogo}
                          w={['60%', '60%', '54px', '83px', '83px', '83px']}
                          margin={['0 auto']}
                        />
                      </Box>
                    </Flex>
                    <Box>
                      <Text as={'p'} sx={shortparagraph}>
                        {data.description}
                      </Text>
                    </Box>
                  </Box>

                  {/* Short Description */}
                  <Box>
                    {/* Flex container for top description and client name with designation */}
                    <Flex flexDirection="column" justifyContent="space-between">
                      {/* Top Description */}

                      {/* Client Name and Designation */}
                      <Box>
                        <Text as={'h4'} sx={clientNameStyle}>
                          {data.clintName}
                        </Text>
                        <Text as={'h5'} sx={designationStyle}>
                          {data.designation}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};
export default ClinetsSays;
