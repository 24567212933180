import React from 'react'
import CuratedUse from '../Reusable/DetailPageSolutionAndIndustry/CuratedServices'

const CuratedServices = () => {
const services = [
  {
    id: 1,
    number: '01',
    title: 'Brand Strategy',
    description:
      'Develop a comprehensive brand strategy to stand out in the market, tailored to meet your business objectives.',
    link: '/services/brand-strategy',
  },
  {
    id: 2,
    number: '02',
    title: 'Digital Marketing',
    description:
      'Boost your online presence with targeted digital marketing campaigns that deliver measurable results.',
    link: '/services/digital-marketing',
  },
  {
    id: 3,
    number: '03',
    title: 'Creative Design',
    description:
      'Create visually stunning designs that captivate and engage your audience across all platforms.',
    link: '/services/creative-design',
  },
  {
    id: 4,
    number: '04',
    title: 'SEO Optimization',
    description:
      'Improve your website’s search engine rankings with cutting-edge SEO techniques.',
    link: '/services/seo-optimization',
  },
  {
    id: 5,
    number: '05',
    title: 'Content Creation',
    description:
      'Produce high-quality content that speaks to your audience and enhances your brand’s message.',
    link: '/services/content-creation',
  },
  {
    id: 6,
    number: '06',
    title: 'Social Media Management',
    description:
      'Manage your social media presence with strategies that engage your audience and grow your brand.',
    link: '/services/social-media-management',
  },
  {
    id: 7,
    number: '07',
    title: 'SEO Optimization',
    description:
      'Improve your website’s search engine rankings with cutting-edge SEO techniques.',
    link: '/services/seo-optimization',
  },
  {
    id: 8,
    number: '08',
    title: 'Content Creation',
    description:
      'Produce high-quality content that speaks to your audience and enhances your brand’s message.',
    link: '/services/content-creation',
  },
  {
    id: 9,
    number: '09',
    title: 'Social Media Management',
    description:
      'Manage your social media presence with strategies that engage your audience and grow your brand.',
    link: '/services/social-media-management',
  },
];

  return (
    <div>
      <CuratedUse
        sectionPadding={['60px 0 0', '60px 0 0']}
        showMoreButtonColor="#00BA61"
        linkSvgColor="green"
        services={services}
      />
    </div>
  );
}

export default CuratedServices
