import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import arrowImage from '../../assets/Home/clientAbout/arrow.svg';

import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import leftIcon from '../../assets/Icons/Slider/leftIcon.svg';
import rightIcon from '../../assets/Icons/Slider/rightIcon.svg';

const BeyondLimitations = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        let region = pathParts[1];

        if (!['us', 'in', 'ae'].includes(region)) {
          region = 'global';
        }
        const response = await axios.get(
          `https://cms.wearetenet.com/api/homepages?filters[region][$eq]=${region}&populate=*`
        );

        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
        setContentLoaded(true);
      }
    };

    fetchData();
  }, [slug, location]);

  const firstItem = data ? data.attributes : null;
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };

  return (
    <Box
      background={
        'linear-gradient(360deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 39.99%)'
      }
      paddingTop={['80px', '80px', '80px', '80px', '100px', '127px']}
      paddingBottom={['60px', '60px', '60px', '80px', '100px', '127px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
      className="customBeyond"
    >
      <Container
        maxW={['100%', '100%', '90%', '90%', '90%', '90%']}
        className="customBeyond1"
      >
        {/* Section Headings */}

        <Box
          width={['100%', '100%', '100%', '80%', '80%', '70%']}
          marginBottom={['34px', '34px', '34px', '65px', '80px', '95px']}
          className="customBeyond2"
          padding={['0px 10px', '0px 10px', '0px 0px', '0px 0px']}
        >
          <Text
            fontSize={['32px', '32px', '40px', '50px', '58px', '58px']}
            fontFamily={'var(--garnett-regular)'}
            fontWeight={400}
            lineHeight={'normal'}
          >
            Push beyond the limitations.
          </Text>
        </Box>
        <Box
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          ml={'auto'}
          display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
        >
          <Box
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
            justifyContent={'space-between'}
            gap={['197px', '197px', '197px', '157px', '157px', '157px']}
          >
            <Box
              width={['100%', '100%', '100%', '35%', '18%', '18%']}
              marginBottom={['20px', '20px', '20px', 0, 0, 0]}
            >
              <Text
                fontSize={['20px', '20px', '30px', '36px', '36px', '36px']}
                fontFamily={'var(--garnett-regular)'}
                fontWeight={'400'}
                marginRight={['30px', '30px', '30px', '60px', '30px', '30px']}
              >
                {firstItem?.limitation_subheading}
              </Text>
            </Box>
            <Box width={['100%', '100%', '100%', '80%', '80%', '80%']}>
              <Flex
                justifyContent={'space-between'}
                gap={['20px', '20px', '20px', '10px', '10px', '10px']}
              >
                <Box w={['100%', '100%', '100%', '45%', '45%', '45%']}>
                  <Box
                    paddingBottom={[
                      '20px',
                      '20px',
                      '30px',
                      '30px',
                      '65px',
                      '75px',
                    ]}
                  >
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_1?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '15px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation_description_1}
                    </Text>
                  </Box>
                  <Box
                    paddingBottom={[
                      '20px',
                      '20px',
                      '30px',
                      '30px',
                      '50px',
                      '60px',
                    ]}
                  >
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_3?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '20px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation_description_3}
                    </Text>
                  </Box>
                  <Box>
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_5?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '15px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation__description_5}
                    </Text>
                  </Box>
                </Box>
                <Box w={['100%', '100%', '100%', '45%', '45%', '45%']}>
                  <Box
                    paddingBottom={[
                      '20px',
                      '20px',
                      '30px',
                      '30px',
                      '55px',
                      '65px',
                    ]}
                  >
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_2?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '15px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation_description_2}
                    </Text>
                  </Box>
                  <Box
                    paddingBottom={[
                      '20px',
                      '20px',
                      '30px',
                      '30px',
                      '50px',
                      '66px',
                    ]}
                  >
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_4?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '15px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation_description_4}
                    </Text>
                  </Box>
                  <Box>
                    <Image
                      src={`https://cms.wearetenet.com${firstItem?.limitation_icon_6?.data?.attributes?.url ||
                        ''
                        }`}
                      paddingBottom={[
                        '5px',
                        '5px',
                        '5px',
                        '10px',
                        '15px',
                        '15px',
                      ]}
                    />
                    <Text
                      lineHeight={['normal']}
                      fontSize={[
                        '16px',
                        '16px',
                        '20px',
                        '20px',
                        '24px',
                        '28px',
                      ]}
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      color={'#333333'}
                      minHeight={[
                        '95px',
                        '95px',
                        '95px',
                        '105px',
                        '80px',
                        '95px',
                      ]}
                    >
                      {firstItem?.limitation_description_6}
                    </Text>
                  </Box>
                </Box>
              </Flex>

              <Box
                padding={[
                  '68px 0 30px',
                  '68px 0 30px',
                  '68px 0 30px',
                  '68px 0 30px',
                  '53px 0 35px',
                  '65px 0 35px',
                ]}
              >
                <Text
                  fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  fontWeight={'400'}
                  color={'#333333'}
                  maxW={['100%', '100%', '100%', '70%', '60%', '50%']}
                  lineHeight={['normal']}
                >
                  If these brand opportunities could unlock potential in your
                  business, reach out and tell us your story.
                </Text>
              </Box>

              <Link href="/contact-us">
                <Text
                  fontWeight="600"
                  fontSize={['32px', '32px', '32px', '46px', '64px', '64px']}
                  color={'#00763D'}
                  fontFamily={'var(--garnett-semibold);'}
                  textDecoration={'underline'}
                  style={{
                    WebkitFontSmoothing: 'antialiased',
                    textRendering: 'optimizeLegibility',
                  }}
                >
                  Let’s work together!
                </Text>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          // padding={["80px 0", "80px 0", "80px 0", "80px 0", "80px 0", "80px 0"]}
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          display={['flex', 'flex', 'none', 'none', 'none', 'none']}
          margin={['0 auto']}
          paddingBottom={['60px', '60px', '60px', '80px', '80px', '80px']}
          flexDir={['column', 'column', 'column', 'column', 'column', 'column']}
        >
          <Container
            maxWidth={['100%', '100%', '100%', '100%', '95%', '95%']}
            margin={['0 auto']}
          >
            <Box
              marginBottom={['26px', '26px', '26px', '48px', '50px', '80px']}
              display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
              justifyContent={[
                'space-between',
                'space-between',
                'space-between',
                'space-between',
                'space-between',
                'space-between',
              ]}
              alignItems={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
              ]}
              flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            >
              <Text
                as="h3"
                m="0px"
                fontSize={['20px', '20px', '20px', '60px', '68px', '68px']}
                fontFamily="var(--garnett-regular)"
                color={'#333'}
                fontWeight={'400'}
                width={['100%', '100%', '100%', '70%', '60%', '60%']}
                lineHeight={['normal']}
              >
                What if?
              </Text>
            </Box>
          </Container>
          <Container
            maxWidth={['100%', '100%', '100%', '100%', '100%', '100%']}
            margin={['0 auto']}
            padding={'0'}
          >
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              justifyContent="center"
              alignItems="flex-start"
              gap={['20px', '20px', '15px', '15px', '15px', '15px']}
              margin="0 auto"
              maxW="100%"
            >
              <Flex
                justifyContent={['space-between']}
                paddingTop={['20px']}
                width={['100%', '100%', '100%', '90%']}
                padding={['0px 10px', '0px 10px', '0px 10px', '0px']}
                display={['flex', 'flex', 'none', 'none', 'none', 'none']}
                margin={['0 auto']}
              >
                <Text
                  fontSize={['14px']}
                  color={['#333']}
                  fontFamily="var(--garnett-regular)"
                  visibility={'hidden'}
                >
                  {`${currentSlide}/${swiperRef.current?.slides?.length ?? 6}`}
                </Text>
                <Box display={['flex']}>
                  <Box onClick={() => goPrev()} mr={'20px'}>
                    <Image src={leftIcon} cursor={'pointer'} />
                  </Box>
                  <Box onClick={() => goNext()}>
                    <Image src={rightIcon} cursor={'pointer'} />
                  </Box>
                </Box>
              </Flex>

              <Box
                flex={['none', 'none', '1', '1', '1', '1']}
                p={['15px', '15px', '25px', '35px', '35px', '35px']}
                paddingRight={['0px', '0px', '15px', 0, 0, 0]}
                position="relative"
                maxWidth={['100%', '100%', '90%', '50%', '50%', '50%']}
                _hover={{ cursor: `url(${arrowImage}), auto` }}
              >
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1.5}
                  modules={[Navigation, FreeMode]}
                  ref={swiperRef}
                  navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.4,
                      spaceBetween: 20,
                      freeMode: false,
                    },
                    480: {
                      slidesPerView: 1.8,
                      spaceBetween: 20,
                      freeMode: false,
                    },
                    768: {
                      slidesPerView: 2.1,
                      spaceBetween: 24,
                    },
                    992: {
                      slidesPerView: 2.4,
                      spaceBetween: 24,
                    },
                  }}
                  onSlideChange={swiper => handleSlideChange(swiper)}
                >
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_1?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if your brand was your most powerful revenue
                          driving tool?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_3?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if your brand could become the benchmark for
                          success and innovation in your sector?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_5?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if you could unlock the full potential of your
                          business through strategic digital solutions?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_2?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if you were positioned not just to compete, but
                          to dominate your industry?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_4?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if you could harness the power of data to drive
                          decision-making, leaving behind the uncertainty of
                          guesswork?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Flex
                      flexDirection="column"
                      backgroundColor={['#DEF6EB']}
                      padding={['20px']}
                      gap={['60px']}
                      height={['255px']}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${firstItem?.limitation_icon_6?.data?.attributes?.url ||
                          ''
                          }`}
                        alt="Brand Logo"
                        width={['50px']}
                      />

                      <Flex
                        gap={['70px', '70px', '70px', '90px', '80px', '95px']}
                        flexDirection={['column']}
                        paddingTop={['0px']}
                      >
                        <Text
                          color={'#00763D'}
                          fontFamily="var(--foundersGrotesk-regular)"
                          fontWeight="400"
                          lineHeight={['normal']}
                          fontSize={[
                            '18px',
                            '20px',
                            '18px',
                            '18px',
                            '18px',
                            '18px',
                          ]}
                        >
                          What if your websites and mobile apps could provide an
                          unparalleled user experience in increasing engagement
                          and driving growth?
                        </Text>
                      </Flex>
                    </Flex>
                  </SwiperSlide>
                </Swiper>
                <Text
                  paddingTop={['40px']}
                  color={'#333'}
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight="400"
                  lineHeight={['normal']}
                  fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
                >
                  If these brand opportunities could unlock potential in your
                  business, reach out and tell us your story.
                </Text>
                <Link href="/contact-us">
                  <Text
                    paddingTop={['40px']}
                    color="#00763D"
                    fontFamily="var(--garnett-semibold)"
                    fontWeight="600"
                    fontSize={['32px', '32px', '32px', '46px', '64px', '64px']}
                    lineHeight="normal"
                    textDecoration="underline"
                    style={{
                      WebkitFontSmoothing: 'antialiased',
                      textRendering: 'optimizeLegibility',
                    }}
                  >
                    Let’s work together!
                  </Text>
                </Link>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default BeyondLimitations;
