import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Container, Text, Button, Image } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const HeroSection = () => {
  const heroSectionRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  const location = useLocation(); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathParts = location.pathname.split('/');
        let region = pathParts[1]; 
        if (!['us', 'in', 'ae'].includes(region)) {
          region = 'global'; 
        }

        console.log('Region:', region); 

        const apiUrl = `https://cms.wearetenet.com/api/homepages?filters[region][$eq]=${region}&populate=*`;
        console.log('API URL:', apiUrl); 

        const response = await axios.get(apiUrl);

        console.log('API Response:', response.data); 

        if (response.data.data && response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setData(null); 
        }
      } catch (err) {
        console.error('An error occurred while fetching data', err);
        setData(null); 
      } finally {
        setLoading(false);
        setContentLoaded(true); 
      }
    };

    fetchData();
  }, [location]); 

  useEffect(() => {
    if (contentLoaded && heroSectionRef.current) {
      const timeoutId = setTimeout(() => {
        const heroSectionHeight = heroSectionRef.current.clientHeight;
        console.log('Hero Section Height:', heroSectionHeight);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [contentLoaded]);

  // Extract the first item from data if available
  const firstItem = data ? data.attributes : null;


  const renderDescription = description => {
    return description.map((desc, index) => {
      if (desc.type === 'paragraph') {
        return (
          <Text
            as="p"
            key={index}
            fontSize={['22px', '22px', '22px', '28px', '28px', '28px']}
            fontFamily="var(--foundersGrotesk-regular)"
            fontWeight="400"
            lineHeight="normal"
            padding={[
              '24px 0 35px',
              '24px 0 35px',
              '30px 0 35px',
              '35px 0 40px',
              '40px 0 33px',
              '50px 0 43px',
            ]}
            letterSpacing="-0.616px"
            maxW="621px"
          >
            {desc.children.map((child, idx) => {
              if (child.type === 'text') {
                return (
                  <Text
                    as={child.italic ? 'span' : 'span'}
                    key={idx}
                    style={
                      child.italic
                        ? {
                          textDecoration: 'underline',
                          fontWeight: '700',
                          fontStyle: 'italic',
                          paddingRight: '3px',
                        }
                        : {}
                    }
                  >
                    {child.text}
                  </Text>
                );
              }
              return null;
            })}
          </Text>
        );
      }
      return null;
    });
  };

  return (
    <Box
      bg="#0057FF"
      ref={heroSectionRef}
      id="hero-section"
      padding={['60px 0 60px', '60px 0 60px', '90px 0 80px', '90px 0 80px']}
      className="homePageSlider"
    >
      <Container
        width={['100%', '100%', '90%', '90%', '90%', '90%']}
        maxW={['100%', '100%', '100%', '90%', '90%', '90%']}
        marginBottom={['30px', '30px', 0, 0, 0, 0]}
      >
        <Box
          display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
          gap={['51px', '51px', '51px', '51px', '51px', '51px']}
        >
          <Box
            w={['100%', '100%', '100%', '55%', '55%', '55%']}
            color="#FFFFFF"
            display="flex"
            flexDirection="column"
          >
            <Box>
              <Box maxW="692px">
                <Text
                  as="h1"
                  fontSize={['40px', '40px', '40px', '50px', '67px', '78px']}
                  fontFamily="var(--garnett-semibold)"
                  fontWeight={600}
                  lineHeight="normal"
                  color="#fff"
                >
                  {firstItem?.heading}
                </Text>

                {data && renderDescription(firstItem.description)}
              </Box>
              <Box marginBottom={['40px', '40px', '0']}>
                <Button
                  w="106px"
                  bg="#0D0E4D"
                  color="#ffffff"
                  border="2.4px solid #ffffff"
                  borderRadius="0"
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight="400"
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  height="42px"
                  position="relative"
                  _hover={{
                    bg: '#0D0E4D',
                    color: '#ffffff',
                    border: '2.4px solid #ffffff',
                    transform: 'translate3d(0, -3px, 0)',
                  }}
                  transition="transform 0.5s ease"
                >
                  <span
                    className="homeHeroButton"
                    style={{
                      fontSize: '16px',
                      position: 'relative',
                      top: '2px',
                    }}
                  >
                    Get Started
                  </span>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box w={['100%', '100%', '100%', '45%', '45%', '45%']}>
            {firstItem?.image?.data?.attributes?.url ? (
              <Image
                src={`https://cms.wearetenet.com${firstItem.image.data.attributes.url}`}
                w="100%"
                height="auto"
                objectFit="cover"
                alt="Hero Image"
              />
            ) : null}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroSection;
