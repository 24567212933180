import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const approachContent = () => {
  const backgroundText = [
    {
      title:
        'Our journey with Pazazz began with brand discovery workshops and a deep dive into market analysis. We aimed to uncover their unique story and brand attributes, as well as the latest trends and consumer demands in the athleisure segment. We pinpointed key challenges in brand differentiation, user engagement, and digital presence. Armed with these insights, we crafted a strategy to build a distinctive brand identity and a dynamic digital ecosystem tailored to their target audience.',
      description:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
      description2:
        'While Zuora achieved global renown — a publicly traded company powering revenue models for some of the world’s largest enterprises — it was also in a state of rapid innovation. Like other established brands at a turning point in their growth, Zuora’s story had become fragmented. Revisiting the brand’s verbal identity was critical to support its bold new look with messaging to match.<br><br>The team’s vision: to get people excited about the brand again, and tell their story with clarity, consistency, and conviction.<br><br>The team’s vision: to get people excited about the brand again, and tell their story with clarity, consistency, and conviction',
    },
  ];
  return (
    <Container
      //  paddingTop={["60px", "60px", "60px", "80px", "100px", "120px"]}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['50px', '50px', 0, 0, 0, 0]}
      padding={['0']}
    >
      <Image
        paddingTop={['40px', '60px', '70px', '80px', '90px', '150px']}
        display={['none', 'none', 'block', 'block', 'block', 'block']}
        paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
        src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/2.png"
        width={['100%', '100%', '100%', '96%', '96%', '96%']}
        margin={['0 auto']}
      />
      <Image
        paddingTop={['40px', '60px', '70px', '80px', '90px', '150px']}
        display={['block', 'block', 'none', 'none', 'none', 'none']}
        paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
        src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/12.png"
        width={['100%', '100%', '100%', '96%', '96%', '96%']}
        margin={['0 auto']}
      />

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              APPROACH
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '24px', '24px', '24px', '24px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              We spoke with the founder Jasmine Ahluwalia and the ThinkVastu
              team to understand their vision for the brand. We then spoke to a
              lot of their existing customers to better understand them, their
              needs, pain points, & expectations. <br />
              <br />
              We then conducted multiple brand discovery and alignment
              workshops. By diving into their world we were able to understand
              what ThinkVastu has to offer, and what are it’s customer’s looking
              for.
            </Text>
          </Box>
        </Box>
      </Box>

      <Image
        paddingTop={['80px', '80px', '80px', '80px', '100px', '150px']}
        display={['none', 'none', 'block', 'block', 'block', 'block']}
        paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
        src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/3.png"
        width={['100%', '100%', '100%', '96%', '96%', '96%']}
        margin={['0 auto']}
      />
      <Image
        paddingTop={['80px', '80px', '80px', '80px', '100px', '150px']}
        display={['block', 'block', 'none', 'none', 'none', 'none']}
        paddingBottom={['10px', '10px', '20px', '50px', '50px', '50px']}
        src="https://d3us4ynq1qsik6.cloudfront.net/work/think-vastu/13.png"
        width={['100%', '100%', '100%', '96%', '96%', '96%']}
        margin={['0 auto']}
      />
    </Container>
  );
};
export default approachContent;
