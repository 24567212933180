import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import bannerImg from '../../../assets/Solution/heroSection.webp';

import Desktops from '../../../assets/BgPatterns/solution/solutionDesktop.gif';
import Mobile from '../../../assets/BgPatterns/solution/solutionMob.gif';

const HeroSection = () => {
  const Desktop = window.innerWidth >= 768 ? Desktops : Mobile;
  // Add Test Cmnt
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  const bannerText = [
    {
      title: 'Solutions That Redefine Growth',
      description:
        'At Tenet, we architect digital solutions that do more than meet the moment—they define it. Partner with us for strategic innovation that scales your brand and secures its future in a rapidly evolving marketplace.',
    },
  ];
  return (
    <Box
      ref={heroSectionRef}
      id="hero-section"
      background={'#050953'}
      overflow="hidden"
      position="relative"
      p={[
        '80px 0 130px 0',
        '80px 0 130px 0',
        '80px 0 100px 0',
        '55px 0 71px',
        '55px 0 71px',
        '55px 0 71px',
      ]}
      height={['auto', 'auto', 'auto']}
      sx={{
        '@media (min-height: 950px)': {
          height: 'auto',
        },
      }}
    >
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          src={Desktops}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          objectPosition={'right bottom'}
          height={'668px'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        width={['100%', '100%', '100%', '94%', '94%', '94%']}
        maxW={['100%', '100%', '100%', '94%', '94%', '94%']}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        position={'relative'}
      >
        <Breadcrumb
          spacing="8px"
          separator=">"
          color={['#ffffff']}
          // marginBottom={["45px", "45px", "55px", "75px", "80px", "70px"]}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
        >
          <BreadcrumbItem alignItems={['baseline']}>
            <BreadcrumbLink
              as={Link} // Use Link from React Router
              href="/" // Specify the target URL
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              color={['#ffffff']}
              fontFamily="var(--foundersGrotesk-light)"
              fontWeight={400} // No need to specify as string
            >
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem alignItems={['baseline']}>
            <BreadcrumbLink
              href="#"
              fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
              color={['#ffffff']}
              as="p"
              fontFamily="var(--foundersGrotesk-light)"
              fontWeight={'400'}
              className="breadCrumb"
            >
              Solution
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex
          direction={[
            'column-reverse',
            'column-reverse',
            'row',
            'row',
            'row',
            'row',
          ]}
          gap={['40px', '40px', '40px', '0', '0', '0']}
        >
          <Box
            w={['100%', '100%', '100%', '57%', '57%', '57%']}
            color="#FFFFFF"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box>
              <Box maxW={['100%', '100%', '100%', '90%', '90%', '90%']}>
                <Text
                  as="h1"
                  fontSize={['36px', '36px', '40px', '40px', '50px', '58px']}
                  m="0 0 23px 0"
                  fontFamily="var(--garnett-semibold)"
                  lineHeight={['normal']}
                >
                  {bannerText[0].title}
                </Text>
                <Text
                  fontSize={['16px', '16px', '16px', '18px', '20px', '23px']}
                  margin={[
                    '0 0 18px 0',
                    '0 0 18px 0',
                    '0 0 25px 0',
                    '0 0 30px 0',
                    '0 0 30px 0',
                    '0 0 30px 0',
                  ]}
                  as="p"
                  fontFamily="var(--foundersGrotesk-light)"
                  fontWeight={'400'}
                  lineHeight={['normal']}
                  width={['100%', '100%', '100%', '85%', '85%', '85%']}
                >
                  {bannerText[0].description}
                </Text>
              </Box>
              <Box maxW={['100%', '100%', '100%', '60%', '60%', '60%']}>
                <Flex
                  direction={['row', 'row', 'row', 'row', 'row', 'row']}
                  alignItems="center"
                >
                  <Link href="/contact-us">
                    <Button
                      w={['100%', '100%', '100%', '146px', '146px', '180px']}
                      fontSize={[
                        '18px',
                        '18px',
                        '18px',
                        '16px',
                        '16px',
                        '16px',
                      ]}
                      bg="#0057FF"
                      color="#ffffff"
                      borderRadius="0"
                      fontFamily="var(--foundersGrotesk-regular)"
                      fontWeight="400"
                      justifyContent="center"
                      display={[
                        'inline-block',
                        'inline-block',
                        'inline-block',
                        'flex',
                        'flex',
                        'flex',
                      ]}
                      alignItems="center"
                      textAlign="center"
                      height="42px"
                      _hover={{
                        bg: '#0057FF',
                        color: '#ffffff',
                        transform: 'translate3d(0, -3px, 0)',
                      }}
                      transition="transform 0.5s ease"
                    >
                      Let's work together
                    </Button>
                  </Link>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box
            w={['100%', '100%', '100%', '35%']}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src={bannerImg}
              w="100%"
              h="100%"
              objectFit="cover"
              alt="Hero Image"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default HeroSection;
