import { Box, Container, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import image2 from '../../../assets/Strategy/3.svg';
import img3 from '../../../assets/Strategy/6.svg';
import img from '../../../assets/Strategy/7.svg';
import image from '../../../assets/Strategy/fullWidth.webp';
import './Custom.css';

const ceoSection = () => {
  return (
    <Box>
      <Container
        width={['100%', '90%', '100%', '100%', '100%', '100%']}
        maxW={['100%', '90%', '100%', '100%', '100%', '100%']}
        padding={'0'}
        marginTop={['80px', '80px', '80px', '85px', '93px', '113px']}
        // paddingTop={["35px", "35px", "48px", "55px", "63px", "63px"]}
        marginBottom={['50px', '50px', 0, 0, 0, 0]}
        // paddingBottom={["35px", "35px", "48px", "55px", "63px", "69px"]}
      >
        <Box
          display={['flex', 'none', 'none', 'none', 'none', 'none']}
          width={'90%'}
          justifyContent={['center']}
          paddingBottom={['60px']}
          margin={'0 auto'}
        >
          <img src={img} />
        </Box>
        <Flex
          width={['100%', '90%', '90%', '94%', '90%', '83%']}
          maxW={['100%', '90%', '90%', '94%', '90%', '83%']}
          direction={[
            'column',
            'column',
            'column',
            'column',
            'column',
            'column',
          ]}
          gap={['25px', '30px', '35px', '40px', '40px', '40px']}
          margin={['0 auto']}
          justifyContent={['center']}
          alignItems={['center']}
          display={['none', 'flex', 'flex', 'flex', 'flex', 'flex']}
        >
          <img src={img3} />
          {/* <Text
                        as="h1"
                        fontSize={['22px', '24px', '24px', '30px', '30px', '36px']}
                        fontFamily='var(--garnett-regular)'
                        color={["#000000"]}
                        fontWeight={["400"]}
                        width={["100%", "100%", "100%", "80%", "80%", "80%"]}
                        fontStyle={["italic"]}
                        textAlign={"center"}
                        lineHeight={"normal"}
                    >
                        <span>
                            <span data-hasquotes="true">
                                I now see why Tenet was recommended so highly. I've never had such supreme-serving from a vendor in my entire life.
                            </span>
                        </span>
                    </Text>
                    <Text
                        fontSize={['15px', '15px', '15px', '18px', '20px', '20px']}
                        fontFamily='var(--garnett-regular)'
                        color={["#333333"]}
                        fontWeight={["400"]}
                        width={["100%", "100%", "100%", "80%", "80%", "80%"]}
                        textAlign={"center"}
                        lineHeight={"normal"}
                    >
                        <span>
                            Shruti Joshi, Sr. Marketing Manager, Gartner
                        </span>
                    </Text> */}
        </Flex>
        <Box
          marginTop={['35px', '80px', '48px', '95px', '123px', '150px']}
          display={['none', 'none', 'block', 'block', 'block', 'block']}
        >
          <Image src={image} width={['100%']} />
        </Box>
        <Box
          marginTop={['35px', '80px', '48px', '95px', '123px', '150px']}
          display={['block', 'block', 'none', 'none', 'none', 'none']}
        >
          <Image src={image2} width={['100%']} />
        </Box>
      </Container>
    </Box>
  );
};

export default ceoSection;
