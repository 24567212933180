import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const Introduction = () => {
  const introductionText = [
    {
      title: 'INTRODUCTION',
      paragraph:
        'At it’s core, IROS leads the way as a groundbreaking UAE-based Contract Research Organization (CRO), driving excellence in clinical research. IROS is dedicated to improving patients’ lives and shaping the health landscape by conducting high-quality research and achieving groundbreaking insights.',
      paragraph1:
        'We collaborated with key stakeholders from IROS across various business departments, as well as the marketing teams at G42 Healthcare and M42. Together, we embarked on a strategic rebranding journey that included in-depth market research, brand strategy development, identity creation, and much more.',
    },
  ];

  return (
    <>
      <Box paddingTop={['78px', '78px', '78px', '40px', '40px', '40px']}>
        <Container
          width={['90%', '90%', '90%', '80%', '72%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '72%', '72%']}
          padding={['0']}
        >
          {/* Introduction Image */}
          <Box>
            <Box display={['none', 'none', 'none', 'block', 'block', 'block']}>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/iros/1.webp"
                w={['100%']}
                height={'auto'}
                objectFit={'cover'}
              />
            </Box>
          </Box>
          {/* Introduction Title */}
          <Box>
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              textTransform={'uppercase'}
              padding={[
                '0 0 16px',
                '0 0 16px',
                '0 0 16px',
                '40px 0 52px',
                '40px 0 52px',
                '40px 0 52px',
              ]}
            >
              {introductionText[0].title}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              marginBottom={['16px', '16px', '16px', '24px']}
              fontFamily="var(--garnett-regular)"
              color={['#333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '24px', '24px', '24px']}
              m="0 0 0px 0"
              fontFamily="var(--foundersGrotesk-regular);"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph1}
            </Text>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Introduction;
