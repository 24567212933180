import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const introduction = () => {
  const introductionText = [
    {
      title: 'INTRODUCTION',
      paragraph:
        'Pazazz is a forward-thinking lifestyle brand redefining the athleisure market in APAC. By seamlessly merging style and functionality, Pazazz creates products that not only empower individuals but also enhance their active lifestyles with cutting-edge innovation in textile and fabrication processes.',
    },
  ];
  return (
    <>
      <Box paddingTop={['93px', '93px', '93px', '40px', '40px', '40px']}>
        <Container
          width={['90%', '90%', '90%', '80%', '72%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '72%', '72%']}
          padding={['0']}
        >
          {/* Introduction Image */}
          <Box>
            <Box display={['none', 'none', 'none', 'block', 'block', 'block']}>
              <Image
                src="https://d3us4ynq1qsik6.cloudfront.net/work/pazazz/main.webp"
                w={['100%']}
                height={'auto'}
                objectFit={'cover'}
              />
            </Box>
          </Box>
          {/* IntroDuction Title */}
          <Box>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              padding={[
                '0 0 22px',
                '0 0 22px',
                '0 0 22px',
                '40px 0 50px',
                '40px 0 50px',
                '40px 0 50px',
              ]}
            >
              {introductionText[0].title}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              m="0 0 23px 0"
              fontFamily="var(--garnett-regular)"
              margin={['0 auto']}
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph}
            </Text>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default introduction;
