import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import OurClientsLoveUs from '../About/ClinetsSays';
import FeaturedClientsLogos from '../Home/ClientsLogo';
import Counter from '../Reusable/CaseStudy/Counter';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import GrowthJourneyEXP from '../Reusable/GrowthJourneyEXP';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import OurRatings from '../Reusable/OurRatings';
import AdvanceTechUseInEcoo from './AdvanceTechUseInEcoo';
import CheckRevenue from './CheckRevenue';
import CuretedServiceUse from './CuratedServicesUse';
import FaqUse from './FaqUse';
import HeroBanner from './HeroBanner';
import LetsWorkTogether from './LetsWorkToGether';
import OurCapabilities from './OurCapabilities';
import TextWithImgUse from './TextWithImgUse';
import TextWithImgUse1 from './TextWithImgUse1';
import TrustedSolutionText from './TrustedSolutionText';

const SolutionByIndustryDetailPage = () => {
  const { slug } = useParams();
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [slug]);
  return (
    <Box>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <TrustedSolutionText />
      </Box>
      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <TextWithImgUse />
      </Box>
      <Box>
        <OurRatings SecbgColor={'#F5F5F5'} />
      </Box>
      <Box>
        <TextWithImgUse1 />
      </Box>
      <Box>
        <CheckRevenue />
      </Box>
      <Box>
        <CuretedServiceUse />
      </Box>
      <Box>
        <AdvanceTechUseInEcoo />
      </Box>
      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <Counter customMarginTop={'0'} />
      </Box>

      <Box>
        <OurCapabilities />
      </Box>
      <Box>
        <FaqUse />
      </Box>
      <Box>
        <FeaturedClientsLogos />
      </Box>
      <Box>
        <OurClientsLoveUs />
      </Box>
      <Box>
        <GrowthJourneyEXP />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default SolutionByIndustryDetailPage;
