import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  Flex,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
import LeftCaret from '../../../assets/Icons/Slider/leftWhite.svg';
import RightCaret from '../../../assets/Icons/Slider/rightWhite.svg';
import './Custom.css';
import irosImage from '../../../assets/work/iros.webp'

const HeroSection = () => {
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  const backgroundText = [
    {
      img: 'https://d3us4ynq1qsik6.cloudfront.net/work/1.webp',
      title:
        'Strongr: Product Development & Strategy for a Leading Fitness Startup',
      description: 'Project Name, Headline with the caption Max 2 lines.',
      link: '/work/strongr',
    },
    {
      img: irosImage,
      title: 'IROS: Brand Strategy, Identity, and Website Transformation',
      description: 'Project Name, Headline with the caption Max 2 lines.',
      link: '/work/iros',
    },
    {
      img: 'https://d3us4ynq1qsik6.cloudfront.net/work/3.webp',
      title: 'Pazazz: Branding, Visual Identity, and E-Commerce Innovation',
      description: 'Project Name, Headline with the caption Max 2 lines.',
      link: '/work/pazazz',
    },
    {
      img: 'https://d3us4ynq1qsik6.cloudfront.net/work/4.webp',
      title:
        'Angles: E-commerce Excellence, Web Design, and Data-Driven Insights',
      description: 'Project Name, Headline with the caption Max 2 lines.',
      link: '/work/angles',
    },
    {
      img: 'https://d3us4ynq1qsik6.cloudfront.net/work/5.webp',
      title:
        'Think Vastu: Strategic Positioning and Digital Mastery for Harmonious Living',
      description: 'Project Name, Headline with the caption Max 2 lines.',
      link: '/work/think-vastu',
    },
  ];

  return (
    <Box position={['relative']}>
      <Box
        background={['#000000']}
        position={'absolute'}
        left={'0'}
        right={'0'}
        id="hero-section"
        top={'0'}
        bottom={['150px', '150px', '185px', '265px', '300px', '310px']}
        zIndex={'-1'}
      ></Box>
      <Box
        p={[
          '23px 0 36px',
          '23px 0 36px',
          '33px 0 46px',
          '50px 0 70px',
          '50px 0 106px',
          '56px 0 106px',
        ]}
      >
        <Container
          width={['100%', '100%', '100%', '100%', '100%', '100%']}
          maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
          padding={0}
        >
          {/* BreadCrum */}
          <Breadcrumb
            spacing="8px"
            separator=">"
            color={['#ffffff']}
            width={['100%', '100%', '100%', '92%', '92%', '92%']}
            margin={['0 auto']}
            display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
          >
            <BreadcrumbItem alignItems={['baseline']}>
              <BreadcrumbLink
                href="#"
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                color={['#ffffff']}
                as="p"
                fontFamily="var(--foundersGrotesk-light)"
                fontWeight={'400'}
              >
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem alignItems={['baseline']}>
              <BreadcrumbLink
                href="#"
                fontSize={['14px', '14px', '16px', '16px', '16px', '16px']}
                color={['#ffffff']}
                as="p"
                fontFamily="var(--foundersGrotesk-light)"
                fontWeight={'400'}
                className="breadCrumb1"
              >
                Work
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex
            width={['90%', '90%', '90%', '92%', '92%', '92%']}
            margin={['0 auto']}
            direction={[
              'column-reverse',
              'column-reverse',
              'row',
              'row',
              'row',
              'row',
            ]}
            gap={['55px', '55px', '55px', '0', '0', '0']}
            paddingTop={['26px', '30px', '50px', '66px', '80px', '100px']}
            paddingBottom={['40px', '40px', '40px', '80px', '80px', '80px']}
          >
            <Box
              w={['100%', '100%', '100%', '83%', '83%', '83%']}
              color="#FFFFFF"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box>
                <Box maxW={['100%', '100%', '100%', '90%', '90%', '90%']}>
                  <Text
                    as="h1"
                    fontSize={['36px', '36px', '46px', '50px', '50px', '58px']}
                    m="0 0 28px 0"
                    fontFamily="var(--garnett-semibold)"
                    color={['#ffffff']}
                    fontWeight={['400']}
                    lineHeight={'normal'}
                  >
                    Brands, Experience & Radical Collaborations
                  </Text>
                  <Divider
                    borderColor="#B882FF"
                    borderWidth="4px"
                    width={['33%']}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
          <Flex
            justifyContent={['center']}
            paddingLeft={['4%', '4%', '4%', '4%', '4%', '4%']}
          >
            <Box margin="0 auto" maxWidth="100%">
              <Container
                maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
                m={'0 auto'}
                display={['block', 'block', 'block', 'none', 'none', 'none']}
                padding={['0']}
              >
                <Flex
                  justifyContent={['space-between']}
                  paddingTop={['20px']}
                  width={['100%']}
                  display={['flex', 'flex', 'none', 'none', 'none', 'none']}
                  margin={['0 auto']}
                  paddingBottom={['31px']}
                  paddingRight={['8%']}
                >
                  <Text
                    fontSize={['14px']}
                    color={['#ffffff']}
                    fontFamily="var(--garnett-regular)"
                    visibility={'hidden'}
                  >
                    {`${currentSlide}/${
                      swiperRef.current?.slides?.length ?? 6
                    }`}
                  </Text>
                  <Box display={['flex']}>
                    <Box onClick={() => goPrev()} mr={'20px'}>
                      <Image src={LeftCaret} cursor={'pointer'} />
                    </Box>
                    <Box onClick={() => goNext()}>
                      <Image src={RightCaret} cursor={'pointer'} />
                    </Box>
                  </Box>
                </Flex>
              </Container>
              <Box
                width={['100%', '100%', '100%', '100%', '100%', '100%']}
                marginLeft="auto"
                marginRight="0"
                // marginTop={["10px", "20px", "20px", "20px", "30px"]}
                // paddingTop={["45px", "45px", "65px", "85px", "105px", "115px"]}
                // paddingBottom={["45px", "45px", "65px", "85px", "105px", "85px"]}
                _hover={{ cursor: `url(${arrowImage}), auto` }}
              >
                <Swiper
                  ref={swiperRef}
                  observeParents={true}
                  observer={true}
                  modules={[Navigation, FreeMode]}
                  speed={750}
                  slidesPerView={2.7}
                  spaceBetween={25}
                  //     style={swipperSliderCustomeStyle}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.2,
                      spaceBetween: 20,
                      freeMode: false,
                    },
                    480: {
                      slidesPerView: 1.2,
                      spaceBetween: 20,
                      freeMode: false,
                    },
                    768: {
                      slidesPerView: 2.3,
                      spaceBetween: 25,
                    },
                    992: {
                      slidesPerView: 2.3,
                      spaceBetween: 25,
                    },
                  }}
                  onSlideChange={swiper => handleSlideChange(swiper)}
                >
                  {backgroundText.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Link
                        href={item.link ? item.link : '#'}
                        style={{ textDecoration: 'none' }}
                      >
                        <Flex flexDirection="column">
                          <Flex
                            gap={[
                              '10px',
                              '10px',
                              '10px',
                              '10px',
                              '10px',
                              '10px',
                            ]}
                            flexDirection={['column']}
                          >
                            <Image src={item.img} aspectRatio={1.54} />
                            <Text
                              as="p"
                              m="0px"
                              fontSize={[
                                '16px',
                                '18px',
                                '20px',
                                '24px',
                                '26px',
                                '30px',
                              ]}
                              color={'#000000'}
                              fontFamily="var(--garnett-regular)"
                              fontWeight="400"
                              width={[
                                '100%',
                                '100%',
                                '100%',
                                '90%',
                                '90%',
                                '90%',
                              ]}
                              lineHeight={['normal']}
                            >
                              {item.title}
                            </Text>
                            {/* <Text
                                                        fontSize={['12px', '14px', '14px', '16px', '18px', '20px']}
                                                        fontFamily='var(--garnett-regular)'
                                                        color={["#333333"]}
                                                        fontWeight={["400"]}
                                                    >
                                                        {item.description}
                                                    </Text> */}
                          </Flex>
                        </Flex>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};
export default HeroSection;
