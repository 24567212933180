import { Box, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image1 from '../../assets/Career/TextStaticAndSlider/icon1.svg';

const TextStaticsAndSlider = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/career-pages?populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const sectionHeading = {
    fontSize: ['24px', '26px', '28px', '33px', '36px', '36px'],
    fontFamily: 'var(--garnett-regular)',
    fontWeight: '400',
    color: '#333',
    lineHeight: 'normal',
    pb: ['22px', '22px', '22px', '37px', '37px', '37px'],
    maxW: ['334px', '700px', '700px', '600px', '600px', '600px'],
    margin: ['0 auto', '0 auto', '0 auto', '0'],
    textAlign: ['center', 'center', 'center', 'left'],
  };
  const sectionParagraph = {
    fontSize: ['14px', '16px', '18px', '18px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular);',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: ['283px', '383px', '483px', '560px', '560px', '560px'],
    margin: ['0 auto', '0 auto', '0 auto', '0'],
    color: '#333',
    textAlign: ['center', 'center', 'center', 'left'],
  };
  // Statics
  const staticNumber = {
    fontSize: ['24px', '26px', '34px', '38px', '44px', '48px'],
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: '600',
    color: '#333',
    lineHeight: 'normal',
    pb: '5px',
    display: 'inline-block',
  };
  const staticNumberParagraph = {
    fontSize: ['18px', '18px', '18px', '20px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular);',
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#333',
    maxW: ['90%', '70%', '70%', '100%'],
    margin: '0 auto',
  };
  return (
    <Box
      p={[
        '42px 0 0px',
        '42px 0 0px',
        '42px 0 0px',
        '80px 0 0px',
        '128px 0 0px',
        '128px 0 0px',
      ]}
      bg={'linear-gradient(180deg, #F5F5F5 0%, rgba(245, 245, 245, 0.00) 100%)'}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '80%', '80%', '80%']}
        m={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
      >
        <Box display={['block', 'block', 'block', 'flex', 'flex']}>
          <Box
            w={['100%', '100%', '100%', '50%', '50%', '50%']}
            paddingBottom={['40px', '40px', '40px', '0px']}
          >
            <Text h={'h3'} sx={sectionHeading}>
              {firstItem?.badge_heading}
            </Text>
            <Text p={'p'} sx={sectionParagraph}>
              {firstItem?.badge_description}
            </Text>
          </Box>

          <Box
            width={['100%', '100%', '100%', '50%', '50%', '50%']}
            display={['block', 'block', 'block', 'flex', 'flex']}
            gap={'10px'}
            justifyContent={'space-between'}
          >
            {/* Statics */}
            <Box
              display={['flex', 'flex', 'flex', 'block']}
              w={['100%', '100%', '100%', '25%', '25%', '25%']}
              gap={['10px', '40px', '60px', '0px']}
              justifyContent={['center', 'center', 'center', 'left']}
            >
              <Box textAlign={'center'} pb={'25px'}>
                <Text as={'span'} sx={staticNumber}>
                  {firstItem?.badge_point_1_heading}
                </Text>
                <Text as={'p'} sx={staticNumberParagraph}>
                  {firstItem?.badge_point_1_description}
                </Text>
              </Box>
              <Box textAlign={'center'} pb={'25px'}>
                <Text as={'span'} sx={staticNumber}>
                  {firstItem?.badge_point_2_heading}
                </Text>
                <Text as={'p'} sx={staticNumberParagraph}>
                  {firstItem?.badge_point_2_description}
                </Text>
              </Box>
              <Box textAlign={'center'}>
                <Text as={'span'} sx={staticNumber}>
                  {firstItem?.badge_point_3_heading}
                </Text>
                <Text as={'p'} sx={staticNumberParagraph}>
                  {firstItem?.badge_point_3_description}
                </Text>
              </Box>
            </Box>
            {/* Slider */}
            <Box
              w={['100%', '100%', '100%', '60%', '60%', '60%']}
              maxW={['288px', '288px', '288px', '100%']}
              margin={['0 auto', '0 auto', '0 auto', '0']}
            >
              <Swiper
                observeParents={true}
                observer={true}
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay]} // Add Autoplay module
                speed={400}
                slidesPerView={1}
                autoplay={{
                  delay: 3000, // Set delay between slides in milliseconds
                  disableOnInteraction: false, // Allow autoplay to continue after interaction
                }}
              >
                {firstItem?.badge_image?.data.map(imageItem => (
                  <SwiperSlide key={imageItem.id}>
                    <Box
                      bg={'#fff'}
                      boxShadow={'0px 4px 42px 0px rgba(0, 0, 0, 0.08)'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      p={'40px 0 40px'}
                      mb={'60px'}
                    >
                      <Image
                        src={`https://cms.wearetenet.com${imageItem.attributes.url}`} // Dynamically load the image from API
                        alt={imageItem.attributes.alternativeText || 'Image'}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TextStaticsAndSlider;
