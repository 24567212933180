import {
  AspectRatio,
  Box,
  Button,
  Container,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
 
import React from 'react';
import tempBannerx from '../../assets/Temp/servicexBanner.png'
 

 
import Breadcrumb from './BreadCrum';

const HeroBanner = () => {
 
 
  return (
    <Box
      bg="#00342B"
      id="hero-section"
      padding={[
        '92px 0 100px',
        '92px 0 100px',
        '92px 0 100px',
        '55px 0 162px',
        '55px 0 162px',
      ]}
      overflow={'hidden'}
      position="relative"
    >
      <Container
        width={['100%', '100%', '100%', '92%', '92%', '92%']}
        maxW={['100%', '100%', '100%', '92%', '92%', '92%']}
        marginBottom={['30px', '30px', 0, 0, 0, 0]}
        position={'relative'}
        zIndex={'3'}
        padding={['0']}
      >
        {/* BreadCrum */}
        <Breadcrumb />
        <Box
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          gap={['35px', '35px', '35px', '51px', '51px', '51px']}
          justifyContent={'space-between'}
          paddingTop={['0px', '0px', '0px', '40px']}
        >
          <Box
            w={['100%', '100%', '100%', '50%', '50%', '50%']}
            color="#FFFFFF"
            display="flex"
            flexDirection="column"
            padding={[
              '0 20px',
              '0px 20px',
              '0px 20px',
              '10px 0 0 0',
              '20px 0 0 0',
              '30px 0 0 0 ',
            ]}
          >
            <Box>
              {/* Banner Title And Banner Paragraph */}
              <Box maxW={['695px']}>
                {/* Solution Banner Title */}
                <Text
                  as="h1"
                  fontSize={['36px', '36px', '36px', '58px', '68px', '68px']}
                  fontFamily="var(--garnett-semibold)"
                  fontWeight={600}
                  lineHeight={[
                    'normal',
                    'normal',
                    'normal',
                    'normal',
                    'normal',
                    '118px',
                  ]}
                  color={'#fff'}
                >
                  Healthcare Web Design Company
                </Text>
                <Text
                  as="p"
                  fontSize={['14px', '14px', '14px', '18px', '20px', '24px']}
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  padding={[
                    '22px 0 25px',
                    '22px 0 25px',
                    '22px 0 25px',
                    '15px 0 30px',
                    '15px 0 30px',
                    '25px 0 38px',
                  ]}
                  maxW={['695px']}
                >
                  At Tenet, we blend empathy with expertise to deliver
                  exceptional service and innovative solutions, ensuring your
                  brand thrives in today's dynamic world.
                </Text>
              </Box>
              {/* Request consultation Button */}
              <Box
                maxW={['454px']}
                display={['flex']}
                border={'2px solid #fff'}
              >
                <Input
                  placeholder="Email Address"
                  color={'#fff'}
                  borderRadius={'0'}
                  border={'0'}
                  width={['59%']}
                  fontSize={['16px', '16px', '20px', '20px', '20px']}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  paddingTop={['12px']}
                  fontFamily="var(--foundersGrotesk-regular)"
                  _placeholder={{
                    color: '#fff',
                    fontFamily: 'var(--foundersGrotesk-regular)',
                  }}
                  paddingBottom={'8px'}
                />
                <Button
                  width={['41%']}
                  bg="#00763D"
                  color="#ffffff"
                  borderRadius="0"
                  fontFamily="var(--foundersGrotesk-regular)"
                  fontWeight="400"
                  justifyContent="center"
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                  position="relative"
                  borderLeft="2px"
                  borderLeftColor="#fff"
                  paddingTop={['5px']}
                  _hover={{
                    bg: '#00763D',
                    color: '#ffffff',
                  }}
                  fontSize={['14px', '16px', '20px', '20px', '20px']}
                >
                  Request Consultation
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            position="relative"
            w={['100%', '100%', '100%', '44%', '44%', '44%']}
          >
            <AspectRatio ratio={1.36} w="100%" h="100%">
              <Image
                src={tempBannerx}
                w="100%"
                h="100%"
                objectFit="cover"
                alt="Hero Image"
              />
            </AspectRatio>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default HeroBanner;
