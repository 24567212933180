import React, { useRef, useEffect, useState } from 'react';
import { Box, Container, Flex, Text, Image } from '@chakra-ui/react';
import Desktop from '../../assets/BgPatterns/About/aboutDesktop.gif';
import Mobile from '../../assets/BgPatterns/About/aboutMob.gif';
import Breadcrumb from './Breadcrumb';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const HeroBanner = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/abouts?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  const bannerTitle = {
    fontSize: ['35px', '41px', '41px', '41px', '58px', '58px'],
    color: '#fff',
    letterSpacing: '-2.2%',
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: 600,
    pb: '22px',
    lineHeight: ['normal'],
  };

  const bannerParagraph = {
    fontSize: ['16px', '16px', '18px', '22px', '22px', '22px'],
    width: ['100%', '100%', '100%', '100%', '85%', '85%'],
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    lineHeight: 'normal',
  };
  return (
    <Box
      ref={heroSectionRef}
      id="hero-section"
      padding={[
        '95px 0 170px',
        '90px 0 170px',
        '90px 0 170px',
        '56px 0 222px',
        '56px 0 222px',
        '56px 0 222px',
      ]}
      overflow="hidden"
      position="relative"
      backgroundColor={'#0c0949'}
    >
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          src={Desktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
        // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          objectPosition={'right'}
          height={'668px'}
          objectFit={'contain'}
        // objectPosition={'right'}
        />
      </Box>

      <Container
        maxWidth={['100%', '100%', '100%', '95%', '95%', '95%']}
        m="0 auto"
        zIndex={2}
        position="relative"
        padding={['0', '0', '0', '0', '0', '0']}
      >
        <Breadcrumb />
        <Flex
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          paddingTop={['0px', '0px', '0px', '80px', '80px', '100px']}
        >
          <Box
            width={['90%', '90%', '90%', '70%', '70%', '60%']}
            display="flex"
            alignItems="center"
            position={['relative']}
            left={['0', '0', '0', '15px', '15px', '15px']}
            margin={['0 auto']}
          >
            <Box
              pr={['0px', '0px', '0px', '55px', '55px', '55px']}
              mb={['60px', '60px', '70px', '0px', '0px', '0px']}
            >
              <Text as="h1" sx={bannerTitle}>
                {firstItem?.heading}
              </Text>
              <Text as="p" sx={bannerParagraph}>
                {firstItem?.paragraph}
              </Text>
            </Box>
          </Box>
          <Box
            width={['100%', '100%', '100%', '50%', '50%', '50%']}
            position={['relative']}
            left={['0', '0', '0', '15px', '15px', '15px']}
          >
            <Image
              src={`https://cms.wearetenet.com${firstItem?.image?.data?.attributes?.url || ''
                }`}
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeroBanner;
