import { Box } from '@chakra-ui/react';
import React from 'react';
import Marquee from 'react-fast-marquee';
import icon from '../../assets/Home/logoMarquee/icon.svg';
import acePlus from '../../assets/Home/logoMarquee/new/acePlus.svg';
import and from '../../assets/Home/logoMarquee/new/and.svg';
import asdav from '../../assets/Home/logoMarquee/new/asdav.svg';
import balsan from '../../assets/Home/logoMarquee/new/balsan.svg';
import CocaCola from '../../assets/Home/logoMarquee/new/cocacola.svg';
import Delloite from '../../assets/Home/logoMarquee/new/delloite.svg';
import DoorDarsh from '../../assets/Home/logoMarquee/new/doordash.svg';
import g42 from '../../assets/Home/logoMarquee/new/g42.svg';
import gartner from '../../assets/Home/logoMarquee/new/gartner.svg';
import havels from '../../assets/Home/logoMarquee/new/havels.svg';
import iros from '../../assets/Home/logoMarquee/new/iros.svg';
import kawaski from '../../assets/Home/logoMarquee/new/kawaski.svg';
import m24 from '../../assets/Home/logoMarquee/new/m24.svg';
import mercedece from '../../assets/Home/logoMarquee/new/mercedice.svg';
import Momando from '../../assets/Home/logoMarquee/new/mormonda.svg';
import pazzaz from '../../assets/Home/logoMarquee/new/pazzaz.svg';
import PizzaHut from '../../assets/Home/logoMarquee/new/pizzahut.svg';
import strongr from '../../assets/Home/logoMarquee/new/strongr.svg';
import thinkVastu from '../../assets/Home/logoMarquee/new/thinkvastu.svg';

import acePlusMob from '../../assets/Home/logoMarquee/mob/acePlus.svg';
import andMob from '../../assets/Home/logoMarquee/mob/and.svg';
import asdavMob from '../../assets/Home/logoMarquee/mob/asdav.svg';
import balsanMob from '../../assets/Home/logoMarquee/mob/balsan.svg';
import CocaColaMob from '../../assets/Home/logoMarquee/mob/cocacola.svg';
import DelloiteMob from '../../assets/Home/logoMarquee/mob/delloite.svg';
import g42Mob from '../../assets/Home/logoMarquee/mob/g42.svg';
import gartnerMob from '../../assets/Home/logoMarquee/mob/gartner.svg';
import havelsMob from '../../assets/Home/logoMarquee/mob/havels.svg';
import mercedeceMob from '../../assets/Home/logoMarquee/mob/mercedice.svg';
import pazzazMob from '../../assets/Home/logoMarquee/mob/pazzaz.svg';
import strongrMob from '../../assets/Home/logoMarquee/mob/strongr.svg';
// import DoorDarshMob from '../../assets/Home/logoMarquee/mob/doordash.svg';
import irosMob from '../../assets/Home/logoMarquee/mob/iros.svg';
import m24Mob from '../../assets/Home/logoMarquee/mob/m24.svg';
import DoorDarshMob from '../../assets/Home/logoMarquee/new/doordash.svg';
import kawaskiMob from '../../assets/Home/logoMarquee/new/kawaski.svg';
// import kawaskiMob from '../../assets/Home/logoMarquee/mob/kawaski.svg';
import MomandoMob from '../../assets/Home/logoMarquee/mob/mormonda.svg';
import PizzaHutMob from '../../assets/Home/logoMarquee/mob/pizzahut.svg';
import thinkVastuMob from '../../assets/Home/logoMarquee/new/thinkvastu.svg';

import './Custome.css';

const LogoMarquee = () => {
  const logosDesktop = [
    { logo: Delloite },
    { logo: CocaCola },
    { logo: g42 },
    { logo: gartner },
    { logo: DoorDarsh },
    { logo: iros },
    { logo: havels },
    { logo: kawaski },
    { logo: mercedece },
    { logo: m24 },
    { logo: PizzaHut },
    { logo: Momando },
    { logo: strongr },
    { logo: pazzaz },
    { logo: thinkVastu },
    { logo: asdav },
    { logo: acePlus },
    { logo: and },
    { logo: balsan },
  ];

  const logosMobile = [
    { logo: DelloiteMob },
    { logo: CocaColaMob },
    { logo: g42Mob },
    { logo: gartnerMob },
    { logo: DoorDarshMob },
    { logo: irosMob },
    { logo: havelsMob },
    { logo: kawaskiMob },
    { logo: mercedeceMob },
    { logo: m24Mob },
    { logo: PizzaHutMob },
    { logo: MomandoMob },
    { logo: strongrMob },
    { logo: pazzazMob },
    { logo: thinkVastuMob },
    { logo: asdavMob },
    { logo: acePlusMob },
    { logo: andMob },
    { logo: balsanMob },
  ];

  return (
    <Box>
      {/* Display For Desktop */}
      <Box
        bg="#000000"
        borderBottom="1px solid #fff"
        height={'auto'}
        display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
        padding={'10px 0'}
      >
        <Marquee style={{ justifyContent: 'space-between' }}>
          {logosDesktop.map((logoObj, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="100%"
            >
              <img
                src={logoObj.logo}
                alt={`Marquee ${index + 1}`}
                className="marqueeLogo"
              />

              <Box
                ml={['16px', '16px', '32px', '32px', '32px', '32px']}
                mr={['16px', '16px', '32px', '32px', '32px', '32px']}
              >
                <img
                  src={icon}
                  alt="Icon"
                  width={'100%'}
                  height="auto"
                  className="marqueeIcon"
                />
              </Box>
            </Box>
          ))}
        </Marquee>
      </Box>

      {/* Display For Mobile */}
      <Box
        bg="#000000"
        borderBottom="1px solid #fff"
        height={'auto'}
        display={['flex', 'flex', 'none', 'none', 'none', 'none']}
        padding={'10px 0'}
      >
        <Marquee style={{ justifyContent: 'space-between' }}>
          {logosMobile.map((logoObj, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="100%"
            >
              <img
                src={logoObj.logo}
                alt={`Marquee ${index + 1}`}
                className="marqueeLogo"
              />

              <Box
                ml={['12px', '12px', '12px', '12px', '12px', '12px']}
                mr={['12px', '12px', '12px', '12px', '12px', '12px']}
              >
                <img
                  src={icon}
                  alt="Icon"
                  width={'100%'}
                  height="auto"
                  className="marqueeIcon"
                />
              </Box>
            </Box>
          ))}
        </Marquee>
      </Box>
    </Box>
  );
};

export default LogoMarquee;
