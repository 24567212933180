import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const SolutionParagraph = () => {
    const paragraphText = [
        {
            title: 'Empowering change for a diverse clientele, Tenet digital solutions have positively impacted over 10 million users worldwide.',
            description: "Spanning industries, our strategic solutions drive user engagement and brand growth.",
        }
    ];
    return (
        <Container
            background={["#000000"]}
            width={["100%", "100%", "100%", "100%", "100%", "100%"]}
            maxW={["100%", "100%", "100%", "100%", "100%", "100%"]}
            paddingTop={["60px", "60px", "65px", "75px", "95px", "128px"]}
            paddingBottom={["60px", "60px", "65px", "75px", "95px", "128px"]}
            borderBottom={["1px solid white"]}
        >
            <Flex
                direction={['column-reverse', 'column-reverse', 'row', 'row', 'row', 'row']}
                justifyContent={["center"]}
            >
                <Box
                    w={['100%', '100%', '100%', '57%', '67%', '70%']}
                    color='#FFFFFF'
                    display="flex"
                    flexDirection="column"
                    justifyContent="center">
                    <Box>
                        <Text
                            fontSize={['24px', '30px', '35px', '35px', '40px', '48px']}
                            fontFamily='var(--garnett-regular)'
                            fontWeight={["400"]}
                            lineHeight={["normal"]}
                            textAlign={["center"]}
                            marginBottom={["30px", "30px", "30px", "30px", "30px", "30px"]}
                        >
                            {paragraphText[0].title}
                        </Text>
                        <Text
                            fontSize={['18px', '18px', '18px', '22px', '25px', '32px']}
                            w={['100%', '100%', '100%', '57%', '67%', '65%']}
                            fontFamily='var(--foundersGrotesk-light)'
                            fontWeight={"400"}
                            lineHeight={["normal"]}
                            textAlign={["center"]}
                            margin={["0 auto"]}
                        >
                            {paragraphText[0].description}
                        </Text>
                    </Box>
                </Box>
            </Flex>
        </Container>
    );
};
export default SolutionParagraph;