import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const solutionContent = () => {
  const backgroundText = [
    {
      title:
        'After more than a decade in business, Zuora was ready to expand its reach and needed a brand that would position it as the only choice for enterprises working to build, run, or grow a subscription solution in its industry.',
    },
  ];
  return (
    <Container
      paddingTop={['20px', '20px', '20px', '30px', '40px', '40px']}
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
      marginBottom={['80px', '0px', 0, 0, 0, 0]}
      padding={['0']}
    >
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          paddingTop={['40px', '40px', '0px', '0px', '0px', '0px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            {' '}
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
            >
              SOLUTION
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            {' '}
            <Flex
              width={['100%', '100%', '100%', '100%', '100%', '100%']}
              maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
              direction={['column']}
              gap={['30px', '30px', '30px', '30px', '30px', '30px']}
              margin={['0 auto']}
            >
              <Text
                as="h1"
                fontSize={['20px', '24px', '28px', '32px', '36px', '36px']}
                fontFamily="var(--garnett-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                A digital ecosystem to empower your fitness journey.
              </Text>
              <Text
                as="h1"
                fontSize={['16px', '16px', '18px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                With the development of Strongr, we've set a new standard in the
                fitness app domain. This innovative app combines personalized
                fitness plans with real-time tracking features, ensuring a
                tailored workout experience for each user. Its sleek,
                user-friendly interface simplifies navigation, encouraging
                sustained user engagement. Strongr not only exemplifies our
                commitment to merging technology with user-centric design but
                also marks a significant leap forward in making personal fitness
                more accessible and engaging for everyone.
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default solutionContent;
