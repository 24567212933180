import { Box } from '@chakra-ui/react';
import React from 'react';
import OurLeading from './OurLeading';

const OurLeadingUse = () => {
  return (
    <Box>
      <OurLeading
        linkBgColor="#0057FF"
        linkBtnText="Request Consultation"
        linkBoder="2px solid #fff"
        containerBgColor="#0D0E4D"
      />
    </Box>
  );
};

export default OurLeadingUse;
