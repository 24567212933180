import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import desktop from '../../../assets/CaseStudy/Iros/resurch/desktop1.webp';
import mobile from '../../../assets/CaseStudy/Iros/resurch/mobile1.webp';

import desktop2 from '../../../assets/CaseStudy/Iros/resurch/desktop2.webp';
import mobile2 from '../../../assets/CaseStudy/Iros/resurch/mobile2.webp';

import approchDesktop from '../../../assets/CaseStudy/Iros/approch/desktop.webp';
import approchMobile from '../../../assets/CaseStudy/Iros/approch/mobile.webp';
const ResearchContent = () => {
  return (
    <>
      {/* OLd Section Content Pain Discovered Slider */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          paddingTop={['24px', '24px', '24px', '40px', '40px', '40px']}
          marginBottom={['80px', '80px', '80px', '150px', '150px', '150px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              visibility={['hidden']}
              lineHeight={'normal'}
              display={['none', 'none', 'none', 'block', 'block']}
            ></Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            <Text
              as="p"
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--garnett-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
              marginBottom={['16px', '16px', '16px', '18px', '18px']}
            >
              We believe whether for is B2B or B2C, all interactions essentially
              boil down to Person to Person (P2P). And, that’s where we began.
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular);"
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              Speaking to the team members gave us a clear understand of their
              surface level needs, as well as the core needs. We then created a
              tailored strategy that ensured we deliver what is required.
            </Text>
          </Box>
        </Box>

        {/* Resurch Text Part */}

        {/* Start ReSearch  */}
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          marginBottom={['24px', '24px', '24px', '40px', '40px', '40px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              textTransform={'uppercase'}
            >
              RESEARCH
            </Text>
          </Box>
          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            <Text
              as="p"
              color={'#333'}
              width={['100%', '100%', '100%', '90%', '80%', '75%']}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              We began by auditing the existing infrastructure to understand the
              brand's current situation and challenges, ensuring we address
              these issues moving forward.
              <br />
              <br />
              We then conducted multiple brand discovery workshops and extensive
              internal stakeholder interviews to capture the essence of the
              brand and what it means to it’s own people. <br />
              <br />
              Through these comprehensive interviews, we distilled the core
              essence of the IROS, including its personality, values, mission,
              vision, beliefs, and behaviours, etc.
              <br />
              <br />
              The insights gained formed the foundation for articulating
              principles, making IROS ready for expansion while being firmly
              rooted its core values.
            </Text>
          </Box>
        </Box>
      </Box>
      {/* Resurch Image One  */}
      <Box
        maxW={['100%', '100%', '100%', '92%', '92%', '92%']}
        margin={'0 auto'}
        paddingBottom={['24px', '24px', '24px', '40px', '40px', '40px']}
      >
        {/*  ColTwo  Image */}
        <Box
          background={'#f5f5f5'}
          padding={[
            '33px 20px 50px',
            '33px 20px 50px',
            '33px 20px 50px',
            '20px 20px 26px 15px',
            '20px 20px 26px 15px',
            '20px 20px 26px 15px',
          ]}
        >
          {/* Desktop */}
          <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
            <Image width={'100%'} height={'auto'} src={desktop} />
          </Box>
          {/* Mobile */}
          <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
            <Image width={'100%'} height={'auto'} src={mobile} />
          </Box>
        </Box>
      </Box>

      {/* Text */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          marginBottom={['24px', '24px', '24px', '40px', '40px', '40px']}
        >
          <Box
            minW={['201px']}
            paddingBottom={['16px', '16px', '16px', '0']}
          ></Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              visibility={['hidden']}
              fontWeight={['500']}
              lineHeight={'normal'}
              display={['none', 'none', 'none', 'block', 'block']}
            ></Text>
            <Text
              width={['100%', '100%', '100%', '90%', '80%', '75%']}
              as="p"
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              After the brand discovery phase, we performed a comprehensive
              Heuristic Evaluation, competitive benchmarking and Industry Trend
              analysis to ensure that the results remain relevant and stay ahead
              of industry standards. . This also enabled us to tackle issues
              such as accessibility (in accordance with WCAG 2 standards),
              improve scalability, improving on existing industry design trends,
              inconsistencies in visual elements, etc.
              <br />
              <br />
              This process led to the foundation of a new strategy that clearly
              communicates the revitalized IROS brand.
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Image Two Reasurch */}
      <Box
        maxW={['100%', '100%', '100%', '92%', '92%', '92%']}
        margin={'0 auto'}
        paddingBottom={['0px', '0px', '0px', '40px', '40px', '40px']}
      >
        {/*  ColTwo  Image */}
        <Box
          background={'#f5f5f5'}
          padding={[
            '24px 0px 30px',
            '24px 0px 30px',
            '24px 0px 30px',
            '50px 0px',
            '50px 0px',
            '50px 0px',
          ]}
        >
          {/* Desktop */}
          <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
            <Image width={'100%'} height={'auto'} src={desktop2} />
          </Box>
          {/* Mobile */}
          <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
            <Image width={'100%'} height={'auto'} src={mobile2} />
          </Box>
        </Box>
      </Box>

      {/* Approch */}

      <Box
        maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
        margin={['0 auto']}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        <Box
          display={['block', 'block', 'block', 'flex']}
          direction={['column', 'column', 'row', 'row', 'row', 'row']}
          justifyContent={['space-between']}
          alignItems={['baseline']}
          gap={['10%']}
          margin={['0 auto']}
          paddingTop={['16px', '18px', '20px', '26px', '30px', '32px']}
          marginBottom={['60px', '60px', '60px', '40px', '40px', '40px']}
        >
          <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
            <Text
              as="h1"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              textTransform={'uppercase'}
            >
              APPROACH
            </Text>
          </Box>

          <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
            <Text
              as="p"
              width={['100%', '100%', '100%', '90%', '80%', '75%']}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight={'400'}
              lineHeight={'normal'}
            >
              There is no one process that fits all. And, even if there were -
              we don’t believe in it. Cookie cutter processes yield cookie
              cutter results.
              <br />
              <br />
              Organizations are different, people are different, business
              challenges are different and that shapes how we work.
              <br />
              <br />
              We started with distilling the DNA and soul of the brand, before
              moving on to design and concept explorations.
              <br />
              <br />
              The culmination of these efforts is aimed at ensuring that the
              revamped identity and website are meticulously curated to address
              and rectify all identified issues, delivering a brand that stands
              out.
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Approch Images */}
      <Box>
        {/*  ColTwo  Image */}
        <Box>
          {/* Desktop */}
          <Box display={['none', 'block', 'block', 'block', 'block', 'block']}>
            <Image width={'100%'} height={'auto'} src={approchDesktop} />
          </Box>
          {/* Mobile */}
          <Box display={['block', 'none', 'none', 'none', 'none', 'none']}>
            <Image width={'100%'} height={'auto'} src={approchMobile} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResearchContent;
