import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import LetsWorkTogether from '../About/LetsWorksToGether';
import RelatedArticle from '../Home/Stories';
import HeroBanner from './HeroBanner';
import MeetOurPartners from './MeetOurPartners';
import PartnershipMarquee from './PartnershipMarquee';
import PartnersProgram from './PartnersProgram';
import WhyChooseTenet from './WhyChooseTenet';

const Partners = () => {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          'https://cms.wearetenet.com/api/partner-with-uses?populate=*'
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, []);
  return (
    <Box>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <PartnershipMarquee />
      </Box>
      <Box>
        <PartnersProgram />
      </Box>
      <Box>
        <MeetOurPartners />
      </Box>
      <Box>
        <WhyChooseTenet />
      </Box>
      <Box>
        <RelatedArticle />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default Partners;
