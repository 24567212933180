import { Box, Container, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const TeamWorkGallary = () => {
  return (
    <Box mb={['0px', '0px', '0px', '0px', '0px', '0px']}>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '80%', '80%']}
        m={'0 auto'}
      >
        {/* Headings */}
        <Box
          maxW={'1040px'}
          p={[
            '40px 0 40px 0',
            '40px 0 40px 0',
            '40px 0 40px 0',
            '125px 0 75px 0',
            '125px 0 75px 0',
            '125px 0 75px 0',
          ]}
        >
          <Text
            as={'h2'}
            fontSize={['32px', '33px', '33px', '48px', '48px', '48px']}
            fontFamily={'var(--garnett-regular)'}
            fontWeight={'400'}
            lineHeight={'normal'}
          >
            Our talented and diverse team works together for one purpose—to
            unlock the potential of those around us.
          </Text>
        </Box>
      </Container>
      {/* Gallery */}
      <Box bg={'#000'}>
        <Flex
          display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
          direction={['column', 'column', 'column', 'row', 'row', 'row']}
        >
          <Box w={['100%', '100%', '100%', '100%', '100%', '100%']}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source
                src="https://d2elmmls1zw4az.cloudfront.net/tenet/Comp+slow+web.mp4"
                type="video/mp4"
              />
            </video>
          </Box>
        </Flex>
        {/* MobileImage */}
        <Box display={['block', 'block', 'block', 'none', 'none', 'none']}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            <source
              src="https://d2elmmls1zw4az.cloudfront.net/tenet/Comp+slow+web.mp4"
              type="video/mp4"
            />
          </video>
        </Box>
      </Box>
    </Box>
  );
};

export default TeamWorkGallary;
