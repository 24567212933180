import React, { useState, useEffect } from 'react';
import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import TimeArrow from '../../assets/Footer/arrow.svg';
import NewYorkIcon from '../../assets/Footer/Ny.svg';
import LondanIcon from '../../assets/Footer/Ldn.svg';
import DxbIcon from '../../assets/Footer/Dxb.svg';
import DelhiIcon from '../../assets/Footer/Dl.svg';
import Sg from '../../assets/Footer/Sg.svg';
import SiteLogo from '../../assets/Footer/site-logo.svg';
import img1 from '../../assets/Footer/1.webp';
import img2 from '../../assets/Footer/2.webp';
import img3 from '../../assets/Footer/3.webp';
import img4 from '../../assets/Footer/4.webp';

const Footer = () => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h12',
      };
      const newYorkTime = now.toLocaleString('en-US', {
        ...options,
        timeZone: 'America/New_York',
      });
      const londonTime = now.toLocaleString('en-GB', {
        ...options,
        timeZone: 'Europe/London',
      });
      const dubaiTime = now.toLocaleString('en-US', {
        ...options,
        timeZone: 'Asia/Dubai',
      });
      const singaporeTime = now.toLocaleString('en-US', {
        ...options,
        timeZone: 'Asia/Singapore',
      });
      const delhiTime = now.toLocaleString('en-IN', {
        ...options,
        timeZone: 'Asia/Kolkata',
      });

      setTime({
        newYork: newYorkTime,
        london: londonTime,
        dubai: dubaiTime,
        singapore: singaporeTime,
        delhi: delhiTime,
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box bg={'#000000'}>
      <Box
        bg={'#111111'}
        paddingTop={['60px', '60px', '60px', '45px', '60px', '60px']}
        paddingBottom={['20px', '20px', '20px', '45px', '60px', '60px']}
        display={['none', 'none', 'none', 'block', 'block', 'block']}
      >
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '80%', '80%']}
          display={['none', 'none', 'none', 'block', 'block', 'block']}
        >
          <Box
            display={'flex'}
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent="space-between"
            gap={['35px', '35px', '2px', '0', '0', '0']}
          >
            <Box
              flex={['50%', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={NewYorkIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  NY
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.newYork}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['50%', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={LondanIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  LDN
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.london}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={DxbIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  DXB
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.dubai}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={DelhiIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  DL
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.delhi}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={Sg} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '20px', '24px', '24px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  SG
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.singapore}
                </Text>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        bg={'#111111'}
        paddingTop={['44px']}
        paddingBottom={['44px']}
        display={['block', 'block', 'block', 'none', 'none', 'none']}
      >
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '80%', '80%']}
          display={['block', 'block', 'block', 'none', 'none', 'none']}
        >
          <Box
            display={'flex'}
            flexDirection={['row', 'row', 'row']}
            justifyContent="space-between"
            gap={['35px', '35px', '35px', '0', '0', '0']}
            paddingBottom={'44px'}
          >
            <Box
              flex={['50%', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={NewYorkIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '18px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  NY
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.newYork}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['50%', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={LondanIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '18px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  LDN
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.london}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={['row', 'row', 'row']}
            justifyContent="space-between"
            gap={['35px', '35px', '35px', '0', '0', '0']}
            paddingBottom={['44px']}
          >
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={DxbIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '18px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  DXB
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.dubai}
                </Text>
              </Box>
            </Box>
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={DelhiIcon} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '18px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  DL
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.delhi}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={['row', 'row', 'row']}
            justifyContent="space-between"
            gap={['35px', '35px', '35px', '0', '0', '0']}
          >
            <Box
              borderLeft={[
                'none',
                'none',
                'none',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
                '1px solid #B5B5B5',
              ]}
              flex={['1', '20%']}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Box>
                <Image src={Sg} />
              </Box>
              <Box>
                <Text
                  color={'#fff'}
                  display={'flex'}
                  fontSize={['16px', '16px', '18px', '18px']}
                  fontFamily={'var(--garnett-regular)'}
                  fontWeight={'400'}
                  textTransform={['uppercase']}
                  className="footerCustomeFontSizeFor290"
                >
                  SG
                  <Image m={'0px 5px'} src={TimeArrow} />
                  {time && time.singapore}
                </Text>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        paddingTop={['60px', '60px', '60px', '45px', '60px', '60px']}
      >
        <Box
          display={['flex']}
          flexDirection={['column']}
          gap={['15px', '15px', '15px', '18px', '20px', '20px']}
          marginBottom={['25px', '25px', '25px', '32px', '32px', '32px']}
        >
          <Text
            as="h3"
            fontFamily={'var(--garnett-regular)'}
            fontWeight={'400'}
            fontSize={['24px', '24px', '24px', '24px', '24px', '24px']}
            color={'#fff'}
          >
            Simplifying Complexities
          </Text>
        </Box>
        <Box
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          justifyContent={'space-between'}
        >
          <Box>
            <Box display={['flex']} flexDirection={['column']} gap={['13px']}>
              <Text
                as="p"
                fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                fontFamily={'var(--foundersGrotesk-regular)'}
                fontWeight={'400'}
                color={'#fff'}
              >
                Call us at:
              </Text>
              <Box display={'flex'} flexDirection={'column'} gap={['13px']}>
                <Link
                  color={'#fff'}
                  fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  to="tel:+971585510094"
                >
                  <Image
                    src={img1}
                    display="inline"
                    mr={2}
                    verticalAlign="middle"
                    width={['25px']}
                  />
                  U.A.E: +971 58 551 0094
                </Link>
                <Link
                  color={'#fff'}
                  fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  to="tel:+918318136998"
                >
                  <Image
                    src={img2}
                    display="inline"
                    mr={2}
                    verticalAlign="middle"
                    width={['25px']}
                  />
                  India: +91 83181 36998
                </Link>
                <Link
                  color={'#fff'}
                  fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  to="tel:+447418379209"
                >
                  <Image
                    src={img3}
                    display="inline"
                    mr={2}
                    verticalAlign="middle"
                    width={['25px']}
                  />
                  UK: +44 7 418379209
                </Link>
                <Link
                  color={'#fff'}
                  fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  to="tel:+19176365898"
                >
                  <Image
                    src={img4}
                    display="inline"
                    mr={2}
                    verticalAlign="middle"
                    width={['25px']}
                  />
                  USA: +1 917 6365898
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            display={'flex'}
            gap={['20px', '25px', '25px', '30px', '45px', '65px']}
            width={['100%', '100%', '100%', '50%', '35%', '35%']}
            marginTop={['15%', '15%', '15%', 0, 0, 0]}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              w={'100%'}
              gap={['20px']}
              fontFamily={'var(--foundersGrotesk-regular)'}
              fontWeight={'400'}
              fontSize={['16px', '18px', '18px', '20px', '20px', '20px']}
            >
              <Link href="/about-us" rel="nofollow" color={'#ffffff'}>
                About
              </Link>
              <Link href="/our-services" rel="nofollow" color={'#ffffff'}>
                Services
              </Link>
              <Link href="/solutions" rel="nofollow" color={'#ffffff'}>
                Solutions
              </Link>
              <Link href="/work" color={'#ffffff'} rel="nofollow">
                Work
              </Link>

              <Link href="/resources" color={'#ffffff'} rel="nofollow">
                Resources
              </Link>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              w={'100%'}
              fontFamily={'var(--foundersGrotesk-regular)'}
              fontWeight={'400'}
              gap={['20px']}
              fontSize={['16px', '18px', '18px', '20px', '20px', '20px']}
            >
              <Link href="/career" color={'#ffffff'} rel="nofollow">
                Career
              </Link>
              <Link
                href="/tools-and-technologies"
                rel="nofollow"
                color={'#ffffff'}
              >
                Tools & Technologies
              </Link>
              <Link href="/partner-with-us" rel="nofollow" color={'#ffffff'}>
                Partner with us
              </Link>
              <Link href="/white-label-agency" rel="nofollow" color={'#ffffff'}>
                White Label Agency
              </Link>
              <Link href="/contact-us" rel="nofollow" color={'#ffffff'}>
                Contact us
              </Link>
            </Box>
          </Box>
        </Box>
        <Flex
          justifyContent={'space-between'}
          marginTop={['57px', '57px', '60px', '70px', '85px', '85px']}
          marginBottom={['15px', '15px', '15px', '15px', '15px', '18px']}
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
        >
          <Box>
            <Text
              as="p"
              color={'#fff'}
              fontSize={['16px', '16px', '16px', '16px', '16px', '16px']}
              fontWeight={'400'}
              fontFamily={' var(--foundersGrotesk-regular);'}
            >
              For any queries, reach out to us at:&nbsp;&nbsp;
              <Link
                href="mailto:hello@wearetenet.com"
                textDecoration={'underline'}
                fontWeight={'600'}
              >
                hello@wearetenet.com
              </Link>
            </Text>
          </Box>
          <Box
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
            alignItems={'baseline'}
            justifyContent={'space-between'}
            marginTop={['40px', '40px', '40px', '0px', '0px', '0px']}
            w={['100%', '100%', '100%', '50%', '35%', '35%']}
          >
            <Box
              gap={['16px', '16px', '16px', '14px', '14px', '14px']}
              display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
              flexDirection={['row']}
            >
              <Link
                rel="nofollow"
                color={'#fff'}
                fontSize={['14px', '14px', '14px', '14px', '14px', '14px']}
                fontWeight={'400'}
                fontFamily={' var(--foundersGrotesk-regular);'}
              >
                Privacy Policy
              </Link>
              <Link
                rel="nofollow"
                color={'#fff'}
                fontSize={['14px', '14px', '14px', '14px', '14px', '14px']}
                fontWeight={'400'}
                fontFamily={' var(--foundersGrotesk-regular);'}
              >
                Cookie Policy
              </Link>
              <Link
                rel="nofollow"
                color={'#fff'}
                fontSize={['14px', '14px', '14px', '14px', '14px', '14px']}
                fontWeight={'400'}
                fontFamily={' var(--foundersGrotesk-regular);'}
              >
                Terms & Conditions
              </Link>
            </Box>
            <Box>
              <Text
                as={'p'}
                marginLeft={['0px', '0px', '0px', '16px', '16px', '16px']}
                marginTop={['18px', '18px', '18px', 0, 0, 0]}
                marginBottom={['43px', '40px', '40px', 0, 0, 0]}
                fontSize={['14px', '14px', '14px', '16px', '16px', '16px']}
                fontWeight={'400'}
                color={'#fff'}
                fontFamily={' var(--foundersGrotesk-regular);'}
              >
                {new Date().getFullYear()} © tenet
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box
          border={'1px solid'}
          borderColor={'#fff'}
          marginBottom={['15px', '15px', '15px', '20px', '30px', '30px']}
        ></Box>
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingBottom={['35px', '35px', '35px', '20px', '20px', '20px']}
        >
          <Box>
            <Image
              src={SiteLogo}
              width={['50%', '50%', '50%', 'auto', 'auto', 'auto']}
            />
          </Box>
          <Box>
            <Text
              as={'h3'}
              color={'#fff'}
              fontSize={['36px', '36px', '36px', '35px', '71px', '71px']}
              fontFamily={'var(--garnett-regular)'}
              fontWeight={'400'}
            >
              ©{new Date().getFullYear()}
            </Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
