import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import FloatingWhatsAppButton from '../src/components/Navbar/FloatingWhatsapp';
import './App.css';
import AllRoutes from './routes/AllRoutes';
import './styles/font.css';

// Header 
import Navbar from './components/Navbar/Navbar';

// Footer
import Footer from './components/Navbar/Footer';

// Newsletter 
import NewsLetter from './components/Navbar/NewsLetter';

// Popups
import ProActiveEngagement from './components/Popups/ProActiveEngagement/ProActiveEngagement';

// Garnett Font
import "./fonts/Garnett-Black.woff2";
import "./fonts/Garnett-BlackItalic.woff2";
import "./fonts/Garnett-Bold.woff2";
import "./fonts/Garnett-BoldItalic.woff2";
import "./fonts/Garnett-Light.woff2";
import "./fonts/Garnett-LightItalic.woff2";
import "./fonts/Garnett-Medium.woff2";
import "./fonts/Garnett-MediumItalic.woff2";
import "./fonts/Garnett-Regular.woff2";
import "./fonts/Garnett-RegularItalic.woff2";
import "./fonts/Garnett-Semibold.woff2";
import "./fonts/Garnett-SemiboldItalic.woff2";

// Founders Grotesk Font 
import "./fonts/FoundersGrotesk-Bold.woff2";
import "./fonts/FoundersGrotesk-BoldItalic.woff2";
import "./fonts/FoundersGrotesk-Light.woff2";
import "./fonts/FoundersGrotesk-LightItalic.woff2";
import "./fonts/FoundersGrotesk-Medium.woff2";
import "./fonts/FoundersGrotesk-MediumItalic.woff2";
import "./fonts/FoundersGrotesk-Regular.woff2";
import "./fonts/FoundersGrotesk-RegularItalic.woff2";
import "./fonts/FoundersGrotesk-Semibold.woff2";
import "./fonts/FoundersGrotesk-SemiboldItalic.woff2";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0
      },
      a: {
        textDecoration: 'none !important',
      },
      '.textDecoration': {
        textDecoration: 'underline !important',
      },
    }
  }
});

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WWP7QZNX'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  const isHomePage = window.location.pathname === '/';
  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <Navbar isHomePage={isHomePage} />
        <div>
          <AllRoutes />
        </div>

        {/* <ProActiveEngagement /> */}
        {/* <WebsiteExitOfferPopup /> */}

        {!isHomePage && <NewsLetter />}
        {!isHomePage && <Footer />}
        {!isHomePage && <FloatingWhatsAppButton />}
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;