import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const convertParagraphToText = (paragraph) => {
  if (!paragraph || !paragraph.length) return '';

  return paragraph.map(p => {
    if (p.type === 'paragraph') {
      return p.children.map(child => {
        if (child.type === 'text') {
          return child.text;
        }
        return '';
      }).join('');
    }
    return '';
  }).join('\n');
};

const fetchCombinedData = async () => {
  try {
    const [featuredResponse, insightResponse] = await Promise.all([
      axios.get('https://cms.wearetenet.com/api/general-blogs?filters[priority][$eq]=true&populate=*'),
      axios.get('https://cms.wearetenet.com/api/insight-blogs?filters[priority][$eq]=true&populate=*'),
    ]);
    const baseUrl = 'https://cms.wearetenet.com';

    const createLinkUrl = (slug) => `/resources/${slug}`;

    const featuredData = featuredResponse.data.data.map(item => {
      const slug = item.attributes.slug;

      return {
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        linkText: item.attributes.category,
        linkUrl: createLinkUrl(slug),
      };
    });
    const insightData = insightResponse.data.data.map(item => {
      const category = item.attributes.category;
      const slug = item.attributes.slug;
      const linkUrl = `/resources/${category}/${slug}`;

      return {
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        linkText: item.attributes.category,
        linkUrl,
      };
    });

    return [...featuredData, ...insightData];
  } catch (error) {
    console.error('Error fetching data', error);
    return [];
  }
};

const ImgWithText = () => {
  const [slide, setSlide] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchCombinedData();
      if (data.length > 0) {
        setSlide(data[0]); // Assuming you want the first item
      }
    };

    loadData();
  }, []);

  if (!slide) return null; // Handle case where slide data is not yet loaded

  return (
    // Main Wrapper
    <Box paddingTop={["40px", "60px"]}>
      <a
        href={slide.linkUrl}
        style={{ textDecoration: 'none', color: 'inherit' }}
        display="inline-block"
      >
        {/* Container */}
        <Box maxW={['100%']} margin="0 auto">
          {/* Content Wrapper */}
          <Box
            display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
            backgroundColor={'#0D0E4D'}
          >

            {/* Image Box */}
            <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
              <Image
                src={slide.image}
                w={['100%']}
                height={['100%', '100%', '100%', '100%', '100%', '100%']}
                objectFit={'cover'}
                alt="Description of image"
              />
            </Box>

            {/* Text Content Box */}
            <Box
              display={['flex']}
              alignItems={'center'}
              w={['100%', '100%', '100%', '50%', '50%', '50%']}
            >
              <Box
                padding={[
                  '28px 27px 46px 37px',
                  '28px 27px 46px 37px',
                  '28px 27px 46px 37px',
                  '0px 56px 0px 76px',
                  '0px 56px 0px 76px',
                  '0px 56px 0px 76px',
                ]}
              >
                <Heading
                  as="h2"
                  fontSize={['20px', '20px', '20px', '20px', '36px', '36px']}
                  color={'#fff'}
                  lineHeight={'normal'}
                  fontWeight={400}
                  fontFamily={'var(--garnett-regular)'}
                  maxW={['266px', '266px', '100%', '100%', '100%', '100%']}
                >
                  {slide.title}
                </Heading>
                <Text
                  as="p"
                  color={'#fff'}
                  fontSize={['14px', '14px', '14px', '14px', '20px', '20px']}
                  fontWeight={400}
                  lineHeight={'normal'}
                  noOfLines={3}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  margin={[
                    '12px 0',
                    '12px 0',
                    '12px 0',
                    '15px 0',
                    '15px 0',
                    '15px 0',
                  ]}
                >
                  {slide.description}
                </Text>
                <Link
                  href={slide.linkUrl}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  display={'inline-block'}
                  fontSize={['13px', '13px', '13px', '13px', '16px', '16px']}
                  color={'#fff'}
                  fontWeight={400}
                  backgroundColor={'#00763D'}
                  padding={[
                    '6px 8px 2px',
                    '6px 8px 2px',
                    '6px 8px 2px',
                    '10px 10px 8px',
                    '10px 10px 8px',
                    '10px 10px 8px',
                  ]}
                  textTransform={["capitalize"]}
                >
                  {slide.linkText}
                </Link>
              </Box>
            </Box>

          </Box>
        </Box>
      </a>
    </Box >
  );
};

export default ImgWithText;
