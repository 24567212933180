import { Box, Container, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import Col2 from '../../assets/Upcoming/1.svg';
import Col1 from '../../assets/Upcoming/2.svg';
import Col3 from '../../assets/Upcoming/3.svg';

const UpComing = () => {
  return (
    <Box bg={'#000'} p={['40px', '50px', '60px', '60px', '60px', '60px']}>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
      >
        <Flex
          alignItems={'center'}
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
            'row',
            'row',
          ]}
        >
          <Box
            w={['100%', '100%', '100%', '50%', '50%', '50%']}
            mt={['40px', '40px', '40px', '0px', '0px', '0px']}
          >
            <Box
              w={['100%', '100%', '100%', '90%', '90%', '90%']}
              display={['none', 'none', 'block', 'block', 'block', 'block']}
            >
              <Image src={Col1} />
            </Box>
            <Box
              w={['100%', '100%', '100%', '90%', '90%', '90%']}
              display={['block', 'block', 'none', 'none', 'none', 'none']}
            >
              <Image src={Col3} />
            </Box>
            {/* Return Button */}
            <Box mt={'37px'}>
              <a
                rel="nofollow"
                href="/"
                style={{
                  background: '#00763D',
                  padding: '13px 14px 7px',
                  fontSize: [
                    '10.607px',
                    '10.607px',
                    '10.607px',
                    '20px',
                    '20px',
                    '20px',
                  ],
                  color: '#fff',
                  display: 'inline-block',
                  fontFamily: 'var(--foundersGrotesk-regular)',
                  fontWeight: '400',
                }}
              >
                Return to home
              </a>
            </Box>
          </Box>
          <Box
            w={['100%', '100%', '100%', '50%', '50%', '50%']}
            display={'flex'}
            justifyContent={'end'}
          >
            <Image
              src={Col2}
              w={['100%', '100%', '100%', '90%', '90%', '90%']}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default UpComing;
