import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Desktop from '../../assets/BgPatterns/Partners/banner.gif';
import Mobile from '../../assets/BgPatterns/Partners/Mobile.gif';
import Breadcrumb from './Breadcrumb';

const HeroBanner = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/partner-with-uses?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  const bannerTitle = {
    fontSize: ['36px', '37px', '40px', '48px', '58px', '58px'],
    color: '#fff',
    letterSpacing: '-2.2%',
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: 600,
    pb: '22px',
    lineHeight: [
      '40.79px',
      '40.79px',
      '40.79px',
      '80.79px',
      '80.79px',
      '80.79px',
    ],
  };
  const bannerParaGraph = {
    fontSize: ['14px', '14px', '18px', '20px', '24px', '24px'],
    color: '#fff',
    fontFamily: 'var(--foundersGrotesk-regular)',
    fontWeight: '400',
    maxW: ['353px', '353px', '425px', '570px', '625px', '625px'],
    lineHeight: ['16.8px', '16.8px', '16.8px', '28.8px'],
    letterSpacing: ['-2.2%', '-2.2%', '-2.2%', ''],
  };
  return (
    <Box
      minH={['553px', '553px', '553px', '750px', '750px']}
      padding={[
        '95px 0 50px',
        '95px 0 50px',
        '95px 0 50px',
        '55px 0 200px',
        '55px 0 200px',
        '55px 0 200px',
      ]}
      display={['block', 'block', 'block', 'flex']}
      alignItems={'center'}
      overflow={'hidden'}
      position="relative"
      ref={heroSectionRef}
      id="hero-section"
      background={'#000'}
    >
      {/* BackGround Video */}
      <Box
        position={['absolute']}
        left={'0'}
        right={'0'}
        top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
        bottom={'0'}
        zIndex={['0', '0', '0', '0', '0']}
      >
        {/* For Desktop */}
        <Image
          display={['none', 'none', 'block', 'block', 'block', 'block']}
          src={Desktop}
          width={'100%'}
          height={'100%'}
          objectFit={'cover'}
          // objectPosition={'right'}
        />
        {/* For Mobile */}
        <Image
          display={['block', 'block', 'none', 'none']}
          src={Mobile}
          width={'100%'}
          objectPosition={'right'}
          height={'254px'}
          objectFit={'contain'}
          // objectPosition={'right'}
        />
      </Box>
      <Container
        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
        m={'0 auto'}
        zIndex={2}
        position={'relative'}
      >
        {/* BreadCrum */}
        <Box
          pb={['42px', '42px', '50px', '135px', '135px']}
          display={['none', 'none', 'block', 'block']}
        >
          <Breadcrumb />
        </Box>
        {/* BanneR Content */}
        <Flex display={['block', 'block', 'block', 'flex', 'flex', 'flex']}>
          {/* Text Part */}
          <Box display={'flex'} alignItems={'center'}>
            <Box
              pr={['0px', '0px', '0px', '55px', '55px', '55px']}
              mb={['90px', '90px', '90px', '0px', '0px', '0px']}
            >
              <Text as="h1" sx={bannerTitle}>
                {firstItem?.main_heading}
              </Text>
              <Text as="p" sx={bannerParaGraph}>
                {firstItem?.main_description}
              </Text>
              <Link
                p={'15px 20px 8px'}
                fontSize={['20px', '20px', '20px', '20px', '20px', '20px']}
                bg={'#0057FF'}
                color={'#fff'}
                fontWeight={'400'}
                fontFamily={'var(--foundersGrotesk-regular)'}
                lineHeight={'normal'}
                mt={'30px'}
                display={'inline-block'}
                _hover={{ transform: 'translate3d(0, -3px, 0)' }}
                transition="transform 0.5s ease"
              >
                Get in touch
              </Link>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
export default HeroBanner;
