import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const GreatUxPoints = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>{error}</Text>;

  const firstItem = data ? data.attributes : null;

  const points = [
    { heading: firstItem?.point_1_heading || 'N/A', description: firstItem?.point_1_description || '' },
    { heading: firstItem?.point_2_heading || 'N/A', description: firstItem?.point_2_description || '' },
    { heading: firstItem?.point_3_heading || 'N/A', description: firstItem?.point_3_description || '' },
    { heading: firstItem?.point_4_heading || 'N/A', description: firstItem?.point_4_description || '' },
  ];
  const numberStyle = {
    fontSize: ['25px', '25px', '25px', '25px', '28px', '28px'],
    width: ['45px'],
    fontFamily: 'var(--garnett-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridTitle = {
    fontSize: ['18px', '18px', '18px', '20px', '28px', '28px'],
    fontFamily: 'var(--garnett-regular)',
    paddingBottom: ['15px'],
    color: '#333',
    fontWeight: '400',
    lineHeight: 'normal',
  };

  const gridParagraph = {
    fontSize: ['14px', '14px', '14px', '16px', '20px', '20px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333333',
    fontWeight: '400',
    lineHeight: 'normal',
    paddingBottom: ['36px', '36px', '36px', '36px', '36px', '36px'],
  };

  return (
    <Box
      padding={[
        '80px 0 62px',
        '80px 0 62px',
        '80px 0 62px',
        '150px 0 80px',
        '150px 0 80px',
      ]}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px']}
      >
        {/* Section Heading */}
        <Box maxW={['809px']} paddingBottom={['56px']}>
          <Heading
            as={'h2'}
            color={['#000']}
            fontSize={['32px', '32px', '35px', '48px', '48px']}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-semibold);'}
            marginBottom={['32px']}
          >
            {firstItem?.heading_1}
          </Heading>
          <Text
            as={'p'}
            fontSize={['20px']}
            fontWeight={'400'}
            color={'#333'}
            lineHeight={'normal'}
            fontFamily={'var(--foundersGrotesk-regular);'}
            maxW={['731px']}
          >
            {firstItem?.description_1}
          </Text>
        </Box>
        {/* Great UX Points */}
        <Box
          display={['grid']}
          gridTemplateColumns={[
            'repeat(1,1fr)',
            'repeat(1,1fr)',
            'repeat(2,1fr)',
            'repeat(2,1fr)',
            'repeat(2,1fr)',
          ]}
          columnGap={['46px']}
          rowGap={['24px', '24px', '24px', '32px', '32px', '32px']}
        >
          {points.map((point, index) => (
            <Box borderBottom={'1px solid #B5B5B5'}>
              <Flex
                key={index}
                direction={['row', 'row', 'row', 'row', 'row', 'row']}
                gap={['43px', '43px', '43px', '55px', '55px', '55px']}
                alignItems={['flex-start']}
                maxW={['538px']}
              >
                <Text as="h1" sx={numberStyle}>
                  0{index + 1}
                </Text>
                <Flex
                  width={['100%', '100%', '100%', '100%', '100%', '100%']}
                  maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
                  direction={[
                    'column',
                    'column',
                    'column',
                    'column',
                    'column',
                    'column',
                  ]}
                  gap={['0px', '0px', '0px', '10px', '10px', '10px']}
                  alignItems={['flex-start']}
                  margin={['0 auto']}
                >
                  <Text as="h1" sx={gridTitle}>
                    {point.heading}
                  </Text>
                  <Text as="p" sx={gridParagraph}>
                    {point.description}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GreatUxPoints;
