import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Mobile from '../../assets/SingleServicesPage/UXProcessDesign/Mobile.svg';

const UXDesignProcess = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=design&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  return (
    <Box
      padding={['89px 0 0px', '89px 0 0x', '72px 0 110px', '72px 0 163px']}
      backgroundColor={['#fff', '#fff', '#f5f5f5', '#f5f5f5', '#f5f5f5']}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        margin={'0 auto'}
        padding={['0px 20px']}
      >
        {/* Headings Section */}
        <Box
          paddingBottom={['60px']}
          display={['flex']}
          justifyContent={[
            'center',
            'center',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
        >
          <Box maxW={['340px', '340px', '340px', '420px', '500px']}>
            <Heading
              as="h2"
              color={'#000'}
              textAlign={['center', 'center', 'left', 'left', 'left', 'left']}
              fontFamily={'var(--garnett-regular)'}
              fontSize={['32px', '32px', '32px', '40px', '48px', '48px']}
              fontStyle={'normal'}
              fontWeight={400}
              lineHeight={'normal'}
              letterSpacing={'-1.056px'}
            >
              {firstItem?.process_heading}
            </Heading>
          </Box>
          <Box maxW={['424px']} display={['none', 'none', 'block']}>
            <Text
              as={'p'}
              color={'#000'}
              fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
              lineHeight={'normal'}
              fontWeight={'400'}
              fontFamily={'var(--foundersGrotesk-regular);'}
              paddingBottom={['24px']}
            >
              {firstItem?.process_description}

            </Text>
            <Link
              href="#"
              style={{
                color: '#fff',
                fontSize: ['16px', '16px', '16px', '20px', '20px', '20px'],
                fontWeight: '400',
                lineHeight: 'normal',
                backgroundColor: '#0057FF',
                padding: '15px 11px 9px',
                display: 'inline-block',
              }}
              fontFamily={'var(--foundersGrotesk-regular);'}
            >
              Get Started
            </Link>
          </Box>
        </Box>
        {/* Images */}
        {/* DeskTop ANd Image */}
        <Box
          backgroundColor={['#fff', '#fff', '#0D0E4D', '#0D0E4D', '#0D0E4D']}
          padding={[
            '0px 0 0px',
            '0px 0 0px',
            '0px 0 0px',
            '0px 0 0px',
            '0px 0 0px',
            '0px 0 0px',
          ]}
        >
          <Box maxW={['88%']} margin={'0 auto'}>
            {/* Desktop */}
            <Image
              width={'100%'}
              height={'auto'}
              display={['none', 'none', 'block', 'block', 'block', 'block']}
              src={`https://cms.wearetenet.com${firstItem?.process_image?.data?.attributes?.url || ''
              }`}            />
            <Image
              width={'100%'}
              height={'auto'}
              display={['block', 'block', 'none', 'none', 'none', 'none']}
              src={`https://cms.wearetenet.com${firstItem?.process_image_mobile?.data?.attributes?.url || ''
              }`}   
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UXDesignProcess;
