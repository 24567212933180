import React from 'react'
import LogoMarquee from '../Reusable/LogoMarquee'

const TechnologyMarqueeUse = () => {
  return (
    <div>
      <LogoMarquee/>
    </div>
  )
}

export default TechnologyMarqueeUse
