import { Box } from '@chakra-ui/react';
import React from 'react';
import ServicesTab from '../../Service/Service/ServicesTab';
import BriefStrategys from '../Service/BriefStrategys';
import HeroBanner from '../Service/HeroBanner';
import ExploreOtherWork from './ExploreOtherWorkUse';
import LetsWorkToGether from './LetsWorksToGether';
import ClientLogoAbout from './OurClients';

const Service = () => {
  return (
    <Box overflow={'hidden'}>
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <BriefStrategys />
      </Box>
      <Box>
        <ServicesTab />
      </Box>
      <Box>
        <ClientLogoAbout />
      </Box>
      <Box>
        <ExploreOtherWork />
      </Box>
      <Box>
        <LetsWorkToGether />
      </Box>
    </Box>
  );
};

export default Service;
