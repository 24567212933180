import { Box, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import CtcIcon1 from '../../../assets/Service/tabs/ctc1.svg';
import CtcIcon2 from '../../../assets/Service/tabs/ctc2.svg';
import CtcIcon3 from '../../../assets/Service/tabs/ctc3.svg';
import CtcIcon4 from '../../../assets/Service/tabs/ctc4.svg';
import PanelImg1 from '../../../assets/Service/tabs/panel1.webp';
import PanelImg2 from '../../../assets/Service/tabs/panel2.webp';
import PanelImg3 from '../../../assets/Service/tabs/panel3.webp';
import PanelImg4 from '../../../assets/Service/tabs/panel4.webp';
import './Custom.css';

const ServicesTab = () => {
  const data = [
    {
      label: 'Research',
      href: '#Research',
      banner: {
        image: PanelImg1,
        paragraph:
          'Unlock the full potential of your brand with our comprehensive research services. Delve into the nuances of your market, user behaviors, and competitive landscape to inform every decision with precision and insight.',
      },
      services: [
        {
          number: '01',
          title: 'Brand Research',
          description:
            'Discover the heartbeat of your market to carve a brand presence that resonates deeply and stands out. Utilize actionable intelligence to tailor your messaging and captivate your audience.',
          link: '/upcoming',
        },
        {
          number: '02',
          title: 'Brand Strategy',
          description:
            "Strategic blueprints that align your brand's essence with market dynamics, positioning you for leadership. Develop a narrative that not only defines but also differentiates your brand in a crowded marketplace.",
          link: '/upcoming',
        },
        {
          number: '03',
          title: 'Heuristic Evaluation',
          description:
            'Expert analysis that ensures your digital interfaces are intuitively aligned with best practices and user expectations. Identify and eliminate usability hurdles to streamline the user experience.',
          link: '/upcoming',
        },
        {
          number: '04',
          title: 'Design Audit',
          description:
            "Comprehensive reviews that elevate your design's effectiveness, ensuring every element serves a purpose. Get a detailed assessment that highlights strengths and pinpoints areas for enhancement.",
          link: '/',
        },
        {
          number: '05',
          title: 'Usability Testing',
          description:
            'Real user feedback that refines your interfaces, making them not just usable but delightful. Foster a user-centric design process that enhances satisfaction and encourages loyalty.',
          link: '/upcoming',
        },
        {
          number: '06',
          title: 'Ethnographic Research',
          description:
            'In-depth insights into customer behaviors and cultures, crafting experiences that feel personal and relevant. Anchor your design strategy in real-world contexts for deeply resonant solutions.',
          link: '7',
        },
        {
          number: '07',
          title: 'Emerging Trends',
          description:
            "Stay ahead of the curve by integrating the latest design and tech trends into your brand's DNA. Anticipate market shifts with forward-thinking solutions that keep you competitive.",
          link: '/',
        },
        {
          number: '08',
          title: 'UX Research',
          description:
            'Invaluable user insights that inform design strategies, ensuring your products are perfectly user-tuned. Translate user data into designs that empathize and engage, boosting usability and adoption.',
          link: '/upcoming',
        },
        {
          number: '09',
          title: 'Conversion Optimization Audit',
          description:
            'Leverage data-driven insights to turn visitors into customers with optimized user journeys. Fine-tune your digital touchpoints for maximum conversion efficiency and market penetration.',
          link: '/upcoming',
        },
        {
          number: '10',
          title: 'Competitive Benchmarking',
          description:
            'Measure up against the best with strategies that push you beyond the competition. Gain strategic insights that enable you to set new standards in your industry.',
          link: '/upcoming',
        },
        {
          number: '11',
          title: 'Discovery & Innovation Workshops',
          description:
            'Interactive sessions that spark creativity and lead to breakthrough innovations for your brand. Collaborate to unlock new ideas that set the stage for your next big leap.',
          link: '/upcoming',
        },

        // Add more services as needed
      ],
      ctc: {
        bg: '#0d0e4d',
        title: 'Engage with Our Leading Research Consultants',
        paragraph:
          'Good research provides the foundation for any digital initiative. Let’s dive in and discover what you need! :)',
        image: CtcIcon1,
        name: 'Natasha M,',
        designation: 'Research Head',
        buttonText: 'Get in Touch',
        buttonLink: '/contact-us',
        buttonColor: '#0036c2',
      },
    },
    {
      label: 'Design',
      href: '#Design',
      banner: {
        image: PanelImg2,
        paragraph:
          'Craft experiences that captivate and convert with our holistic design approach. From initial sketches to final visuals, our design services ensure that every touchpoint resonates with your target audience and reinforces your brand identity.',
      },
      services: [
        {
          number: '01',
          title: 'Product Design',
          description:
            'Design that’s not just seen but felt, creating products that users love and advocate for. Deliver memorable product experiences that lead to increased user engagement and retention.',
          link: '/upcoming',
        },
        {
          number: '02',
          title: 'UX Design',
          description:
            'Craft user experiences that captivate and retain, with designs that embody simplicity, functionality, and aesthetic finesse. Optimize your digital landscape to be as intuitive as it is impactful, elevating every interaction.',
          link: '/upcoming',
        },
        {
          number: '03',
          title: 'UI Design',
          description:
            'Visual designs that communicate clearly, attract users, and embody your brand’s aesthetic values. Create visual stories that engage the senses and compel users to take action.',
          link: '/',
        },
        {
          number: '04',
          title: 'Interaction Design',
          description:
            'Create captivating interfaces where each interaction is an opportunity for engagement. Design meaningful touchpoints that make every swipe, tap, and click a delightful experience.',
          link: '/upcoming',
        },
        {
          number: '05',
          title: 'Digital Prototyping',
          description:
            'Bring ideas to life with prototypes that give you a real feel of the product before it hits the market. Validate concepts quickly and refine features iteratively with user-centered design prototypes.',
          link: '/',
        },
        {
          number: '06',
          title: 'Brand Design & Rebranding',
          description:
            'Revitalize your brand with designs that narrate your story with freshness and authenticity. Make a lasting impression with a reimagined brand identity that speaks to the hearts of your audience.',
          link: '/upcoming',
        },
        {
          number: '07',
          title: 'Motion Graphics',
          description:
            "Animate your brand’s story with motion graphics that grab attention and don't let go. Infuse energy and emotion into your narrative with visuals that move and inspire.",
          link: '/',
        },
        {
          number: '08',
          title: 'Digital Illustrations',
          description:
            'Illustrations that speak a thousand words of your brand’s unique voice and vision. Stand out with bespoke illustrations that encapsulate your brand’s character and ethos.',
          link: '/upcoming',
        },
        {
          number: '09',
          title: 'Brand Style Guides',
          description:
            'Ensure brand consistency with comprehensive style guides that serve as your design compass. Maintain a cohesive brand presence across all platforms with clear, detailed style directives.',
          link: '/',
        },
        {
          number: '10',
          title: 'Collateral, Print & Packaging',
          description:
            'Design materials that people want to touch, keep, and share, extending your brand’s reach. Create tangible connections with print and packaging that tell your brand story in the physical world.',
          link: '/upcoming',
        },
        {
          number: '11',
          title: 'Iconography',
          description:
            'Distinctive icons that are instantly recognizable, adding to the visual language of your brand. Enhance user interfaces with iconography that simplifies navigation and enhances understanding.',
          link: '/upcoming',
        },
        // Add more services as needed
      ],
      ctc: {
        bg: '#30054a',
        title: 'Engage with Our Leading User Experience Specialists',
        paragraph:
          'Great design is about solving problems. It’s also about finding a strategic balance between form and function. Let’s speak, I’m always up for a chat!',
        image: CtcIcon2,
        name: 'Alisha P,',
        designation: 'Manager (UI/UX)',
        buttonText: 'Get in Touch',
        buttonLink: '/contact-us',
        buttonColor: '#7610b5',
      },
    },
    {
      label: 'Build',
      href: '#Build',
      banner: {
        image: PanelImg3,
        paragraph:
          "Bring your digital projects to life with our robust build solutions. Whether it's a state-of-the-art website, a versatile mobile app, or scalable software, we ensure your projects are built for optimal performance and long-term success.",
      },
      services: [
        {
          number: '01',
          title: 'Website Development',
          description:
            'Build the digital cornerstone of your brand with websites that are robust, responsive, and remarkable. Create a digital home for your brand that invites users in and encourages them to stay.',
          link: '/upcoming',
        },
        {
          number: '02',
          title: 'Android App Development',
          description:
            'Custom Android applications that deliver functionality with the freedom of open-source flexibility. Reach a vast audience with apps that perform flawlessly across a multitude of devices.',
          link: '/upcoming',
        },
        {
          number: '03',
          title: 'iOS App Development',
          description:
            'Premium iOS apps that blend design and technology to deliver a seamless experience on Apple devices. Harness the power of the App Store with apps that set industry standards for quality and innovation.',
          link: '/upcoming',
        },
        {
          number: '04',
          title: 'SaaS Development',
          description:
            "Scalable SaaS platforms that grow with your business, ensuring continuous innovation and user satisfaction. Enter the market with confidence, offering solutions that evolve with your customers' needs.",
          link: '/',
        },
        {
          number: '05',
          title: 'Custom CMS Development',
          description:
            'Tailor-made CMS solutions that give you the power to manage content dynamically and efficiently. Empower your team with the tools to publish, manage, and track content effortlessly.',
          link: '/upcoming',
        },
        {
          number: '06',
          title: 'Cross Platform App Development',
          description:
            'Bridge user experiences across devices with cross-platform apps that ensure consistency and performance. Maximize your reach with applications that deliver a unified brand experience everywhere.',
          link: '/upcoming',
        },

        // Add more services as needed
      ],
      ctc: {
        bg: '#00342b',
        title: 'Engage with Our Leading Product Consultants',
        paragraph:
          'An idea is only as good as it’s execution. I’m here to make sure we reach the chequered flag, together. Let’s chat over coffee, or chai? Whatever you prefer.',
        image: CtcIcon3,
        name: 'DEEPNA T,',
        designation: 'RESEARCH HEAD',
        buttonText: 'Get in Touch',
        buttonLink: '/contact-us',
        buttonColor: '#00ba61',
      },
    },
    {
      label: 'Growth',
      href: '#Growth',
      banner: {
        image: PanelImg4,
        paragraph:
          'Drive sustainable business growth with our targeted marketing strategies. Our growth services are designed to increase your reach, enhance your engagement, and maximize your ROI, ensuring your brand thrives in a competitive digital landscape.',
      },
      services: [
        {
          number: '01',
          title: 'Search Engine Optimization(SEO)',
          description:
            'Elevate your online visibility with SEO strategies that boost your ranking and draw in your target audience. Dominate search results with optimized content that increases visibility and drives organic traffic.',
          link: '/upcoming',
        },
        {
          number: '02',
          title: 'Conversion Rate Optimization',
          description:
            'Turn traffic into revenue with optimizations that make every click count. Elevate your conversion rates with strategies that streamline the path to purchase.',
          link: '/upcoming',
        },
        {
          number: '03',
          title: 'PPC Advertising',
          description:
            'Strategically crafted PPC campaigns that put your brand in the spotlight and deliver tangible ROI. Capture the attention of your target demographic with ads that speak to their needs and desires.',
          link: '/upcoming',
        },
        {
          number: '04',
          title: 'Email Marketing',
          description:
            'Personalized email campaigns that build relationships and drive engagement. Cut through the noise with messages that matter to your audience and bolster brand loyalty.',
          link: '/',
        },
        {
          number: '05',
          title: 'Analytics, Reporting & Insights',
          description:
            'Insights that shape strategy with detailed analytics and reports that track your growth and guide your decisions. Make data-backed decisions that drive strategic action and measurable results.',
          link: '/upcoming',
        },
        {
          number: '06',
          title: 'Engagement & Growth Strategy',
          description:
            "Forge meaningful connections with engagement strategies that fuel growth and foster brand loyalty. Cultivate a community around your brand that's active, engaged, and continually growing.",
          link: '/upcoming',
        },
        {
          number: '07',
          title: 'Content Marketing',
          description:
            "Content that tells your brand's story, educates your audience, and drives organic growth. Build a content ecosystem that nurtures leads and converts them into advocates.",
          link: '/upcoming',
        },
        {
          number: '08',
          title: 'B2B Marketing',
          description:
            'Cultivate valuable business relationships with targeted B2B marketing that speaks directly to your industry peers. Connect with decision-makers through strategies that resonate with their business goals and challenges.',
          link: '/upcoming',
        },
        // Add more services as needed
      ],
      ctc: {
        bg: '#3e001a',
        title: 'Engage with Our Leading User Experience Specialists',
        paragraph:
          'I don’t know what to write here. I’m a performance marketer, not a copywriter haha. Let’s speak if you got growth on your mind. :)',
        image: CtcIcon4,
        name: 'RAJAT M,',
        designation: 'GROWTH MARKETER',
        buttonText: 'Get in Touch',
        buttonLink: '/contact-us',
        buttonColor: '#e0005e',
      },
    },
  ];

  // Data

  // Example tab data, adjust according to your data structure

  // Move all hooks to the top level of the component
  const [active, setActive] = useState('Research');
  const sectionRefs = useRef([]);
  const containerRef = useRef(null);
  const stickyBoxRef = useRef(null);

  const desktopOffset = 68;
  const mobileOffset = 63;
  // Sticky navigation effect
  // Sticky navigation effect

  let isClicked = false; 
 useEffect(() => {
   // Track if a click has occurred

   const handleScroll = () => {
    if (isClicked) return; // If clicked, don't apply sticky behavior

     const container = containerRef.current;
     const stickyBox = stickyBoxRef.current;
     const tabbingWrapper = document.querySelector('.TabbinGwrapper');

     if (!container || !stickyBox || !tabbingWrapper) return;

     const containerRect = container.getBoundingClientRect();
     const stickyBoxHeight = stickyBox.offsetHeight;
     const windowHeight = window.innerHeight;
     const offset = window.innerWidth <= 768 ? mobileOffset : desktopOffset;

     const stickyShouldBeFixed =
       containerRect.top <= offset &&
       containerRect.bottom - stickyBoxHeight - offset >= 0;

     const stickyShouldBeAbsolute =
       containerRect.bottom - stickyBoxHeight <= offset;

     if (stickyShouldBeFixed) {
       stickyBox.style.position = 'fixed';
       stickyBox.style.top = `${offset}px`;
       stickyBox.style.bottom = 'auto';
       tabbingWrapper.style.paddingTop = `${stickyBoxHeight - 15}px`;
     } else if (stickyShouldBeAbsolute) {
       stickyBox.style.position = 'relative';
       stickyBox.style.top = 'auto';
       stickyBox.style.bottom = '0';
       tabbingWrapper.style.paddingTop = '0';
     } else {
       stickyBox.style.position = 'static';
       stickyBox.style.top = 'auto';
       stickyBox.style.bottom = 'auto';
       tabbingWrapper.style.paddingTop = '0';
     }
   };

   const resetSticky = () => {
     isClicked = false; // Reset the click state when scrolling
     handleScroll(); // Reapply the scroll behavior
   };

   window.addEventListener('scroll', resetSticky); // Reset on scroll
   window.addEventListener('scroll', handleScroll); // Initialize the sticky behavior
   handleScroll(); // Initial call to set the position

   return () => {
     window.removeEventListener('scroll', resetSticky);
     window.removeEventListener('scroll', handleScroll);
   };
 }, [mobileOffset, desktopOffset]);

const handleActive = linkName => {
  setActive(linkName);

  // On click, disable the sticky behavior
  const stickyBox = stickyBoxRef.current;
  const tabbingWrapper = document.querySelector('.TabbinGwrapper');

  stickyBox.style.position = 'static'; // Remove the sticky behavior
  stickyBox.style.top = 'auto';
  stickyBox.style.bottom = 'auto';
  tabbingWrapper.style.paddingTop = '0';

  isClicked = true; // Mark as clicked to prevent scroll reapplying sticky
};

  // Observer
  const prevActiveRef = useRef(null); // Ref to store the previous active section
 

useEffect(() => {
  const handleScroll = () => {
    sectionRefs.current.forEach(ref => {
      if (ref) {
        const rect = ref.getBoundingClientRect();
        const id = ref.getAttribute('id');
        const capability = data.find(cap => cap.label === id);

        // Check if the section has reached the top of the viewport
        if (capability && rect.top <= 120 && rect.bottom > 0) {
          if (prevActiveRef.current !== capability.label) {
            // Update the active section only if it has changed
            setActive(capability.label);
            prevActiveRef.current = capability.label;
          }
        }
      }
    });
  };

  window.addEventListener('scroll', handleScroll);

  // Clean up the event listener on unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [data]);


  // Test

  

  const activLinkColor = {
    background: 'rgba(0, 117, 255, 0.10)',
    color: '#0057ff',
    borderBottom: '5px solid #0057FF',
  };
  const tabLinkWrapper = {
    justifyContent: 'center',
    borderBottom: '1px solid',
    borderBottomColor: '#D9D9D9',
    bg: '#fff',
    boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.08)',
    textAlign: 'center',
    display: 'flex',
    gap: ['2px', '15px', '30px', '30px', '30px', '30px'],
  };
  const tabsDesign = {
    padding: ['8px', '8px', '20px 16px', '25px 16px', '29px 16px'],
    display: 'inline-block',
    fontSize: ['16px', '20px', '24px', '32px', '40px', '48px'],
    fontWeight: '400',
    fontFamily: 'garnett-regular',
    textDecoration: 'none',
    borderBottom: '5px solid transparent', // Initial border

    '&:hover': {
      textDecoration: 'none',
    },
  };
  const boxDesign = {
    bg: '#F5F5F5',
    p: [
      '13px 20px 26px 20px',
      '13px 20px 26px 20px',
      '13px 20px 26px 20px',
      '20px 20px 40px 20px',
      '20px 20px 40px 20px',
      '20px 20px 40px 20px',
    ],
    position: 'relative',
  };
  // Typo
  const serviceListNumber = {
    fontSize: ['15px', '15px', '15px', '20px', '20px', '20px'],
    fontWeight: ['400', '400', '400', '300', '300', '300'],
    color: '#0D0E4D',
    fontFamily: [
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-light)',
      'var(--garnett-light)',
      'var(--garnett-light)',
      'var(--garnett-light)',
    ],
    mr: ['5px', '5px', '5px', '0px', '0px', '0px'],
    display: 'block',
  };
  const serviceListTitle = {
    fontSize: ['18px', '18px', '18px', '24px', '24px', '24px'],
    fontWeight: ['500', '500', '500', '400', '400', '400'],
    color: '#0D0E4D',
    fontFamily: [
      'var(--garnett-medium)',
      'var(--garnett-medium)',
      'var(--garnett-medium)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
      'var(--garnett-regular)',
    ],
    p: [
      '0px 0 0px',
      '0px 0 0px',
      '0px 0 0px',
      '12px 0 15px',
      '16px 0 20px',
      '20px 0 24px',
    ],
  };
  const serviceListDescription = {
    fontSize: ['14px', '14px', '14px', '18px', '18px', '18px'],
    fontWeight: '400',
    lineHeight: 'normal',
    color: '#333333',
    fontFamily: 'var(--foundersGrotesk-regular)',
  };

  return (
    <Box mb={['80px', '80px', '80px', '152px', '152px', '152px']}>
      <Box>
        {/* Tabs Weaper */}
        <Box ref={containerRef}>
          {/* Tab List Wrapper */}
          <Box
            sx={tabLinkWrapper}
            ref={stickyBoxRef}
            zIndex="500"
            width={['100%']}
            top="0"
          >
            {data.map(tab => (
              <Link
                key={tab.label}
                sx={{
                  ...tabsDesign,
                  ...(active === tab.label && activLinkColor),
                }}
                onClick={() => handleActive(tab.label)}
                href={tab.href}
              >
                {tab.label}
              </Link>
            ))}
          </Box>
          {/* Tab Panels Wrapper */}
          <Box className="TabPannelWrapper TabbinGwrapper">
            {/* Tab Pannel */}
            {data.map((item, index) => (
              <Box
                p={'0'}
                key={index}
                ref={el => (sectionRefs.current[index] = el)}
                id={item.label} // Ensure clean ID without '#'
                scrollMarginTop={[
                  '130px',
                  '130px',
                  '130px',
                  '80px',
                  '80px',
                  '80px',
                ]}
              >
                {/* Banner */}
                {/* Tabbing Image Related Service */}
                {item.banner && (
                  <Flex
                    flexDirection={[
                      'column-reverse',
                      'column-reverse',
                      'column-reverse',
                      'column',
                      'column',
                      'column',
                    ]}
                  >
                    <Container
                      maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
                      m={'0 auto'}
                      p={['0', '0', '0', '0 16px', '0 16px', '0 16px']}
                    >
                      <Box
                        p={[
                          '0px 0 40px',
                          '0px 0 40px',
                          '0px 0 50px',
                          '100px 0 50px',
                          '100px 0 68px',
                          '100px 0 68px',
                        ]}
                      >
                        <Image
                          src={item.banner.image}
                          w={'100%'}
                          objectFit={'cover'}
                          alt={`${item.label} banner`}
                        />
                      </Box>
                    </Container>
                    {/* Service Title And Short Description For Overview */}
                    <Box
                      bg={[
                        '#F5F5F5',
                        '#F5F5F5',
                        '#F5F5F5',
                        '#fff',
                        '#fff',
                        '#fff',
                      ]}
                    >
                      <Container
                        maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
                        m={'0 auto'}
                      >
                        <Flex
                          justifyContent={'space-between'}
                          display={[
                            'block',
                            'block',
                            'block',
                            'flex',
                            'flex',
                            'flex',
                          ]}
                          mb={['18px', '18px', '18px', '60px', '60px', '60px']}
                        >
                          <Box
                            w={['100%', '100%', '100%', '100%', '100%', '100%']}
                          >
                            <Text
                              as="p"
                              color={'#333'}
                              fontSize={[
                                '16px',
                                '16px',
                                '16px',
                                '20px',
                                '20px',
                                '20px',
                              ]}
                              fontFamily="var(--foundersGrotesk-regular)"
                              fontWeight="400"
                              m={[
                                '0',
                                '0',
                                '0',
                                '0 0 0 auto',
                                '0 0 0 auto',
                                '0 0 0 auto',
                              ]}
                              textAlign={[
                                'left',
                                'left',
                                'left',
                                'left',
                                'left',
                                'left',
                              ]}
                              marginBottom={['38px', '38px', '38px', 0, 0, 0]}
                              paddingTop={['38px', '38px', '38px', '0px']}
                            >
                              {item.banner.paragraph}
                            </Text>
                          </Box>
                        </Flex>
                      </Container>
                    </Box>
                  </Flex>
                )}
                {/* Box Grid */}
                <Container
                  maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
                  m={'0 auto'}
                >
                  {item.services && item.services.length > 0 && (
                    <Box
                      display={'grid'}
                      gridTemplateColumns={[
                        'repeat(1,1fr)',
                        'repeat(1,1fr)',
                        'repeat(1,1fr)',
                        'repeat(3,1fr)',
                        'repeat(3,1fr)',
                        'repeat(3,1fr)',
                      ]}
                      gridGap={[
                        '16px 20px',
                        '16px 20px',
                        '40px 20px',
                        '40px 20px',
                        '40px 20px',
                        '40px 20px',
                      ]}
                    >
                      {item.services.map((service, serviceindex) => (
                        <Box
                          key={serviceindex}
                          sx={{
                            ...boxDesign,
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#E1ECFF',
                            },
                          }}
                        >
                          {/* Link */}
                          <Box as="span">
                            {service.link &&
                            service.link !== '/' &&
                            service.link !== '' ? (
                              <Link
                                href={service.link}
                                position={'absolute'}
                                top={'0'}
                                right={'0'}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="32"
                                  height="33"
                                  viewBox="0 0 32 33"
                                  fill="none"
                                  style={{ background: '#0057FF' }}
                                >
                                  <g clipPath="url(#clip0_2470_13451)">
                                    <path
                                      d="M27 13.4053L26.9988 5.40652L19 5.40527"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                    />
                                    <path
                                      d="M17 15.6738L26 6.67383"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M23 17.4053V26.4053C23 26.6705 22.8946 26.9248 22.7071 27.1124C22.5196 27.2999 22.2652 27.4053 22 27.4053H6C5.73478 27.4053 5.48043 27.2999 5.29289 27.1124C5.10536 26.9248 5 26.6705 5 26.4053V10.4053C5 10.1401 5.10536 9.8857 5.29289 9.69817C5.48043 9.51063 5.73478 9.40527 6 9.40527H15"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2470_13451">
                                      <rect
                                        width="32"
                                        height="32"
                                        fill="white"
                                        transform="translate(0 0.405273)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </Link>
                            ) : null}
                          </Box>
                          {/* Number and Title */}
                          <Flex
                            pb={['15px', '15px', '15px', '0px', '0px', '0px']}
                            alignItems={[
                              'baseline',
                              'baseline',
                              'baseline',
                              'start',
                              'start',
                              'start',
                            ]}
                            flexDirection={[
                              'row',
                              'row',
                              'row',
                              'column',
                              'column',
                              'column',
                            ]}
                          >
                            <Box>
                              <Text as={'span'} sx={serviceListNumber}>
                                {service.number}
                              </Text>
                            </Box>
                            <Box>
                              <Text as={'h4'} sx={serviceListTitle}>
                                {service.title}
                              </Text>
                            </Box>
                          </Flex>
                          {/* Description */}
                          <Box>
                            <Text as={'p'} sx={serviceListDescription}>
                              {service.description}
                            </Text>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Container>

                {/* Ctc */}
                {item.ctc && (
                  <Box
                    bg={item.ctc.bg}
                    p={[
                      '23px 0 40px',
                      '23px 0 40px',
                      '23px 0 40px',
                      '61px 0',
                      '61px 0',
                      '61px 0',
                    ]}
                    marginTop={['40px', '40px', '40px', '80px', '80px', '80px']}
                  >
                    <Container
                      maxWidth={['90%', '90%', '100%', '90%', '90%', '90%']}
                      m={'0 auto'}
                    >
                      <Flex
                        display={[
                          'block',
                          'block',
                          'block',
                          'flex',
                          'flex',
                          'flex',
                        ]}
                      >
                        <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                          <Text
                            as={'h2'}
                            fontSize={[
                              '20px',
                              '20px',
                              '20px',
                              '38px',
                              '48px',
                              '48px',
                            ]}
                            color={'#fff'}
                            fontWeight={'600'}
                            fontFamily={'var(--garnett-semibold)'}
                            maxW={'616px'}
                          >
                            {item.ctc.title}
                          </Text>
                        </Box>
                        <Box
                          w={['100%', '100%', '100%', '50%', '50%', '50%']}
                          pt={['58px', '58px', '58px', '0px', '0px', '0px']}
                        >
                          <Flex
                            justifyContent={[
                              'start',
                              'start',
                              'start',
                              'end',
                              'end',
                              'end',
                            ]}
                          >
                            <Box
                              w={[]}
                              display={'flex'}
                              alignItems={[
                                'start',
                                'start',
                                'start',
                                'center',
                                'center',
                                'center',
                              ]}
                              mr={'15px'}
                            >
                              <Image
                                src={item.ctc.image}
                                pt={[
                                  '15px',
                                  '15px',
                                  '15px',
                                  '0px',
                                  '0px',
                                  '0px',
                                ]}
                              />
                            </Box>
                            <Box w={['']}>
                              <Box maxW={'400px'} ml={'auto'}>
                                {/* Name And Degination */}
                                <Box
                                  display={'flex'}
                                  alignItems={'baseline'}
                                  textTransform={'uppercase'}
                                >
                                  <Text
                                    as={'span'}
                                    color={'#fff'}
                                    fontStyle={
                                      ' var(--foundersGrotesk-semibold)'
                                    }
                                    fontSize={[
                                      '14px',
                                      '14px',
                                      '16px',
                                      '24px',
                                      '24px',
                                      '24px',
                                    ]}
                                    fontWeight={'600'}
                                  >
                                    {item.ctc.name}
                                  </Text>
                                  <Text
                                    as={'p'}
                                    fontSize={[
                                      '14px',
                                      '14px',
                                      '16px',
                                      '16px',
                                      '16px',
                                      '16px',
                                    ]}
                                    color={'#fff'}
                                    fontStyle={
                                      ' var(--foundersGrotesk-regular)'
                                    }
                                    fontWeight={'400'}
                                  >
                                    {item.ctc.designation}
                                  </Text>
                                </Box>
                                <Box>
                                  {/* Pargagraph */}
                                  <Text
                                    as={'p'}
                                    color={'#fff'}
                                    fontSize={[
                                      '14px',
                                      '14px',
                                      '14px',
                                      '20px',
                                      '20px',
                                      '20px',
                                    ]}
                                    fontFamily={
                                      'var(--foundersGrotesk-regular)'
                                    }
                                    fontWeight={'400'}
                                    lineHeight={'normal'}
                                    pt={[
                                      '10px',
                                      '10px',
                                      '10px',
                                      '22px',
                                      '22px',
                                      '22px',
                                    ]}
                                    pb={[
                                      '22px',
                                      '22px',
                                      '22px',
                                      '14px',
                                      '14px',
                                      '14px',
                                    ]}
                                  >
                                    {item.ctc.paragraph}
                                  </Text>
                                </Box>
                                {/* Link */}
                                <Box>
                                  <Link
                                    href={item.ctc.buttonLink}
                                    display={'inline-flex'}
                                    bg={item.ctc.buttonColor}
                                    paddingTop={[
                                      '8px',
                                      '8px',
                                      '8px',
                                      '10px',
                                      '10px',
                                      '10px',
                                    ]}
                                    paddingBottom={[
                                      '5px',
                                      '5px',
                                      '5px',
                                      '7px',
                                      '7px',
                                      '7px',
                                    ]}
                                    paddingLeft={[
                                      '30px',
                                      '30px',
                                      '30px',
                                      '38px',
                                      '38px',
                                      '38px',
                                    ]}
                                    paddingRight={[
                                      '30px',
                                      '30px',
                                      '30px',
                                      '38px',
                                      '38px',
                                      '38px',
                                    ]}
                                    lineHeight={'normal'}
                                    color={'#fff'}
                                    fontSize={[
                                      '15px',
                                      '15px',
                                      '15px',
                                      '20px',
                                      '20px',
                                      '20px',
                                    ]}
                                    _hover={{
                                      transform: 'translate3d(0, -3px, 0)',
                                    }}
                                    transition="transform 0.5s ease"
                                    fontWeight={'400'}
                                    fontFamily={
                                      'var(--foundersGrotesk-regular)'
                                    }
                                  >
                                    {item.ctc.buttonText}
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Container>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ServicesTab;
 
