import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

const backgroundContent = () => {
  return (
    <Box
      paddingTop={['80px', '80px', '80px', '150px', '150px', '150px']}
      paddingBottom={['80px', '80px', '80px', '150px', '150px', '150px']}
    >
      <Container width={['100%']} maxWidth={['100%']} padding={['0']}>
        {/* Image  */}
        <Flex
          direction={[
            'column-reverse',
            'column-reverse',
            'row',
            'row',
            'row',
            'row',
          ]}
          gap={['55px', '55px', '55px', '0', '0', '0']}
          marginBottom={['24px', '24px', '24px', '0px', '0px', '0px']}
        >
          <Box
            maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
            margin={['0 auto']}
          >
            {/* Desktop */}
            <Image
              display={['none', 'none', 'block', 'block', 'block', 'block']}
              paddingBottom={['24px', '24px', '40px', '40px', '40px', '40px']}
              src="https://d3us4ynq1qsik6.cloudfront.net/work/strongr/2.webp"
              margin={['0 auto']}
            />
            {/* Mobile */}
            <Image
              display={['block', 'block', 'none', 'none', 'none', 'none']}
              paddingBottom={['0px', '20px', '40px', '40px', '40px', '40px']}
              src="https://d3us4ynq1qsik6.cloudfront.net/work/strongr/5.webp"
              margin={['0 auto']}
            />
          </Box>
        </Flex>
        {/* Text */}
        <Box
          maxW={['100%', '100%', '100%', '79%', '69%', '69%']}
          margin={['0 auto']}
          padding={['0px 20px', '0px 20px', '0px 20px', '0']}
        >
          <Box
            display={['block', 'block', 'block', 'flex']}
            direction={['column', 'column', 'row', 'row', 'row', 'row']}
            justifyContent={['space-between']}
            alignItems={['baseline']}
            gap={['10%']}
            margin={['0 auto']}
          >
            <Box minW={['201px']} paddingBottom={['16px', '16px', '16px', '0']}>
              {' '}
              <Text
                as="h1"
                fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-medium)"
                color={['#860038']}
                fontWeight={['500']}
                lineHeight={'normal'}
              >
                BACKGROUND
              </Text>
            </Box>

            <Box w={['100%', '100%', '100%', '74%', '74%', '74%']}>
              {' '}
              <Text
                as="p"
                fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                lineHeight={'normal'}
              >
                The online fitness industry is big and it’s saturated with big
                promises but most brands are lost in the seam of sameness. They
                fail to solve for a key challenge: fitness is a journey, not a
                destination.
                <br />
                <br />
                Websites & Mobile Applications built around online fitness fail
                to understand user psychology for maintaining a long-term
                fitness journey. Most users set overly ambitious goals and
                struggle to stay committed.
                <br />
                <br />
                Founder Sajil Shah envisioned a game-changing fitness platform
                Strongr that provides personalized training and fitness
                programs.
                <br />
                <br />
                The product’s aim is to make you stronger than yesterday. Giving
                you the ability to have incremental improvements in your fitness
                journey, that eventually lead to great compounded results.
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default backgroundContent;
