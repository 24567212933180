import React from 'react';
import red1 from '../../assets/Reusable/LetsWorkToGether/red1.svg';
import red2 from '../../assets/Reusable/LetsWorkToGether/red2.svg';
import LetsWorktogether from '../Reusable/LetsWorkToGether';

const LetsWorkToGether = () => {
  return (
    <div>
      <LetsWorktogether bgColor="#3E001A" hand1Img={red1} hand2Img={red2} />
    </div>
  );
};

export default LetsWorkToGether;
