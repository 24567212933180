import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const convertParagraphToText = paragraph => {
  if (!paragraph || !paragraph.length) return '';

  return paragraph
    .map(p => {
      if (p.type === 'paragraph') {
        return p.children
          .map(child => {
            if (child.type === 'text') {
              return child.text;
            }
            return '';
          })
          .join('');
      }
      return '';
    })
    .join('\n');
};

const fetchCombinedData = async () => {
  try {
    const [featuredResponse, insightResponse] = await Promise.all([
      axios.get('https://cms.wearetenet.com/api/general-blogs?populate=*'),
      axios.get('https://cms.wearetenet.com/api/insight-blogs?populate=*'),
    ]);
    const baseUrl = 'https://cms.wearetenet.com';

    const createLinkUrl = slug => `/resources/${slug}`;

    const featuredData = featuredResponse.data.data.map(item => {
      const slug = item.attributes.slug;
      const imageHeight = item.attributes.image_height || '';

      return {
        id: item.id,
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        linkText: item.attributes.type,
        imageHeight: getImageHeight(imageHeight),
        linkUrl: createLinkUrl(slug),
        category: item.attributes.category,
      };
    });

    const insightData = insightResponse.data.data.map(item => {
      const category = item.attributes.category;
      const slug = item.attributes.slug;
      const linkUrl = `/resources/${category}/${slug}`;
      const imageHeight = item.attributes.image_height || '';

      return {
        id: item.id,
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        imageHeight: getImageHeight(imageHeight),
        linkText: item.attributes.category,
        linkUrl,
        category,
      };
    });

    return [...featuredData, ...insightData];
  } catch (error) {
    console.error('Error fetching data', error);
    return [];
  }
};

const getImageHeight = imageHeight => {
  const heightMatch = imageHeight.match(/size\d=(\d+)/);
  return heightMatch ? `${heightMatch[1]}px` : '';
};

const ExploreAllStoryTwo = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleStories, setVisibleStories] = useState(4); // Initially show 4 items

  const itemsPerClick = useBreakpointValue({ base: 2, md: 4 });
  const sliceStartIndex = useBreakpointValue({ base: 4, md: 8 });

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchCombinedData();
        if (data.length > 0) {
          setSlidesData(data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleShowMore = () => {
    setVisibleStories(prevVisibleStories => prevVisibleStories + itemsPerClick);
  };

  const getCategoryBgColor = category => {
    switch (category) {
      case 'thoughts':
        return '#0057FF';
      case 'insights':
        return '#E0005E';
      case 'guides':
        return '#00763D';
      case 'glossary':
        return '#9B4DFF';
      case 'updates':
        return '#0036C2';
      case 'listen':
        return '#FF813A';
      default:
        return '#0057ff';
    }
  };

  const gridCss = {
    '@media (max-width: 599px)': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@media (min-width: 600px) and (max-width: 1023px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    '@media (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    columnGap: ['24px'],
    rowGap: ['43px', '43px', '43px', '60px', '60px', '60px'],
  };

  return (
    <Box
      padding={[
        '40px 0 80px',
        '40px 0 80px',
        '40px 0 80px',
        '40px 0 60px',
        '40px 0 60px',
        '40px 0 60px',
      ]}
    >
      <Box maxW={['100%']} margin={'auto'}>
        <Grid sx={gridCss}>
          {slidesData
            .slice(sliceStartIndex, sliceStartIndex + visibleStories)
            .map(story => (
              <GridItem key={story.id}>
                <a
                  href={story.linkUrl}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  display="inline-block"
                >
                <AspectRatio
                  paddingBottom={'12px!important'}
                  ratio={
                    story.imageHeight === 330 / 220
                      ? 1.5
                      : story.imageHeight === 330 / 275
                        ? 1.2
                        : story.imageHeight === 330 / 382
                          ? 0.863874
                          : story.imageHeight === 330 / 247
                            ? 1.336032
                            : 1
                  }
                  className="resourcesAspectRatioPaddingIssue"
                >
                  <Image
                    src={story.image}
                    w={['100%']}
                    height={'auto!important'}
                    objectFit={'cover'}
                    alt="story Image"
                    position={'relative!important'}
                  />
                </AspectRatio>
                <Box paddingBottom={'12px'}>
                  <Text
                    as={'span'}
                    fontSize={['16px']}
                    fontWeight={400}
                    lineHeight={'normal'}
                    color={'#fff'}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                    display={'inline-block'}
                    backgroundColor={getCategoryBgColor(story.linkText)}
                    padding={['10px 10px 7px']}
                    textTransform={['capitalize']}
                  >
                    {story.linkText}
                  </Text>
                </Box>
                <Box>
                  <Heading
                    as={'h3'}
                    fontSize={['20px']}
                    fontWeight={500}
                    lineHeight={'normal'}
                    color={'#333'}
                    fontFamily={'var(--garnett-medium);'}
                    paddingBottom={'12px'}
                  >
                    {story.title}
                  </Heading>
                  <Text
                    as={'p'}
                    fontSize={['14px']}
                    fontWeight={400}
                    lineHeight={'normal'}
                    color={'#333'}
                    noOfLines={['4']}
                    fontFamily={'var(--foundersGrotesk-regular);'}
                  >
                    {story.description}
                  </Text>
                </Box>
              </a>
              </GridItem>
            ))}
      </Grid>

      {visibleStories + sliceStartIndex < slidesData.length && (
        <Box
          textAlign={'center'}
          marginTop={['40px', '40px', '40px', '60px', '60px', '60px']}
        >
          <Button
            onClick={handleShowMore}
            fontSize={['24px']}
            color={'#333'}
            lineHeight={'133px'}
            fontWeight={400}
            fontFamily={' var(--garnett-regular);'}
            border={'1px solid #333'}
            borderRadius={'none'}
            backgroundColor={'transparent'}
          >
            Show more{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <g clipPath="url(#clip0_5918_10900)">
                <path
                  d="M20 9.14941L12.5 16.6494L5 9.14941"
                  stroke="#333333"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_5918_10900">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5 0.149414)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Button>
        </Box>
      )}
    </Box>
    </Box >
  );
};

export default ExploreAllStoryTwo;
