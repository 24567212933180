import { Box } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OurLeading from '../Reusable/CTA/OurLeading';

const OurLeadingUse = () => {
  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/white-labels?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  return (
    <Box>
      <OurLeading
        // Section
        sectionPaddingTopBottom={[
          '41px 0 79px',
          '41px 0 79px',
          '41px 0 79px',
          '80px 0 80px',
          '80px 0 80px',
          '80px 0 80px',
        ]}
        // Title
        titleText={firstItem?.box_heading}
        titleFontFaimly={'var(--garnett-semibold)'}
        titleFontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
        titleFontWeight={'600'}
        titleTextAlign={'left'}
        titleWrapMargin={'0'}
        titleLineHeight={[
          '44.58px',
          '44.58px',
          '44.58px',
          '66.86px',
          '66.86px',
          '66.86px',
        ]}
        titleWrapMaxWith={[
          '616px',
          '616px',
          '616px',
          '616px',
          '616px',
          '616px',
        ]}
        // Paragraph
        paragraphText={firstItem?.box_description}
        paragraphFontSize={['14px', '14px', '14px', '20px']}
        paragraphTextAlign={'left'}
        containerBgColor={'#30054A'}
        linkButtonAlignment={'left'}
        paragraphAndLinkMargin={'0'}
        paragraphTopPadding={['22px', '22px', '22px', '22px', '18px', '18px']}
        paragraphBottomPadding={[
          '40px',
          '40px',
          '40px',
          '18px',
          '18px',
          '18px',
        ]}
        paragraphMargin={'0'}
        paragraphLineHeight={['16.8px', '16.8px', '16.8px', '24px']}
        containerMarginBottom={'0'}
        linkUrl="/contact-us"
      />
    </Box>
  );
};

export default OurLeadingUse;