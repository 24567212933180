import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import BreadCrum from './BreadCrum';
import './Custom.css';
import Introduction from './introduction';

const heroSection = () => {
  const styles = {
    title: {
      fontSize: ['32px', '32px', '36px', '58px', '58px', '58px'],
      fontFamily: 'var(--garnett-semibold)',
      color: '#ffffff',
      fontWeight: '600',
      lineHeight: 'normal',
      maxW: '992px',
    },
    industryText: {
      fontSize: ['16px', '16px', '16px', '18px', '20px', '20px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      marginBottom: ['6px', '6px', '6px', '8px', '8px', '8px'],
    },
    tagText: {
      fontSize: ['12px', '12px', '12px', '12px', '12px', '12px'],
      fontFamily: 'var(--foundersGrotesk-regular)',
      fontWeight: '400',
      color: '#ffffff',
      border: '1px solid #00BA61',
      width: 'fit-content',
      paddingTop: '5px',
      paddingBottom: '2px',
      paddingLeft: '8px',
      paddingRight: '8px',
      marginBottom: '7px',
      lineHeight: 'normal',
    },
  };

  return (
    <Box position="relative" id="hero-section">
      <Box
        position={[
          'relative',
          'relative',
          'relative',
          'absolute',
          'absolute',
          'absolute',
        ]}
        left={'0'}
        right={'0'}
        top={'0'}
        bottom={['0%', '0%', '0%', '46%', '46%', '46%']}
        bg="#000"
        zIndex={'-1'}
      ></Box>
      <Box
        background={[
          '#000',
          '#000',
          '#000',
          'transparent',
          'transparent',
          'transparent',
        ]}
        paddingTop={['95px', '75px', '65px', '56px', '56px', '56px']}
      >
        <Container
          width={['100%', '100%', '100%', '94%', '94%', '94%']}
          maxW={['100%', '100%', '100%', '94%', '94%', '94%']}
          marginBottom={['0px', '0px', 0, 0, 0, 0]}
          padding={['0', '0', '0', '0 20px', '0 20px']}
        >
          <BreadCrum />
          {/* This is a Case Study Banner Title And Tags of Services and Inustry */}
          <Box
            padding={[
              '0px 20px 80px',
              '0px 20px 80px',
              '0px 20px 80px',
              '66px 0 104px',
              '66px 0 104px',
              '66px 0 104px',
            ]}
          >
            <Flex
              direction={['column', 'column', 'row', 'row', 'row', 'row']}
              gap={['40px', '40px', '40px', '0', '0', '0']}
              justifyContent={'space-between'}
            >
              <Box
                w={['100%', '100%', '70%', '78%', '83%', '83%']}
                color="#FFFFFF"
                display="flex"
                flexDirection="column"
              >
                {/* Banner Title */}
                <Box>
                  <Box maxW={['100%', '100%', '100%', '100%', '90%', '90%']}>
                    <Text as="h1" sx={styles.title}>
                      Product Development & Strategy for{' '}
                      <span style={{ color: '#DAFF00' }}>Strongr</span>, The
                      Ultimate Personalized Fitness Platform
                    </Text>
                  </Box>
                </Box>
              </Box>
              {/* Start Tags And Industry Services */}
              <Box w={['217px', '217px', '217px', '210px', '210px']}>
                <Box
                  marginBottom={[
                    '30px',
                    '30px',
                    '30px',
                    '34px',
                    '34px',
                    '34px',
                  ]}
                >
                  <Text sx={styles.industryText}>Industry</Text>
                  <Text sx={styles.tagText}> Healthcare & Fitness</Text>
                </Box>
                <Box>
                  <Text sx={styles.industryText}>Services Provided</Text>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Mobile App Development</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Backend Development</Text>
                    <Text sx={styles.tagText}>DevOps</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>E-commerce Enablement</Text>
                  </Flex>
                  <Flex gap={['6px']}>
                    <Text sx={styles.tagText}>Product Marketing</Text>
                    <Text sx={styles.tagText}>Email Marketing</Text>
                  </Flex>
                </Box>
              </Box>
              {/* End Tags And Industry Services */}
            </Flex>
          </Box>
          {/* Image Display Only For Mobile Not Desktop tHIS IS A Introduction Image */}
          <Box display={['block', 'block', 'block', 'none', 'none', 'none']}>
            <Image
              src="https://d3us4ynq1qsik6.cloudfront.net/work/strongr/4.webp"
              width={['100%', '100%', '100%', '72%', '72%', '72%']}
              margin={['0 auto']}
            />
          </Box>
        </Container>
      </Box>
      <Box>
        <Introduction />
      </Box>
    </Box>
  );
};

export default heroSection;
