import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const projects = [
  {
    id: 1,
    image: require('../../assets/work/iros.webp'),
    link: '/work/iros',
    title: 'IROS',
    description:
      'Brand Strategy, Identity, and Website Transformation for a leading healthcare giant in the UAE.',
    technologies: [
      'Brand Identity',
      'Brand Strategy',
      'Logo Design',
      'UX Research',
      'UI Design',
      'Website Development',
    ],
  },
  {
    id: 2,
    image: 'https://d3us4ynq1qsik6.cloudfront.net/work/5.webp',
    // image: require('../../assets/work/asdav.webp'),
    link: '/work/asdav',
    title: 'ASDAV',
    description:
      'Brand Strategy, Identity, Website Design, and GTM Enablement.',
    technologies: [
      'Brand Identity',
      'Brand Strategy',
      'Logo Design',
      'UX Research',
      'UI Design',
      'Website Development',
      'Ecommerce Enablement',
      'GTM Strategy',
    ],
  },
  {
    id: 3,
    image: require('../../assets/work/g42.webp'),
    link: '/work/g42healthcare',
    title: 'G42 Healthcare',
    description:
      'Digital Transformation, Website Development, & Digital Experience Management for a leading healthcare giant in the UAE.',
    technologies: [
      'Digital Transformation',
      'UX Research',
      'UI Design',
      'Website Development',
      'Data Analytics',
      'Competitive Benchmarking',
    ],
  },
  {
    id: 4,
    image: 'https://d3us4ynq1qsik6.cloudfront.net/work/1.webp',
    // image: require('../../assets/work/strongr.webp'),
    link: '/work/strongr',
    title: 'Strongr',
    description:
      'Product Development & Strategy for , a leading fitness startup in India.',
    technologies: [
      'Mobile App Development',
      'Backend Development',
      'Ecommerce Enablement',
      'DevOps',
      'Product Marketing',
      'Email Marketing',
    ],
  },
  {
    id: 5,
    // image: require('../../assets/work/ace.webp'),
    image: 'https://d3us4ynq1qsik6.cloudfront.net/work/ace_plus.webp',
    link: '/work/strongr',
    title: 'ACE plus',
    description:
      'Product Development & Digital Transformation for India’s leading soft skill education platform for young learners. ',
    technologies: [
      'Mobile App Development',
      'QA Testing',
      'Backend Development',
      'Game Development',
      'Ecommerce Enablement',
      'Product Development',
      'Marketing Enablement',
    ],
  },
  {
    id: 6,
    // image: require('../../assets/work/angels.webp'),
    image: 'https://d3us4ynq1qsik6.cloudfront.net/work/4.webp',
    link: '/work/angles',
    title: 'Angles',
    description:
      'Product Development, Ecommerce Enablment and Digital Transformation for Kuwait’s leading lifestyle store.',
    technologies: [
      'UX Research',
      'Ecommerce Enablement',
      'UI Design',
      'Web Development',
      'Mobile App Developemnt',
      'Backend Development',
      'DevOps',
      'Marketing Enablement',
    ],
  },
  // Add more projects as needed
];

const OurWorksNew = () => {
  return (
    <Box
      padding={[
        '78px 0 80px',
        '78px 0 80px',
        '78px 0 80px',
        '150px 0',
        '150px 0',
      ]}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '79%', '79%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        {/* Our Works Headings */}
        <Box
          display={['block', 'block', 'block', 'Flex']}
          justifyContent={['space-between']}
          gap={['30px']}
          textAlign={['center', 'center', 'center', 'left', 'left']}
        >
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '40px', '72px', '72px']}
            color={'#333'}
            fontFamily={'var(--garnett-regular);'}
            fontWeight={'400'}
            lineHeight={'normal'}
            paddingBottom={['35px', '35px', '35px', '0']}
          >
            Our Work
          </Heading>
          <Text
            as={'p'}
            fontFamily={'var(--foundersGrotesk-regular);'}
            fontSize={['16px', '16px', '16px', '18px', '20px', '20px']}
            color={'#333'}
            fontWeight={'400'}
            lineHeight={'normal'}
            maxWidth={['528px']}
            margin={['0 auto', '0 auto', '0 auto', '0']}
          >
            Dive into a selection of transformative projects to see how
            commitment to excellence turns challenges into tangible successes.
            Experience the impact of our committed partnerships and innovative
            strategies.
          </Text>
        </Box>
        {/* Our Works Showcase */}
        <Box
          padding={[
            '40px 0 38px',
            '40px 0 38px',
            '40px 0 38px',
            '70px 0 66px',
            '70px 0 66px',
          ]}
        >
          {/* Grid */}
          <Box
            display={['grid']}
            gridTemplateColumns={[
              'repeat(1,1fr)',
              'repeat(1,1fr)',
              'repeat(2,1fr)',
              'repeat(2,1fr)',
            ]}
            rowGap={['46px', '46px', '46px', '74px']}
            columnGap={['23px']}
          >
            {projects.map(project => (
              <Box key={project.id}>
                <Link href={project.link} _hover={{ textDecoration: 'none' }}>
                  {/* Project Image */}
                  <AspectRatio ratio={1.7}>
                    <Image
                      w={'100%'}
                      src={project.image}
                      loading="lazy"
                      alt="Work Image"
                    />
                  </AspectRatio>
                  {/* Project Description */}
                  <Box>
                    {/* Title and Descriptions */}
                    <Box>
                      <Heading
                        as={'h3'}
                        color={'#1A1A1A'}
                        fontSize={[
                          '16px',
                          '16px',
                          '16px',
                          '20px',
                          '20px',
                          '20px',
                        ]}
                        fontFamily={'var(--garnett-semibold);'}
                        fontWeight={'600'}
                        lineHeight={'normal'}
                        padding={[
                          '21px 0 11px',
                          '21px 0 11px',
                          '21px 0 11px',
                          '20px 0 15px',
                          '20px 0 15px',
                        ]}
                      >
                        {project.title}
                      </Heading>
                      <Text
                        as={'p'}
                        fontFamily={'var(--foundersGrotesk-regular);'}
                        fontSize={[
                          '16px',
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '18px',
                        ]}
                        color={'#1A1A1A'}
                        fontWeight={'400'}
                        lineHeight={'normal'}
                        paddingBottom={['22px', '22px', '22px', '25px']}
                      >
                        {project.description}
                      </Text>
                    </Box>
                    {/* Technologies Used */}
                    <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
                      {project.technologies.map((tech, index) => (
                        <Button
                          key={index}
                          color={'#333'}
                          fontSize={[
                            '10px',
                            '10px',
                            '10px',
                            '14px',
                            '14px',
                            '14px',
                          ]}
                          height={['27px', '27px', '27px', '29px']}
                          fontWeight={'400'}
                          lineHeight={'normal'}
                          border={'1px'}
                          borderColor={'#E9E9E9'}
                          backgroundColor={'#E9E9E9'}
                          boxShadow={'none'}
                          borderRadius={'0'}
                          padding={['11px 10px 7px ']}
                          disabled
                          display={['flex']}
                          alignItems={'center'}
                          fontFamily={'var(--foundersGrotesk-regular);'}
                        >
                          {tech}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
        {/* See All Works Button */}
        <Box textAlign={['left', 'left', 'left', 'right']}>
          <Link
            className="textDecoration"
            href="/work"
            sx={{
              color: '#333',
              fontSize: ['32px', '32px', '32px', '64px', '64px', '64px'],
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              textDecoration: 'underline',
              fontFamily: 'var(--garnett-semibold);',
              display: 'inline-block',
            }}
          >
            See all Projects
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default OurWorksNew;
