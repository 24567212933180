import {
  Box,
  Container,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
import LeftCaret from '../../../assets/Icons/Slider/leftIcon.svg';
import RightCaret from '../../../assets/Icons/Slider/rightIcon.svg';
import image5 from '../../../assets/Reusable/exploreOtherWork/ace.webp';
import image6 from '../../../assets/Reusable/exploreOtherWork/angels.webp';
import image2 from '../../../assets/Reusable/exploreOtherWork/strongr.webp';
import image4 from '../../../assets/Reusable/exploreOtherWork/thinkVastu.webp';

import { Link } from 'react-router-dom';

const ExploreOtherWork = () => {
  const eowData = [
    {
      image: image2,
      title:
        'Product Development & Strategy for Strongr, a leading fitness startup in India.',
      description: 'Project Name, Headline with the caption Max 2 lines.',
    },
    {
      image: image4,
      title:
        'Brand Strategy, Identity, Website Design, and GTM Enablement for ASDAV.',
      description: 'Project Name, Headline with the caption Max 2 lines.',
    },
    {
      image: image5,
      title:
        'Product Development & Digital Transformation for AcePlus, India’s leading soft skill education platform for young learners.',
      description: 'Project Name, Headline with the caption Max 2 lines.',
    },
    {
      image: image6,
      title:
        'Product Development, Ecommerce Enablment and Digital Transformation for Angles, Kuwait’s leading lifestyle store.',
      description: 'Project Name, Headline with the caption Max 2 lines.',
    },
  ];

  const sectionHeading = {
    fontSize: ['32px', '32px', '32px', '32px', '32px', '32px'],
    fontFamily: 'var(--garnett-regular)',
    color: ['#333'],
    fontWeight: ['400'],
    lineHeight: 'normal',
  };

  const viewLink = {
    background: '#00763D',
    color: '#fff',
    fontSize: ['20px', '20px', '20px', '20px', '20px', '20px'],
    padding: ['10px 36px 7px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    display: 'inline-block',
    transition: 'transform 0.5s ease',
    _hover: {
      transform: 'translate3d(0, -3px, 0)',
    },
  };
  const swipperSliderCustomeStyle = {
    paddingLeft: useBreakpointValue({
      base: 'calc(5%)',
      sm: 'calc(5%)',
      md: 'calc(5%)',
    }),
    paddingRight: useBreakpointValue({
      base: 'calc(5% + 10px)',
      sm: 'calc(5% + 10px)',
      md: 'calc(5% + 15px)',
    }),
  };
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
    // console.log(swiperRef.current.swiper);
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };
  return (
    <Box
      paddingBottom={['60px', '60px', '60px', '80px', '100px', '100px']}
      overflowX={['hidden']}
      marginTop={['82px', '82px', '82px', '100px', '100px']}
    >
      {/* Section Heading */}
      <Container
        maxW={['100%', '95%', '90%', '90%', '90%', '90%']}
        m={'0 auto'}
      >
        <Flex
          justifyContent={'space-between'}
          alignItems={[
            'flex-start',
            'flex-start',
            'center',
            'center',
            'center',
            'center',
          ]}
          borderTop={['1px solid #B5B5B5']}
          p={[
            '40px 0 30px',
            '40px 0 30px',
            '60px 0 40px',
            '60px 0 40px',
            '60px 0 40px',
            '60px 0 40px',
          ]}
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
          gap={['40px', '40px', 0, 0, 0, 0]}
        >
          <Box>
            <Text as="h1" sx={sectionHeading}>
              Explore other works
            </Text>
          </Box>
          <Box>
            <Link style={viewLink}>View All</Link>
          </Box>
        </Flex>
      </Container>
      <Container
        maxW={['100%', '100%', '100%', '100%', '100%', '100%']}
        mr={'0'}
        className="exploreOtherWorks"
      >
        {/* Slider */}
        <Container
          maxWidth={['100%', '100%', '100%', '90%', '90%', '90%']}
          m={'0 auto'}
          display={['block', 'block', 'block', 'none', 'none', 'none']}
          padding={['0']}
        >
          <Flex
            justifyContent={['space-between']}
            paddingTop={['20px']}
            width={['90%']}
            display={['flex', 'flex', 'none', 'none', 'none', 'none']}
            margin={['0 auto']}
            paddingBottom={['31px']}
          >
            <Text
              fontSize={['14px']}
              color={['#333']}
              fontFamily="var(--garnett-regular)"
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 4}`}
            </Text>
            <Box display={['flex']}>
              <Box onClick={() => goPrev()} mr={'20px'}>
                <Image src={LeftCaret} cursor={'pointer'} />
              </Box>
              <Box onClick={() => goNext()}>
                <Image src={RightCaret} cursor={'pointer'} />
              </Box>
            </Box>
          </Flex>
        </Container>
        <Box _hover={{ cursor: `url(${arrowImage}), auto` }}>
          <Swiper
            ref={swiperRef}
            observeParents={true}
            observer={true}
            modules={[Navigation, FreeMode]}
            speed={750}
            slidesPerView={2.7}
            spaceBetween={24}
            style={swipperSliderCustomeStyle}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                spaceBetween: 20,
                freeMode: false,
              },
              480: {
                slidesPerView: 2.6,
                spaceBetween: 20,
                freeMode: false,
              },
              768: {
                slidesPerView: 2.6,
                spaceBetween: 24,
              },
              992: {
                slidesPerView: 2.6,
                spaceBetween: 24,
              },
            }}
            onSlideChange={swiper => handleSlideChange(swiper)}
          >
            {eowData.map((data, index) => (
              <SwiperSlide key={index}>
                <Box>
                  <Image
                    src={data.image}
                    w={'100%'}
                    minH={[
                      '180px',
                      '200px',
                      '220px',
                      '318px',
                      '318px',
                      '318px',
                    ]}
                    height={[
                      '180px',
                      '200px',
                      '220px',
                      '318px',
                      '318px',
                      '318px',
                    ]}
                    objectFit={'cover'}
                  />
                </Box>
                <Text
                  fontSize={['14px', '14px', '14px', '20px', '20px', '20px']}
                  fontFamily="var(--garnett-regular)"
                  color={['#333']}
                  fontWeight={['400']}
                  lineHeight={'normal'}
                  p={'29px 0 16px'}
                >
                  {data.title}
                </Text>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default ExploreOtherWork;
