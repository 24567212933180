import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

function NicePageWithANiceCardStackAnimation() {
  const stackAreaRef = useRef(null);
  const leftRef = useRef(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [slideIndex, setSlideIndex] = useState(0);
  const handleSwipe = useCallback(
    _.debounce(eventData => {
      const cards = document.querySelectorAll('.card');
      const n = cards.length;

      // Clamp new index between 0 and n - 2 (to exclude the last card)
      const newIdx = Math.max(
        0,
        Math.min(
          eventData.dir === 'Up' ? slideIndex + 1 : slideIndex - 1,
          n - 2
        )
      );

      for (let i = 0; i < n - 1; i++) {
        // Loop up to the second-last card
        if (!cards[i]) continue;
        if (i <= newIdx) {
          cards[i].classList.add('active');
        } else {
          cards[i].classList.remove('active');
        }
      }

      setSlideIndex(newIdx);
      rotateCards();
    }, 1000), // Debounce delay in milliseconds
    [slideIndex]
  );

  const handlers = useSwipeable({
    onSwiped: eventData => {
      if (eventData.dir === 'Up' || eventData.dir === 'Down') {
        handleSwipe(eventData);
      }
    },
  });

  function rotateCards() {
    let offsetY = 0;
    const offsetYIncrement = 30;

    let offsetWidth = 0;
    const offsetWidthDecrement = 15;

    let offsetOpacity = 0;
    const offsetOpacityDecrement = 0.25;

    const cards = document.querySelectorAll('.card');
    cards.forEach((card, idx) => {
      if (card && !card.style) return;
      if (card.classList.contains('active')) {
        card.style.transform = `translate(-50%, -120vh)`;
        card.style.opacity = '0';
        card.style.width = '100%';
      } else {
        card.style.transform = `translate(-50%, calc(-50% - ${offsetY}px))`;
        card.style.opacity = '' + (1 - offsetOpacity);
        card.style.width = `calc(100% - ${offsetWidth}px)`;

        offsetY += offsetYIncrement;
        offsetWidth += offsetWidthDecrement;
        offsetOpacity += offsetOpacityDecrement;
      }
    });
  }

  useEffect(() => {
    if (typeof window === 'undefined') return;

    function handleResize() {
      if (
        !leftRef.current ||
        !stackAreaRef.current ||
        typeof window === 'undefined'
      )
        return;

      let windowWidth = window.innerWidth;
      if (windowWidth < 800) {
        stackAreaRef.current.insertAdjacentElement(
          'beforebegin',
          leftRef.current
        );
      } else {
        stackAreaRef.current.insertAdjacentElement(
          'afterbegin',
          leftRef.current
        );
      }
    }

    rotateCards();

    if (!isMobile) {
      window.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      if (!isMobile) window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  function handleScroll() {
    if (stackAreaRef.current == null || isMobile) return;
    const cards = document.querySelectorAll('.card');

    let proportion =
      stackAreaRef.current.getBoundingClientRect().top / window.innerHeight;
    if (proportion <= 0) {
      let n = cards.length;
      let index = Math.ceil((proportion * n) / 2);
      index = Math.abs(index) - 1;

      // Ensure the index does not exceed n - 2 (to exclude the last card)
      index = Math.min(index, n - 2);

      for (let i = 0; i < n - 1; i++) {
        // Loop up to the second-last card
        if (!cards[i]) continue;

        // Add a delay before adding the class
        setTimeout(() => {
          if (i <= index) {
            cards[i].classList.add('active');
          } else {
            cards[i].classList.remove('active');
          }
        }, 110 * i); // Adjust the delay as needed (100ms in this example)
      }
      rotateCards();
    }
  }

  const { region } = useParams(); 
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/abouts?filters[region][$eq]=${region || 'global'}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [region]);

  const firstItem = data ? data.attributes : null;
  return (
    <>
      <Flex
        w={'full'}
        h="fit-color"
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Flex
          w={'100%'}
          height={isMobile ? '100dvh' : '300vh'}
          direction={isMobile ? 'column' : 'row'}
          position={'relative'}
          justifyContent={'center'}
          ref={stackAreaRef}
        >
          <Flex
            h={'100vh'}
            direction={'column'}
            alignItems={'center'}
            justifyContent={isMobile ? 'start' : 'center'}
            pos={!isMobile ? 'sticky' : 'relative'}
            top={0}
            boxSizing="border-box"
            basis={'50%'}
            ref={leftRef}
          >
            <Flex
              direction="column"
              paddingTop={['57px', '57px', '0', '0', '0', '0']}
              width={['100%', '100%', '100%', '68%', '68%', '68%']}
              alignItems="center" // Center align vertically
              bg={''}
              zIndex={999}
            >
              <Text
                as="h1"
                fontSize={['32px', '32px', '32px', '38px', '44px', '48px']}
                fontFamily="var(--garnett-regular)"
                color={['#000000']}
                fontWeight={['400']}
                lineHeight={'normal'}
                width={['100%']}
                paddingBottom={['22px', '22px', '22px', '30px', '30px', '30px']}
                textAlign={[
                  'center',
                  'center',
                  'center',
                  'center',
                  'left',
                  'left',
                ]}
              >
                {firstItem?.about_heading}
              </Text>
              <Text
                as="p"
                fontSize={['16px', '16px', '16px', '16px', '18px', '20px']}
                fontFamily="var(--foundersGrotesk-regular)"
                color={['#333333']}
                fontWeight={['400']}
                width={['95%', '95%', '95%', '100%', '100%', '100%']}
                lineHeight={'normal'}
                textAlign={[
                  'center',
                  'center',
                  'center',
                  'center',
                  'left',
                  'left',
                ]}
              >
                {firstItem?.about_paragraph}
              </Text>
            </Flex>
          </Flex>
          <Flex
            className="right foo"
            h={'100vh'}
            position={['sticky']}
            alignItems={'center'}
            justifyContent={'center'}
            top={0}
            boxSizing="border-box"
            basis={['100%', '100%', '100%', '50%', '50%', '50%']}
            style={{ touchAction: slideIndex > 10 - 1 ? 'pan-y' : 'none' }}
          >
            <Box w={'full'} h={'full'} pos={'relative'} {...handlers}>
              {Array(10)
                .fill(0)
                .map((_, idx) => (
                  <Card key={idx} idx={idx}>
                    <Image
                      src={`/slides/slide${idx + 1}.svg`}
                      alt={`slide ${idx + 1}`}
                    />
                  </Card>
                ))}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default NicePageWithANiceCardStackAnimation;

const Card = ({ children, idx }) => {
  return (
    <Flex
      className={'card'}
      justifyContent={'space-between'}
      direction={'column'}
      aspectRatio={'16/9'}
      h={'auto'}
      boxSizing={'border-box'}
      p={'2.25rem'}
      borderRadius={'6mm'}
      pos={'absolute'}
      top={'50%'}
      left={'50%'}
      transition={
        'transform 0.5s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out'
      }
      zIndex={100 - idx}
    >
      {children}
    </Flex>
  );
};
