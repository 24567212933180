import { Box, Heading, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PowerofUXDesign = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=build&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;

  const heading = {
    fontSize: ['32px', '32px', '32px', '42px', '42px', '42px'],
    paddingBottom: '25px',
    color: ['#000'],
    letterSpacing: '-0.462px',
    lineHeight: 'normal',
    fontFamily: 'var(--garnett-regular);',
  };
  const paragraph = {
    fontSize: ['16px', '16px', '16px', '18px', '18px', '18px'],
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#333',
    fontWeight: '400',
    lineHeight: 'normal',
    maxW: ['756px'],
    margin: ['0 auto'],
  };
  return (
    <Box>
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '80%', '80%', '80%']}
        padding={['0px 20px']}
        margin={['0 auto']}
        backgroundColor={'#F5F5F5'}
      >
        {/* Content */}
        <Box
          maxW={['766px']}
          margin={['0 auto']}
          textAlign={'center'}
          padding={[
            '44px 0 30px',
            '44px 0 30px',
            '44px 0 30px',
            '80px 0 60px',
            '80px 0 60px',
            '80px 0 60px',
          ]}
        >
          {/* Title */}
          <Heading as={'h2'} sx={heading}>
            {firstItem?.heading}
          </Heading>
          <Text as={'p'} sx={paragraph}>
            {firstItem?.paragraph}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default PowerofUXDesign;
