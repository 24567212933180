import React from 'react';
import UseClientSaysLoveSlider from '../Reusable/ClientSaysSliderHomeAndWhiteLabel/ClientSaysSliderHomeAndWhiteLabel';

const CleintSaysSliderUse = () => {
  return (
    <UseClientSaysLoveSlider
      sectionBgColor={'#003b31'}
      sectionTitle="Our clients love us. See what they have to say."
      sectionParagraph="We look at our engagements differently. For us, it's about recognising that all businesses, B2B or B2C, are essentially P2P (Person To Person)."
      sectionMarginTopBottom={'0'}
    />
  );
};

export default CleintSaysSliderUse;
