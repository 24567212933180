import React from 'react'
import LeadingCtcUSe from '../Reusable/CTA/OurLeading';

const OurLeadingCTA = () => {
  return (
    <div>
      <LeadingCtcUSe
        linkBgColor="#0057FF"
        linkBoder="2px solid #fff"
        titleWrapMaxWith={['485px', '485px', '585px']}
        linkBtnText="Request Consultation"
        titleText="Build a robust healthcare software that works beyond your expectations."
        paragraphText="Elevate your service with our exceptional user experience expertise. Reach out to our specialists to enhance your digital solutions today."
      />
    </div>
  );
}

export default OurLeadingCTA
