import { Box, Container, Flex, Image } from '@chakra-ui/react';
import React from 'react';

const backgroundContent = () => {
  return (
    <Container
      width={['100%']}
      maxWidth={['100%']}
      marginBottom={['50px', '50px', 0, 0, 0, 0]}
      padding={['0']}
      paddingTop={['80px', '80px', '80px', '80px', '90px', '150px']}
    >
      <Flex
        direction={[
          'column-reverse',
          'column-reverse',
          'row',
          'row',
          'row',
          'row',
        ]}
        gap={['55px', '55px', '55px', '0', '0', '0']}
        marginBottom={['10px', '10px', '10px', '10px', '10px', '10px']}
      >
        <Box maxW={['100%', '100%', '100%', '100%', '100%', '100%']}>
          <Image
            display={['none', 'none', 'block', 'block', 'block', 'block']}
            paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
            src="https://d3us4ynq1qsik6.cloudfront.net/work/pazazz/1.webp"
            width={['100%', '100%', '100%', '96%', '96%', '96%']}
            margin={['0 auto']}
          />
          <Image
            display={['block', 'block', 'none', 'none', 'none', 'none']}
            paddingBottom={['30px', '40px', '50px', '50px', '50px', '50px']}
            src="https://d3us4ynq1qsik6.cloudfront.net/work/pazazz/6.webp"
            width={['100%', '100%', '100%', '96%', '96%', '96%']}
            margin={['0 auto']}
          />
        </Box>
      </Flex>
    </Container>
  );
};
export default backgroundContent;
