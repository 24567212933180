import React from 'react';
import { Box } from '@chakra-ui/react';

import HeroSection from './LandlingPage/HeroSection';
import CaseStudy from './LandlingPage/CaseStudy';
import LetsWorkToGether from '../Reusable/LetsWorkToGether';

const Work = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>
      <Box>
        <CaseStudy />
      </Box>
      <Box>
        <LetsWorkToGether bgColor="#0D0E4D" />
      </Box>
    </>
  );
};

export default Work;
