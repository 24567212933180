import { Box, Button, Checkbox, Flex, FormControl, Heading, Input, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';

// Define input field styles
const inputStyles = {
  fontFamily: "var(--foundersGrotesk-regular);",
  fontSize: '13px',
  fontWeight: 'normal',
  lineHeight: 'normal',
  bg: "#f5f5f5",
  borderBottom: "1px solid #b5b5b5",
  borderRadius: "0",
  paddingTop: "7px",
  marginBottom: "14px",
  _placeholder: { color: "#333333" }
};

// Define button styles
const buttonStyles = {
  lineHeight: "normal",
  p: "8px 23px",
  borderRadius: "none",
  bg: "#00342B",
  fontSize: "14px",
  fontWeight: "400",
  color: "#fff",
  fontFamily: "var(--foundersGrotesk-regular);",
  _hover: {
    bg: "#00342B",
    color: "#fff", 
  },
};


const DataDownloadForm = () => {
  const formHeading = {
    color: '#00342B',
    fontFamily: 'var(--garnett-regular)',
    fontSize: ['25px', "27px", "29px", "30px", "32px", "32px"],
    fontWeight: 'normal',
    lineHeight: 'normal',
    paddingBottom: ["30px", "32px", "34px", "36px", "36px", "38px"]
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [country, setCountry] = useState('');
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const toast = useToast();

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleReceiveUpdatesChange = (event) => {
    setReceiveUpdates(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!firstName || !lastName || !email || !phone || !jobTitle || !companyName || !country || !receiveUpdates) {
      toast({
        title: 'Please fill in all required fields',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    const formData = {
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phone,
        job_title: jobTitle, // Note the change in key name
        company_name: companyName, // Note the change in key name
        country,
        receive_updates: receiveUpdates // Note the change in key name
      }
    };


    try {
      const response = await fetch('https://cms.wearetenet.com/api/insight-forms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 8979fca7f4441861ed50a2a4afc1acfd819a5d2055c8682566dc7518ec29cb082b8ae7202644a8f350f5368e4d35207bf05cb0256e98a7fb9467f0a2749bff434d650b7dd3ed1714abef6b49734878b0eb9276b052cc31ceecbbe7dd510e3102efa2e14ef0cc7326ebb2743ddd5c1457c8b815db1626d7a09c1c42dccf199216',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const responseData = await response.json();
        toast({
          title: 'Form Submitted Successfully',
          description: "We'll get back to you shortly",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // Clear form fields
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setJobTitle('');
        setCompanyName('');
        setCountry('');
        setReceiveUpdates(false);
        console.log('Success:', responseData);
        // Optionally handle success response
      } else {
        const errorData = await response.json();
        console.error('Error:', response.status, errorData);
        toast({
          title: 'Submission Failed',
          description: 'There was a problem with your submission. Please try again.',
          status: 'error',
          isClosable: true,
        });
        // Handle error response
      }
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Network Error',
        description: 'There was a problem connecting to the server. Please try again later.',
        status: 'error',
        isClosable: true,
      });
      // Handle network or other errors
    }
  };

  return (
    <Box position={'sticky'} top={'10%'}>
      <Box bg={'#CCF1DF'} p={'40px 36px'}>
        {/* Form Heading */}
        <Box><Heading as={'h4'} sx={formHeading}>Download Now</Heading></Box>
        {/* Download Form */}
        <Box bg={'#fff'} p={'68px 30px 28px'}>
          <form onSubmit={handleSubmit}>
            <Box bg={"#fff"}>
              {/* First Name And Last Name */}
              <Flex justifyContent={'space-between'}>
                <FormControl id="FirstName" w={'calc(50% - 4px)'}>
                  <Input
                    placeholder="First Name*"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    color={firstName ? "#000000" : "#333333"}
                    {...inputStyles} // Apply input field styles
                  />
                </FormControl>
                <FormControl id="LastName" w={'calc(50% - 4px)'}>
                  <Input
                    placeholder="Last Name*"
                    value={lastName}
                    onChange={handleLastNameChange}
                    color={lastName ? "#000000" : "#333333"}
                    {...inputStyles} // Apply input field styles
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <Input
                  type="email"
                  placeholder="Email Address*"
                  value={email}
                  onChange={handleEmailChange}
                  color={email ? "#000000" : "#333333"}
                  {...inputStyles} // Apply input field styles
                />
              </FormControl>
              <FormControl>
                <Input
                  type="number"
                  placeholder="Phone*"
                  value={phone}
                  onChange={handlePhoneChange}
                  color={phone ? "#000000" : "#333333"}
                  {...inputStyles} // Apply input field styles
                />
              </FormControl>
              <FormControl>
                <Input
                  type="Text"
                  placeholder="Job Title*"
                  value={jobTitle}
                  onChange={handleJobTitleChange}
                  color={jobTitle ? "#000000" : "#333333"}
                  {...inputStyles} // Apply input field styles
                />
              </FormControl>
              <FormControl>
                <Input
                  type="Text"
                  placeholder="Company Name*"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                  color={companyName ? "#000000" : "#333333"}
                  {...inputStyles} // Apply input field styles
                />
              </FormControl>
              <FormControl>
                <Input
                  type="Text"
                  placeholder="Country*"
                  value={country}
                  onChange={handleCountryChange}
                  color={country ? "#000000" : "#333333"}
                  {...inputStyles} // Apply input field styles
                />
              </FormControl>
              <Box mb={"18px"} mt={"16px"}>
                <Box display={"flex"} alignItems={"flex-start"}>
                  <Checkbox
                    checked={receiveUpdates}
                    onChange={handleReceiveUpdatesChange}
                    mr={"12px"}
                  />
                  <Text
                    as="p"
                    color={"#333333"}
                    mt={"0px"}
                    fontSize={"11px"}
                    fontFamily="var(--foundersGrotesk-regular)"
                    fontWeight={"400"}
                  >
                    By submitting your information to our website you agree to the terms outlined in our Privacy Policy
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  {...buttonStyles} // Apply button styles
                  type="submit"
                >
                  Download Now
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default DataDownloadForm;
