import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Simplified CategoryModal Component
const CategoryModal = ({ isOpen, onClose, categories, onCategorySelect }) => {
  const MotionBox = motion(Box);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  return (
    isOpen && (
      <Box
        position="fixed"
        top={['inherit', 'inherit', '0%', '0%', '0%']}
        bottom={['0', '0', 'inherit']}
        left="0"
        width="100%"
        height={['inherit', 'inherit', 'inherit', '100%', '100%']}
        bg="rgba(0,0,0,.24)"
        zIndex="2"
        overflowY="auto"
        onClick={onClose}
      >
        <MotionBox
          bg={'#fff'}
          height={['auto', 'auto', '370px', '370px', '370px']}
          borderRadius="md"
          p="4"
          onClick={e => e.stopPropagation()}
          initial={{ y: '-100vh' }}
          animate={{ y: 0 }}
          exit={{ y: '-100vh' }}
          transition={{ duration: 0.3 }}
        >
          <Box
            maxW={['94%']}
            margin="0 auto"
            paddingTop={['0px', '0px', '70px', '70px', '70px', '70px']}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'center'}
              marginBottom={['40px', '40px', '40px', '50px', '50px', '50px']}
              position={'relative'}
            >
              <Text
                fontWeight="400"
                fontSize={['14px', '14px', '14px', '24px', '24px', '24px']}
                fontFamily="var(--garnett-regular)"
                display={'flex'}
                gap={'5px'}
                onClick={onClose}
                className="picCate"
              >
                Pick category
                <Box
                  w={['10px', '10px', '10px', '15px', '15px', '15px']}
                  h={['5px', '5px', '5px', '7px', '7px', '7px']}
                  paddingTop={'6px'}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5918_12496)">
                      <path
                        d="M19.5 15L12 7.5L4.5 15"
                        stroke="#333333"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5918_12496">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="matrix(1 0 0 -1 0 24)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Box>
              </Text>
              <CloseIcon
                position={'absolute'}
                right={'0'}
                onClick={onClose}
                cursor={'pointer'}
              />
            </Box>
            <Collapse in={isExpanded}>
              <Box>
                {/* First Row */}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-around"
                  mb={['0px', '0px', '0px', '20px', '20px', '20px']}
                >
                  <List
                    display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
                    flexWrap="wrap"
                    gap="60px"
                    width="100%"
                    justifyContent="center"
                  >
                    {categories.slice(0, 4).map(category => (
                      <ListItem
                        key={category}
                        onClick={() => onCategorySelect(category)}
                        cursor="pointer"
                        _hover={{ color: '#9b4cff' }}
                        color="#000"
                        fontSize={['32px', '32px', '32px', '32px', '32px']}
                        fontFamily="var(--garnett-regular)"
                        lineHeight="normal"
                        textAlign={['left', 'left', 'left', 'center', 'center']}
                        marginBottom={['30px', '30px', '30px', '0px', '0px']}
                        display={['flex', 'flex', 'block']}
                        justifyContent={'center'}
                      >
                        {category}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {/* Second Row */}
                <Box
                  display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
                  justifyContent="center"
                >
                  <List
                    display={['block', 'block', 'flex', 'flex', 'flex', 'flex']}
                    gap="60px"
                    width="100%"
                    justifyContent="center"
                  >
                    {categories.slice(4, 6).map(category => (
                      <ListItem
                        key={category}
                        onClick={() => onCategorySelect(category)}
                        cursor="pointer"
                        _hover={{ color: '#9b4cff' }}
                        color="#000"
                        fontSize={['32px', '32px', '32px', '32px', '32px']}
                        fontFamily="var(--garnett-regular)"
                        lineHeight="normal"
                        textAlign={['left', 'left', 'left', 'center', 'center']}
                        marginBottom={['30px', '30px', '30px', '0px', '0px']}
                        display={['flex', 'flex', 'block']}
                        justifyContent={'center'}
                      >
                        {category}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </MotionBox>
      </Box>
    )
  );
};

// Simplified CategoryDisplay Component
const CategoryDisplay = ({
  categories,
  selectedCategory,
  onCategorySelect,
}) => {
  const filteredCategories = selectedCategory
    ? categories.filter(cat => cat !== selectedCategory)
    : categories;

  return (
    <Box
      paddingTop={['0px', '0px', '0px', '90px', '90px', '90px']}
      paddingBottom={['0px', '0px', '0px', '114px', '114px', '114px']}
      backgroundColor="#0D0E4D"
    >
      <Box
        display={['flex']}
        alignItems={'center'}
        gap={['20px', '20px', '20px']}
        mx="auto"
        maxW="96%"
      >
        <Text
          cursor="pointer"
          color={'#fff'}
          fontSize={['32px', '32px', '32px', '128px', '128px', '128px']}
          fontWeight={600}
          lineHeight={['normal', 'normal', 'normal', '133px', '133px', '133px']}
          whiteSpace={'nowrap'}
          fontFamily={'var(--garnett-semibold);'}
          onClick={() => onCategorySelect(selectedCategory)}
        >
          {selectedCategory || 'All Stories'}
        </Text>
        <Box display="flex" whiteSpace={'nowrap'} gap="10px">
          <List
            display="flex"
            whiteSpace={'nowrap'}
            gap={['20px', '20px', '20px', '10px', '10px', '10px']}
            alignItems={'center'}
          >
            {filteredCategories.map(category => (
              <React.Fragment key={category}>
                <Box
                  onClick={() => onCategorySelect(category)}
                  cursor="pointer"
                >
                  <Box
                    as="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={['14px', '27px']}
                    height={['14px', '25px']}
                    viewBox="0 0 27 25"
                    fill="none"
                  >
                    <path
                      d="M13.5001 16.302L8.06013 24.878L4.22013 22.062L10.6201 14.254L0.828125 11.694L2.30013 7.15L11.7721 10.862L11.1321 0.75H15.9321L15.2921 10.862L24.7001 7.15L26.1721 11.694L16.3801 14.254L22.8441 22.062L19.0041 24.878L13.5001 16.302Z"
                      fill="#0057FF"
                    />
                  </Box>
                </Box>
                <ListItem
                  onClick={() => onCategorySelect(category)}
                  cursor="pointer"
                  color="#0057FF"
                  fontSize={['32px', '32px', '32px', '128px', '128px', '128px']}
                  fontWeight="300"
                  lineHeight="133px"
                >
                  {category}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

// Filter Component
const Filter = ({ onSearch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate hook
  const categories = [
    'Insights',
    'Guides',
    'Thoughts',
    'Glossary',
    'Updates',
    'Listen',
  ];
  const handleCategorySelect = category => {
    setSelectedCategory(category);
    onClose();
    const categorySlug = category.toLowerCase().replace(/\s+/g, '-');
    navigate(`/resources/${categorySlug}`);
  };

  const handleSearchSubmit = e => {
    if (e.key === 'Enter') {
      onSearch(searchTerm);
    }
  };
  return (
    <>
      <Box backgroundColor="#0D0E4D">
        <Box maxW="96%" mx="auto" overflow="hidden">
          <Button
            onClick={onOpen}
            bg="transparent"
            lineHeight="133px"
            fontSize={['14px', '24px']}
            fontWeight="400"
            color="#fff"
            _hover={{ backgroundColor: 'transparent' }}
          >
            Pick category
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M19.5 15L12 7.5L4.5 15"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
          <CategoryDisplay
            categories={categories}
            selectedCategory={selectedCategory}
            onCategorySelect={handleCategorySelect}
          />
        </Box>
      </Box>
      <CategoryModal
        isOpen={isOpen}
        onClose={onClose}
        categories={categories}
        onCategorySelect={handleCategorySelect}
      />
      {/* Search */}
      <Box backgroundColor="#CCF1DF" pb={['46px', '80px']}>
        <Box maxW="90%" mx="auto">
          <Box
            bg="#FFF"
            boxShadow="0px 4px 13px rgba(0, 0, 0, 0.08)"
            p={['20px 25px']}
            display="flex"
            alignItems="center"
          >
            <InputGroup bg="#F5F5F5">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="#333333" w="24px" h="24px" />
              </InputLeftElement>
              <Input
                border="none"
                borderBottomWidth="1px"
                borderBottomColor="#B5B5B5"
                placeholder="Search stories"
                color="#333"
                fontSize={['18px', '20px']}
                fontWeight="400"
                _placeholder={{ color: '#333' }}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchSubmit} // Listen for Enter key press
              />
            </InputGroup>
            <Box ml="4" w={['152px', '152px', '152px', '122px', '122px']}>
              <Select
                color="#333"
                fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                fontWeight="400"
                padding="0!important"
              >
                <option value="" disabled selected>
                  Topics
                </option>
                <option value="topics1">Topics 1</option>
                <option value="topics2">Topics 2</option>
                <option value="topics3">Topics 3</option>
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Filter;
