import { Box } from '@chakra-ui/react';
import React from 'react';
import GrowthJourneyEXP from '../Reusable/GrowthJourneyEXP';

const GrowthJourneyEXPReuse = () => {
  return (
    <Box>
      <GrowthJourneyEXP />
    </Box>
  );
};

export default GrowthJourneyEXPReuse;
