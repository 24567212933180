import { Box, Container, Image, Text } from '@chakra-ui/react';
import React from 'react';

const Introduction = () => {
  const introductionText = [
    {
      title: 'INTRODUCTION',
      paragraph:
        "Angles, Kuwait's premier lifestyle store, aimed to revolutionize its digital presence. Partnering with us, Angles sought to enhance its ecommerce experience by launching a new website and mobile app, as part of a comprehensive digital transformation.",
      paragraph1:
        "Our mission was to create a ecommerce enablement strategy that enables seamless shopping experience for modern consumers, driving significant growth and expanding Angles' global reach.",
    },
  ];

  return (
    <>
      <Box paddingTop={['93px', '93px', '93px', '40px', '40px', '40px']}>
        <Container
          width={['90%', '90%', '90%', '80%', '72%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '72%', '72%']}
          padding={['0']}
        >
          {/* Introduction Image */}
          
          {/* Introduction Title */}
          <Box>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              padding={[
                '0 0 22px',
                '0 0 22px',
                '0 0 22px',
                '40px 0 50px',
                '40px 0 50px',
                '40px 0 50px',
              ]}
            >
              {introductionText[0].title}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              marginBottom={['24px', '24px', '24px', '50px']}
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              m="0 0 0px 0"
              fontFamily="var(--garnett-regular)"
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {introductionText[0].paragraph1}
            </Text>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Introduction;
