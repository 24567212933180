import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import HeroBanner from './HeroBanner';
import OurTenets from './OurTenets';
import OurValues from './OurValues';
import OurJourney from './OurJourney';
import ClientsLogos from '../Home/ClientsLogo';
import SolutionForEveryone from './SolutionForEveryone';
import EndToEndProductTeam from './EndToEndProductTeam';
import Usps from './Usps';
import TeamWorkGallary from './TeamWorkGallary';
import CeoSection from './CeoSection';
import LetsWorkToGether from './LetsWorksToGether';
import ClientSays from './ClinetsSays';

const About = () => {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          'https://cms.wearetenet.com/api/abouts?populate=*'
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, []);

  return (
    <Box>
      {seoData && (
        <Helmet>
          {seoData.metaTitle && <title>{seoData.metaTitle}</title>}

          {seoData.metaDescription && (
            <meta name="description" content={seoData.metaDescription} />
          )}

          {seoData.keywords && (
            <meta name="keywords" content={seoData.keywords} />
          )}

          <meta
            name="robots"
            content={seoData.indexed ? 'index, follow' : 'noindex, nofollow'}
          />

          {seoData.canonicalURL && (
            <link rel="canonical" href={seoData.canonicalURL} />
          )}

          {seoData.metaViewport && (
            <meta name="viewport" content={seoData.metaViewport} />
          )}
        </Helmet>
      )}
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <OurTenets />
      </Box>
      <Box>
        <OurValues />
      </Box>
      <Box>
        <OurJourney />
      </Box>
      <Box>
        <CeoSection />
      </Box>
      <Box>
        <ClientsLogos />
      </Box>
      <Box>
        <ClientSays />
      </Box>
      <Box>
        <SolutionForEveryone />
      </Box>
      <Box>
        <EndToEndProductTeam />
      </Box>
      <Box>
        <Usps />
      </Box>
      <Box>
        <TeamWorkGallary />
      </Box>
      <Box>
        <LetsWorkToGether />
      </Box>
    </Box>
  );
};

export default About;
