import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
// For Resurch
import Desktop from '../../assets/BgPatterns/SingleServicepage/purple/Desktop.gif';
import Mobile from '../../assets/BgPatterns/SingleServicepage/purple/Mobile.gif';
// backgroundColor={'#2e0448'}
// For Design
// import Desktop from '../../assets/Background/aboutDesktop.gif';
// import Mobile from '../../assets/Background/aboutMob.gif';
// background={'0d0e4e'}
// forBuild

// import Desktop from '../../assets/BgPatterns/SingleServicepage/maroom/Desktop.gif';
// import Mobile from '../../assets/BgPatterns/SingleServicepage/maroom/Mobile.gif';
// backgroundColor={'#420119'}

// for Growth
// import Desktop from '../../assets/Partners/Banner/banner.gif';
// import Mobile from '../../assets/Partners/Banner/Mobile.gif';
// backgroundColor={'#000'}

const Banner = () => {
  const linkDesign = {
    backgroundColor: '#9B4DFF',
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#fff',
    fontSize: ['20px', '20px', '20px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    height: '41px',
    width: '122px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 12px 0',
  };
  return (
    <>
      {/* Main Wrapper */}
      <Flex
        backgroundColor={'#2e0448'}
        top={['-1px']}
        minH={['396px', '396px', '396px', '600px', '600px', '600px']}
        alignItems={['end', 'end', 'end', 'center', 'center', 'center']}
        padding={[
          '64px 16px',
          '64px 16px',
          '64px 16px',
          '64px 68px',
          '64px 68px',
          '64px 68px',
        ]}
        overflow={'hidden'}
        position="relative"
      >
        {/* Video Background */}
        <Box
          position={['absolute']}
          left={'0'}
          right={'0'}
          top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
          bottom={'0'}
          zIndex={['0', '0', '0', '0', '0']}
        >
          {/* For Desktop */}
          <Image
            display={['none', 'none', 'block', 'block', 'block', 'block']}
            src={Desktop}
            width={'100%'}
            height={'100%'}
            objectFit={'cover'}
            // objectPosition={'right'}
          />
          {/* For Mobile */}
          <Image
            display={['block', 'block', 'none', 'none']}
            src={Mobile}
            width={'100%'}
            objectPosition={'right bottom'}
            height={'240px'}
            objectFit={'contain'}
          />
        </Box>

        {/* TagLine Text */}
        <Box maxW={['94%', '94%', '94%', '93%', '93%', '93%']}>
          <Box
            maxW={'992px'}
            position={'relative'}
            zIndex={'3'}
            marginBottom={['54px', '54px', '54px', '0px', '0px', '0px']}
          >
            <Heading
              as={'h1'}
              color={'#fff'}
              fontSize={['32px', '42px', '52px', '62px', '72px', '72px']}
              lineHeight={'110%'}
              fontWeight={'500'}
              fontFamily={' var(--garnett-semibold);'}
            >
              Is your UX design as{' '}
              <span style={{ color: '#B882FF', fontWeight: '600' }}>
                effective
              </span>{' '}
              as it can be?
            </Heading>
            <Text
              as={'p'}
              color={'#fff'}
              fontSize={['16px', '16px', '20px', '22px', '24px', '24px']}
              fontWeight={'500'}
              fontFamily={'var(--foundersGrotesk-regular);'}
              lineHeight={'normal'}
              padding={['36px 0 30px']}
            >
              Ensure your website or app performs at its best with our expert UX
              design services. Through continuous improvement and strategic
              design enhancements, we deliver experiences that engage users and
              drive business growth.
            </Text>
            <Link to="#" style={linkDesign}>
              Get Started
            </Link>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Banner;
