import React from 'react';
import { Box, Container, Flex, Text } from '@chakra-ui/react';
const WhyChooseUs = () => {
  return (
    <Box
      padding={[
        '80px 0',
        '80px 0',
        '80px 0',
        '120px 80px',
        '150px 0 100px',
        '150px 0 100px',
      ]}
      width={['90%', '90%', '90%', '90%', '90%', '83%']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
      margin={['0 auto']}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '100%', '100%', '100%']}
        margin={['0 auto']}
      >
        <Box
          marginBottom={['40px', '40px', '48px', '48px', '40px', '40px']}
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          justifyContent={[
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
          alignItems={[
            'flex-start',
            'flex-start',
            'flex-start',
            'center',
            'center',
            'center',
          ]}
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
        >
          <Text
            as="h3"
            m="0px"
            fontSize={['32px', '32px', '40px', '48px', '48px', '48px']}
            fontFamily="var(--garnett-regular)"
            color={'#000000'}
          >
            Growth Mantras
          </Text>
          <Text
            paddingTop={['22px', '22px', '22px', 0, 0, 0]}
            as="p"
            m="0px"
            fontSize={['16px', '16px', '20px', '20px', '20px', '20px']}
            maxW={['424px', '424px', '424px', '464px', '464px', '464px']}
            color={'#333333'}
            fontFamily="var(--foundersGrotesk-regular)"
            fontWeight="400"
            lineHeight={['normal']}
          >
            Delivering good services isn’t enough. You deserve an impeccable
            servicing experience and we know that.
          </Text>
        </Box>
        <Box
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          flexWrap={['wrap', 'wrap', 'wrap', 'wrap', 'wrap', 'wrap']}
          justifyContent={[
            'center',
            'center',
            'center',
            'center',
            'center',
            'center',
          ]}
          gap={['20px', '20px', '15px', '15px', '15px', '15px']}
        >
          <Box
            display={['flex']}
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            width={['100%', '100%', '100%', '100%', '100%', '100%']}
            maxWidth={['100%', '100%', '100%', '100%', '100%', '100%']}
            gap={['20px', '20px', '15px', '15px', '15px', '15px']}
          >
            <Box
              background={'#EBDBFF'}
              padding={['30px', '30px', '30px', '30px', '55px', '55px']}
              flex="1"
              display={'flex'}
            >
              <Flex
                alignItems={[
                  'flex-start',
                  'flex-start',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
                gap={['32px', '32px', '32px', '43px', '43px', '43px']}
                flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
              >
                <Text
                  as="h3"
                  fontSize={['36px', '38px', '45px', '50px', '68px', '68px']}
                  fontFamily="var(--garnett-regular);"
                  color={'#000000'}
                >
                  01
                </Text>
                <Text
                  as="p"
                  fontSize={['16px', '16px', '18px', '19px', '20px', '20px']}
                  fontFamily="var(--garnett-regular);"
                  color="#333333"
                  lineHeight={['normal']}
                >
                  No cookie cutter stuff. All our strategies and solutions are
                  tailored to suit your specific needs and preferences.
                </Text>
              </Flex>
            </Box>
            <Box
              background={'#FFE1D8'}
              padding={['30px', '30px', '30px', '30px', '55px', '55px']}
              flex="1"
            >
              <Flex
                alignItems={[
                  'flex-start',
                  'flex-start',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
                gap={['32px', '32px', '32px', '43px', '43px', '43px']}
                flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
              >
                <Text
                  as="h3"
                  fontSize={['36px', '38px', '45px', '50px', '68px', '68px']}
                  fontFamily="var(--garnett-regular);"
                  color={'#000000'}
                >
                  02
                </Text>
                <Text
                  as="p"
                  fontSize={['16px', '16px', '18px', '19px', '20px', '20px']}
                  fontFamily="var(--garnett-regular);"
                  color="#333333"
                  lineHeight={['normal']}
                >
                  For us, success isn’t just about business. Its also about
                  crafting experiences that foster genuine human connections and
                  experiences.
                </Text>
              </Flex>
            </Box>
          </Box>

          <Box
            display={['flex']}
            flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
            width={['100%', '100%', '100%', '100%', '100%', '100%']}
            maxWidth={['100%', '100%', '100%', '100%', '100%', '100%']}
            gap={['20px', '20px', '15px', '15px', '15px', '15px']}
          >
            <Box
              background={[
                '#EBDBFF',
                '#EBDBFF',
                '#FFE1D8',
                '#FFE1D8',
                '#FFE1D8',
                '#FFE1D8',
              ]}
              padding={['30px', '30px', '30px', '30px', '55px', '55px']}
              flex="1"
              display={'flex'}
            >
              <Flex
                alignItems={[
                  'flex-start',
                  'flex-start',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
                gap={['32px', '32px', '32px', '43px', '43px', '43px']}
                flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
              >
                <Text
                  as="h3"
                  fontSize={['36px', '38px', '45px', '50px', '68px', '68px']}
                  fontFamily="var(--garnett-regular);"
                  color={'#000000'}
                >
                  03
                </Text>
                <Text
                  as="p"
                  fontSize={['16px', '16px', '18px', '19px', '20px', '20px']}
                  fontFamily="var(--garnett-regular);"
                  color="#333333"
                  lineHeight={['normal']}
                >
                  Beyond delivering mind-blowing solutions, our simplified
                  processes ensure a well-guided and highly satisfying service
                  delivery experience.
                </Text>
              </Flex>
            </Box>
            <Box
              background={[
                '#FFE1D8',
                '#FFE1D8',
                '#EBDBFF',
                '#EBDBFF',
                '#EBDBFF',
                '#EBDBFF',
              ]}
              padding={['30px', '30px', '30px', '30px', '55px', '55px']}
              flex="1"
              display={'flex'}
            >
              <Flex
                alignItems={[
                  'flex-start',
                  'flex-start',
                  'center',
                  'center',
                  'center',
                  'center',
                ]}
                gap={['32px', '32px', '32px', '43px', '43px', '43px']}
                flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
              >
                <Text
                  as="h3"
                  fontSize={['36px', '38px', '45px', '50px', '68px', '68px']}
                  fontFamily="var(--garnett-regular);"
                  color={'#000000'}
                >
                  04
                </Text>
                <Text
                  as="p"
                  fontSize={['16px', '16px', '18px', '19px', '20px', '20px']}
                  fontFamily="var(--garnett-regular);"
                  color="#333333"
                  lineHeight={['normal']}
                >
                  Stop getting ignored by “Experts”. We work closely with you,
                  allowing your inputs to shape processes and solutions that
                  drive measurable success.
                </Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default WhyChooseUs;
