import { Box, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Custome.css';

import logo1 from '../../assets/Reusable/GrowthJourney/1.svg';
import logo2 from '../../assets/Reusable/GrowthJourney/2.svg';
import logo3 from '../../assets/Reusable/GrowthJourney/3.svg';
import logo4 from '../../assets/Reusable/GrowthJourney/4.svg';
import logo5 from '../../assets/Reusable/GrowthJourney/5.svg';
import logo6 from '../../assets/Reusable/GrowthJourney/6.svg';
import logo7 from '../../assets/Reusable/GrowthJourney/7.svg';

const GrowthJourneyEXP = () => {
  const data = [
    {
      image: logo1,
      title: 'Top Web Developer',
      year: '2023',
    },
    {
      image: logo2,
      title: 'Top Branding Agency',
      year: '2023',
    },
    {
      image: logo3,
      title: 'Top Web Design Company',
      year: '2023',
    },
    {
      image: logo4,
      title: 'Best Website Design Agency',
      year: '2023',
    },
    {
      image: logo5,
      title: 'Top Mobile App Development Company',
      year: '2023',
    },
    {
      image: logo6,
      title: 'Best Place to Work',
      year: '2023',
    },
    {
      image: logo7,
      title: 'Top iOS App Developmet Company',
      year: '2023',
    },
  ];

  const YearAndTitleDesign = {
    color: '#FFF',
    fontFamily: 'var(--foundersGrotesk-regular);',
    fontSize: ['14px', '14px', '14px', '16px', '16px'],
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textTrim: 'both',
    textEdge: 'cap',
    textAlign: 'center',
  };

  return (
    <Box
      backgroundColor={'#000'}
      padding={[
        '46px 0 92px',
        '46px 0 92px',
        '46px 0 92px',
        '76px 0 92px',
        '76px 0 92px',
        '76px 0 92px',
      ]}
      overflow={'hidden'}
    >
      {/* Container */}
      <Box
        maxW={['100%', '100%', '100%', '91.5%', '91.5%', '91.5%']}
        ml={'auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        {/* Section Heading */}
        <Box maxW={['691px']} paddingBottom={['70px', '70px', '70px', '60px']}>
          <Heading
            as={'h2'}
            fontSize={['20px', '20px', '20px', '24px', '24px', '24px']}
            color={'#fff'}
            fontWeight={'400'}
            lineHeight={'normal'}
            fontFamily={'var(--garnett-regular);'}
          >
            Our growth journey as a experience design company has bagged a few
            reputed accolades as well.
          </Heading>
        </Box>
      </Box>

      <Box
        maxW={['100%', '100%', '100%', '91.5%', '91.5%', '91.5%']}
        ml={'auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0']}
      >
        {/* Slider */}
        <Box>
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            // autoplay={false}
            loop={true} // Enable infinite scrolling
            slidesPerView={'auto'}
            speed={1000} // Adjust the speed of the transition
            spaceBetween={20} // Adjust space between slides
            className="growthJourney"
            style={{ overflow: 'hidden' }}
          >
            {/* SliderItems */}
            {data.map(item => (
              <SwiperSlide key={item.id}>
                <Box
                  style={{
                    background: '#171717',
                    border: '3px solid #343434',

                    padding: ['20px'],
                    height: ['228px'],
                    width: ['245px'],
                    display: ['flex'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Image */}
                  <Box
                    display={['flex']}
                    alignItems={['center']}
                    justifyContent={'center'}
                    height={['128px']}
                  >
                    <Image
                      src={item.image}
                      objectFit={'contain'}
                      backdropBlur={'#000'}
                      alt="Company Logo"
                    />
                  </Box>
                  {/* Text and year */}
                  <Box textAlign={'center'}>
                    <Text as={'p'} sx={YearAndTitleDesign}>
                      {item.year}
                    </Text>
                    <Text as="p" sx={YearAndTitleDesign}>
                      {item.title}
                    </Text>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default GrowthJourneyEXP;
