import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Desktop from '../../assets/BgPatterns/Partners/banner.gif';
import Mobile from '../../assets/BgPatterns/Partners/Mobile.gif';
// backgroundColor={'#000'}
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Banner = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/research-services?filters[slug][$eq]=${slug}&filters[service_type][$eq]=growth&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const linkDesign = {
    backgroundColor: '#0057FF',
    fontFamily: 'var(--foundersGrotesk-regular)',
    color: '#fff',
    fontSize: ['20px', '20px', '20px', '20px', '20px', '20px'],
    fontWeight: '400',
    lineHeight: 'normal',
    height: '41px',
    width: '122px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 12px 0',
  };
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const heroSectionHeight = heroSectionRef.current.clientHeight;
  }, []);
  return (
    <>
      {/* Main Wrapper */}
      <Flex
        backgroundColor={'#000'}
        ref={heroSectionRef}
        id="hero-section"
        top={['-1px']}
        minH={['396px', '396px', '396px', '600px', '600px', '600px']}
        alignItems={['end', 'end', 'end', 'center', 'center', 'center']}
        padding={[
          '64px 16px',
          '64px 16px',
          '64px 16px',
          '64px 68px',
          '64px 68px',
          '64px 68px',
        ]}
        overflow={'hidden'}
        position="relative"
      >
        {/* Video Background */}
        <Box
          position={['absolute']}
          left={'0'}
          right={'0'}
          top={['inhrit', 'inhrit', 'inhrit', 'inhrit', 'inhrit']}
          bottom={'0'}
          zIndex={['0', '0', '0', '0', '0']}
        >
          {/* For Desktop */}
          <Image
            display={['none', 'none', 'block', 'block', 'block', 'block']}
            src={Desktop}
            width={'100%'}
            height={'100%'}
            objectFit={'cover'}
          // objectPosition={'right'}
          />
          {/* For Mobile */}
          <Image
            display={['block', 'block', 'none', 'none']}
            src={Mobile}
            width={'100%'}
            objectPosition={'right bottom'}
            height={'240px'}
            objectFit={'contain'}
          />
        </Box>

        {/* TagLine Text */}
        <Box maxW={['94%', '94%', '94%', '93%', '93%', '93%']}>
          <Box
            maxW={'992px'}
            position={'relative'}
            zIndex={'3'}
            marginBottom={['54px', '54px', '54px', '0px', '0px', '0px']}
          >
            <Heading
              as="h1"
              fontSize={['32px', '42px', '52px', '62px', '72px', '72px']}
              lineHeight="110%"
              color={'#fff'}
              fontWeight="500"
              fontFamily="var(--garnett-semibold)"
            >
              {firstItem?.hero_heading &&
                firstItem.hero_heading.map((headingItem, index) =>
                  headingItem.children.map((child, childIndex) =>
                    child.italic ? (
                      <Text
                        as="span"
                        fontStyle="italic"
                        color={firstItem.hero_heading_color || '#B882FF'}
                        key={childIndex}
                      >
                        {child.text}
                      </Text>
                    ) : (
                      <Text as="span" key={childIndex}>
                        {child.text}
                      </Text>
                    )
                  )
                )}
            </Heading>

            <Text
              as={'p'}
              color={'#fff'}
              fontSize={['16px', '16px', '20px', '22px', '24px', '24px']}
              fontWeight={'500'}
              fontFamily={'var(--foundersGrotesk-regular);'}
              lineHeight={'normal'}
              padding={['36px 0 30px']}
            >
              {firstItem?.hero_paragraph}
            </Text>
            <Link to="#" style={linkDesign}>
              Get Started
            </Link>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Banner;
