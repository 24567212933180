import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Image,
  Link,
  Flex,
  useBreakpointValue,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from '../../assets/Logo/header_logo.svg';
// import contactIcon from '../../assets/Logo/contactIcon.svg';
import stickyLogo from '../../assets/Logo/sticky_header_logo.svg';
import drawerLogo from '../../assets/Logo/drawerLogo.svg';
import workLogo from '../../assets/Logo/workLogo.svg';
import contactLogo from '../../assets/Logo/contactLogo.svg';
import './Custom.css';
import ServiceMobileMenu from './MobileMenu/ServiceMobileMenu';
import SolutionMobileMenu from './MobileMenu/SolutionMobileMenu';
// import Test from './MobileMenu/Test';
// import ServiceMega from './DesktopMegaMenu/ServiceMega';
// import SolutionMega from './DesktopMegaMenu/SolutionMega';
import ServiceMegaNew from './DesktopMegaMenu/ServiceMegaNew';
import SolutionMegaNew from './DesktopMegaMenu/SolutionMegaNew';
import LanguageDrawer from './LanguageDrawer/LanguageDrawer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const DesktopNavbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [heroSectionHeight, setHeroSectionHeight] = useState(0);
  const [backgroundColorFromAPI, setBackgroundColorFromAPI] = useState(null);
  const [buttonBackgroundColorFromAPI, setButtonBackgroundColorFromAPI] =
    useState(null);
  const [workPageBackgroundColor, setWorkPageBackgroundColor] = useState(null);
  const [workPageButtonBackgroundColor, setWorkPageButtonBackgroundColor] =
    useState(null);
  const location = useLocation();
  const currentRoute = location.pathname;
  const extractSlug = path => {
    const industryMatch = path.match(/^\/industry\/([^\/]+)$/);
    const workMatch = path.match(/^\/work\/([^\/]+)$/);
    if (industryMatch) {
      return { type: 'industry', slug: industryMatch[1] };
    } else if (workMatch) {
      return { type: 'work', slug: workMatch[1] };
    }
    return null;
  };

  const { type, slug } = extractSlug(currentRoute) || {};

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const totalScroll = documentHeight - windowHeight;
      const scrollPercentage = (scrollTop / totalScroll) * 100;
      setScrollProgress(scrollPercentage);
      setIsSticky(scrollTop > 0);
    };

    const updateHeroSectionHeight = debounce(() => {
      const heroSection = document.getElementById('hero-section');
      if (heroSection) {
        const height = heroSection.clientHeight;
        setHeroSectionHeight(height);
        console.log('Hero Section Height:', height); // Logs the final height after debounce
      }
    }, 700); // Adjust the debounce delay as needed (200ms here)

    // Initial height update
    updateHeroSectionHeight();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateHeroSectionHeight);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateHeroSectionHeight);
    };
  }, [heroSectionHeight]);

  useEffect(() => {
    if (slug) {
      const fetchData = async () => {
        try {
          if (type === 'industry') {
            const response = await axios.get(
              `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
            );
            if (response.data.data.length > 0) {
              const industryData = response.data.data[0];
              setBackgroundColorFromAPI(
                industryData.attributes.background_color
              );
              setButtonBackgroundColorFromAPI(
                industryData.attributes.header_button_background
              );
              setWorkPageBackgroundColor(null); // Clear work page data if fetching industry data
              setWorkPageButtonBackgroundColor(null);
            }
          } else if (type === 'work') {
            const response = await axios.get(
              `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
            );
            if (response.data.data.length > 0) {
              const workPageData = response.data.data[0];
              setWorkPageBackgroundColor(
                workPageData.attributes.hex_header_background_color
              );
              setWorkPageButtonBackgroundColor(
                workPageData.attributes.button_background_color
              );
              setBackgroundColorFromAPI(null); // Clear industry data if fetching work page data
              setButtonBackgroundColorFromAPI(null);
            }
          }
        } catch (err) {
          setBackgroundColorFromAPI(null);
          setButtonBackgroundColorFromAPI(null);
          setWorkPageBackgroundColor(null);
          setWorkPageButtonBackgroundColor(null);
        }
      };

      fetchData();
    }
  }, [slug, type]);

  const scrollThreshold =
    (heroSectionHeight / document.body.clientHeight) * 100;

  // Hover
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  // Function to handle opening the dropdown
  const handleDropdownToggle = dropdownName => {
    setOpenDropdown(dropdownName);
  };

  // Function to close dropdown when clicking outside
  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    const handleMouseLeaveWindow = event => {
      // Check if mouse leaves the document/window area
      if (
        !event.relatedTarget &&
        (event.clientY <= 0 ||
          event.clientX <= 0 ||
          event.clientX >= window.innerWidth ||
          event.clientY >= window.innerHeight)
      ) {
        setOpenDropdown(null); // Hide the dropdown
      }
    };

    if (openDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('mouseout', handleMouseLeaveWindow);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mouseout', handleMouseLeaveWindow);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mouseout', handleMouseLeaveWindow);
    };
  }, [openDropdown]);

  let headerBackgroundColor;
  if (type === 'industry' && backgroundColorFromAPI) {
    headerBackgroundColor =
      scrollProgress < scrollThreshold ? backgroundColorFromAPI : '#ffffff';
  } else if (type === 'work' && workPageBackgroundColor) {
    headerBackgroundColor =
      scrollProgress < scrollThreshold ? workPageBackgroundColor : '#ffffff';
  } else {
    if (currentRoute === '/main') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0057FF' : '#ffffff';
    } else if (
      ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
    ) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (currentRoute === '/our-strategy') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (currentRoute === '/tools-and-technologies') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (currentRoute === '/partner-with-us') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/white-label-agency') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#30054A' : '#ffffff';
    } else if (currentRoute === '/work') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/career') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (/^\/career\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/thankyou') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/work/pazazz') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#2C021D' : '#ffffff';
    } else if (currentRoute === '/work/think-vastu') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#2C021D' : '#ffffff';
    } else if (currentRoute === '/work/strongr') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/work/angles') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/singleinsight') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#00342B' : '#ffffff';
    } else if (/^\/resources\/[^\/]+\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#00342B' : '#ffffff';
    } else if (/^\/resources\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (/^\/outcome\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (/^\/research\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#30054A' : '#ffffff';
    } else if (/^\/design\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (/^\/build\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (/^\/growth\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#111111' : '#ffffff';
    } else if (currentRoute === '/work/iros') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D212C' : '#ffffff';
    } else if (currentRoute === '/resources') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else {
      switch (currentRoute) {
        case '/case-study':
          headerBackgroundColor = isSticky ? '#ffffff' : '#3E001A';
          break;
        case '/contact-us':
        case '/404':
        case '/Upcoming':
          headerBackgroundColor = isSticky ? '#ffffff' : '#000000';
          break;
        default:
          headerBackgroundColor = isSticky ? '#ffffff' : '#000000';
          break;
      }
    }
  }
  let buttonBackgroundColor;
  if (type === 'industry' && buttonBackgroundColorFromAPI) {
    buttonBackgroundColor =
      scrollProgress < scrollThreshold
        ? buttonBackgroundColorFromAPI
        : '#9B4DFF';
  } else if (type === 'work' && workPageButtonBackgroundColor) {
    buttonBackgroundColor =
      scrollProgress < scrollThreshold
        ? workPageButtonBackgroundColor
        : '#9B4DFF';
  } else {
    if (currentRoute === '/main') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0D0E4D'
          : isSticky
          ? '#9B4DFF'
          : '#E0005E';
    } else if (
      ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
    ) {
      buttonBackgroundColor = '#0057FF';
    } else if (currentRoute === '/our-strategy') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#E0005E'
          : isSticky
          ? '#9B4DFF'
          : '#E0005E';
    } else if (currentRoute === '/tools-and-technologies') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute === '/partner-with-us') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute === '/white-label-agency') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#9B4DFF'
          : isSticky
          ? '#9B4DFF'
          : '#9B4DFF';
    } else if (currentRoute === '/work') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute === '/thankyou') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#9B4DFF'
          : isSticky
          ? '#9B4DFF'
          : '#9B4DFF';
    } else if (currentRoute === '/career') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#9B4DFF'
          : isSticky
          ? '#9B4DFF'
          : '#9B4DFF';
    } else if (currentRoute.startsWith('/career/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#9B4DFF'
          : isSticky
          ? '#9B4DFF'
          : '#9B4DFF';
    } else if (currentRoute === '/work/pazazz') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute === '/work/think-vastu') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#E0005E'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute === '/work/strongr') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00BA61'
          : isSticky
          ? '#9B4DFF'
          : '#00BA61';
    } else if (currentRoute === '/work/angles') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#B882FF'
          : isSticky
          ? '#9B4DFF'
          : '#00BA61';
    } else if (currentRoute === '/singleinsight') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00BA61'
          : isSticky
          ? '#9B4DFF'
          : '#00BA61';
    } else if (currentRoute.startsWith('/resources/insights')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00342B'
          : isSticky
          ? '#9B4DFF'
          : '#00342B';
    } else if (currentRoute.startsWith('/outcome/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#9B4DFF'
          : '#0057FF';
    } else if (currentRoute.startsWith('/research/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#9B4DFF'
          : isSticky
          ? '#9B4DFF'
          : '#9B4DFF';
    } else if (currentRoute.startsWith('/design/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#B882FF'
          : isSticky
          ? '#9B4DFF'
          : '#B882FF';
    } else if (currentRoute.startsWith('/build/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#E0005E'
          : isSticky
          ? '#9B4DFF'
          : '#E0005E';
    } else if (currentRoute.startsWith('/growth/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#B882FF'
          : isSticky
          ? '#9B4DFF'
          : '#B882FF';
    } else if (/^\/resources\/[^\/]+\/[^\/]+/.test(currentRoute)) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00BA61'
          : isSticky
          ? '#9B4DFF'
          : '#00BA61';
    } else if (/^\/resources\/[^\/]+/.test(currentRoute)) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#E0005E'
          : isSticky
          ? '#9B4DFF'
          : '#E0005E';
    } else if (currentRoute === '/work/iros') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/insights') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/guides') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/glossary') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/thoughts') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/updates') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/listen') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else {
      switch (currentRoute) {
        case '/case-study':
          buttonBackgroundColor = isSticky ? '#9B4DFF' : '#E0005E';
          break;
        case '/contact-us':
        case '/404':
        case '/Upcoming':
          buttonBackgroundColor = '#9B4DFF';
          break;
        default:
          buttonBackgroundColor = '#9B4DFF';
      }
    }
  }
  let headerLogo;
  if (currentRoute === '/main') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (
    ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
  ) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/our-strategy') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/tools-and-technologies') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work/pazazz') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work') {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute === '/career') {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute.startsWith('/career/')) {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute === '/thankyou') {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute === '/partner-with-us') {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute === '/white-label-agency') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work/think-vastu') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work/strongr') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work/angles') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/singleinsight') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/resources/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/outcome/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/research/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/design/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/build/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/growth/')) {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (currentRoute.startsWith('/industry/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/work/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work/iros') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/resources') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else {
    switch (currentRoute) {
      case '/case-study':
      case '/404':
      case '/upcoming':
        headerLogo = isSticky ? stickyLogo : logo;
        break;
      case '/contact-us':
        headerLogo = isSticky ? stickyLogo : contactLogo;
        break;
      default:
        headerLogo = stickyLogo;
    }
  }

  let textColor;
  if (currentRoute === '/main') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#000000';
  } else if (
    ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
  ) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/our-strategy') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/tools-and-technologies') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/partner-with-us') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/white-label-agency') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/career') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/career/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/work/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/thankyou') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work/pazazz') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work/think-vastu') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work/strongr') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work/angles') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/singleinsight') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/resources/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/outcome/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/research/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/design/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/build/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/growth/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute.startsWith('/industry/')) {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/work/iros') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else if (currentRoute === '/resources') {
    textColor = scrollProgress < scrollThreshold ? '#FFFFFF' : '#333333';
  } else {
    switch (currentRoute) {
      case '/case-study':
      case '/our-strategy':
        textColor = isSticky ? '#333333' : '#FFFFFF';
        break;
      case '/contact-us':
      case '/404':
        textColor = isSticky ? '#333333' : '#FFFFFF';
        break;
      default:
        textColor = isSticky ? '#333333' : '#FFFFFF';
    }
  }

  const getHoverColorForRoute = route => {
    if (scrollProgress < scrollThreshold) {
      switch (route) {
        case '/main':
          return '#FFCCB6';
        case '/about-us':
          return '#0057FF';
        case '/our-services':
          return '#0057FF';
        case '/solutions':
          return '#0057FF';
        case '/our-strategy':
          return '#E0005E';

        case '/resources':
          return '#0057FF';
        case '/tools-and-technologies':
          return '#0057FF';
        case '/white-label-agency':
          return '#B882FF';
        case '/contact-us':
          return '#0057FF';
        case '/work':
          return '#0057FF';
        default:
          return '#F0F0F0';
      }
    } else {
    }
  };

  const hoverColor = getHoverColorForRoute(currentRoute);

  const isScrollBelowThreshold = scrollProgress < scrollThreshold;

  const getLinkColor = route => {
    if (isScrollBelowThreshold) {
      switch (route) {
        case '/about-us':
          return '#0057FF';
        case '/work':
          return '#0057FF';
        case '/our-services':
          return '#0057FF';
        case '/solutions':
          return '#0057FF';
        case '/our-strategy':
          return '#E0005E';
        case '/resources':
          return '#0057FF';
        default:
          return textColor;
      }
    } else {
      return textColor;
    }
  };

  const aboutUsLinkColor =
    currentRoute === '/about-us' ? getLinkColor('/about-us') : textColor;
  const workLinkColor =
    currentRoute === '/work' ? getLinkColor('/work') : textColor;
  const ourServicesLinkColor =
    currentRoute === '/our-services'
      ? getLinkColor('/our-services')
      : textColor;
  const ourSolutionsLinkColor =
    currentRoute === '/solutions' ? getLinkColor('/solutions') : textColor;
  const ourStrategyLinkColor =
    currentRoute === '/our-strategy'
      ? getLinkColor('/our-strategy')
      : textColor;
  const resourcesLinkColor =
    currentRoute === '/resources' ? getLinkColor('/resources') : textColor;

  // Hover

  // Drawer state
  const [isLanguageDrawerOpen, setIsLanguageDrawerOpen] = useState(false);
  // State to store selected country
  const [selectedCountry, setSelectedCountry] = useState('Global');

  // Open the drawer
  const onOpenLanguage = () => setIsLanguageDrawerOpen(true);

  // Close the drawer
  const onCloseLanguage = () => setIsLanguageDrawerOpen(false);

  // Update the selected country
  const handleCountrySelect = countryName => {
    setSelectedCountry(countryName); // Store selected country
    setIsLanguageDrawerOpen(false); // Close the drawer after selection
  };

  return (
    <Box>
      {currentRoute !== '/' && (
        <Box
          position={isSticky ? 'fixed' : 'relative'}
          top={0}
          width="100%"
          zIndex={1000}
          backgroundColor={headerBackgroundColor}
          color={isSticky ? '#000000' : '#000000'}
          boxShadow={isSticky ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none'}
          transition="background-color 0.3s, color 0.3s, box-shadow 0.3s"
          padding={'12px 0'}
          className="navbar"
        >
          <Container maxW={'94%'} m={'0 auto'}>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <Box
                onMouseEnter={() => setOpenDropdown(null)}
                display={['flex']}
                alignItems={['center']}
                gap={['16px']}
                pr={['5px']}
              >
                <Link href="/main" style={{ textDecoration: 'none' }}>
                  <Image src={headerLogo} />
                </Link>
                {/* Language */}
                <Box paddingTop={'5px'} minW={['51px']}>
                  <Box
                    // className="menuDropDownIconRoted"
                    fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                    fontWeight={'400'}
                    fontFamily={'var(--foundersGrotesk-regular)'}
                    color={ourStrategyLinkColor}
                    cursor="pointer"
                    _hover={{ color: hoverColor }}
                    transition="color 0.3s ease"
                    display={'flex'}
                    alignItems={'baseline'}
                    gap={'6px'}
                    onClick={onOpenLanguage}
                  >
                    {selectedCountry}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0L6 7.5L0 0L12 0Z"
                        fill={ourServicesLinkColor}
                      />
                    </svg>
                  </Box>
                  <LanguageDrawer
                    isOpen={isLanguageDrawerOpen}
                    onClose={onCloseLanguage}
                    onSelectCountry={handleCountrySelect}
                  />
                </Box>
              </Box>
              <Flex
                alignItems={'center'}
                flex={1}
                justifyContent={'center'}
                gap={['18px', '18px', '20px', '25px', '32px', '32px']}
              >
                <Link
                  href="/about-us"
                  rel="nofollow"
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  color={aboutUsLinkColor}
                  borderBottom={
                    currentRoute === '/about-us' ? `3px solid #0057FF` : 'none'
                  }
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                  onMouseEnter={() => setOpenDropdown(null)} // Close dropdown on hover
                >
                  About
                </Link>
                <Box
                  onMouseEnter={() => handleDropdownToggle('services')}
                  onClick={() => handleDropdownToggle('services')}
                  className="menuDropDownIconRoted"
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  color={ourServicesLinkColor}
                  borderBottom={
                    currentRoute === '/our-services'
                      ? `3px solid #0057FF`
                      : 'none'
                  }
                  cursor="pointer"
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                  display={'flex'}
                  alignItems={'baseline'}
                  gap={'6px'}
                >
                  Services
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 0L6 7.5L0 0L12 0Z"
                      fill={ourServicesLinkColor}
                    />
                  </svg>
                </Box>
                <Box
                  onMouseEnter={() => handleDropdownToggle('solutions')}
                  className="menuDropDownIconRoted"
                  onClick={() => handleDropdownToggle('solutions')}
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular)'}
                  color={ourSolutionsLinkColor}
                  borderBottom={
                    currentRoute === '/solutions' ||
                    currentRoute.startsWith('/outcome/') ||
                    currentRoute.startsWith('/industry/')
                      ? '3px solid #0057FF'
                      : 'none'
                  }
                  cursor="pointer"
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                  display={'flex'}
                  alignItems={'baseline'}
                  gap={'6px'}
                >
                  Solutions
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    transition="transform 0.3s ease"
                  >
                    <path
                      d="M12 0L6 7.5L0 0L12 0Z"
                      fill={ourSolutionsLinkColor}
                    />
                  </svg>
                </Box>
                <Link
                  onMouseEnter={() => setOpenDropdown(null)} // Close dropdown on hover
                  href="/our-strategy"
                  rel="nofollow"
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  color={ourStrategyLinkColor}
                  borderBottom={
                    currentRoute === '/our-strategy'
                      ? `3px solid #E0005E`
                      : 'none'
                  }
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                >
                  Strategy
                </Link>
                <Link
                  onMouseEnter={() => setOpenDropdown(null)} // Close dropdown on hover
                  href="/work"
                  rel="nofollow"
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  color={workLinkColor}
                  borderBottom={
                    currentRoute === '/work' ? `3px solid #0057FF` : 'none'
                  }
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                >
                  Work
                </Link>
                <Link
                  onMouseEnter={() => setOpenDropdown(null)} // Close dropdown on hover
                  href="/resources"
                  rel="nofollow"
                  fontSize={['13px', '13px', '13px', '18px', '18px', '18px']}
                  fontWeight={'400'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  color={resourcesLinkColor}
                  borderBottom={
                    currentRoute === '/resources' ? `3px solid #0057FF` : 'none'
                  }
                  _hover={{ color: hoverColor }}
                  transition="color 0.3s ease"
                >
                  Resources
                </Link>
              </Flex>
              <Button
                onMouseEnter={() => setOpenDropdown(null)} // Close dropdown on hover
                bg={buttonBackgroundColor}
                color={'#fff'}
                borderRadius={'none'}
                as={Link}
                fontFamily={'var(--foundersGrotesk-regular);'}
                fontSize={['18px']}
                fontWeight={['400']}
                href="/contact-us"
                rel="nofollow"
                _hover={{
                  bg: buttonBackgroundColor,
                  color: '#fff',
                  transform: 'translate3d(0, -3px, 0)',
                }}
                transition="transform 0.5s ease"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.0774 3.21745C20.9762 3.1167 20.8483 3.04695 20.7088 3.01639C20.5693 2.98584 20.424 2.99574 20.2899 3.04495L3.78992 9.04495C3.64762 9.09892 3.52511 9.19491 3.43866 9.32016C3.35221 9.44542 3.30591 9.59401 3.30591 9.7462C3.30591 9.89839 3.35221 10.047 3.43866 10.1722C3.52511 10.2975 3.64762 10.3935 3.78992 10.4474L10.9899 13.3274L13.8699 20.5275C13.924 20.6635 14.0168 20.7807 14.1367 20.8647C14.2567 20.9487 14.3986 20.9957 14.5449 20.9999C14.6965 20.9968 14.8436 20.9479 14.9667 20.8595C15.0899 20.7711 15.1834 20.6475 15.2349 20.5049L21.2349 4.00495C21.286 3.87226 21.2983 3.72777 21.2705 3.58834C21.2426 3.44891 21.1756 3.32028 21.0774 3.21745ZM14.5449 18.15L12.4524 12.9L16.0449 9.30745L14.9874 8.24995L11.3649 11.8724L6.14492 9.74995L19.2924 5.00245L14.5449 18.15Z"
                    fill="white"
                  />
                </svg>
                <span style={{ marginTop: '7px' }}>Contact us</span>
              </Button>
            </Flex>
          </Container>
          {/* Dropdown menus */}
          <Box ref={dropdownRef} onMouseLeave={() => setOpenDropdown(null)}>
            {openDropdown === 'services' && <ServiceMegaNew />}
            {openDropdown === 'solutions' && <SolutionMegaNew />}
          </Box>
          <Box
            height="6px"
            bg="#B882FF"
            position="absolute"
            bottom={0}
            left={0}
            width={`${scrollProgress}%`}
            zIndex={999}
          />
        </Box>
      )}

      {currentRoute === '/' && (
        <Box
          position={'fixed'}
          top={0}
          width="100%"
          zIndex={1000}
          backgroundColor={'#000000'}
          color={isSticky ? '#000000' : '#000000'}
          boxShadow={isSticky ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none'}
          transition="background-color 0.3s, color 0.3s, box-shadow 0.3s"
          padding={'12px 0'}
        >
          <Container maxW={'94%'} m={'0 auto'}>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <Box>
                <Image src={workLogo} />
              </Box>
            </Flex>
          </Container>
        </Box>
      )}
    </Box>
  );
};

const MobileNavbar = ({ isHomePage }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [heroSectionHeight, setHeroSectionHeight] = useState(0);
  const [backgroundColorFromAPI, setBackgroundColorFromAPI] = useState(null);
  const [buttonBackgroundColorFromAPI, setButtonBackgroundColorFromAPI] =
    useState(null);
  const [workPageBackgroundColor, setWorkPageBackgroundColor] = useState(null);
  const [workPageButtonBackgroundColor, setWorkPageButtonBackgroundColor] =
    useState(null);

  const location = useLocation();
  const currentRoute = location.pathname;
  const extractSlug = path => {
    const industryMatch = path.match(/^\/industry\/([^\/]+)$/);
    const workMatch = path.match(/^\/work\/([^\/]+)$/);
    if (industryMatch) {
      return { type: 'industry', slug: industryMatch[1] };
    } else if (workMatch) {
      return { type: 'work', slug: workMatch[1] };
    }
    return null;
  };

  const { type, slug } = extractSlug(currentRoute) || {};
  // Handle scroll progress and sticky state
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const totalScroll = documentHeight - windowHeight;
      const scrollPercentage = (scrollTop / totalScroll) * 100;
      setScrollProgress(scrollPercentage);
      setIsSticky(scrollTop > 0);
    };

    const updateHeroSectionHeight = debounce(() => {
      const heroSection = document.getElementById('hero-section');
      if (heroSection) {
        let additionalHeight = 20; // Default extra height of 20px

        if (currentRoute.includes('solutions')) {
          // For URLs containing 'solutions'
          if (window.innerWidth >= 608) {
            additionalHeight = 88; // Add 55px if the screen width is 768px or above
          } else {
            additionalHeight = 45; // Add 35px if the screen width is below 768px
          }
        } else if (currentRoute === '/main') {
          additionalHeight = 25; // Specific case for the /main slug
        } else if (currentRoute === '/our-strategy') {
          additionalHeight = 52; // Specific case for the /main slug
        } else if (currentRoute === '/career') {
          additionalHeight = 74; // Specific case for the /main slug
        } else if (currentRoute === '/partner-with-us') {
          additionalHeight = 74; // Specific case for the /main slug
        } else {
          if (window.innerWidth > 700) {
            additionalHeight = 48; // General case for wider screens
          } else {
            additionalHeight = 40; // General case for narrower screens
          }
        }

        const height = heroSection.clientHeight + additionalHeight;
        setHeroSectionHeight(height);
        console.log(
          `Hero Section Height (with +${additionalHeight}px):`,
          height
        ); // Logs the final height with added pixels after debounce
      }
    }, 800); // Adjust the debounce delay as needed (200ms here)

    // Initial height update
    updateHeroSectionHeight();

    updateHeroSectionHeight(); // Initial height update
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateHeroSectionHeight); // Update height on resize
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateHeroSectionHeight);
    };
  }, [heroSectionHeight]); // Update effect if heroSectionHeight changes

  useEffect(() => {
    if (slug) {
      const fetchData = async () => {
        try {
          if (type === 'industry') {
            const response = await axios.get(
              `https://cms.wearetenet.com/api/industry-solution-details?filters[slug][$eq]=${slug}&populate=*`
            );
            if (response.data.data.length > 0) {
              const industryData = response.data.data[0];
              setBackgroundColorFromAPI(
                industryData.attributes.background_color
              );
              setButtonBackgroundColorFromAPI(
                industryData.attributes.header_button_background
              );
              setWorkPageBackgroundColor(null); // Clear work page data if fetching industry data
              setWorkPageButtonBackgroundColor(null);
            }
          } else if (type === 'work') {
            const response = await axios.get(
              `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
            );
            if (response.data.data.length > 0) {
              const workPageData = response.data.data[0];
              setWorkPageBackgroundColor(
                workPageData.attributes.hex_header_background_color
              );
              setWorkPageButtonBackgroundColor(
                workPageData.attributes.button_background_color
              );
              setBackgroundColorFromAPI(null); // Clear industry data if fetching work page data
              setButtonBackgroundColorFromAPI(null);
            }
          }
        } catch (err) {
          setBackgroundColorFromAPI(null);
          setButtonBackgroundColorFromAPI(null);
          setWorkPageBackgroundColor(null);
          setWorkPageButtonBackgroundColor(null);
        }
      };

      fetchData();
    }
  }, [slug, type]);

  const scrollThreshold =
    ((heroSectionHeight +
      (window.innerWidth >= 768
        ? 55
        : window.innerWidth < 768
        ? 25
        : window.innerWidth > 700
        ? 45
        : 20)) /
      document.body.scrollHeight) *
    100;

  // Define background colors based on route and scroll progress
  let headerBackgroundColor;
  if (type === 'industry' && backgroundColorFromAPI) {
    headerBackgroundColor =
      scrollProgress < scrollThreshold ? backgroundColorFromAPI : '#ffffff';
  } else if (type === 'work' && workPageBackgroundColor) {
    headerBackgroundColor =
      scrollProgress < scrollThreshold ? workPageBackgroundColor : '#ffffff';
  } else {
    if (currentRoute === '/main') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0057FF' : '#ffffff';
    } else if (
      ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
    ) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (currentRoute === '/our-strategy') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (currentRoute === '/tools-and-technologies') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (currentRoute === '/partner-with-us') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/white-label-agency') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#30054A' : '#ffffff';
    } else if (currentRoute === '/work') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/resources') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (currentRoute === '/career') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (/^\/career\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/thankyou') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/work/pazazz') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#2C021D' : '#ffffff';
    } else if (currentRoute === '/work/think-vastu') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#2C021D' : '#ffffff';
    } else if (currentRoute === '/work/strongr') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/work/angles') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#000000' : '#ffffff';
    } else if (currentRoute === '/singleinsight') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#00342B' : '#ffffff';
    } else if (/^\/resources\/[^\/]+\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#00342B' : '#ffffff';
    } else if (/^\/resources\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (/^\/outcome\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (/^\/research\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#30054A' : '#ffffff';
    } else if (/^\/design\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D0E4D' : '#ffffff';
    } else if (/^\/build\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#3E001A' : '#ffffff';
    } else if (/^\/growth\/[^\/]+/.test(currentRoute)) {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#111111' : '#ffffff';
    } else if (currentRoute === '/work/iros') {
      headerBackgroundColor =
        scrollProgress < scrollThreshold ? '#0D212C' : '#ffffff';
    } else {
      // Default color handling
      switch (currentRoute) {
        case '/case-study':
          headerBackgroundColor = isSticky ? '#ffffff' : '#3E001A';
          break;
        case '/contact-us':
        case '/404':
        case '/upcoming':
          headerBackgroundColor = isSticky ? '#ffffff' : '#000000';
          break;
        default:
          headerBackgroundColor = isSticky ? '#ffffff' : '#000000';
          break;
      }
    }
  }
  // Set header logo based on route
  let headerLogo;

  if (currentRoute === '/main') {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (
    [
      '/about-us',
      '/solutions',
      '/our-services',
      '/our-strategy',
      '/tools-and-technologies',
      '/work/pazazz',
    ].includes(currentRoute)
  ) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/work') {
    headerLogo = scrollProgress < scrollThreshold ? workLogo : stickyLogo;
  } else if (
    currentRoute === '/career' ||
    currentRoute.startsWith('/career/') ||
    currentRoute.startsWith('/work/') ||
    currentRoute === '/thankyou' ||
    currentRoute === '/partner-with-us' ||
    currentRoute === '/white-label-agency' ||
    currentRoute === '/work/think-vastu' ||
    currentRoute === '/work/strongr' ||
    currentRoute === '/work/angles'
  ) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (
    currentRoute === '/singleinsight' ||
    currentRoute.startsWith('/resources/') ||
    currentRoute.startsWith('/outcome/') ||
    currentRoute.startsWith('/industry/') ||
    currentRoute === '/work/iros'
  ) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/research/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/design/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/build/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute.startsWith('/growth/')) {
    headerLogo = scrollProgress < scrollThreshold ? logo : stickyLogo;
  } else if (currentRoute === '/contact-us') {
    headerLogo = isSticky ? stickyLogo : contactLogo;
  } else if (currentRoute === '/resources') {
    headerLogo = isSticky ? logo : stickyLogo;
  } else if (
    currentRoute === '/case-study' ||
    currentRoute === '/404' ||
    currentRoute === '/upcoming'
  ) {
    headerLogo = isSticky ? stickyLogo : logo;
  } else {
    headerLogo = stickyLogo;
  }

  function getSvgFillColor(buttonBackgroundColor) {
    const colorMap = {
      '#0D0E4D': '#ffffff',
      '#0057FF': '#ffffff',
      '#E0005E': '#ffffff',
      '#9B4DFF': '#ffffff',
      '#30054A': '#ffffff',
      '#00BA61': '#ffffff',
      '#B882FF': '#ffffff',
      '#00342B': '#ffffff',
      '#000000': '#ffffff',
    };

    return colorMap[buttonBackgroundColor] || '#ffffff';
  }

  let svgFillColor;
  let buttonBackgroundColor;
  if (type === 'industry' && buttonBackgroundColorFromAPI) {
    buttonBackgroundColor =
      scrollProgress < scrollThreshold
        ? '#ffffff'
        : buttonBackgroundColorFromAPI;
  } else if (type === 'work' && workPageButtonBackgroundColor) {
    buttonBackgroundColor =
      scrollProgress < scrollThreshold
        ? '#ffffff'
        : workPageButtonBackgroundColor;
  } else {
    if (currentRoute === '/main') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (
      ['/about-us', '/solutions', '/our-services'].includes(currentRoute)
    ) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#0057ff'
          : '#ffffff';
    } else if (currentRoute === '/our-strategy') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/tools-and-technologies') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/partner-with-us') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/white-label-agency') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/thankyou') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/career') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute.startsWith('/career/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work/pazazz') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work/think-vastu') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work/strongr') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work/angles') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/singleinsight') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute.startsWith('/resources/insights')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00342B'
          : isSticky
          ? '#9B4DFF'
          : '#00342B';
    } else if (currentRoute.startsWith('/outcome/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute.startsWith('/research/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute.startsWith('/design/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#B882FF'
          : '#ffffff';
    } else if (currentRoute.startsWith('/build/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#E0005E'
          : '#ffffff';
    } else if (currentRoute.startsWith('/growth/')) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#B882FF'
          : '#ffffff';
    } else if (/^\/resources\/[^\/]+\/[^\/]+/.test(currentRoute)) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#00BA61'
          : isSticky
          ? '#9B4DFF'
          : '#00BA61';
    } else if (/^\/resources\/[^\/]+/.test(currentRoute)) {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#9B4DFF'
          : '#ffffff';
    } else if (currentRoute === '/work/iros') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#0057FF'
          : '#ffffff';
    } else if (currentRoute === '/resources') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#ffffff'
          : isSticky
          ? '#0057FF'
          : '#ffffff';
    } else if (currentRoute === '/resources/insights') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/guides') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/glossary') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/thoughts') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/updates') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else if (currentRoute === '/resources/listen') {
      buttonBackgroundColor =
        scrollProgress < scrollThreshold
          ? '#0057FF'
          : isSticky
          ? '#0057FF'
          : '#0057FF';
    } else {
      switch (currentRoute) {
        case '/case-study':
          buttonBackgroundColor = isSticky ? '#9B4DFF' : '#E0005E';
          break;
        case '/contact-us':
        case '/404':
        case '/Upcoming':
          buttonBackgroundColor = '#9B4DFF';
          break;
        default:
          buttonBackgroundColor = '#9B4DFF';
      }
    }
  }
  // Determine SVG fill color
  svgFillColor = getSvgFillColor(buttonBackgroundColor);

  // NavToggle Work
  const [isOpen, setIsOpen] = useState(false); // Main drawer state
  const [isNestedDrawerOpen, setIsNestedDrawerOpen] = useState(false); // Nested drawer state
  const [activeComponent, setActiveComponent] = useState(null); // To manage active nested component

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const openNestedDrawer = component => {
    setActiveComponent(component);
    setIsOpen(false); // Close the main drawer when nested drawer opens
    setIsNestedDrawerOpen(true);
  };

  const closeNestedDrawer = () => {
    setIsNestedDrawerOpen(false);
    setIsOpen(true); // Reopen the main drawer when nested drawer closes
  };

  //   // NavToggle Work
  // const [isOpen, setIsOpen] = useState(false); // Main drawer state
  // const [isNestedDrawerOpen, setIsNestedDrawerOpen] = useState(false); // Nested drawer state
  // const [activeComponent, setActiveComponent] = useState(null); // To manage active nested component

  // const toggleDrawer = () => {
  //   setIsOpen(!isOpen);
  // };

  // const openNestedDrawer = component => {
  //   setActiveComponent(component);

  //   // Close the main drawer after 2 seconds delay
  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 1000); // 2000 milliseconds = 1 seconds

  //   setIsNestedDrawerOpen(true);
  // };

  // const closeNestedDrawer = () => {
  //   setIsNestedDrawerOpen(false);
  //   setIsOpen(true); // Reopen the main drawer when nested drawer closes
  // };

  // Drawer state
  const [isLanguageDrawerOpen, setIsLanguageDrawerOpen] = useState(false);
  // State to store selected country
  const [selectedCountry, setSelectedCountry] = useState('Global');

  // Open the drawer
  const onOpenLanguage = () => setIsLanguageDrawerOpen(true);

  // Close the drawer
  const onCloseLanguage = () => setIsLanguageDrawerOpen(false);

  // Update the selected country
  const handleCountrySelect = countryName => {
    setSelectedCountry(countryName); // Store selected country
    setIsLanguageDrawerOpen(false); // Close the drawer after selection
  };

  return (
    <Box
      position="sticky"
      top="0"
      zIndex="1000"
      padding={'12px 0'}
      backgroundColor={isHomePage ? '#000000' : headerBackgroundColor}
    >
      {/* Navbar */}
      {/* Main NavBar */}
      <Container maxW={'100%'} m={'0 auto'}>
        <Flex alignItems="center" justifyContent="space-between">
          {isHomePage ? (
            <Link href="#" width={['104px']} height={['40px']}>
              <Image
                src={workLogo}
                objectFit={'cover'}
                width={['100%']}
                height={['100%']}
              />
            </Link>
          ) : (
            <>
              <Box
                display={['flex']}
                alignItems={['center']}
                gap={['11px']}
                pr={['5px']}
              >
                <Link href="/main" width={['104px']} height={['40px']}>
                  <Image
                    src={headerLogo}
                    objectFit={'cover'}
                    width={['100%']}
                    height={['100%']}
                  />
                </Link>
                {/* Language */}
                <Box paddingTop={'5px'}>
                  <Box
                    // className="menuDropDownIconRoted"
                    fontSize={['18px', '18px', '18px', '24px', '24px', '24px']}
                    fontWeight={'400'}
                    fontFamily={'var(--foundersGrotesk-regular)'}
                    color={'#fff'}
                    cursor="pointer"
                    // _hover={{ color: hoverColor }}
                    transition="color 0.3s ease"
                    display={'flex'}
                    alignItems={'baseline'}
                    gap={'6px'}
                    onClick={onOpenLanguage}
                  >
                    {selectedCountry}
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 0L6 7.5L0 0L12 0Z"
                        fill={'#fff'}
                        // fill={ourServicesLinkColor}
                      />
                    </svg>
                  </Box>
                  <LanguageDrawer
                    isOpen={isLanguageDrawerOpen}
                    onClose={onCloseLanguage}
                    onSelectCountry={handleCountrySelect}
                  />
                </Box>
              </Box>
              <Flex alignItems="center" gap="12px">
                <IconButton
                  aria-label="Menu"
                  icon={<HamburgerIcon boxSize={8} />}
                  onClick={toggleDrawer}
                  color={buttonBackgroundColor}
                  background={['transparent']}
                />
              </Flex>
            </>
          )}
        </Flex>
      </Container>

      {!isHomePage && (
        <>
          {/* Main Drawer */}
          <Drawer
            placement="right"
            onClose={toggleDrawer}
            isOpen={isOpen}
            size="full"
          >
            <DrawerOverlay>
              <DrawerContent bg={['#000000']}>
                <Flex
                  alignItems="center"
                  padding={['20px']}
                  justifyContent={['space-between']}
                  borderBottom={'1px'}
                  borderBottomColor={'#333'}
                >
                  <Link href="/main" width={['104px']} height={['40px']}>
                    <img
                      src={drawerLogo}
                      objectFit={'cover'}
                      width={['100%']}
                      height={['100%']}
                      alt="Drawer Logo"
                    />
                  </Link>
                  <Box display={'flex'} gap={'12px'} alignItems={'center'}>
                    <Box>
                      <Link
                        fontSize={['16px']}
                        color={'#fff'}
                        href="/contact-us"
                        background={'#0057FF'}
                        fontFamily={'var(--foundersGrotesk-regular);'}
                        fontWeight={400}
                        lineHeight={'normal'}
                        width={['103px']}
                        height={'38px'}
                        display={'inline-flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        paddingTop={'4px'}
                      >
                        Get Started
                      </Link>
                    </Box>
                    <DrawerCloseButton color="#ffffff" position={['static']} />
                  </Box>
                </Flex>
                <DrawerBody
                  marginTop={'35px'}
                  display={['flex']}
                  flexDirection={['column']}
                  justifyContent={['space-between']}
                  padding={['0']}
                >
                  <Flex direction="column" gap={['79px']} height={['100%']}>
                    <Flex direction="column" gap={'10px'}>
                      {[
                        { label: 'About', link: '/about-us' },
                        { label: 'Services', component: 'services' },
                        { label: 'Solutions', component: 'solutions' },
                        { label: 'Strategy', link: '/our-strategy' },
                        { label: 'Work', link: '/work' },
                        { label: 'Resources', link: '/resources' },
                        { label: 'Contact us', link: '/contact-us' },
                      ].map(item => (
                        <Flex
                          key={item.label}
                          justifyContent={['space-between']}
                          paddingLeft={['40px']}
                          paddingRight={['40px']}
                          alignItems={['center']}
                          onClick={() =>
                            item.component
                              ? openNestedDrawer(item.component)
                              : (window.location.href = item.link)
                          }
                          _hover={{ backgroundColor: '#0057FF' }}
                          height={['50px']}
                        >
                          <Link
                            fontSize={'24px'}
                            color={'#fff'}
                            fontFamily={'var(--foundersGrotesk-regular);'}
                            rel="nofollow"
                            paddingTop={'10px'}
                            fontWeight={'400'}
                            lineHeight={'normal'}
                          >
                            {item.label}
                          </Link>
                          {/* Conditionally render SVG only for Services and Solutions */}
                          {item.component && (
                            <svg
                              width="8"
                              height="13"
                              viewBox="0 0 8 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0.714844L7.5 6.71484L0 12.7148V0.714844Z"
                                fill="white"
                              />
                            </svg>
                          )}
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>

          {/* Nested Drawer for Services and Solutions */}
          <Drawer
            placement="right"
            onClose={closeNestedDrawer}
            isOpen={isNestedDrawerOpen}
            size="full"
          >
            <DrawerOverlay>
              <DrawerContent bg="#000000" padding={'0'}>
                <DrawerBody padding={['0']}>
                  <Box
                    display={['flex']}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    height={'100%'}
                  >
                    <Box>
                      {/* Nav */}
                      <Box
                        position="sticky"
                        top="0"
                        zIndex="1000"
                        marginBottom={['31px']}
                        background={'#000'}
                      >
                        <Flex
                          alignItems="center"
                          padding={['20px']}
                          justifyContent={['space-between']}
                          borderBottom={'1px'}
                          borderBottomColor={'#333'}
                        >
                          <Link
                            href="/main"
                            width={['104px']}
                            height={['40px']}
                          >
                            <img
                              src={drawerLogo}
                              objectFit={'cover'}
                              width={['100%']}
                              height={['100%']}
                              alt="Drawer Logo"
                            />
                          </Link>
                          <Box
                            display={'flex'}
                            gap={'12px'}
                            alignItems={'center'}
                          >
                            <Box>
                              <Link
                                fontSize={['16px']}
                                color={'#fff'}
                                href="/contact-us"
                                background={'#0057FF'}
                                fontFamily={'var(--foundersGrotesk-regular);'}
                                fontWeight={400}
                                lineHeight={'normal'}
                                width={['103px']}
                                height={'38px'}
                                display={'inline-flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                paddingTop={'4px'}
                              >
                                Get Started
                              </Link>
                            </Box>
                            <DrawerCloseButton
                              color="#ffffff"
                              position={['static']}
                            />
                          </Box>
                        </Flex>
                      </Box>
                      {/* BackButton */}
                      <Box padding={['0px 20px']} position={'relative'}>
                        <Button
                          background={'none'}
                          borderRadius={'0'}
                          boxShadow={'none'}
                          _hover={{ background: 'transparent' }}
                          padding={'0'}
                          display={['flex']}
                          alignItems={'center'}
                          gap={['6px']}
                          onClick={closeNestedDrawer}
                          height={['18px!important']}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_2277_1134)">
                              <path
                                d="M6.25052 10L12.5005 3.75L13.3755 4.625L8.00052 10L13.3755 15.375L12.5005 16.25L6.25052 10Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2277_1134">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(-1 0 0 -1 20 20)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <Text
                            as={'span'}
                            fontSize={['16px']}
                            color={'#fff'}
                            fontWeight={'400'}
                            lineHeight={'normal'}
                            fontFamily={'var(--foundersGrotesk-regular);'}
                            paddingTop={['5px']}
                          >
                            Main Menu
                          </Text>
                        </Button>
                      </Box>

                      <Box>
                        {activeComponent === 'services' && (
                          <ServiceMobileMenu />
                        )}
                        {activeComponent === 'solutions' && (
                          <SolutionMobileMenu />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      )}
    </Box>
  );
};

const Navbar = ({ isHomePage }) => {
  const displayMobileNavbar = useBreakpointValue({ base: true, md: false });

  return (
    <Router>
      {displayMobileNavbar ? (
        <MobileNavbar isHomePage={isHomePage} />
      ) : (
        <DesktopNavbar isHomePage={isHomePage} />
      )}
    </Router>
  );
};

export default Navbar;
