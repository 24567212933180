import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';

import HeroSection from './DetailPage/HeroSection';
import WhatToExpect from './DetailPage/ExpectSection';
import OurProcess from './DetailPage/OurProcess';
import OutcomeSection from './DetailPage/OutcomeSection';
import CeoSection from './DetailPage/CeoSection';
import CheckoutSection from './DetailPage/CheckoutSection';
import WorkTogether from '../Reusable/LetsWorkToGether';
import lwt1 from '../../assets/Reusable/LetsWorkToGether/purple1.svg';
import lwt2 from '../../assets/Reusable/LetsWorkToGether/purple2.svg';
const Strategy = () => {
  return (
    <>
      <Box>
        <HeroSection />
      </Box>

      <Box>
        <WhatToExpect />
      </Box>

      <Box>
        <OurProcess />
      </Box>

      <Box>
        <OutcomeSection />
      </Box>

      <Box>
        <CeoSection />
      </Box>

      <Box>
        <CheckoutSection />
      </Box>

      <Box>
        <WorkTogether bgColor="#30054A" hand1Img={lwt1} hand2Img={lwt2} />
      </Box>
    </>
  );
};

export default Strategy;
