import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

import mobileClient from '../../../assets/Home/clientsLogo/21.webp';
import desktopClient from '../../../assets/Home/clientsLogo/desktopClient.webp';
import './Custom.css';

const ClientsLogo = () => {
  return (
    <Box
      // padding={["80px 0", "80px 0", "80px 0", "80px 0", "80px 0", "80px 0"]}
      width={['100%', '100%', '90%', '90%', '90%', '83%']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
      margin={['0 auto']}
      marginBottom={['80px', '80px', '60px', '80px', '120px', '150px']}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '100%', '100%', '100%']}
        margin={['0 auto']}
        className="customClient"
      >
        <Box
          marginBottom={['0px', '0px', '68px', '48px', '40px', '40px']}
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          justifyContent={[
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
          alignItems={[
            'center',
            'center',
            'center',
            'center',
            'center',
            'center',
          ]}
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
        >
          <Text
            as="h3"
            m="0px"
            fontSize={['32px', '32px', '40px', '48px', '48px', '48px']}
            fontFamily="var(--garnett-regular)"
            color={'#000000'}
            className="featuredClients"
          >
            Our Clients
          </Text>
          <Text
            as="p"
            m="0px"
            fontSize={['16px', '16px', '20px', '20px', '20px', '20px']}
            maxW={['424px', '424px', '424px', '324px', '424px', '424px']}
            color={'#333333'}
            fontFamily="var(--foundersGrotesk-regular)"
            fontWeight="400"
            lineHeight={['normal']}
            className="featuredClients1"
          >
            Our clients trust us with their success. Explore a curation of
            featured clients that believe in our impact.{' '}
          </Text>
        </Box>
        <Flex
          display={['none', 'none', 'flex', 'flex', 'flex', 'flex']}
          flexWrap="wrap"
          justifyContent={[
            'center',
            'center',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
        >
          <img src={desktopClient} width={['100%']} alt="" />
        </Flex>
        <Flex
          display={['flex', 'flex', 'none', 'none', 'none', 'none']}
          flexWrap="wrap"
          justifyContent={[
            'center',
            'center',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
          background={'#F5F5F5'}
          padding={[
            '60px 20px 70px 20px',
            '60px 40px 70px 40px',
            '80px 60px 80px 60px',
            '80px 150px 80px 150px',
            '80px 150px 80px 150px',
            '77px 194px 77px 194px',
          ]}
        >
          <Image src={mobileClient} margin={['0 auto']} />
        </Flex>
      </Container>
    </Box>
  );
};
export default ClientsLogo;
