import React from 'react';
import LetsWorktogetherResuable from '../Reusable/LetsWorkToGether';
import purple1 from '../../assets/Reusable/LetsWorkToGether/purple1.svg';
import purple2 from '../../assets/Reusable/LetsWorkToGether/purple2.svg';

const LetsWorkToGether = () => {
  return (
    <div>
      <LetsWorktogetherResuable
        bgColor="#30054A"
        hand1Img={purple1}
        hand2Img={purple2}
      />
    </div>
  );
};

export default LetsWorkToGether;
