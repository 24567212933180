import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import arrowImage from '../../../assets/Home/clientAbout/arrow.svg';
import brandLogo1 from '../../../assets/Home/clientAbout/brand1.svg';
import brandLogo2 from '../../../assets/Home/clientAbout/brand2.svg';
import brandLogo3 from '../../../assets/Home/clientAbout/brand3.svg';
import brandLogo4 from '../../../assets/Home/clientAbout/brand4.svg';
import brandLogo5 from '../../../assets/Home/clientAbout/brand5.svg';
import CardBlueBg from '../../../assets/Reusable/ClientSaysLoveSliderHomeAndWhiteLabel/bgBlue.webp';
import CradGreenBg from '../../../assets/Reusable/ClientSaysLoveSliderHomeAndWhiteLabel/cardGreenBg.svg';

import img from '../../../assets/Reusable/ClientSaysLoveSliderHomeAndWhiteLabel/DummyImageCol1.webp';
import leftIcon from '../../../assets/Solution/icon/leftIcon.svg';
import rightIcon from '../../../assets/Solution/icon/rightIcon.svg';
import axios from 'axios';

const ClientAboutUs = ({
  sectionBgColor = '#0D0E4D',
  sectionTitle = 'What our client’s has to say about us',
  sectionParagraph = ' We’re not just Developers. We’re Solutions Specialists. We create  Digital Products, which work as solutions to the client’s requirements.',
  cardBgColorGreen = 'green', // default value is 'green'
  sectionMarginTopBottom = [' 0 0 80px', '0 0 80px', ' 0 0  80px', '0 0 150px'],
}) => {
  const cardBgImage = cardBgColorGreen === 'green' ? CradGreenBg : CardBlueBg;
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [currentSlide, setCurrentSlide] = useState(1); // Assuming the initial slide is 1

  // Function to update current slide index
  const handleSlideChange = swiper => {
    setCurrentSlide(swiper.realIndex + 1); // Swiper indexes start from 0, so adding 1
  };

  // const slidesData = [
  //   {
  //     logo: brandLogo1,
  //     backgroundImage: cardBgImage,
  //     testimonial:
  //       'Their style of communication and empathy towards our requirements were amazing!',
  //     position: 'Product Head',
  //     company: 'LAZADA',
  //   },
  //   {
  //     logo: brandLogo2,
  //     backgroundImage: cardBgImage,
  //     testimonial:
  //       'We were impressed by the work culture, documentation, agile methodology, and most importantly, the human capital.',
  //     position: 'Operation Head',
  //     company: 'MOMONDO',
  //   },
  //   {
  //     logo: brandLogo3,
  //     backgroundImage: cardBgImage,
  //     testimonial:
  //       'Just wanted to write a quick note and thank you for being such stellar partners...',
  //     role: 'Product Head',
  //     company: 'SEPHORA',
  //   },
  //   {
  //     logo: brandLogo4,
  //     backgroundImage: cardBgImage,
  //     testimonial:
  //       'Their articulation of the process and their understanding of what we wanted...',
  //     role: 'Head of Marketing',
  //     company: 'DOORDASH',
  //   },
  //   {
  //     logo: brandLogo5,
  //     backgroundImage: cardBgImage,
  //     testimonial:
  //       'I’ve had a chance to sit with our CEO this morning, I think you’ve made us fall in love a little bit...',
  //     role: 'Operation Head',
  //     company: 'KUONI',
  //   },
  // ];

  const [slidesData, setSlidesData] = useState([]);
  useEffect(() => {
    // Fetching data from API
    axios
      .get('https://cms.wearetenet.com/api/testimonials/?populate=*')
      .then(response => {
        // Extracting data from the response
        const testimonials = response.data.data.map(item => {
          const {
            name: company,
            designation: position,
            logo,
            paragraph,
          } = item.attributes;

          // Extracting testimonial text from paragraph structure
          const testimonial = paragraph
            .map(p => p.children.map(child => child.text).join(' '))
            .join(' ');

          // Constructing each slide object
          return {
            logo: logo
              ? `https://cms.wearetenet.com${logo.data.attributes.url}`
              : '',
            backgroundImage: cardBgImage, // Assuming this is predefined
            testimonial,
            position,
            company,
          };
        });

        // Updating state with structured data
        setSlidesData(testimonials);
      })
      .catch(error => {
        console.error('Error fetching testimonials:', error);
      });
  }, []);
  return (
    <Box
      width={['100%', '100%', '100%', '100%', '100%', '100%']}
      display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
      padding={['80px 0 53px', '99px 0 53px', '99px 0 53px', '99px 0 195px']}
      background={sectionBgColor}
      flexDir={['column', 'column', 'column', 'column', 'column', 'column']}
      margin={sectionMarginTopBottom}
    >
      {/* Section Headings */}
      <Container
        maxWidth={['100%', '100%', '100%', '95%', '95%', '95%']}
        margin={['0 auto']}
        paddingBottom={['64px', '64px', '64px', '80px']}
      >
        <Box
          display={['flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
          justifyContent={[
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
            'space-between',
          ]}
          alignItems={[
            'flex-start',
            'flex-start',
            'flex-start',
            'flex-start',
            'flex-start',
            'flex-start',
          ]}
          flexDirection={['column', 'column', 'row', 'row', 'row', 'row']}
        >
          <Box maxW={['369px', '469px', '489px', '469px', '650px', '669px']}>
            <Text
              as="h3"
              fontSize={['32px', '32px', '40px', '45px', '58px', '58px']}
              fontFamily="var(--garnett-semibold)"
              color={'#ffffff'}
              fontWeight={'600'}
              lineHeight={'normal'}
              padding={'0'}
            >
              {sectionTitle}
            </Text>
          </Box>
          <Box maxW={['384px', '384px', '384px']}>
            <Text
              as="p"
              fontSize={['16px', '16px', '16px', '18px', '18px', '18px']}
              color={'#ffffff'}
              fontFamily="var(--foundersGrotesk-regular)"
              fontWeight="400"
              lineHeight={['normal']}
              paddingTop={['40px', '40px', '40px', '0px']}
            >
              {sectionParagraph}
            </Text>
          </Box>
        </Box>
      </Container>

      {/* Slider */}
      <Container
        maxWidth={['100%', '100%', '100%', '96%', '96%', '96%']}
        mr={['0']}
        padding={'0'}
      >
        <Box
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          gap={['33px']}
          justifyContent={['end']}
          alignItems={['center']}
        >
          {/* Image Bg */}
          <Box
            display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
            alignItems={'center'}
            w={['100%', '100%', '100%', '50%', '50%', '50%']}
            padding={['0px 20px', '0px 20px', '0px 20px', '0px']}
          >
            <Image src={img} alt="Project 01" w={['100%']} h={'auto'} />
          </Box>

          {/* Slider Navigation */}
          <Box
            justifyContent={['space-between']}
            padding={['40px 20px 22px']}
            display={['flex', 'flex', 'flex', 'none', 'none', 'none']}
            margin={['0 auto']}
          >
            {/* <Text
              fontSize={['14px']}
              color={['#ffffff']}
              fontFamily="var(--garnett-regular)"
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text> */}
            <Box display={['flex']}>
              <Box onClick={() => goPrev()} mr={'20px'}>
                <Image src={leftIcon} cursor={'pointer'} />
              </Box>
              <Box onClick={() => goNext()}>
                <Image src={rightIcon} cursor={'pointer'} />
              </Box>
            </Box>
          </Box>

          {/* Slider */}
          <Box
            flex={['none', 'none', 'none', '1', '1', '1']}
            paddingLeft={['20px', '20px', '20px', '0px']}
            position="relative"
            maxWidth={['100%', '100%', '100%', '50%', '50%', '50%']}
            _hover={{ cursor: `url(${arrowImage}), auto` }}
          >
            {/* <Text
              display={['none', 'none', 'none', 'flex', 'flex', 'flex']}
              fontSize={['14px']}
              color={['#ffffff']}
              fontFamily="var(--garnett-regular)"
            >
              {`${currentSlide}/${swiperRef.current?.slides?.length ?? 5}`}
            </Text> */}
            <Swiper
              spaceBetween={50}
              slidesPerView={2.4}
              modules={[Navigation, FreeMode]}
              ref={swiperRef}
              navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
              }}
              breakpoints={{
                290: {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
                  freeMode: false,
                },
                320: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                  freeMode: false,
                },
                480: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                  freeMode: false,
                },
                552: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                  freeMode: false,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 24,
                },
                992: {
                  slidesPerView: 2.1, // Changed to 1.7 for 1000px to 1100px
                  spaceBetween: 20, // Adjust according to your design
                },
                1100: {
                  slidesPerView: 2.1, // Changed to 1.7 for 1000px to 1100px
                  spaceBetween: 20, // Adjust according to your design
                },
                1200: {
                  slidesPerView: 2.2,
                  spaceBetween: 24,
                },
                1512: {
                  slidesPerView: 2.5,
                  spaceBetween: 24,
                },
                2000: {
                  slidesPerView: 3.0,
                  spaceBetween: 24,
                },
              }}
              onSlideChange={swiper => handleSlideChange(swiper)}
            >
              {slidesData.map((slide, index) => (
                <SwiperSlide
                  key={index}
                  style={{ display: 'flex', alignItems: 'stretch' }}
                >
                  <Flex
                    flexDirection="column"
                    backgroundImage={cardBgImage}
                    padding={['20px']}
                    backgroundSize={[
                      'cover',
                      'cover',
                      'cover',
                      'cover',
                      'cover',
                      'cover',
                    ]}
                    backgroundRepeat={['no-repeat']}
                    height={[
                      '300px',
                      '350px',
                      '350px',
                      '380px',
                      '380px',
                      '400px',
                    ]}
                  >
                    {/* Logo and Description Text */}
                    <Flex justifyContent={['end']}>
                      <Image
                        src={slide.logo}
                        alt="Brand Logo"
                        width={['35%', '35%', '53px', '63px', '73px', '83px']}
                        paddingTop={[
                          '20px',
                          '30px',
                          '30px',
                          '30px',
                          '30px',
                          '30px',
                        ]}
                        paddingBottom={[
                          '20px',
                          '30px',
                          '30px',
                          '30px',
                          '40px',
                          '50px',
                        ]}
                      />
                    </Flex>
                    <Flex
                      gap={['90px', '90px', '90px', '40px', '40px', '45px']}
                      flexDirection={['column']}
                    >
                      <Text
                        color={'#ffffff'}
                        fontFamily="var(--foundersGrotesk-light)"
                        fontWeight="400"
                        lineHeight={['normal']}
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '18px',
                          '18px',
                        ]}
                      >
                        {slide.testimonial}
                      </Text>
                    </Flex>

                    {/* Operation Head and Company */}
                    <Flex
                      gap={['2PX']}
                      flexDirection={['column']}
                      marginTop="auto" // Pushes this Flex to the bottom
                    >
                      <Text
                        color={'#ffffff'}
                        fontFamily="var(--foundersGrotesk-light)"
                        fontWeight="400"
                        lineHeight={['normal']}
                        fontSize={[
                          '16px',
                          '16px',
                          '18px',
                          '18px',
                          '18px',
                          '18px',
                        ]}
                      >
                        {slide.position}
                      </Text>
                      <Text
                        color={'#ffffff'}
                        fontFamily="var(--foundersGrotesk-light)"
                        fontWeight="400"
                        lineHeight={['normal']}
                        fontSize={[
                          '14px',
                          '14px',
                          '16px',
                          '16px',
                          '16px',
                          '16px',
                        ]}
                      >
                        {slide.company}
                      </Text>
                    </Flex>
                  </Flex>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default ClientAboutUs;
