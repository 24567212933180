import { Box } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeaturedClientsLogos from '../Home/ClientsLogo';
import Counter from '../Reusable/CaseStudy/Counter';
import ExplorwOtherWorkSlider from '../Reusable/CaseStudy/ExploreOtherWorkSlider';
import ClientSaysSlider from '../Reusable/ClientSaysSlider';
import GrowthJourneyEXP from '../Reusable/GrowthJourneyEXP';
import LogoMarqueeReuse from '../Reusable/LogoMarquee';
import OurRating from '../Reusable/OurRatings';
import CuretedServiceUse from './CuratedServicesUse';
import FaqUse from './FaqUse';
import GreatUxPoints from './GreatUXPoint';
import HeroBanner from './HeroBanner';
import LetsDisccusion from './LetsDisccusion';
import LetsWorkTogether from './LetsWorkToGether';
import OurCapabilities from './OurCapabilities';
import OurLeading from './OurLeadingUse';
import UXDesignProcess from './Process';
import TextWithImgUse from './TextWithImgUse';
import TextWithImgUse1 from './TextWithImgUse1';

const SolutionsByOutComesDetailsPage = () => {
  const { slug } = useParams();
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchSeoData = async () => {
      try {
        const response = await fetch(
          `https://cms.wearetenet.com/api/outcome-solution-details?filters[slug][$eq]=${slug}&populate=*`
        );
        const result = await response.json();
        const seo = result.data[0]?.attributes?.seo;
        setSeoData(seo);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      }
    };

    fetchSeoData();
  }, [slug]);
  return (
    <Box>
      <Box>
        <HeroBanner />
      </Box>
      <Box>
        <LetsDisccusion />
      </Box>
      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <GreatUxPoints />
      </Box>
      <Box>
        <OurRating SecbgColor={'#F5F5F5'} />
      </Box>
      <Box>
        <TextWithImgUse />
      </Box>
      <Box>
        <GrowthJourneyEXP />
      </Box>
      <Box>
        <ClientSaysSlider />
      </Box>
      <Box>
        <CuretedServiceUse />
      </Box>
      <Box>
        <OurLeading />
      </Box>
      <Box>
        <TextWithImgUse1 />
      </Box>

      <Box>
        <LogoMarqueeReuse />
      </Box>
      <Box>
        <Counter customMarginTop={'0'} />
      </Box>
      <Box>
        <OurCapabilities />
      </Box>
      <Box>
        <UXDesignProcess />
      </Box>
      <Box>
        <FaqUse />
      </Box>
      <Box>
        <FeaturedClientsLogos />
      </Box>
      <Box>
        <ExplorwOtherWorkSlider />
      </Box>
      <Box>
        <LetsWorkTogether />
      </Box>
    </Box>
  );
};

export default SolutionsByOutComesDetailsPage;
