import { Box, Link } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingWhatsAppButton = () => {
    return (
        <Box
            position="fixed"
            bottom="4"
            right="4"
            zIndex="tooltip"
            borderRadius="full"
            bg="green.500"
            p={3}
            boxShadow="md"
        >
            <Link href="https://api.whatsapp.com/send?phone=971585510094&text=Hi%20there%2C%20I%20would%20like%20to%20speak%20with%20someone." isExternal>
                <FaWhatsapp color="white" size="30px" />
            </Link>
        </Box>
    );
};

export default FloatingWhatsAppButton;
