import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import axios from 'axios';
import HoverImg from '../../assets/Icons/Slider/whiteAboutJourny/sliderhover.svg';
import './custome.css';

const convertParagraphToText = paragraph => {
  if (!paragraph || !paragraph.length) return '';

  return paragraph
    .map(p => {
      if (p.type === 'paragraph') {
        return p.children
          .map(child => {
            if (child.type === 'text') {
              return child.text;
            }
            return '';
          })
          .join('');
      }
      return '';
    })
    .join('\n');
};

const fetchCombinedData = async () => {
  try {
    const [featuredResponse, insightResponse] = await Promise.all([
      axios.get(
        'https://cms.wearetenet.com/api/general-blogs?filters[is_featured][$eq]=yes&populate=*'
      ),
      axios.get(
        'https://cms.wearetenet.com/api/insight-blogs?filters[is_featured][$eq]=yes&populate=*'
      ),
    ]);
    const baseUrl = 'https://cms.wearetenet.com';

    const createLinkUrl = slug => `/resources/${slug}`;

    const featuredData = featuredResponse.data.data.map(item => {
      const slug = item.attributes.slug; // Assuming 'slug' is available in your data

      return {
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        linkText: item.attributes.category,
        linkUrl: createLinkUrl(slug),
      };
    });
    const insightData = insightResponse.data.data.map(item => {
      const category = item.attributes.category;
      const slug = item.attributes.slug;
      const linkUrl = `/resources/${category}/${slug}`;

      return {
        title: item.attributes.title,
        description: convertParagraphToText(item.attributes.paragraph),
        image: `${baseUrl}${item.attributes.image.data.attributes.url}`,
        linkText: item.attributes.category,
        linkUrl,
      };
    });

    return [...featuredData, ...insightData];
  } catch (error) {
    console.error('Error fetching data', error);
    return [];
  }
};
// Sample data array
// const slidesData = [
//   {
//     // image: ImageOne,
//     title:
//       'Ask Tenet: How to automate the our everyday task as a tech specialist.',
//     description:
//       'There’s something very powerful about working in interdisciplinary teams to build and evolve digital products. This is the way we work at',
//     linkText: 'Build',
//     linkUrl: '#',
//   },
//   {
//     image: ImageTwo,
//     title: 'Another interesting story',
//     description: 'Here’s another description for a different story.',
//     linkText: 'Learn',
//     linkUrl: '#',
//   },
//   {
//     image: ImageTwo,
//     title: 'Another interesting story',
//     description: 'Here’s another description for a different story.',
//     linkText: 'Learn',
//     linkUrl: '#',
//   },
//   // Add more slides as needed
// ];

const FeaturedStory = () => {
  const [slidesData, setSlidesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchCombinedData();
        if (data.length > 0) {
          setSlidesData(data);
        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const getCategoryBgColor = category => {
    switch (category) {
      case 'thoughts':
        return '#0057FF';
      case 'insights':
        return '#E0005E';
      case 'guides':
        return '#00763D';
      case 'glossary':
        return '#9B4DFF';
      case 'updates':
        return '#0036C2';
      case 'listen':
        return '#FF813A';
      default:
        return '#0057ff';
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Box
      backgroundColor={'#CCF1DF'}
      paddingBottom={['54px', '54px', '54px', '80px', '80px', '80px']}
      overflow={'hidden'}
      position={'relative'}
    >
      {/* Heading Container */}
      <Box maxW={['90%']} margin={'auto'}>
        <Box marginBottom={['30px', '30px', '30px', '60px', '60px', '60px']}>
          <Heading
            as={'h2'}
            fontSize={['32px', '32px', '32px', '48px', '48px', '48px']}
            color={'#000'}
            fontWeight={400}
            fontFamily={'var(--garnett-regular);'}
          >
            Featured
          </Heading>
        </Box>
      </Box>
      {/* Slider Container */}
      <Box
        maxW={['90%']}
        margin={'0 auto'}
        className="featuredStory"
        _hover={{ cursor: `url(${HoverImg}), auto` }}
      >
        <Swiper
          speed={750}
          slidesPerView={'1.4'}
          spaceBetween={75}
          breakpoints={{
            250: { spaceBetween: 68, slidesPerView: '1.3' },
            392: { spaceBetween: 68, slidesPerView: '1.6' },
            562: { spaceBetween: 68, slidesPerView: '2.2' },
            992: { spaceBetween: 68, slidesPerView: '1.4' },
            1192: { spaceBetween: 75, slidesPerView: '1.4' },
            1280: { spaceBetween: 75, slidesPerView: '1.4' },
            1536: { spaceBetween: 75, slidesPerView: '1.4' },
          }}
          style={{ overflow: 'visible' }}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide key={index}>
              <Link
                href={slide.linkUrl}
                style={{ textDecoration: 'none', color: 'inherit' }}
                _hover={{ cursor: `url(${HoverImg}), auto` }}
              >
                <Box
                  display={[
                    'block',
                    'block',
                    'block',
                    'flex!important',
                    'flex!important',
                    'flex!important',
                  ]}
                  gap={['50px']}
                >
                  {/* Image Box */}
                  <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                    <Image
                      w={'100%'}
                      height={[
                        '186px',
                        '186px',
                        '286px',
                        '415px',
                        '415px',
                        '415px',
                      ]}
                      objectFit={'cover'}
                      src={slide.image}
                    />
                  </Box>
                  {/* Content Box */}
                  <Box w={['100%', '100%', '100%', '50%', '50%', '50%']}>
                    <Box
                      display={[
                        'flex',
                        'flex',
                        'flex',
                        'block',
                        'block',
                        'block',
                      ]}
                      flexDirection={[
                        'column-reverse',
                        'column-reverse',
                        'column-reverse',
                        'column',
                        'column',
                        'column',
                      ]}
                    >
                      <Box>
                        <Heading
                          as={'h3'}
                          color={'#333'}
                          fontSize={[
                            '18px',
                            '18px',
                            '18px',
                            '36px',
                            '36px',
                            '36px',
                          ]}
                          fontWeight={400}
                          lineHeight={'normal'}
                          fontFamily={'var(--garnett-regular);'}
                          padding={[
                            '18px 0',
                            '18px 0',
                            '18px 0',
                            '0',
                            '0',
                            '0',
                          ]}
                          _hover={{ cursor: `url(${HoverImg}), auto` }}
                        >
                          {slide.title}
                        </Heading>
                        <Text
                          as={'p'}
                          color={'#333'}
                          fontSize={[
                            '14px',
                            '14px',
                            '14px',
                            '20px',
                            '20px',
                            '20px',
                          ]}
                          fontWeight={400}
                          noOfLines={4}
                          lineHeight={'normal'}
                          fontFamily={'var(--foundersGrotesk-regular);'}
                          margin={['0', '0', '0', '18px 0', '18px 0', '18px 0']}
                          _hover={{ cursor: `url(${HoverImg}), auto` }}
                        >
                          {slide.description}
                        </Text>
                      </Box>
                      {/* Category Button */}
                      <Box mt={['9px', '9px', '9px', '0px', '0px', '0px']}>
                        <Text
                          display={'inline-flex'}
                          fontSize={['16px']}
                          color={['#fff']}
                          fontWeight={400}
                          lineHeight={'normal'}
                          fontFamily={'var(--foundersGrotesk-regular);'}
                          padding={['5px 7px 0px;']}
                          textTransform={['capitalize']}
                          backgroundColor={getCategoryBgColor(slide.linkText)}
                          cursor={`url(${HoverImg}), auto`}
                          height={['26px']}
                          alignItems={'center'}
                        >
                          {slide.linkText}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default FeaturedStory;
