import { Box, Container, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const extractText = (description) => {
  return description
    .map(paragraph =>
      paragraph.children.map(child =>
        child.text
      ).join('')
    ).join('\n');
};

const Introduction = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://cms.wearetenet.com/api/case-studies?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length > 0) {
          const blogData = response.data.data[0];
          setData(blogData);

        } else {
          setError('No data found');
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const firstItem = data ? data.attributes : null;
  const introductionDescription = firstItem?.introduction_description || [];
  const descriptionText = extractText(introductionDescription);

  const introductionText = [
    {
      title: 'INTRODUCTION',
      paragraph:
        'ThinkVastu is a India’s leading platform dedicated to providing top-tier Vastu consultation from industry experts. Established to bring ancient wisdom into modern living, ThinkVastu connects individuals with personalized Vastu solutions for homes, offices, and commercial spaces.',
    },
  ];
  return (
    <>
      <Box paddingTop={['93px', '93px', '93px', '40px', '40px', '40px']}>
        <Container
          width={['90%', '90%', '90%', '80%', '72%', '72%']}
          maxW={['90%', '90%', '90%', '80%', '72%', '72%']}
          padding={['0']}
        >
          {/* Introduction Image */}
          <Box>
            <Box display={['none', 'none', 'none', 'block', 'block', 'block']}>
              <Image
                src={`https://cms.wearetenet.com${firstItem?.banner_image?.data?.attributes?.url || ''
                  }`}
                w={['100%']}
                height={'auto'}
                objectFit={'cover'}
              />
            </Box>
          </Box>
          {/* IntroDuction Title */}
          <Box>
            <Text
              as="h1"
              fontSize={['18px', '20px', '20px', '20px', '20px', '20px']}
              fontFamily="var(--foundersGrotesk-medium)"
              color={['#860038']}
              fontWeight={['500']}
              lineHeight={'normal'}
              padding={[
                '0 0 22px',
                '0 0 22px',
                '0 0 22px',
                '40px 0 50px',
                '40px 0 50px',
                '40px 0 50px',
              ]}
            >
              {introductionText[0].title}
            </Text>
            <Text
              as={'p'}
              fontSize={['16px', '16px', '16px', '32px', '32px', '32px']}
              m="0 0 23px 0"
              fontFamily="var(--garnett-regular)"
              margin={['0 auto']}
              color={['#333333']}
              fontWeight={['400']}
              lineHeight={'normal'}
            >
              {descriptionText}
            </Text>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Introduction;
